import styled from "styled-components";
import { Grid } from "@mui/material";

export const StyledBg = styled.div`
  margin-bottom: ${props => props.differentSpacing ? "24px" : "0"};
  padding: ${props => props.differentSpacing ? "28px 16px 7px" : "28px 16px 40px"};
  background: #fff;
  .card_header {
    margin: 0 0 20px 0;
    font-weight: 600;
    font-size: 20px;
    color: rgba(23, 142, 128, 1);
    font-family: Montserrat-SemiBold;
  }
  .brokerage_type_error_container {
    position: relative
  }
`

export const LOBContainerStyled = styled.div`
  background: #fff;
  padding: 0 26px 3px 0;
  margin-top: -3px;
  color: #11555F;
  font-family: Montserrat-Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  label {
    color: rgb(17, 85, 95);
    padding-left: 1px;
    font-size: ${props => props.smallFontSize ? "12px" : "14px"};
  }
  label + div {
    margin-top: 4px;
  }
`;

export const InputFrame = styled.form`
  width: 100%;
  position: relative;
  .enterBrokerageText {
    font-size: 14px
  }
`;

export const ButtonFrame = styled(Grid)`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 15px;
`;

export const StyledHeading = styled.h3`
margin-left: 0;
margin-top: 0;
margin-bottom: 10px;
font-size: 22px;
font-family: "Montserrat", sans-serif;
`

export const InputStyled = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
  padding: 5px 10px 5px 5px;
  margin-right: 14px;
  border: 0.25px solid #e4e4e4;
  background: rgba(214, 214, 214, 1);
  span {
    font-size: 12px;
  }
  input {
    padding: 5px;
    font-size: 12px;
    background: rgba(214, 214, 214, 1);
    border: 0;
    width: 100%;
    &:focus {
      border: 0;
      outline: none;
    }
    &::placeholder {
      font-size: 12px;
    }
  }
`

export const PremiumBearableStyled = styled.div`
background: rgba(238, 238, 238, 1);
margin-top: 20px;
padding: 16px 0 16px 12px;
border-radius: 4px;
.heading, .brokerageInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0;
    font-size: 16px;
  }
  svg {
    color: rgba(17, 85, 95, 1);
    margin-right: 11px;
    cursor: pointer;
  }
}
.heading > h3 {
  color: rgba(23, 142, 128, 1);
  font-weight: 500;
  letter-spacing: 0.4px;
  margin: 0 0 10px 0;
}
.brokerageInput {
  margin-top: 10px;
  flex-wrap: wrap;
}
`

export const ConditionalBrokerageStyled = styled.div`
background: rgba(238, 238, 238, 1);
margin-top: 20px;
padding: 16px 12px 16px 12px;
border-radius: 4px;
.priority_text {
  font-size: 12px;
  font-weight: 400;
  color: rgba(23, 142, 128, 1);
  letter-spacing: 0.4px;
}
.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0;
    font-size: 16px;
  }
  svg {
    color: rgba(17, 85, 95, 1);
    cursor: pointer;
  }
  h3 {
    font-size: 20px;
    color: rgba(23, 142, 128, 1);
    font-weight: 500;
    margin: 0;
  }
}
.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  svg {
    font-size: 20px;
  }
  p {
    margin: 0;
  }
  .button_action {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    padding: 6px 16px;
    background: rgba(17, 85, 95, 1);
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
  }
  & .button_action:last-of-type {
    color: rgba(17,85,95,1);
    background: #fff;
    border: .25px solid rgba(17,85,95,1);  
  }
}
`

export const BasicDetailsSummaryStyled = styled.div`
display: flex;
flex-flow: row wrap;
padding: 16px;
margin-bottom: 40px;
border-radius: 8px;
background: rgba(238, 238, 238, 0.93);
.end_date {
  margin-left: 6px;
}
& > div {
  flex: 1 0 100%;
  display: flex;
  justify-content: space-between;
  & > p{
    font-size: 12px;
    font-weight: 500;
    width: 33%;
    color: rgba(17, 85, 95, 1);
    span {
      font-weight: 600;
    }
  }
}
& > div:last-of-type {
  justify-content: flex-start;
}
`

export const BasicDetailsEditHeader = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
   & > p {
    font-family: Montserrat-SemiBold;
    width: 50% !important;
   }
  p {
    margin: 4px 0 10px 0;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    color: rgba(23, 142, 128, 1);
  }
`

export const BasicDetailsEditStyled = styled.div`
display: flex;
padding: 16px;
flex-wrap: wrap;
border-radius: 8px;
border: 1px solid #C9CDD4;
background: rgba(238, 238, 238, 0.93);
.end_date {
  margin-left: 6px;
}
& + hr {
  margin: 24px 0;
  background: rgba(217, 217, 217, 1)
}
& > div.summary_header {
  position: relative;
  width: 100%;
   & > p {
    font-family: Montserrat-SemiBold;
    font-weight: 600;
    font-size: 16px;
   }
  p {
    margin: 4px 0 10px 0;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    color: rgba(23, 142, 128, 1);
  }
  button {
    position: absolute;
    right: -13px;
    top: 0
  }
}
& > div.summary_fields {
  flex: 1 0 100%;
  display: flex;
  justify-content: space-between;
  & > p{
    font-size: 12px;
    font-weight: 500;
    width: 33%;
    color: rgba(17, 85, 95, 1);  
    span {
      font-weight: 600
    }
  }
}

& > div.summary_fields:last-of-type {
  justify-content: flex-start;
}
`

export const ErrorStyled = styled.div`
position: relative;
width: 100%;
margin-top: 18px;
display: flex;
justify-content: flex-end;
margin-right: 19px;
p {
  font-size: 10px !important;
}
`

export const TableContainerStyled = styled.div`
width: 99.9%;
border: .25px solid rgba(201, 205, 212, 1);
border-radius: 4px;
padding: 16px;
`

export const BrokerageAffectingFieldsStyled = styled.div`
margin: 40px 0;
padding: 16px;
border-radius: 8px;
border: 1px solid #178E80;
background: #CBF9E7; 
 & input[type=checkbox] {
    line-height: normal;
    width: 18px;
    height: 18px;
}
`

export const RulesStyled = styled.div`
display: flex;
flex-wrap: wrap;
padding: 16px 12px;
border-radius: 4px;
border: ${props => props.isFocused ? "5px solid rgb(23, 142, 128)" : "2px solid #C9CDD4"};
margin: 20px 0;
background: ${props => props.isDisabled ? "#EEE" : "#fff"};
background: ${props => !props.isFocused && "#EEE"};
.rules_header {
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    margin: 0;
    color: #178E80;
    font-family: 'Montserrat-SemiBold';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.15px; 
  }
  button {
    pointer-events: ${props => (props.isDisabled || !props.isFocused) && "none"};
  }
}
& > div:nth-of-type(2) {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  div.select_fields { 
    flex-shrink: 0;
    display: flex;
    justify-content: ${props => props.isDisabled ? "center" : "flex-start"};
    align-items:  ${props => props.isDisabled ? "center" : "flex-start"};
    width: 100%;
    height: 128px;
    padding: 16px 12px;
    border-radius: 8px;
    border: 2px dashed #178E80; 
    margin-bottom: 24px;
    & > p {
      color: #11555F;
      font-size: 16px;
      font-weight: 500;
    }
    div.premium_affecting_fields {
      cursor: pointer;
      display: flex;
      gap: 10px;
      padding: 4px 10px;
      border-radius: 4px;
      border: 1px solid #757575;
      background: #E0E0E0; 
      margin: 0 10px;
      svg {
        font-size: 16px;
      }
      p {
        margin: 0;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  div.btn_actions {
    margin-top: 10px;
    margin-right: 15px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    & > button:first-of-type {
      pointer-events: ${props => (props.isDisabled || !props.isFocused) && "none"};
    }
    & > div {
      display: flex;
      align-items: center;
    }
  }
  div.brokerage_options {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div.payout_container{
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      width: 100%;
    } 
    div.payout {
      display: flex;
      align-items: center;
      margin-left: 25px;
      width: 42%;
      gap: 28px;
        div.error_container {
          position: relative;
          p {
            padding: 0;
            font-size: 10px;
          }
      }
      p {
        margin: 0 20px 0 10px;
        font-size: 14px;
        font-weight: 500;
        margin-left: auto
      }
      p:nth-of-type(2) {
        background-color: rgba(0, 0, 0, 0.3);
        padding: 7px 16px !important;
      }
      input {
        padding:8px; 
      }
      p:last-of-type {
        padding: 4px 16px; 
      }
    }
  }
}
`

export const ExcelUploadStyled = styled.div`
padding: 16px;
p {
  margin: 0;
}
.btn_action_container {
  display: flex;
  gap: 20px;
  button:first-of-type {
    margin-right: auto;
  }
}
.file_error {
  color: red;
  margin-bottom: 15px;
}
.preview {    
  position: relative;
  width: fit-content;
  border: 0.25px solid rgb(23, 142, 128);
  border-radius: 5px;
  margin-bottom: 15px;
  padding: 10px;
  .remove_file {
    font-size: 15px;
    position: absolute;
    top: -19%;
    left: 94%;
    color: rgb(23, 142, 128);
    border: .25px solid rgb(23, 142, 128);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    text-align: center;
    background: #fff;
    cursor: pointer;
  }
  & > div {
    display: flex;
    align-items: center;
    gap: 20px;
    img {
      width: 40px;
      height: 40px;
    }
    .file_name {
      font-weight: 600;
    }
  }
}
`

export const FileDropAreaStyled = styled.div`
& > div {
  cursor: pointer;
}
margin: 24px 0;
height: 164px;
.fileDrop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
svg {
  margin-bottom: 16px;
}
.file_drop_text {
  margin: 0;
  font-weight: 500;
  b {
    color: #2BC5DA;
    text-decoration: underline;
  }
}
`


export const SelectedFileStyled = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0 0 17px;
  p {
    margin: 0;
  }
  & > p {
    padding: 10px 10px 10px 0;
    font-size: 14px;
    font-weight: 600;
  }
`;

export const FileStyled = styled.div`
  border-radius: 10px;
  border: 0.25px solid #e4e4e4;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  & > div {
    display: flex;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
    p {
      margin-left: 10px;
      text-transform: capitalize;
      font-size: 12px;
      letter-spacing: 0.75px;
    }
  }
  svg {
    cursor: pointer;
    font-size: 20px;
    margin-left: 50px;
    color: #000;
  }
`;

export const ReaarangeContainerStyled = styled.div`
margin: 24px 16px;
background: #e4e4e4;
border: 1px solid #1F1F1F
`

export const RearrangeStyled = styled.div`
display: flex;
align-items: center;
padding: 10px 0;
border-bottom: .25px solid #757575;
font-size: 14px;
p{
  margin: 0;
}
& > * {
  flex-grow: 1;
}
.title {
  flex-grow: 12
}
& > *:not(& > svg) {
  border-left: solid #757575;
  padding-left: 10px
}
p:last-of-type {
  color: rgba(17, 85, 95, 1);
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}
`

export const RulesDeviationStyled = styled.div`
padding: 16px;
& > div.fields {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  background: #FAFAFA; 
  border-radius: 4px;
border: 1px solid #C9CDD4; 
  p {
    margin: 0;
    font-size: 14px;
    padding: 4px 10px; 
    border-radius: 4px;
    border: solid #757575;
    background: #E0E0E0; 
  }
}
`