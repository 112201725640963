import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import PNGImage from "../../../utils/png.png";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Controller } from "react-hook-form";

function CustomerFilePicker({
  files,
  setFiles,
  name,
  control,
  trigger,
  setValue,
}) {
  const [error, setError] = useState(false);
  const maxSize = 2 * 1024 * 1024;
  const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      setError(true);
    } else {
      const base64Promises = acceptedFiles.map(
        (file) =>
          new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = () => {
              const base64String = reader.result.split(",")[1];
              resolve({ file, base64String });
            };
            reader.readAsDataURL(file);
          })
      );

      const base64Files = await Promise.all(base64Promises);
      setValue("files", acceptedFiles);
      setValue("base64Files", base64Files);
      trigger("files");
      setFiles(
        acceptedFiles.map((file) => {
          return [].concat(file);
        })
      );
      setError(false);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png", "image/jpeg"],
    },
    maxSize,
  });

  const removeSelection = (file) => {
    if (Array.isArray(file)) {
      const newFiles = [...files];
      newFiles.splice(newFiles.indexOf(file), 1);
      setFiles(newFiles);
    } else {
      setFiles([]);
    }
  };
  return (
    <>
      <FilePickerContainerStyled>
        <div>
          <Controller
            name={name}
            control={control}
            render={() => (
              <div
                {...getRootProps()}
                id="customerFilePicker"
                className="file-picker"
              >
                <input {...getInputProps()} />
                <FileUploadOutlinedIcon />
                <div>
                  <p className="upload-img">
                    <b>Browse Image</b>
                  </p>
                  <p className="warnings">
                    File Formats: (.png .jpeg) - Max File Size: 1MB
                  </p>
                  {error && <p className="warnings">File size too large</p>}
                </div>
              </div>
            )}
          />
        </div>
        <div>
          {files[0] instanceof File
            ? files.length > 0 && (
              <SelectedFileStyled>
                <p>Uploaded Image :</p>
                <FileStyled>
                  <div>
                    <img src={PNGImage} alt="file logo" />
                  </div>
                  <CloseOutlinedIcon onClick={() => removeSelection(files)} />
                </FileStyled>
              </SelectedFileStyled>
            )
            : files.length > 0 && (
              <SelectedFileStyled>
                <p>Uploaded Image :</p>
                {files.length > 0 &&
                  files.flat(2).map((file) => {
                    const fileName = file.path.slice(
                      0,
                      file.path.indexOf(".")
                    );
                    const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(
                      2
                    );
                    return (
                      <FileStyled>
                        <div>
                          <img src={PNGImage} alt="file logo" />
                          <p>{fileName}</p>
                          <p>{fileSizeInMB} MB</p>
                        </div>
                        <CloseOutlinedIcon
                          onClick={() => removeSelection(file)}
                        />
                      </FileStyled>
                    );
                  })}
              </SelectedFileStyled>
            )}
        </div>
      </FilePickerContainerStyled>
    </>
  );
}

export default CustomerFilePicker;

const HeadingStyled = styled.h3`
  margin: 0 0 5px 14px;
  color: rgb(17, 85, 95);
  font-size: 14px;
  font-weight: 500;
  span {
    color: red;
    position: relative;
    left: 5px;
    top: -9px;
    font-size: 12px;
  }
`;

const FilePickerContainerStyled = styled.div`
  display: flex;
  gap: 20px;
  margin-left: 20px;
  & > div:first-of-type {
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 2px dashed rgb(228, 228, 228);
    padding: 15px;
    & > div > p {
      font-weight: 600;
      font-size: 15px;
      margin: 0 0 0 20px;
    }
    .warnings {
      font-weight: 500;
      margin: 0px 0px 0px 0px;
      font-size: 12px;
      color: red;
    }
  }
  svg {
    font-size: 1.9rem;
  }
  b {
    color: rgb(23, 142, 128);
    text-decoration: underline;
  }

  .upload-img {
    margin: 0;
  }

  .file-picker {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
`;

const SelectedFileStyled = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 8px 0px 8px p {
    margin: 0;
  }
  & > p {
    padding: 10px;
    font-family: Montserrat-Medium;
    color: rgb(17, 85, 95);
    line-height: 18px;
    font-size: 13px;
    font-weight: 500;
  }
`;

const FileStyled = styled.div`
  border-radius: 10px;
  border: 0.25px solid #e4e4e4;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div {
    display: flex;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
    p {
      margin: 5px;
      text-transform: capitalize;
      font-size: 12px;
      letter-spacing: 0.75px;
    }
  }
  svg {
    cursor: pointer;
    font-size: 20px;
    margin-left: 50px;
    color: #000;
  }
`;
