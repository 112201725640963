import React from "react";
import styled from "styled-components";
import { Grid } from "@mui/material";
import { Typography, TileCheckbox } from "../../../components";
const { StyledH2 } = Typography;

const ConfigurationDetails = () => {
  const Data = (key, value) => {
    return (
      <div
        style={{
          display: "flex",
          overflow: "hidden",
          fontSize: "15px",
          textOverflow: "ellipsis",
          marginTop : "-15px",
          paddingLeft : "46px",
          height : "30px"
        }}
      >
        <div>{`${key} : `}</div>
        <SpaceDiv
        >{value}</SpaceDiv>
      </div>
    );
  };

  //Required Typography
  return (
    <>
      <InputFrame>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 3, md: 3 }}>
          <HeaderWall>
            <StyledGrid item xs={12} md={12} sm={12} lg={12}>
              <StyledH2  fontSize={"19px"}>
                {" "}
                Dashboard Configuation Details
              </StyledH2>
            </StyledGrid>
          </HeaderWall>
        </Grid>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 3, md: 3 }}>
          <StyledGrid item xs={2} md={2} sm={2} lg={2}>
            <StyledH2  border style={{ fontSize: "12px;" }}>
              {Data("Broker ID", "13282")}
            </StyledH2>
          </StyledGrid>
          <StyledGrid  item xs={4} md={4} sm={4} lg={4}>
            <Divider>
              <StyledH2>
              {Data("Broker Name", "Alliance Insurance Broker")}
            </StyledH2>
            </Divider>
          </StyledGrid>
          <StyledGrid  item xs={4} md={4} sm={4} lg={4}>
            <StyledH2>{Data("Broker Short Name ", "13282")}</StyledH2>
          </StyledGrid>
        </Grid>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {[...Array(3)].map((item) => (
            <StyledGrid item xs={10} md={3} sm={10} lg={3}>
              <TileCheckbox />
            </StyledGrid>
          ))}
        </Grid>
      </InputFrame>
    </>
  );
};

const SpaceDiv = styled.div`
   margin-left : 5px;

`;

const InputFrame = styled.form`
  width: calc(100% -65px);
  padding-left: 40px;
  margin-top: 30px;
`;

const StyledGrid = styled(Grid)`
  // position: relative;

  ${({ border }) =>
    border
      ? `border-left: 0.5px solid grey; border-right: 0.5px solid green`
      : ``}
      
`;

const HeaderWall = styled.div`
  width: 100%;
  margin-left : 56px;
  color : #178E80;
`;

const Divider = styled.div`
border-left: 1px solid grey;
border-right: 1px solid grey;
height : 30px;

`;

export default ConfigurationDetails;
