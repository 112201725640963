import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HttpClient from "../../api/httpClient";
import { useDispatch } from "react-redux";
import {
    setProductMasterErrorAction,
    setProductMasterSuccessAction,
} from "./product.slice";

export const useGetProductsList = () => {
    const { data, refetch, isFetching } = useQuery({
        queryKey: ["products"],
        queryFn: () =>
            HttpClient("product_master/list", {
                method: "POST",
                data: {
                    page: 0,
                    size: 0,
                    sortBy: "product_id",
                    sortOrder: "DESC",
                    searchValue: "",
                },
            }),
    });
    return { data, refetch, isFetching };
};

export const useGetProductById = (id) => {
    const { data } = useQuery({
        queryKey: ["productById", id],
        queryFn: () => HttpClient(`/product_master/list/${id}`),
        enabled: id ? true : false,
    });
    return { data };
};

export const useAddProduct = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { mutate, isLoading, isError } = useMutation(
        (payload) => {
            return HttpClient("product_master/add", {
                method: "POST",
                data: payload,
            });
        },
        {
            onSuccess: (data) => {
                if (!data.success) {
                    dispatch(setProductMasterErrorAction(data.error));
                    return;
                }
                queryClient.invalidateQueries(["products"]);
                dispatch(setProductMasterSuccessAction(data.message));
            },
        }
    );
    return { mutate, isLoading, isError };
};

export const useUpdateProduct = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { mutate, isLoading, isError } = useMutation(
        (payload) => {
            const { id, ...restPayload } = payload?.[0];
            return HttpClient(`product_master/update/${id}`, {
                method: "PUT",
                data: restPayload,
            });
        },
        {
            onSuccess: (data) => {
                if (!data.success) {
                    dispatch(setProductMasterErrorAction(data.error));
                    return;
                }
                queryClient.invalidateQueries(["products"]);
                dispatch(setProductMasterSuccessAction(data.message));
            },
        }
    );
    return { mutate, isLoading, isError };
};

export const useDeleteProduct = () => {
    const queryClient = useQueryClient();
    const { mutate, isLoading, isError } = useMutation(
        (id) => {
            return HttpClient(`product_master/delete/${id}`, { method: "DELETE" });
        },
        {
            onSuccess: (data) => {
                if (!data.success) {
                    return;
                }
                queryClient.invalidateQueries(["products"]);
            },
        }
    );
    return { mutate, isLoading, isError };
};
