import { useState } from "react";
import styled from "styled-components";
import { Typography } from "../../../../components";
import GlobalModal from "../../../../components/Modal/modal";
import {
  useGetCustomerDocumentList,
  useGetCustomerDocumentListById,
} from "../../service";
import useCustomer from "../../useCustomer";
import CustomerDocuments from "../customerDocuments";
import CustomerDocumentTable from "./customerTable";

const { StyledH1 } = Typography;
const CustomerDocumentListing = () => {
  const {
    modalTitle,
    open,
    setModalTitle,
    setOpen,
    handleClose,
    rowData,
    setRowData,
    docDataForUploadDocumentsOBJ,
    updateAdditionalDocDetails,
  } = useCustomer();
  const [id, setId] = useState();

  const { data: customerDocListing } = useGetCustomerDocumentList();
  const { data: customerDocListingByid } = useGetCustomerDocumentListById(id);

  const rows = Array.isArray(customerDocListing?.data?.data)
    ? customerDocListing?.data?.data?.map((item) => {
        return {
          id: item?.customerAdditionalDocumentId,
          customerId: item?.customerId,
          name: item ? item?.docPath.split("/").pop() : null,
          docName: item ? item?.docPath.split("/").pop() : null,
        };
      })
    : [];

  return (
    <>
      <StyledBg>
        <StyledH1
          themeType="theme1"
          partialU
          color={"rgb(23, 142, 128)"}
          align={"left"}
          style={{ marginBottom: "0" }}
        >
          Customer Document Listing
        </StyledH1>
      </StyledBg>
      <TableFrame>
        <CustomerDocumentTable
          rows={rows}
          setId={setId}
          setModalTitle={setModalTitle}
          setOpen={setOpen}
          setRowData={setRowData}
        />
      </TableFrame>
      <GlobalModal
        open={open}
        title={modalTitle + " Customer"}
        onClose={handleClose}
        overflowVisible={true}
      >
        <CustomerDocuments
          modalTitle={modalTitle}
          rowData={rowData}
          updateAdditionalDocDetails={updateAdditionalDocDetails}
          docDataForUploadDocumentsOBJ={docDataForUploadDocumentsOBJ}
          customerDocListingByid={customerDocListingByid}
        />
      </GlobalModal>
    </>
  );
};

export default CustomerDocumentListing;

const StyledBg = styled.div`
  background: #fff;
  padding: 24px 16px;
  margin-right: 7px;
`;

const TableFrame = styled.div`
  display: flex;
  position: relative;
  align-items: ${({ position }) => (position ? "start" : "center")};
  flex-direction: column;
  font-family: "Nunito";
  box-shadow: 0px 22px 45px -15px #dddde2;
  background-color: #ffffff;
  margin-top: 15px;
  padding: 20px;
  max-width: 99.5% !important;
`;
