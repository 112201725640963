import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import HttpClient from "../../api/httpClient";
import {
  setDocumentErrorAction,
  setDocumentSuccessAction,
} from "./doc-config.slice";
import { useDispatch } from "react-redux";

// Document Config CRUD Operation
export const useGetDocumentData = (payload) => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: ["Document_Data"],
    queryFn: () =>
      HttpClient("document_management/list", {
        method: "POST",
        data: payload,
      }),
  });

  return { data, isFetching, refetch };
};

export const useCreateDocumentData = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (payload) => {
      return HttpClient(`document_management/add`, {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        if (!data.success) {
          dispatch(setDocumentErrorAction(data.error));
          return;
        }
        queryClient.invalidateQueries(["Document_Data"]);
        dispatch(setDocumentSuccessAction(data.message));
      },
    }
  );

  return { mutate };
};

export const useUpdateDocumentData = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (payload) => {
      return HttpClient(
        `document_management/update/${payload.transactionTypeId}`,
        {
          method: "PUT",
          data: payload,
        }
      );
    },
    {
      onSuccess: (data) => {
        if (!data.success) {
          dispatch(setDocumentErrorAction(data.error));
          return;
        }
        queryClient.invalidateQueries(["Document_Data"]);
        dispatch(setDocumentSuccessAction(data.message));
      },
    }
  );
  return { mutate };
};

export const useDeleteDocumentData = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (id) =>
      HttpClient(`document_management/delete/${id}`, {
        method: "DELETE",
      }),

    {
      onSuccess: () => {
        queryClient.invalidateQueries(["Document_Data"]);
      },
    }
  );
  return { mutate };
};

export const useGetDocumentsFormat = () => {
  const { data } = useQuery({
    queryKey: ["doc_format"],
    queryFn: () => HttpClient("document/list"),
  });

  return { data };
};
