import styled from "styled-components"
import Skeleton from "@mui/material/Skeleton"
import Grid from "@mui/material/Grid"

function SkeletonLoader({ arraySize = 1, isNormalForm = false }) {
    const loaderFields = Array(arraySize).fill(null)
    return (
        <SkeletonLoaderStyled isNormalForm={isNormalForm}>
            {!isNormalForm ? <Skeleton variant="text" animation={false} width={250} height={60} /> : <></>}
            <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ margin: "15px 0" }}
            >
                {loaderFields.map(() => {
                    return (
                        <Grid item lg={3}>
                            <Skeleton variant="text" sx={{ marginBottom: "-8px" }} width={120} height={20} />
                            <Skeleton variant="text" height={60} />
                        </Grid>
                    )
                })}
            </Grid>
            {!isNormalForm ? <Skeleton variant="text" animation={false} width={150} height={60} /> : <></>}
        </SkeletonLoaderStyled>
    )
}

export default SkeletonLoader

const SkeletonLoaderStyled = styled.div`
padding: ${props => props.isNormalForm ? 0 : "40px 50px"};
`