import { createSlice } from "@reduxjs/toolkit";
import { serializeError } from "../../utils";

const MasterConfigSlice = createSlice({
    name: "MasterConfigSlice",
    initialState: {
        masterConfigError: null,
        masterConfigSuccess: null,
    },
    reducers: {
        setMasterConfigSuccessAction: (state, { payload }) => {
            state.masterConfigSuccess = payload;
            state.masterConfigError = null;
        },
        setMasterConfigErrorAction: (state, { payload }) => {
            state.masterConfigError = serializeError(payload);
            state.masterConfigSuccess = null;
        },
    },
});

export const { setMasterConfigSuccessAction, setMasterConfigErrorAction } =
    MasterConfigSlice.actions;
export default MasterConfigSlice.reducer;