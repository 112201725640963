import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import { Button, Typography } from "../../../components";
import Dropdown from "../../../components/Dropdown/Dropdown";
import AdditionalDocFilePicker from "./AdditionalDocFilePicker";

const CustomerDocuments = ({
  modalTitle,
  getAdditionalDocDetails,
  updateAdditionalDocDetails,
  rowData,
  docDataForUploadDocumentsOBJ,
  customerDocListingByid,
  setRowData = () => {},
}) => {
  const { Error } = Typography;
  const [mergedSchema, setMergedSchema] = useState(null);
  const yupValidate = yup.object().shape({});

  const {
    control,
    setValue,
    trigger,
    handleSubmit,
    watch,
    reset,
    getValues,
    formState: { errors },
    unregister,
  } = useForm({
    resolver: yupResolver(mergedSchema),
    defaultValues: { additionalFile: [{ files: null }] },
    mode: "all",
    reValidateMode: "onChange",
  });

  const [activeStep, setActiveStep] = useState([0]);
  const { document, additionalFile } = watch();
  const values = getValues();

  const dynamicYupValidate = yup.object().shape({
    document: yup.array().of(
      yup.object().shape({
        docType: yup.mixed().required("Select a value"),
      })
    ),
    additionalFile: yup.array().of(
      yup.object().shape({
        files: yup.mixed().required("Please upload a file"),
      })
    ),
  });

  useMemo(() => {
    const mergedSchema = dynamicYupValidate
      ? yupValidate.concat(dynamicYupValidate)
      : yupValidate;
    setMergedSchema(mergedSchema);
  }, [document, additionalFile]);

  useEffect(() => {
    if (modalTitle === "Add Documents For") {
      reset({
        additionalFile: null,
        document: null,
      });
    }
  }, [modalTitle, reset]);

  useMemo(() => {
    if (document) {
      activeStep.forEach((value) => {
        setValue(
          `document.${value}.docType`,
          document[value]?.docType
            ? {
                value: document[value]?.docType?.value,
                label: document[value]?.docType?.label,
                id: document[value]?.docType?.id,
              }
            : null
        );
        setValue(
          `additionalFile.${value}.files`,
          additionalFile[value]?.files ? additionalFile[value]?.files : null
        );
        // setValue(
        //   `additionalFile.${value}.files`,
        //   additionalFile[value]?.files ? additionalFile[value]?.files : null
        // );
      });
    }
  }, [activeStep.length]);

  useEffect(() => {
    if (customerDocListingByid) {
      const customerData = customerDocListingByid?.data?.data;
      const arrayLength = customerData?.length;
      if (arrayLength !== undefined) {
        const newArray = Array.from(Array(arrayLength).keys());
        setActiveStep(newArray);
      }
    }
  }, [customerDocListingByid]);

  const addAdditionalDocs = (formData) => {
    const payload = activeStep.map((index) => ({
      documentManagementConfigId: values?.document[index]?.docType?.id || "",
      docPath: values?.additionalFile?.[index]?.files?.name || "",
      data: values?.additionalFile?.[index]?.files?.base64 || "",
      customerId: rowData?.id,
    }));
    getAdditionalDocDetails(payload);
  };
  const updateAdditionalDocs = (formData) => {
    const payload = activeStep.map((index) => ({
      customerAdditionalDocumentId:
        values?.customerAdditionalDocumentId[index]?.id || "",
      documentManagementConfigId: values?.document[index]?.docType?.id || "",
      docPath: values?.additionalFile?.[index]?.files?.name || "",
      data: values?.additionalFile?.[index]?.files?.base64 || "",
      customerId: rowData?.id,
    }));
    updateAdditionalDocDetails(payload);
  };

  const handleActiveStepCard = async () => {
    const validationPassed = await trigger();
    if (validationPassed) {
      setActiveStep((prevSteps) => [...prevSteps, prevSteps.length]);
    }
  };

  const removeActiveStepCard = (indexToRemove) => {
    setActiveStep((prevSteps) =>
      prevSteps
        .filter((_, index) => index !== indexToRemove)
        .map((step) => (step > indexToRemove ? step - 1 : step))
    );
    const setFiles = additionalFile?.filter(
      (_, index) => index !== indexToRemove
    );
    const setdocument = document?.filter((_, index) => index !== indexToRemove);

    setValue(`document`, setdocument);
    setValue("additionalFile", setFiles);
  };
  useEffect(() => {
    customerDocListingByid &&
      customerDocListingByid?.data?.data?.forEach(
        (customerDocListingByidData, i) => {
          setValue(`document.${i}.docType`, {
            value: customerDocListingByidData?.documentName,
            label: customerDocListingByidData?.documentName,
            id: customerDocListingByidData?.documentManagementConfigId,
          });
          setValue(
            `additionalFile.${i}.files`,
            customerDocListingByidData?.docPath
          );
          setValue(
            `customerAdditionalDocumentId.${i}.id`,
            customerDocListingByidData?.customerAdditionalDocumentId
          );
        }
      );
  }, [customerDocListingByid, setValue]);

  return (
    <form
      onSubmit={handleSubmit(
        modalTitle !== "Modify Documents For"
          ? addAdditionalDocs
          : updateAdditionalDocs
      )}
      style={{ position: "relative" }}
    >
      <Grid
        container
        sx={{
          overflow: activeStep.length >= 3 ? "auto" : "visible",
          maxHeight: "450px",
        }}
      >
        {activeStep.length === 0 ||
          activeStep.map((activeTab) => {
            return (
              <>
                <Grid
                  container
                  sx={{
                    padding: "10px 5px 10px 20px",
                    position: "relative",
                    borderBottom:
                      activeTab + 1 < document?.length
                        ? "1px solid #e4e4e4"
                        : "none",
                  }}
                  key={activeTab}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    lg={4}
                    sx={{ position: "relative" }}
                  >
                    <Dropdown
                      id={`document.${activeTab}.docType`}
                      label="Document Type"
                      control={control}
                      unregister={unregister}
                      selectObj={docDataForUploadDocumentsOBJ}
                      isMulti={false}
                      maxMenuHeight={200}
                      proposalRead={
                        modalTitle === "View Documents For" ? true : false
                      }
                    />
                    {!!errors?.document && (
                      <Error top="7px">
                        {errors.document[activeTab]?.docType?.message}
                      </Error>
                    )}
                  </Grid>
                  {document?.[activeTab]?.docType?.value && (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={12}
                        sx={{ position: "relative" }}
                      >
                        <AdditionalDocFilePicker
                          name={`additionalFile.${activeTab}.files`}
                          mainArrayIndex={activeTab}
                          control={control}
                          docType={"docType"}
                          watch={watch}
                          getValues={getValues}
                          additionalFile={additionalFile}
                          document={document}
                          unregister={unregister}
                          setValue={setValue}
                          trigger={trigger}
                          isReadOnly={
                            modalTitle === "View Documents For" ? true : false
                          }
                        />
                        {!!errors?.additionalFile && (
                          <Error top="7px" style={{ position: "relative" }}>
                            {errors.additionalFile[activeTab]?.files?.message}
                          </Error>
                        )}
                      </Grid>
                    </>
                  )}
                  {modalTitle !== "View Documents For" ? (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      sx={{
                        display: "flex",
                        gap: "5px",
                        justifyContent: "end",
                        position: "absolute",
                        right: "20px",
                        top: "31px",
                      }}
                    >
                      {activeTab !== 0 && (
                        <Button
                          btnBack={"secondary"}
                          bgColor={"#11555F"}
                          themeType={"theme1"}
                          onClick={() => removeActiveStepCard(activeTab)}
                        >
                          - Remove
                        </Button>
                      )}
                      {activeTab + 1 === document?.length && (
                        <Button
                          btnBack={"secondary"}
                          bgColor={"#11555F"}
                          themeType={"theme1"}
                          onClick={() => handleActiveStepCard()}
                        >
                          + Add
                        </Button>
                      )}
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
              </>
            );
          })}
      </Grid>
      {modalTitle !== "View Documents For" ? (
        <ButtonFrame modalTitle={modalTitle}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <NextButtonWrap>
              <Button
                type="submit"
                btnBack={"secondary"}
                bgColor={"#11555F"}
                themeType={"theme1"}
              >
                {modalTitle === "Modify Documents For" ? "Update" : "Add"}
              </Button>
            </NextButtonWrap>
          </Grid>
        </ButtonFrame>
      ) : (
        <></>
      )}
    </form>
  );
};

export default CustomerDocuments;

const NextButtonWrap = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
`;

const ButtonFrame = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
  margin-top: 0;
  background: #fff;
  border-top: 1px solid #ccc;
  z-index: 10;
  padding: 12px 18px 12px 0px;
`;
