import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HttpClient from "../../api/httpClient";
import { useDispatch } from "react-redux";
import {
  setBrokerageErrorAction,
  setBrokerageSuccessAction,
} from "./Brokerage.slice";

const useCreateBrokerage = () => {
  const dispatch = useDispatch();
  const { mutate } = useMutation(
    (payload) => {
      return HttpClient("brokerage_Configurator/add", {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        if (!data?.data?.status) {
          dispatch(setBrokerageErrorAction(data?.data?.message));
          return;
        }
        dispatch(setBrokerageSuccessAction(data?.data?.message));
      },
    }
  );
  return { mutate };
};

const useBrokerageCalculator = () => {
  const { data, mutate } = useMutation((payload) => {
    return HttpClient("/brokerage_Configurator/Calculator", {
      method: "POST",
      data: payload,
    });
  });
  return { data, mutate };
};

const useBrokerageData = () => {
  const { data } = useQuery({
    queryKey: ["brokerageConfig"],
    queryFn: () => HttpClient("brokerage_Configurator/list"),
  });
  return { data };
};

const useDownloadMaster = () => {
  const { data, mutate } = useMutation((payload) => {
    const { lobId, ...restPayload } = payload;
    return HttpClient(`brokerage_Configurator/download?lobId=${lobId}`, {
      method: "POST",
      data: restPayload,
      responseType: "blob",
    });
  });
  return { data, mutate };
};

const useDeleteBrokerageData = () => {
  const queryClient = useQueryClient();

  const deleteBrokerageData = async (id) => {
    await HttpClient(`/brokerage_Configurator/delete/${id}`, {
      method: "DELETE",
    });
    queryClient.invalidateQueries("brokerageData");
  };

  const { mutate } = useMutation(deleteBrokerageData, {
    onSuccess: (data) => {
      return data;
    },
  });

  return { mutate };
};

export {
  useBrokerageCalculator,
  useBrokerageData,
  useCreateBrokerage,
  useDownloadMaster,
  useDeleteBrokerageData,
};
