import styled from "styled-components"

function NoData({ customMsg }) {
    return (
        <NoDataStyled>{customMsg ? customMsg : "There are no records to display."}</NoDataStyled>
    )
}

export default NoData

export const NoDataStyled = styled.div`
display: flex;
justify-content: center;
align-items: center;
font-weight: 600;
font-size: 1.25rem;
padding: 25px 0;
background: #fff;
font-family: Montserrat-SemiBold;
`