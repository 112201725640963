import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Typography } from "../../components";
import { NomenclatureForm } from "./form/nomenclature-form";
import { NomenclatureTable } from "./nomenClatureTable";
import { SearchForm } from "./form/SearchForm";
import GlobalModal from "../../components/Modal/modal";

export const Nomenclature = () => {
  //Required Typography
  const { StyledH1 } = Typography;
  const [isOpen, setOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [preFillData, setPrefillData] = useState();
  const [nomenclatureId, setnomenClatureId] = useState("");
  const [searchData, setSearchData] = useState({
    page: 0,
    size: 0,
    sortBy: "brokerOfficeNomenclaturesid",
    sortOrder: "DESC",
    searchValue: "",
  });

  return (
    <StyledDiv>
      <StyledBg>
        <StyledH1
          themeType="theme1"
          partialU
          color={"rgb(23, 142, 128)"}
          align={"left"}
          style={{ marginBottom: "12px" }}
        >
          Office Nomenclature
        </StyledH1>
        <SearchForm searchData={searchData} setSearchData={setSearchData} />
      </StyledBg>
      <TableFrame>
        <NomenclatureTable
          setPrefillData={setPrefillData}
          setModalTitle={setModalTitle}
          setOpen={setOpen}
          setnomenClatureId={setnomenClatureId}
          searchData={searchData}
        />
      </TableFrame>
      {
        <GlobalModal
          open={isOpen}
          title={modalTitle + " Nomenclature"}
          width="750"
          onClose={() => {
            setOpen(false);
          }}
        >
          <NomenclatureForm
            nomenclatureId={nomenclatureId}
            modalTitle={modalTitle}
            handleClose={() => {
              setOpen(false);
            }}
          />
        </GlobalModal>
      }
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
`;
const StyledBg = styled.div`
  background: #ffffff;
  padding: 24px 16px;
  margin-right: 7px;
`;

const TableFrame = styled.div`
  display: flex;
  position: relative;
  align-items: ${({ position }) => (position ? "start" : "center")};
  flex-direction: column;
  font-family: "Nunito";
  box-shadow: 0px 22px 45px -15px #dddde2;
  background-color: #ffffff;
  margin-top: 15px;
  padding: 20px;
  max-width: 99.5% !important;
`;
