import React from "react";
import { Typography, Input, Button } from "../../../components";
import styled from "styled-components";
import { Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

//Required Typography
const { StyledH1 } = Typography;

const Form = () => {
  return (
    <StyledDiv>
      <StyledH1 partialU color={"rgb(23, 142, 128)"} align={"left"}>
        Dashboard Controller and Configurator
      </StyledH1>
      <InputFrame>
        <Grid
          container
          style={{ marginBottom: "10px", paddingBottom: "0px" }}
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <StyledGrid item xs={10} md={3} sm={10} lg={3}>
            <Input
              themeType="theme1"
              label="Broker License Number"
              name="broker_license_number"
              id="broker_license_number"
              maxLength="50"
              placeholder="Enter Broker License Number"
              autoComplete="none"
              defaultValue={""}
              //   isRequired={true}
              required={false}
            />
          </StyledGrid>
          <StyledGrid item xs={10} md={3} sm={10} lg={3}>
            <Input
              themeType="theme1"
              label="Broker Name"
              name="broker_name"
              id="broker_name"
              maxLength="50"
              placeholder="Enter Broker Name"
              autoComplete="none"
              defaultValue={""}
              //   isRequired={true}
              required={false}
            />
          </StyledGrid>
          <StyledGrid item xs={10} md={3} sm={10} lg={3}>
            <Input
              themeType="theme1"
              label="Broker Short Name"
              name="broker_short_name"
              id="broker_short_name"
              maxLength="50"
              placeholder="Enter Broker Short Name"
              autoComplete="none"
              defaultValue={""}
              //   isRequired={true}
              required={false}
            />
          </StyledGrid>
        </Grid>
        <Grid
          container
          style={{ marginBottom: "10px", paddingBottom: "0px" }}
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={10} sm={10} md={9} lg={9}>
            <ButtonFrame>
              <Button bgColor="#178E80" round startIcon={<SearchIcon />}>
                Search
              </Button>
            </ButtonFrame>
          </Grid>
        </Grid>
      </InputFrame>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 40px;
  margin-top: 0px;
`;

const InputFrame = styled.form`
  margin-top: 30px;
  width: 100%;
`;

const TableFrame = styled.div`
  margin-top: 30px;
  width: calc(100% - 65px) !important;
  padding-top: 10px;
`;

const StyledGrid = styled(Grid)`
  position: relative;
`;

const ButtonFrame = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  @media (max-width: 767px) {
    justify-content: flex-start;
  }
`;

export default Form;
