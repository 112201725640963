import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import styled from "styled-components";
import {
  preventSpecialAndNumbersInInput,
  preventWhiteSpaceInInput,
} from "../../HelperFunctions/HelperFunctions";
import { Button, Input, Typography } from "../../components";
import Dropdown from "../../components/Dropdown/Dropdown";
import ViewDescription from "../../components/ViewDescription/ViewDescription";
import InlineLoader from "../../components/loader/InlineLoader";
import ProductFilePicker from "./ProductFilePicker";

function ProductModal({
  modalTitle,
  LOB_Obj,
  businessType_Obj,
  company_Obj,
  addProduct,
  updateProduct,
  productDataById,
  id,
  isAddProductLoading,
  isUpdateProductLoading,
  register,
  control,
  handleSubmit,
  setValue,
  reset,
  errors,
  unregister,
  watch,
  trigger,
}) {
  const { Error } = Typography;

  const transactionTypeData =
    productDataById?.data?.data?.transactionTypes?.map((value, index) => ({
      value,
      label: value,
      businessId: productDataById?.data?.data?.transactionTypeIds?.[index],
    }));

  useEffect(() => {
    if (productDataById && ["Update", "View"].includes(modalTitle)) {
      const data = productDataById?.data?.data;
      const transactionTypeData = data?.transactionTypes?.map(
        (value, index) => ({
          value,
          label: value,
          businessId: data?.transactionTypeIds?.[index],
        })
      );

      setValue("productCode", data?.productCode);
      setValue("productName", data?.productName);
      setValue("uin", data?.uinNumber);
      setValue("description", data?.description);
      setValue(
        "lob",
        data?.lobId
          ? { value: data?.lobId, label: data?.lobName, lobId: data?.lobId }
          : null
      );
      setValue("businessType", transactionTypeData);
      setValue(
        "company",
        data?.companyName
          ? {
              companyId: data?.companyId,
              label: data?.companyName,
              value: data?.companyName,
            }
          : null
      );
      setValue("brochure", data?.brouchure ? data?.brouchure : null);
      setValue(
        "policyWording",
        data?.policyWording ? data?.policyWording : null
      );
      setValue("claimForm", data?.claimForm ? data?.claimForm : null);
    } else {
      //for some reasons direct reset is not working
      reset({
        productCode: null,
        productName: null,
        uin: null,
        description: null,
        lob: null,
        businessType: null,
        company: null,
        brochure: null,
        policyWording: null,
        claimForm: null,
      });
    }
  }, [modalTitle, productDataById, reset, setValue]);

  const onSubmit = (value) => {
    if (modalTitle === "Update") {
      updateProduct([
        {
          id,
          productCode: value.productCode,
          productName: value.productName,
          description: value.description,
          uinNumber: value.uin,
          lobId: value.lob?.lobId,
          companyId: value.company?.companyId,
          brouchure: value.brochure?.[0]?.base64String
            ? value.brochure?.[0]?.base64String
            : "",
          transactionTypeId: value.businessType?.length
            ? value.businessType?.map((value) => value.businessId)
            : [],
          policyWording: value.policyWording?.[0]?.base64String
            ? value.policyWording?.[0]?.base64String
            : "",
          claimForm: value.claimForm?.[0]?.base64String
            ? value.claimForm?.[0]?.base64String
            : "",
        },
      ]);
    } else {
      let substringBeforeDot = value.brochure?.[0]?.file?.path?.slice(
        0,
        value.brochure?.[0]?.file?.path.indexOf(".")
      );

      // Replace extra spaces with underscores
      let result = substringBeforeDot.replace(/\s+/g, "_");
      addProduct([
        {
          productCode: value.productCode,
          status: "Y",
          productName: value.productName,
          description: value.description,
          uinNumber: value.uin,
          docName: result,
          lobId: value.lob?.lobId,
          companyId: value.company?.companyId,
          brouchure: value.brochure?.[0]?.base64String,
          transactionTypeId: value.businessType?.length
            ? value.businessType?.map((value) => value.businessId)
            : [],
          policyWording: value.policyWording?.[0]?.base64String,
          claimForm: value.claimForm?.[0]?.base64String,
        },
      ]);
    }
  };

  return (
    <InputFrame onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        sx={{ paddingInline: "24px" }}
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          style={{
            position: "relative",
            marginBottom: "-26px",
            marginLeft: "0px",
          }}
        >
          <StyledHeading>Product Details</StyledHeading>
        </Grid>
        <Grid item sx={{ position: "relative" }} xs={12} sm={12} md={6} lg={4}>
          <Dropdown
            id="lob"
            label="Line of Business"
            control={control}
            selectObj={LOB_Obj}
            maxMenuHeight={200}
            proposalRead={modalTitle === "View" ? true : false}
            isRequired={modalTitle === "View" ? false : true}
          />
          {!!errors?.lob && (
            <Error top="3px" left="3px">
              {errors.lob.message}
            </Error>
          )}
        </Grid>

        <Grid item sx={{ position: "relative" }} xs={12} sm={12} md={6} lg={4}>
          <Dropdown
            id="company"
            label="Select Company"
            control={control}
            selectObj={company_Obj}
            maxMenuHeight={200}
            proposalRead={modalTitle === "View" ? true : false}
            isRequired={modalTitle === "View" ? false : true}
          />
          {!!errors?.company && (
            <Error top="3px" left="3px">
              {errors.company.message}
            </Error>
          )}
        </Grid>

        <Grid item sx={{ position: "relative" }} xs={12} sm={12} md={6} lg={4}>
          {modalTitle === "View" ? (
            <Grid item sx={{ position: "relative", marginLeft: "5px" }} md={8}>
              <h5 className="document-title-for-View">Document</h5>
              {transactionTypeData?.map((item, index) => (
                <>
                  <span className="display-text-for-view">{item.label}</span>
                  {index < transactionTypeData?.length - 1 && <span>, </span>}
                </>
              ))}
            </Grid>
          ) : (
            <Dropdown
              id="businessType"
              label="Select Business Type"
              control={control}
              selectObj={businessType_Obj}
              maxMenuHeight={200}
              isMulti={true}
              proposalRead={modalTitle === "View" ? true : false}
              isRequired={modalTitle === "View" ? false : true}
            />
          )}
          {!!errors?.businessType && (
            <Error top="3px" left="3px">
              {errors.businessType.message}
            </Error>
          )}
        </Grid>

        <Grid item sx={{ position: "relative" }} xs={12} sm={12} md={6} lg={4}>
          <Input
            themeType={"theme1"}
            label="Product Code"
            name="productCode"
            id="productCode"
            maxLength="100"
            placeholder="Enter Product Code"
            autoComplete="none"
            defaultValue={""}
            required={false}
            onChange={(e) => {
              preventWhiteSpaceInInput(e);
            }}
            proposalRead={modalTitle === "View" ? true : false}
            readOnly={modalTitle === "View" ? true : false}
            inputRef={register("productCode")}
            testId="modal_input"
            isRequired={modalTitle === "View" ? false : true}
          />
          {!!errors?.productCode && (
            <Error top="3px" left="3px">
              {errors.productCode.message}
            </Error>
          )}
        </Grid>

        <Grid item sx={{ position: "relative" }} xs={12} sm={12} md={6} lg={4}>
          <Input
            themeType={"theme1"}
            label="Product Name"
            name="productName"
            id="productName"
            maxLength="100"
            placeholder="Enter Product Name"
            autoComplete="none"
            defaultValue={""}
            required={false}
            onChange={(e) => {
              preventSpecialAndNumbersInInput(e);
            }}
            proposalRead={modalTitle === "View" ? true : false}
            readOnly={modalTitle === "View" ? true : false}
            inputRef={register("productName")}
            testId="modal_input"
            isRequired={modalTitle === "View" ? false : true}
          />
          {!!errors?.productName && (
            <Error top="3px" left="3px">
              {errors.productName.message}
            </Error>
          )}
        </Grid>

        <Grid item sx={{ position: "relative" }} xs={12} sm={12} md={6} lg={4}>
          <Input
            themeType={"theme1"}
            label="UIN Number"
            name="uin"
            id="uin"
            maxLength="100"
            placeholder="Enter UIN Number"
            autoComplete="none"
            defaultValue={""}
            required={false}
            onChange={(e) => {
              preventWhiteSpaceInInput(e);
            }}
            proposalRead={modalTitle === "View" ? true : false}
            readOnly={modalTitle === "View" ? true : false}
            inputRef={register("uin")}
            testId="modal_input"
            isRequired={modalTitle === "View" ? false : true}
          />
          {!!errors?.uin && (
            <Error top="3px" left="3px">
              {errors.uin.message}
            </Error>
          )}
        </Grid>
        <Grid item sx={{ position: "relative" }} xs={12}>
          {modalTitle !== "View" ? (
            <Input
              themeType={"theme1"}
              label="Description"
              name="description"
              id="description"
              maxLength="500"
              placeholder="Enter Description"
              autoComplete="none"
              required={false}
              proposalRead={modalTitle === "View" ? true : false}
              readOnly={modalTitle === "View" ? true : false}
              inputRef={register("description")}
              testId="modal_input"
              isRequired={modalTitle === "View" ? false : true}
            />
          ) : productDataById?.data?.data?.description ? (
            <ViewDescription
              label="Description"
              text={productDataById?.data?.data?.description}
            />
          ) : (
            ""
          )}
          {!!errors?.description && (
            <Error top="3px" left="3px">
              {errors.description.message}
            </Error>
          )}
        </Grid>

        <Grid item sx={{ position: "relative" }} xs={12} sm={12} md={6} lg={12}>
          <ProductFilePicker
            label="Upload Brochure PDF"
            name="brochure"
            control={control}
            watch={watch}
            setValue={setValue}
            unregister={unregister}
            trigger={trigger}
            isReadOnly={modalTitle === "View" ? true : false}
          />
          {!!errors?.brochure && (
            <Error top="3px" left="15px">
              {errors.brochure.message}
            </Error>
          )}
        </Grid>
        <Grid item sx={{ position: "relative" }} xs={12} sm={12} md={6} lg={12}>
          <ProductFilePicker
            label="Upload Policy Wording"
            name="policyWording"
            control={control}
            watch={watch}
            setValue={setValue}
            unregister={unregister}
            trigger={trigger}
            isReadOnly={modalTitle === "View" ? true : false}
          />
          {!!errors?.policyWording && (
            <Error top="3px" left="15px">
              {errors.policyWording.message}
            </Error>
          )}
        </Grid>
        <Grid item sx={{ position: "relative" }} xs={12} sm={12} md={6} lg={12}>
          <ProductFilePicker
            label="Claim Form"
            name="claimForm"
            control={control}
            watch={watch}
            setValue={setValue}
            unregister={unregister}
            trigger={trigger}
            isReadOnly={modalTitle === "View" ? true : false}
          />
          {!!errors?.claimForm && (
            <Error top="3px" left="15px">
              {errors.claimForm.message}
            </Error>
          )}
        </Grid>
      </Grid>

      {modalTitle !== "View" ? (
        <Grid
          container
          mt={1}
          className="button_container"
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ justifyContent: "end" }}
        >
          <Grid
            item
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "end",
            }}
            xs={6}
            sm={6}
            md={6}
            lg={6}
          >
            <Button
              type="submit"
              themeType={"theme1"}
              id={modalTitle === "Modify" ? "update_btn" : "save_btn"}
              bgColor={"#11555F"}
            >
              {modalTitle === "Add" ? (
                isAddProductLoading ? (
                  <>
                    Creating
                    <InlineLoader />
                  </>
                ) : (
                  "Add"
                )
              ) : isUpdateProductLoading ? (
                <>
                  Updating
                  <InlineLoader />
                </>
              ) : (
                "Update"
              )}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </InputFrame>
  );
}

export default ProductModal;

const InputFrame = styled.form`
  width: 100%;
  .button_container {
    margin-top: 10px;
    background-color: #fff;
    box-shadow: 0 0 1px #000;
    padding: 8px 18px 16px 0;
    width: 100%;
    margin-left: 0;
  }
  .document-title-for-View {
    margin: 0;
    margin-bottom: 9px;
    cursor: text;
    pointer-events: none;
    top: -22px;
    left: 3px;
    font-family: Montserrat-Medium;
    color: rgb(17, 85, 95);
    line-height: 18px;
    font-size: 12px;
    font-weight: 500;
  }
  .display-text-for-view {
    font-weight: 700 !important;
    font-size: 14px !important;
    margin-top: 0px;
    color: rgba(0, 0, 0, 0.87);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
  }
`;

const StyledHeading = styled.h3`
  margin-left: 2px;
  margin-top: 8px;
  margin-bottom: 32px;
  font-size: 16px;
  font-family: "Montserrat-SemiBold";
  color: #178e80;
  font-weight: 600;
`;
