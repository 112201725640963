import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components";
import Swal from "sweetalert2";
import { Button } from "../../components";
import {
  deleteNomenclature,
  getNomenclature,
} from "../broker-configuration/service";
import NoData from "../../components/NoData/NoData";
import TableSkeleton from "../../components/SkeletonLoader/TableSkeleton";
import { useLocation } from "react-router-dom";
import useGetOperations from "../../hooks/useGetOperations";
import { Tooltip } from "@mui/material";
export const NomenclatureTable = ({
  setPrefillData,
  setModalTitle,
  setOpen,
  setnomenClatureId,
  searchData,
}) => {
  const location = useLocation();

  const { allowedOperations } = useGetOperations(location.pathname);
  const { data: rowData, isFetching } = getNomenclature(searchData);
  const { mutate } = deleteNomenclature();
  const columns = [
    {
      field: "offices",
      flex: 1,
      headerName: "Office Name",
      background: "#E4E4E4",
    },
    {
      field: "is_head_office",
      flex: 1,
      headerName: "Is Head Office",
      background: "#E4E4E4",
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <ActionContainer>
            {(allowedOperations.includes("View") ||
              allowedOperations.includes("Edit") ||
              allowedOperations.includes("Delete")) && (
              <Tooltip title="View">
                <PreviewOutlinedIcon
                  onClick={() => {
                    setnomenClatureId(params.row.id);
                    setModalTitle("View");
                    setOpen(params.row.id);
                    setPrefillData({
                      id: params.row.id,
                    });
                  }}
                />
              </Tooltip>
            )}
            {allowedOperations.includes("Edit") && (
              <Tooltip title="Edit">
                <BorderColorRoundedIcon
                  onClick={() => {
                    setnomenClatureId(params.row.id);
                    setModalTitle("Modify");
                    setOpen(params.row.id);
                    setPrefillData({
                      id: params.row.id,
                    });
                  }}
                  index={params.row.id}
                />
              </Tooltip>
            )}
            {(allowedOperations.includes("Delete") ||
              allowedOperations.includes("Edit")) && (
              <Tooltip title="Delete">
                <DeleteIcon
                  onClick={() => {
                    Swal.fire({
                      title: "Delete record?",
                      text: "Confirmation required to delete this record.",
                      icon: "question",
                      confirmButtonText: "Delete",
                      confirmButtonColor: "#DC004E",
                      showCancelButton: true,
                      cancelButtonText: "Cancel",
                      reverseButtons: true,
                      focusConfirm: false,
                      focusCancel: false,
                      scrollbarPadding: false,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        mutate(params.row.id);
                        Swal.fire(
                          "Deleted!",
                          "Deleted Successfully",
                          "success"
                        );
                      }
                    });
                  }}
                  index={params.row.id}
                />
              </Tooltip>
            )}
          </ActionContainer>
        );
      },
    },
  ];

  // prettier - ignore;
  const rows = rowData?.data?.data?.map((item) => ({
    id: item.brokerOfficeNomenclaturesid,
    broker_id: item.brokerId,
    offices: item.officeName,
    is_head_office: item.isHeadOffice,
    Edit: 1,
    Delete: 2,
  }));

  const getRowId = (row) => {
    return row.id;
  };

  if (isFetching) return <TableSkeleton />;

  return (
    <>
      <Heading>Nomenclature Table</Heading>
      {allowedOperations.includes("Edit") && (
        <Button
          bgColor="transparent"
          textColor="#11555F"
          variant="outlined"
          borderColor="#11555F"
          themeType={"theme1"}
          startIcon={<ControlPointIcon />}
          onClick={() => {
            setModalTitle("Add");
            setOpen(true);
          }}
          style={{
            position: "absolute",
            right: "20px",
          }}
        >
          Add Nomenclature
        </Button>
      )}
      {!rows?.length ? (
        <NoData />
      ) : (
        <TableContainer>
          <DataGrid
            rows={rows ? rows : []}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                },
              },
            }}
            getRowId={getRowId}
            pageSizeOptions={[5]}
            columns={columns}
            size="small"
            sx={{
              ".MuiDataGrid-columnSeparator": {
                display: "none",
              },
              "&.MuiDataGrid-root": {
                border: "none",
                fontSize: "14px",
              },
              ".MuiDataGrid-columnHeaders": {
                background: "#D2F8E9",
                color: "#11555F",
                fontSize: "14px",
                fontWeight: 500,
                fontFamily: "Montserrat-Medium",
              },
              ".MuiTablePagination-root p": {
                fontSize: "14px",
              },
            }}
          />
        </TableContainer>
      )}
    </>
  );
};

const TableContainer = styled.div`
  padding-top: 10px;
  width: 100%;
  background: #fff;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  svg {
    color: rgb(23, 142, 128);
    font-size: 20px;
    gap: 2px;
  }
`;
export const Heading = styled.h2`
  width: 100%;
  margin-top: -3px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  color: #178e80;
  font-family: "Montserrat-SemiBold";
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
`;
