import React from "react";
import styled from "styled-components";
import { Grid, Icon } from "@mui/material";

const TileContainer = styled.div`
  min-width: 180px;
  height: 70px;
  left: 253px;
  top: 452px;
  padding-left: 65px;
  margin-left : 4px;
// margin: 10px;
  background: #ffffff;
  background-blend-mode: darken;
  box-shadow:  0px 11px 9px -1px rgba(21, 139, 125, 0.17);
  border-radius: 4px;
  font-family: "Nunito", sans-serif;

  &:hover {
    
    border-bottom: 1px solid #189C8D;
    border-right: 1px solid #189C8D;
    box-shadow: 0px 8px 16px #178E80;
    transition: all 0.3s ease-in-out;
    
  }
  
`;

const InnerTile = styled.div`
  display: flex;
`;

const StyledGrid = styled(Grid)`
  position: relative;
  align-item: center;
`;

const Image = styled.img`
  width: 45px;
  align-items: center;
  display: flex;
  margin-top: 10px;
  margin-left: -33px;
`;

const TileCheckbox = () => {
  return (
    <TileContainer>
      <InnerTile>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
          <StyledGrid item container xs={12} md={12} sm={12} lg={12}>
            <StyledGrid item xs={2} md={2} sm={2} lg={2}>
              <Image src="/assets/lead.png" />
            </StyledGrid>

            <StyledGrid item xs={10} md={10} sm={10} lg={10}>
              <label
                style={{
                  color: "#000",
                  display: "flex",
                  alignItems: "center",
                  padding: "24px",
                  marginLeft: "-82px",
                  justifyContent: "center",
                  fontWeight: 700,
                  color: "#000000",
                  fontSize :'13px',
                  letterSpacing: '-0.02em'
                }}
              >
                LEADS
              </label>
            </StyledGrid>
          </StyledGrid>
        </Grid>
      </InnerTile>
    </TileContainer>
  );
};

export default TileCheckbox;