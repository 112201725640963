import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import * as yup from "yup";
import {
  addBrokerOffice,
  updateBrokerOffice,
  useGetStateCityFromPincode,
} from "../../broker-configuration/service";
import { setOfficeErrorAction } from "../office.slice";

function useModifyLocation({ preFillData, modalTitle, handleClose }) {
  const [pincode, setPincode] = useState("");

  const [showDate, setShowDate] = useState(false);

  const dispatch = useDispatch();

  const handleDate = () => {
    setShowDate(!showDate);
  };

  const currentDate = new Date();

  const previousDate = preFillData?.effective_from_date
    ? new Date(preFillData.effective_from_date)
    : new Date(currentDate);
  previousDate.setDate(previousDate.getDate());

  const previousMaxDate = preFillData?.effective_to_date
    ? new Date(preFillData.effective_to_date)
    : new Date(currentDate);
  previousMaxDate.setDate(previousMaxDate.getDate());

  const yupValidate = yup.object({
    contact_person_name: yup.string().required("Name is Required"),
    email_id: yup
      .string()
      .email("Invalid Email")
      .required("Email ID is required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
        "Please enter valid Email"
      ),
    mobile_no: yup
      .string()
      .required("Mobile Number is Required")
      .min(10, "Must be 10 Digits")
      .max(10, "Must be 10 Digits"),
    office_address: yup.string().required("Office Address is Required"),
    office_code: yup.string().required("Office Code is Required"),
    pincode: yup
      .string()
      .required("Pincode is Required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(6, "Must be 6 Digits")
      .max(6, "Must be 6 Digits"),
    city: yup
      .string()
      .required("City is Required")
      .matches(/[1-9A-Za-z]/, "City Required"),
    state: yup
      .string()
      .required("State is Required")
      .matches(/[1-9A-Za-z]/, "State Required"),
    effective_from_date: !showDate
      ? yup.date().typeError("Invalid Date").required("Start Date Required")
      : yup.date().notRequired(),
    effective_to_date: !showDate
      ? yup
        .date()
        .typeError("Invalid Date")
        .required("End Date Required")
        .test(
          "is-greater",
          "End date must be greater than start date",
          function (value) {
            const { effective_from_date } = this.parent;
            return (
              !effective_from_date || !value || value > effective_from_date
            );
          }
        )
      : yup.date().notRequired(),
  });

  const { mutate } = addBrokerOffice();
  const { mutate: updateBroker } = updateBrokerOffice();

  const { officeError, officeSuccess } = useSelector(
    (state) => state.brokerOfficeConfig
  );

  const {
    register,
    control,
    reset,
    setValue,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yupValidate),
    mode: "all",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (modalTitle === "Modify" || modalTitle === "View") {
      const prefillDataWithDateHandled = {
        ...preFillData,
        effective_from_date: preFillData?.effective_from_date
          ? new Date(preFillData?.effective_from_date)
          : "",
        effective_to_date: preFillData?.effective_to_date
          ? new Date(preFillData?.effective_to_date)
          : "",
      };
      reset(prefillDataWithDateHandled);
      if (
        !preFillData?.effective_from_date &&
        !preFillData?.effective_to_date
      ) {
        setShowDate(true);
      }
      if (prefillDataWithDateHandled.pincode) {
        setPincode(prefillDataWithDateHandled.pincode);
      }
    }
  }, []);

  useEffect(() => {
    if (officeError) {
      Swal.fire({
        text: officeError,
        icon: "warning",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        allowOutsideClick: false,
        focusCancel: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(setOfficeErrorAction(null));
        }
      });
    }
  }, [officeError]);

  useEffect(() => {
    if (officeSuccess) {
      Swal.fire({
        text: officeSuccess,
        icon: "success",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        focusCancel: false,
        scrollbarPadding: false,
      });
      handleClose();
    }
  }, [officeSuccess]);

  const addLocation = (value) => {
    if (modalTitle === "Modify") {
      updateBroker({
        id: preFillData.id,
        brokerOfficeNomemclatureId: 1 + Math.random(),
        officeCode: value.office_code,
        pincode: value.pincode,
        state: value.state,
        city: value.city,
        officeAddress: value.office_address,
        contactPersonName: value.contact_person_name,
        mobileNo: value.mobile_no,
        emailId: value.email_id,
        parentOffice: Math.random(),
        effectiveFromDate: value.effective_from_date
          ? moment(value.effective_from_date).format("YYYY-MM-DD")
          : null,
        effectiveToDate: value.effective_to_date
          ? moment(value.effective_to_date).format("YYYY-MM-DD")
          : null,
      });
    } else {
      mutate({
        brokerOfficeNomemclatureId: 1 + Math.random(),
        officeCode: value.office_code,
        pincode: value.pincode,
        state: value.state,
        city: value.city,
        officeAddress: value.office_address,
        contactPersonName: value.contact_person_name,
        mobileNo: value.mobile_no,
        emailId: value.email_id,
        parentOffice: Math.random(),
        effectiveFromDate: value.effective_from_date
          ? moment(value.effective_from_date).format("YYYY-MM-DD")
          : null,
        effectiveToDate: value.effective_to_date
          ? moment(value.effective_to_date).format("YYYY-MM-DD")
          : null,
      });
    }
  };

  const { data } = useGetStateCityFromPincode(pincode);

  useEffect(() => {
    if (data) {
      const state = data?.data?.data[0]?.state?.stateName;
      const city = data?.data?.data[0]?.city?.cityName;
      if (state) {
        setValue("state", state);
        trigger("state");
      }
      if (city) {
        setValue("city", city);
        trigger("city");
      }
    }
  }, [data]);

  const handlePincodeChange = (value) => {
    const sanitizedValue = value.replace(/\D/g, "");
    setPincode(sanitizedValue);
  };

  return {
    handleSubmit,
    addLocation,
    errors,
    register,
    pincode,
    data,
    handlePincodeChange,
    handleDate,
    control,
    showDate,
    previousDate,
    previousMaxDate,
    showDate,
  };
}

export default useModifyLocation;
