import { useState } from "react";
import GlobalModal from "../../../components/Modal/modal";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DensityMediumOutlinedIcon from '@mui/icons-material/DensityMediumOutlined';
import { ReaarangeContainerStyled, RearrangeStyled } from "../Brokerage.style";
import { Grid } from "@mui/material";
import { Button } from "../../../components";

function Rearrange({ open, handleClose }) {
    const [items, setItems] = useState(['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5']);

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const reorderedItems = [...items];
        const [movedItem] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, movedItem);

        setItems(reorderedItems);
    };
    return (
        <GlobalModal
            open={open}
            title={"Rearrange & Edit"}
            onClose={handleClose}
        >
            <ReaarangeContainerStyled>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="list">
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {items.map((item, index) => (
                                    <Draggable key={item} draggableId={item} index={index}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <RearrangeStyled>
                                                    <DensityMediumOutlinedIcon />
                                                    <p className="title">{item}</p>
                                                    <p>Edit</p>
                                                </RearrangeStyled>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </ReaarangeContainerStyled>
            <Grid container rowSpacing={1} mb={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6} sm={6} md={6} lg={8} />
                <Grid item xs={6} sm={6} md={6} lg={1.5}>
                    <Button
                        themeType={"theme1"}
                        btnBack={"tertiary"}
                        id="cancel"
                        textColor="rgb(23, 142, 128)"
                        bgColor="transparent"
                        borderColor="none"
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={2}>
                    <Button themeType={"theme1"} bgColor="rgba(17, 85, 95, 1)">
                        Save New Order
                    </Button>
                </Grid>
            </Grid>
        </GlobalModal>
    )
}

export default Rearrange