import { Navigate, Outlet } from "react-router-dom";
import ErrorBoundary from "../../hoc/ErrorBoundry";

function AuthRoute() {
  const isAuthenticated =
    sessionStorage.getItem("tokenID") && sessionStorage.getItem("tokenID");

  //authentication will be maintained completely either in redux or context api along with other roles.

  if (
    isAuthenticated?.length ? !(isAuthenticated.length > 25) : !isAuthenticated
  ) {
    return <Navigate to="/login" replace />;
  } else {
    return (
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    );
  }
}

export default AuthRoute;
