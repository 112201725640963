import styled, { keyframes } from "styled-components";

export const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledBg = styled.div`
  background: #fff;
  padding: 24px 16px;
  margin-right: 7px;
  margin-bottom: 7px;
  border: 0.5px solid #f1ecec;
`;

export const InputFrame = styled.form`
  width: 100%;
  position: relative;
`;
export const DisplayContainer = styled.div`
  margin-top: 3px;
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => (props.marginBottom ? "14px" : 0)};
  label {
    color: rgb(17, 85, 95);
    padding-left: 1px;
    font-size: 14px;
  }
  .doc-display {
    margin-top: 5px;
    width: 100%;
    overflow: scroll;
    label {
      color: #000000;
      font-weight: bold;
      margin-right: 3px;
      font-size: 14px;
    }
  }
`;

export const ButtonWrp = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 12px;
`;

export const TableContainer = styled.div`
  background: #fff;
  width: 100%;
`;

export const Heading = styled.h2`
  margin-top: -3px;
  margin-bottom: 0;
  display: flex;
  color: #178e80;
  justify-content: space-between;
  font-family: "Montserrat-SemiBold";
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
`;

export const TableFrame = styled.div`
  display: flex;
  position: relative;
  align-items: ${({ position }) => (position ? "start" : "center")};
  flex-direction: column;
  box-shadow: 0px 22px 45px -15px #dddde2;
  background-color: #ffffff;
  margin-top: 15px;
  padding: 16px;
  max-width: 99.5% !important;
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  svg {
    color: #178e80;
    font-size: 20px;
  }
`;
export const LeadActivityHeader = styled.h3`
  color: #178e80;
  margin: 0;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 5px;
`;
const fadeInOut = keyframes`
  0% {
    opacity: 0;
    height: 0;
  }
  100% {
    opacity: 1;
    height: 300px;
  }
`;
export const LeadIdActivityDisplay = styled.div`
  width: 100%;
  height: 300px;
  overflow-y: scroll;
  margin-top: 7px;
  border-top: 0.5px solid #f1ecec;
  animation: ${fadeInOut} 0.3s ease;
  padding-top: 7px;

  &::-webkit-scrollbar {
    width: 10px !important; /* Set the width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: red !important; /* Set the color of the scrollbar thumb */
    border-radius: 5px !important; /* Set the border radius of the scrollbar thumb */
  }

  &::-webkit-scrollbar-track {
    background-color: #f0f0f0 !important; /* Set the color of the scrollbar track */
  }
`;
export const LeadActivityCard = styled.div`
  position: relative;
  background-color: rgb(249 249 249);
  border-radius: 3px;
  border: 0.5px solid #f1ecec;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  .activity {
    font-size: 15px;
    font-weight: bold;
    margin-left: 3px;
    display: flex;
    justify-content: space-between;
  }

  .stage {
    font-size: 12px;
    color: #555;
  }
  .seprator {
    width: 2px;
    height: 18px;
    position: relative;
    top: 1px;
    margin-left: 6px;
    margin-right: 6px;
    background: rgb(197 197 197);
  }
  svg {
    position: absolute;
    text-align: right;
    color: rgb(179 179 179);
    right: 0;
    top: 0;
    font-weight: bolder;
    color: #178e80;
    cursor: pointer;
  }
`;
export const CreatedAt = styled.div`
  position: absolute;
  margin-right: 5px;
  text-align: right;
  font-size: 11px;
  color: rgb(179 179 179);
  right: 0;
  bottom: 0;
`;
export const NoDataDisplay = styled.p`
  position: relative;
  top: 40%;
  left: 40%;
  font-weight: bolder;
  font-size: 20px;
`;
