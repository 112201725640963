import styled from "styled-components";
import { Typography } from "../../components";
import GlobalModal from "../../components/Modal/modal";
import ProductModal from "./ProductModal";
import ProductTable from "./ProductTable";
import useProduct from "./useProduct";

function ProductMaster() {
    const { StyledH1 } = Typography;

    const {
        isProductModalOpen,
        addProduct,
        isAddProductLoading,
        updateProduct,
        isUpdateProductLoading,
        LOB_Obj,
        businessType_Obj,
        company_Obj,
        handleOpen,
        handleClose,
        productDataById,
        register,
        control,
        handleSubmit,
        setValue,
        reset,
        errors,
        rows,
        columns,
        isProductsFetching,
        unregister,
        watch,
        trigger
    } = useProduct();

    return (
        <>
            <StyledBg>
                <StyledH1
                    themeType="theme1"
                    partialU
                    color={"rgb(23, 142, 128)"}
                    align={"left"}
                    style={{ marginBottom: 0 }}
                >
                    Product Master
                </StyledH1>
            </StyledBg>
            <TableFrame>
                <ProductTable
                    handleOpen={handleOpen}
                    rows={rows}
                    columns={columns}
                    isProductsFetching={isProductsFetching}
                />
            </TableFrame>
            <GlobalModal
                open={isProductModalOpen.state}
                title={isProductModalOpen.title + " Product"}
                onClose={handleClose}
            >
                <ProductModal
                    id={isProductModalOpen.productId}
                    modalTitle={isProductModalOpen.title}
                    addProduct={addProduct}
                    isAddProductLoading={isAddProductLoading}
                    updateProduct={updateProduct}
                    isUpdateProductLoading={isUpdateProductLoading}
                    LOB_Obj={LOB_Obj}
                    businessType_Obj={businessType_Obj}
                    company_Obj={company_Obj}
                    productDataById={productDataById}
                    register={register}
                    control={control}
                    handleSubmit={handleSubmit}
                    setValue={setValue}
                    reset={reset}
                    errors={errors}
                    unregister={unregister}
                    watch={watch}
                    trigger={trigger}
                />
            </GlobalModal>
        </>
    );
}

export default ProductMaster;

const StyledBg = styled.div`
  background: #fff;
  padding: 24px 16px;
  margin-right: 7px;
`;

const TableFrame = styled.div`
  display: flex;
  position: relative;
  align-items: ${({ position }) => (position ? "start" : "center")};
  flex-direction: column;
  box-shadow: 0px 22px 45px -15px #dddde2;
  background-color: #ffffff;
  margin-top: 15px;
  padding: 16px;
  max-width: 99.5% !important;
`;
