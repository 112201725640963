import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import styled from "styled-components";
import { Button } from "./../../../components";
import { useNavigate } from "react-router";

//prettier-ignore
const rows = [
  { id: 1, A: "operation", B: "Cheif Management", C: "Ops_Approval_10Lacs", Edit : 1,Edit : 1  },
  { id: 2, A: "Call center", B: "AVP", C: "CC_Motors_2Lacs", Edit : 1,Edit : 1  },
  { id: 3, A: "Underwritin", B: "Executive", C: "UW_Motors_2Lacs", Edit : 1,Edit : 1  },
  { id: 4, A: "sales", B: "senior Executive", C: "CC_Motors_2Lacs", Edit : 1,Edit : 1  },
 
];

export const RoleTable = ({ setOpen }) => {
  const navigate = useNavigate();
  const columns = [
    {
      field: "id",
      headerName: "Sr No.",
      flex: 1,
      background: "#E4E4E4",
    },
    {
      field: "A",
      headerName: "Department",
      flex: 1,
      background: "#E4E4E4",
    },
    {
      field: "B",
      headerName: "Designation",
      flex: 1,
      background: "#E4E4E4",
    },
    {
      field: "C",
      headerName: "Role Name",
      flex: 1,
      background: "#E4E4E4",
    },
    {
      field: "D",
      headerName: "Activity",
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <ActionContainer>
            <Button
              onClick={() => navigate("/role-access")}
              themeType="theme2"
              btnBack={"secondary"}
              textColor={"#ffff"}
              round
              index={params.row.id}
              startIcon={<RemoveRedEyeIcon />}
            >
              {" "}
              View
            </Button>
          </ActionContainer>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <ActionContainer>
            <BorderColorRoundedIcon index={params.row.id} />
            <DeleteIcon index={params.row.id} />
          </ActionContainer>
        );
      },
    },
  ];
  return (
    <>
      <TableContainer style={{ marginTop: "20px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          size="small"
          paginationModel={{ page: 0, pageSize: 20 }}
          sx={{
            ".MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "&.MuiDataGrid-root": {
              border: "none",
            },
            ".MuiDataGrid-columnHeaders": {
              background: "#E4E4E4",
            },
          }}
        />
      </TableContainer>
    </>
  );
};

const TableContainer = styled.div`
  padding-top: 10px;
  border-top: 2px solid rgba(224, 224, 224, 1);
  /* margin-right: 40px; */
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
`;
