import React from "react";
import {
  LeadActivityHeader,
  LeadIdActivityDisplay,
  LeadActivityCard,
  CreatedAt,
  NoDataDisplay,
} from "./style";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import _ from "lodash";
import { useDeleteLeadActivity } from "./service";
import Swal from "sweetalert2";

const PrevLeadActivity = ({ leadIdActivityData, show, onClick }) => {
  const formatDateAndTime = (isoDateString) => {
    const dateObject = new Date(isoDateString);

    const formattedDate = dateObject.toLocaleDateString();
    const formattedTime = dateObject.toLocaleTimeString();

    return `${formattedDate} ${formattedTime}`;
  };
  const { mutate: deleteLeadActivity } = useDeleteLeadActivity();

  return (
    <>
      <LeadActivityHeader onClick={onClick}>
        Activity History <ArrowDropDownIcon />
      </LeadActivityHeader>
      {show && (
        <LeadIdActivityDisplay>
          {_.isEmpty(leadIdActivityData) ? (
            <NoDataDisplay>No Previous Activity</NoDataDisplay>
          ) : (
            leadIdActivityData?.map((item) => {
              return (
                <LeadActivityCard>
                  <div className="stage">Stage: {item.leadStage}</div>
                  <div className="activity">{item.leadActivity}</div>
                  <CreatedAt>{formatDateAndTime(item.createdAt)}</CreatedAt>
                  <DeleteForeverOutlinedIcon
                    onClick={() =>
                      Swal.fire({
                        title: "Delete record?",
                        text: "Confirmation required to delete this record.",
                        icon: "question",
                        confirmButtonText: "Delete",
                        confirmButtonColor: "#DC004E",
                        showCancelButton: true,
                        cancelButtonText: "Cancel",
                        reverseButtons: true,
                        focusConfirm: false,
                        focusCancel: false,
                        scrollbarPadding: false,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          Swal.fire(
                            "Deleted!",
                            "Deleted Successfully",
                            "success"
                          );
                          deleteLeadActivity(item?.leadActivityMasterId);
                        }
                      })
                    }
                  />
                </LeadActivityCard>
              );
            })
          )}
        </LeadIdActivityDisplay>
      )}
    </>
  );
};

export default PrevLeadActivity;
