import { Divider, FormControlLabel, Grid, Switch } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineEdit, AiTwotoneEye } from "react-icons/ai";
import { FaUserEdit } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import styled from "styled-components";
import { Button, DatePickerInput, DialogBox, Input } from "../../../components";
import CurdChebox from "./curd-checkboxes";

export const RoleConfiguration = ({ modalTitle, isOpen, handleClose }) => {
  const { register, handleSubmit, watch } = useForm({
    defaultValues: {},
  });

  const ChekboxComponent = (label, labelShow, color) => {
    return (
      <CheckBoxWrap
        color={color}
        style={{
          ...(labelShow
            ? { display: "flex" }
            : { display: "flex", justifyContent: "center" }),
        }}
      >
        <input
          type="checkbox"
          ref={register}
          name={label}
          value={label?.split(" ")?.join("_")}
          id={label?.split(" ")?.join("_")}
        />
        {labelShow && (
          <label htmlFor={label?.split(" ")?.join("_")}>{label}</label>
        )}
      </CheckBoxWrap>
    );
  };

  const onSubmitRole = (data) => {};

  const Inputes = () => (
    <form onSubmit={handleSubmit(onSubmitRole)}>
      <GridRow container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <StyledDiv1>
            <span className="span">Department</span>
            <b className="bold">Operations</b>
          </StyledDiv1>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <StyledDiv1>
            <span className="span">Designation</span>
            <b className="bold">Chief Mangager</b>
          </StyledDiv1>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <StyledDiv1 style={{ border: "none" }}>
            <span className="span">Role Name</span>
            <b className="bold">Ops_Approval_10Lacs</b>
          </StyledDiv1>
        </Grid>
      </GridRow>
      <Divider
        variant="middle"
        style={{ borderStyle: "dashed", padding: "20px 0px 20px 0px" }}
      />
      <GridRow2
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 1, md: 1 }}
      >
        <Grid
          style={{
            gap: "3px",
            paddingTop: "0px",
            display: "flex",
            alignItems: "center",
          }}
          item
          xs={4}
          sm={4}
          md={4}
          lg={4}
        >
          <AiTwotoneEye className="IconSize" />
          {` `} Role Name
        </Grid>
        <GridRow2Col item xs={2} sm={2} md={2} lg={2}>
          <AiTwotoneEye className="IconSize" />
          {` `} View
        </GridRow2Col>
        <GridRow2Col item xs={2} sm={2} md={2} lg={2}>
          <FaUserEdit className="IconSize" />
          {` `} Create
        </GridRow2Col>
        <GridRow2Col item xs={2} sm={2} md={2} lg={2}>
          <AiOutlineEdit className="IconSize" />
          {` `} Edit
        </GridRow2Col>

        <GridRow2Col item xs={2} sm={2} md={2} lg={2}>
          <RiDeleteBin5Line />
          {` `}
          Delete
        </GridRow2Col>
      </GridRow2>
      <GridRow3
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 1, md: 1 }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {ChekboxComponent("Fyntune Super Admin", true, true)}
        </Grid>
      </GridRow3>
      <GridRow3
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 1, md: 1 }}
      >
        <Grid style={{ paddingLeft: "20px" }} item xs={4} sm={4} md={4} lg={4}>
          {ChekboxComponent("Super Administrator", true)}
        </Grid>
        <CurdChebox
          ChekboxComponent={ChekboxComponent}
          title={"Super Administrator"}
        />
      </GridRow3>
      {/* Home Page */}
      <GridRow3
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 1, md: 1 }}
      >
        <Grid style={{ paddingLeft: "20px" }} item xs={4} sm={4} md={4} lg={4}>
          {ChekboxComponent("Home Page", true)}
        </Grid>
        <CurdChebox ChekboxComponent={ChekboxComponent} title={"Home Page"} />
      </GridRow3>
      {/* Administrator Tile */}
      <GridRow3
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 1, md: 1 }}
      >
        <Grid style={{ paddingLeft: "20px" }} item xs={4} sm={4} md={4} lg={4}>
          {ChekboxComponent("Administrator Tile", true)}
        </Grid>
        <CurdChebox
          ChekboxComponent={ChekboxComponent}
          title={"Administrator Tile"}
        />
      </GridRow3>
      {/* Broker Theme Setting */}
      <GridRow3
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 1, md: 1 }}
      >
        <Grid style={{ paddingLeft: "20px" }} item xs={4} sm={4} md={4} lg={4}>
          {ChekboxComponent("Broker Theme Setting", true)}
        </Grid>
        <CurdChebox
          ChekboxComponent={ChekboxComponent}
          title={"Broker Theme Setting"}
        />
      </GridRow3>
      <GridRow3
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 1, md: 1 }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {ChekboxComponent("Broker Admin Configuration", true, true)}
        </Grid>
      </GridRow3>
      <GridRow3
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 1, md: 1 }}
      >
        <Grid style={{ paddingLeft: "20px" }} item xs={4} sm={4} md={4} lg={4}>
          {ChekboxComponent("Administrator", true)}
        </Grid>
        <CurdChebox
          ChekboxComponent={ChekboxComponent}
          title={"Administrator"}
        />
      </GridRow3>
      <GridRow3
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 1, md: 1 }}
      >
        <Grid style={{ paddingLeft: "20px" }} item xs={4} sm={4} md={4} lg={4}>
          {ChekboxComponent("Office Nomenclature", true)}
        </Grid>
        <CurdChebox
          ChekboxComponent={ChekboxComponent}
          title={"Office Nomenclature"}
        />
      </GridRow3>
      <GridRow3
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 1, md: 1 }}
      >
        <Grid style={{ paddingLeft: "20px" }} item xs={4} sm={4} md={4} lg={4}>
          {ChekboxComponent("Broker Office Configuration", true)}
        </Grid>
        <CurdChebox
          ChekboxComponent={ChekboxComponent}
          title={"Broker Office Configuration"}
        />
      </GridRow3>
      <GridRow3
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 1, md: 1 }}
      >
        <Grid style={{ paddingLeft: "20px" }} item xs={4} sm={4} md={4} lg={4}>
          {ChekboxComponent("Broker Theme Setting2", true)}
        </Grid>
        <CurdChebox
          ChekboxComponent={ChekboxComponent}
          title={"Broker Theme Setting2"}
        />
      </GridRow3>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6} sm={6} md={6} lg={6}></Grid>
        <Grid
          style={{ display: "flex", justifyContent: "end" }}
          item
          xs={6}
          sm={6}
          md={6}
          lg={6}
        >
          <Button
            style={{ position: "relative", top: "18px" }}
            type="submit"
            themeType={"theme2"}
            round
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <div>
      {isOpen && (
        <DialogBox
          themeType={"theme2"}
          headerBG={"#F7F7F7"}
          TitleText={`${modalTitle} location`}
          MainBody={Inputes}
          isOpen={isOpen}
          handleClose={handleClose}
        />
      )}
    </div>
  );
};

const GridRow = styled(Grid)``;
const GridRow2 = styled(Grid)`
  background: #e4e4e4;
  border-radius: 5px;
  padding: 5px 3px;
`;
const GridRow2Col = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0px !important;
  gap: 3px;
  .IconSize {
    font-size: 14px !important;
  }
`;

const GridRow3 = styled(Grid)`
  margin-bottom: 10px;
`;

const StyledDiv1 = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid gray;

  @media (max-width: 600px) {
    border: none;
  }
  & > span {
    font-size: 11px;
    word-wrap: wrap;
  }
  & > b {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const CheckBoxWrap = styled.div`
  display: flex;
  padding-top: 10px;
  ${({ color, theme }) =>
    color
      ? `color : ${theme?.theme2?.litePrimary || "#918925"}`
      : `font-size:12px`};

  input {
    margin-right: 5px;
    border-radius: 50%;
  }
  label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
