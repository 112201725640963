import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled, { createGlobalStyle } from "styled-components";
import { BsImages, BsFiletypeXls } from "react-icons/bs";
import { Input, Button } from "..";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

export const FilePicker = (props) => {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <PickerWrap>
      <div {...getRootProps({ className: "dropzone" })}>
        <BsImages className="ImageIcon" />
        <input {...getInputProps()} />
        <TextDiv>
          <p>
            Drag and drop, or <StuledSpan>Browser</StuledSpan> your file
          </p>
          <StuledSpan>Supported formates: XLS,PNG,PDF</StuledSpan>
        </TextDiv>
      </div>

      <aside style={thumbsContainer}>{thumbs}</aside>
    </PickerWrap>
  );
};

const PickerWrap = styled.div`
  // margin-top: 15px;
  border-radius: 10px;
  width: 100%;

  .dropzone {
    min-height: 70px;
    cursor: pointer;
    padding: 5px 20px;
    background: #effff4;
    border: 1.5px dotted #269400;
    display: flex;
    justify-content: start;
    align-items: center;
    border-radius: 10px;
  }
  .ImageIcon {
    font-size: 50px;
    color: #24aa9a;
    padding: 5px;
  }
`;
const TextDiv = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    margin: 0px;
    font-size: 14px;
    word-wrap: break-word;
  }
`;
const StuledSpan = styled.span`
  color: #269400;
  font-size: 9px;
`;
