import { createSlice } from "@reduxjs/toolkit";

const endorsement = createSlice({
  name: "endorsement",
  initialState: {
    mmv: {
      model: "",
      make: "",
      version: "",
    },
  },
  reducers: {
    setMmv: (state, { payload }) => {
      state.mmv = { ...state.mmv, ...payload };
    },
  },
});

export const { setMmv } = endorsement.actions;
export default endorsement.reducer;
