import { createSlice } from "@reduxjs/toolkit";
import { serializeError } from "../../utils";

const holidayConfig = createSlice({
    name: "brokerHolidayConfig",
    initialState: {
        holidayError: null,
        holidaySuccess: null,
    },
    reducers: {
        setHolidaySuccessAction: (state, { payload }) => {
            state.holidaySuccess = payload;
            state.holidayError = null;
        },
        setHolidayErrorAction: (state, { payload }) => {
            state.loading = null;
            state.holidayError = serializeError(payload);
            state.holidaySuccess = null;
        },
    },
});

export const { setHolidaySuccessAction, setHolidayErrorAction } =
holidayConfig.actions;
export default holidayConfig.reducer;