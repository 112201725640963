import { Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import styled from "styled-components";
import { Button, Input, Typography } from "../../../components";
import { preventSpecialAndNumbersInInput } from "../../../HelperFunctions/HelperFunctions";
import useGetLOBMaster from "../../../hooks/useGetLOBMaster";
import { useGetDocumentData } from "../service";
import SkeletonLoader from "../../../components/SkeletonLoader/SkeletonLoader";

export const DocManagementForm = ({ searchData, setSearchData }) => {
  const { Error } = Typography;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const { data } = useGetLOBMaster();
  const { refetch, isFetching } = useGetDocumentData(searchData);

  const resetFields = () => {
    setTimeout(() => {
      reset();
      refetch();
    }, 500);
    setSearchData({
      page: 0,
      size: 0,
      sortBy: "documentManagementConfigurationId",
      sortOrder: "DESC",
      irdaRegistrationNo: "",
      brokerName: "",
    });
  };

  const LOB_Obj = data?.data?.data?.map((value) => {
    return {
      value: value.lob,
      label: value.lob,
      lobId: value.lob_id,
    };
  });

  const onSubmit = (data) => {
    setTimeout(() => {
      refetch();
    }, 500);

    setSearchData({
      page: 0,
      size: 0,
      sortBy: "documentManagementConfigurationId",
      sortOrder: "DESC",
      documentName: data.document_name,
      documentCode: data.document_code,
      documentType: data.document_type,
    });
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "111%",
      border: "0.25px solid rgb(169, 167, 167)",
      fontSize: "12px",
      minHeight: 20,
      boxShadow: state.isFocused ? "0 0 0 0.2rem transparent" : "none",
      backgroundColor: "rgb(247, 248, 250)",
      marginTop: "-1px",
      padding: "0 5px",
    }),
    menu: (provided) => ({
      ...provided,
      maxHeight: 175,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      border: 0,
      padding: 0,
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      fontWeight: 600,
      fontSize: "12px",
      color: "#2BB9A8",
      borderBottom: ".25px solid",
      backgroundColor: isSelected ? "#D1F8E8" : isFocused ? "#fff" : "#fff",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: "0",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
    }),
  };

  if (isFetching) return <SkeletonLoader arraySize={3} isNormalForm={true} />;

  return (
    <InputFrame onSubmit={handleSubmit(onSubmit)}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <StyledGrid
          item
          sx={{ position: "relative" }}
          xs={10}
          md={3}
          sm={10}
          lg={3}
        >
          <Input
            themeType="theme1"
            label="Document Name"
            name="document_name"
            id="document_name"
            maxLength="50"
            placeholder="Enter Document Name"
            autoComplete="none"
            defaultValue={""}
            onChange={(e) => preventSpecialAndNumbersInInput(e)}
            required={false}
            inputRef={register("document_name")}
          />
          {!!errors?.document_name && (
            <Error top="3px" left="3px">
              {errors.document_name.message}
            </Error>
          )}
        </StyledGrid>
        <StyledGrid
          item
          sx={{ position: "relative" }}
          xs={10}
          md={3}
          sm={10}
          lg={3}
        >
          <LOBContainerStyled>
            <label htmlFor="lob">Line of Business</label>
            <Controller
              control={control}
              name="lob"
              render={({ field }) => (
                <Select
                  {...field}
                  id="lob"
                  options={LOB_Obj}
                  styles={customStyles}
                  placeholder="Select LOB"
                />
              )}
            />
          </LOBContainerStyled>
          {!!errors?.lob && (
            <Error top="3px" left="3px">
              {errors.lob.message}
            </Error>
          )}
        </StyledGrid>
        <Grid item xs={10} sm={10} md={9} lg={6}>
          <ButtonFrame>
            <Button type="submit" id="submit_btn" bgColor="#11555F">
              Search
            </Button>
            <Button
              themeType={"theme1"}
              btnBack={"tertiary"}
              id="reset_btn"
              type="reset"
              textColor="#11555F"
              bgColor="transparent"
              onClick={resetFields}
              borderColor="none"
            >
              Reset
            </Button>
          </ButtonFrame>
        </Grid>
      </Grid>
    </InputFrame>
  );
};

const StyledGrid = styled(Grid)`
  position: relative;
`;

const LOBContainerStyled = styled.div`
  background: #fff;
  padding: 0 26px 3px 12px;
  margin-top: -3px;
  label {
    color: rgb(17, 85, 95);
    padding-left: 1px;
    font-size: 14px;
  }
`;

const InputFrame = styled.form`
  position: relative;
  width: 100%;
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    background: white;
    padding-bottom: 30px;
  }
  .MuiButtonBase-root {
    margin-right: 10px;
  }
`;

const ButtonFrame = styled(Grid)`
  width: 100%;
  display: flex;
  margin-top: 18px;
`;
