import { Grid } from "@mui/material";
import { Button, Typography } from "../../components";
import Dropdown from "../../components/Dropdown/Dropdown";
import { StyledBg, TableFrame } from "./MasterConfig.style";
import useMasterConfig from "./useMasterConfig";
import MasterConfigTable from "./components/MasterConfigTable";
import GlobalModal from "../../components/Modal/modal";
import MasterConfigModal from "./components/MasterConfigModal";
import NewMasterModal from "./addNewMaster/components/NewMasterModal";
import useAddNewMaster from "./addNewMaster/useAddNewMaster";

function MasterConfig() {
  const { StyledH1 } = Typography;

  const {
    register,
    control,
    watch,
    errors,
    unregister,
    setValue,
    allMaster_Obj,
    isAllMastersFetching,
    isMasterDetailsByIdFetching,
    dynamicRows,
    dynamicColumns,
    masterList,
    modalState,
    handleOpen,
    handleClose,
    fieldValues,
    handleSubmit,
    handleFormSubmit,
    isAddDataInMasterLoading,
    isUpdateDataInMasterLoading,
    docFormats,
    masterTableData,
    allowedOperations,
  } = useMasterConfig();

  const {
    newMasterModal,
    setNewMasterModal,
    createNewMaster,
    masterId,
    createFieldsForMaster,
    fieldTypeOptions,
    isCreateMasterSuccess,
    isCreateFieldSuccess,
    validationGroupOptions,
    addFieldsToMasterValidation,
    addNewMasterValidation,
  } = useAddNewMaster();

  return (
    <>
      <StyledBg>
        <StyledH1
          themeType="theme1"
          partialU
          color={"rgb(23, 142, 128)"}
          align={"left"}
          style={{ marginBottom: "12px" }}
        >
          Master Configuration
        </StyledH1>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item md={3}>
            <Dropdown
              id="masterList"
              label="Select Master"
              control={control}
              selectObj={allMaster_Obj}
              maxMenuHeight={200}
              isLoading={isAllMastersFetching}
            />
          </Grid>
          <Grid item md={12}>
            <Button
              bgColor="transparent"
              textColor="#11555F"
              id="add_master"
              variant="outlined"
              borderColor="#11555F"
              onClick={() => {
                setNewMasterModal({
                  state: true,
                  title: "Add New Master",
                });
              }}
            >
              Add New Master
            </Button>
          </Grid>
        </Grid>
      </StyledBg>

      <TableFrame>
        <MasterConfigTable
          isMasterDetailsByIdFetching={isMasterDetailsByIdFetching}
          dynamicRows={dynamicRows}
          dynamicColumns={dynamicColumns}
          masterList={masterList}
          handleOpen={handleOpen}
          allowedOperations={allowedOperations}
        />
      </TableFrame>

      <GlobalModal
        open={modalState.state}
        title={modalState.title}
        onClose={handleClose}
        overflowVisible={true}
      >
        <MasterConfigModal
          masterList={masterList}
          fieldValues={fieldValues}
          title={modalState.title}
          register={register}
          control={control}
          watch={watch}
          unregister={unregister}
          setValue={setValue}
          errors={errors}
          handleSubmit={handleSubmit}
          handleFormSubmit={handleFormSubmit}
          isAddDataInMasterLoading={isAddDataInMasterLoading}
          isUpdateDataInMasterLoading={isUpdateDataInMasterLoading}
          docFormats={docFormats}
          masterTableData={masterTableData}
        />
      </GlobalModal>

      <GlobalModal
        open={newMasterModal?.state}
        title={newMasterModal?.title}
        onClose={() => {
          setNewMasterModal({
            state: false,
            title: "",
          });
        }}
        overflowVisible={true}
      >
        <NewMasterModal
          createNewMaster={createNewMaster}
          masterId={masterId}
          createFieldsForMaster={createFieldsForMaster}
          fieldTypeOptions={fieldTypeOptions}
          validationGroupOptions={validationGroupOptions}
          isCreateMasterSuccess={isCreateMasterSuccess}
          isCreateFieldSuccess={isCreateMasterSuccess}
          addFieldsToMasterValidation={addFieldsToMasterValidation}
          addNewMasterValidation={addNewMasterValidation}
        />
      </GlobalModal>
    </>
  );
}

export default MasterConfig;
