import React, { useEffect, useRef, useState } from "react";
import { StyledDiv, StyledBg } from "./style.js";
import LeadDataForm from "./leadDataForm";
import LeadActivityForm from "./leadActivityForm.js";
import PrevLeadActivity from "./prevLeadActivity.js";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetLeadById } from "../lead/service";
import {
  useGetLeadStage,
  useAddLeadActivity,
  useGetLeadActivityById,
  useUpdataLeadActivity,
  useGetLeadActivityByLeadId,
} from "./service.js";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import _ from "lodash";

const LeadActivity = () => {
  const [title, setTitle] = useState("Add");
  const [show, setShow] = useState(true);
  const location = useLocation();
  const url = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const currentUrl = location.pathname;
  const leadID = url.get("leadId");
  const activityId = url.get("id");
  const { register, control, handleSubmit, setValue, reset } = useForm();

  const { data: leadDataById } = useGetLeadById(leadID);
  const { data: leadStageData } = useGetLeadStage();
  const { mutate: addLeadActivity, data: addDataStatus } = useAddLeadActivity();
  const { data: leadActiviUpdateData } = useGetLeadActivityById(activityId);
  const { mutate: updateLeadActivity, data: updateDataStatus } =
    useUpdataLeadActivity();
  const { data: activityDataByLeadId } = useGetLeadActivityByLeadId(leadID);

  const leadData = leadDataById?.data?.data || [];
  const updateData = leadActiviUpdateData?.data?.data || [];
  const leadActivityByLeadId = activityDataByLeadId?.data?.data || [];
  const leadStageOpt =
    leadStageData?.data?.data?.map((item) => {
      return {
        label: item.leadStage,
        value: item.leadStage,
      };
    }) || [];

  useEffect(() => {
    if (currentUrl === "/lead-activity-update") {
      setTitle("Update");
    }
  }, [currentUrl]);

  useEffect(() => {
    if (currentUrl === "/lead-activity-update" && updateData) {
      setValue("leadActivity", updateData.leadActivity);
      setValue("leadStage", {
        label: updateData.leadStage,
        value: updateData.leadStage,
      });
    }
  }, [currentUrl, updateData]);

  useEffect(() => {
    if (
      (currentUrl === "/lead-activity" ||
        currentUrl === "/lead-activity-update") &&
      _.isEmpty(leadID)
    ) {
      Swal.fire({ text: "No lead Id found", icon: "error" }).then((result) => {
        if (result.isConfirmed) {
          navigate("/lead");
        }
      });
    }
  }, [currentUrl, leadID]);

  // useEffect(() => {
  //   if (updateDataStatus && updateDataStatus?.data.status) {
  //     Swal.fire({
  //       text: updateDataStatus?.data?.message,
  //       icon: "success",
  //     }).then(() => {
  //       navigate(`/lead-activity-listing`);
  //     });
  //   }
  //   if (updateDataStatus && !updateDataStatus?.data.status) {
  //     Swal.fire({
  //       text: updateDataStatus?.data?.message,
  //       icon: "error",
  //     });
  //   }
  // }, [updateDataStatus]);

  // useEffect(() => {
  //   if (addDataStatus && addDataStatus?.data.status) {
  //     Swal.fire({
  //       text: addDataStatus?.data?.message,
  //       icon: "success",
  //     }).then(() => {
  //       navigate(`/lead-activity-listing`);
  //     });
  //   }
  //   if (addDataStatus && !addDataStatus?.data.status) {
  //     Swal.fire({
  //       text: addDataStatus?.data?.message,
  //       icon: "error",
  //     });
  //   }
  // }, [addDataStatus]);

  const onSubmit = (data) => {
    if (currentUrl === "/lead-activity-update" && activityId) {
      const payload = {
        id: activityId,
        leadId: leadID,
        leadActivity: data?.leadActivity,
        leadStage: data.leadStage?.label,
      };
      updateLeadActivity(payload);
    } else {
      const payload = {
        leadId: leadID,
        leadActivity: data?.leadActivity,
        leadStage: data.leadStage?.label,
      };
      reset({
        leadActivity: null,
        leadStage: null
      })
      addLeadActivity(payload);
    }
  };

  return (
    <StyledDiv>
      <StyledBg>
        <LeadDataForm leadData={leadData} />
      </StyledBg>
      <StyledBg>
        <PrevLeadActivity
          show={show}
          onClick={() => setShow(!show)}
          leadIdActivityData={leadActivityByLeadId}
        />
      </StyledBg>
      <StyledBg>
        <LeadActivityForm
          register={register}
          control={control}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          leadStageOpt={leadStageOpt}
          title={title}
        />
      </StyledBg>
    </StyledDiv>
  );
};

export default LeadActivity;
