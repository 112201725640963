import { useLocation } from "react-router-dom";
import styled from "styled-components";
import AccessDenied from "../../components/AccessComponents/accessAllowed";
import useGetOperations from "../../hooks/useGetOperations";
import { InputFrame } from "./Brokerage.style";
import BrokerageStepper from "./BrokerageStepper";
import ConditionalBrokerage from "./ConditionalBrokerage";
import useBrokerage from "./useBrokerage";

function BrokerageConfigurator() {
  const {
    register,
    control,
    conditonalBrokerage,
    lobId,
    transactionType,
    setValue,
    effective_start_date,
    effective_end_date,
    icType,
    premiumBearableFields,
    baseRule,
    items,
    files,
    setFiles,
    downloadMaster,
    handleDragEnd,
    handleRemove,
    handleSubmit,
    throttledSubmit,
    customStyles,
    regionsObj,
    premiumBearingObj,
    resetRespectiveAmountField,
    LOB_Obj,
    transactionData,
    companyData,
    fieldSlugForExcel,
    handleFieldSlugForExcel,
    premiumAffectFields,
    selectedBrokerageAffectedFields,
    isDisabled,
    selectedBrokerageType,
    cloneButton,
    rulesLength,
    cardInFocus,
    handleFocus,
    steps,
    activeStep,
    setActiveStep,
    trigger,
    errors,
    handleSave,
    operator,
    multipleBrokerage,
    multipleOperators,
    handleRuleDelete,
    selectedBrokerage,
    single,
    bundle,
    allSingleCheckedValues,
    allBundleCheckedValues,
    isTransactionFieldFetching,
  } = useBrokerage();
  const location = useLocation();

  const { allowedOperations } = useGetOperations(location.pathname);
  return allowedOperations.includes("Edit") ? (
    <InputFrame onSubmit={handleSubmit(throttledSubmit)}>
      <StyledBg noPaddingTop={true}>
        <BrokerageStepper
          register={register}
          control={control}
          customStyles={customStyles}
          lobId={lobId}
          LOB_Obj={LOB_Obj}
          regionsObj={regionsObj}
          transactionData={transactionData}
          companyData={companyData}
          handleRemove={handleRemove}
          transactionType={transactionType}
          effective_start_date={effective_start_date}
          effective_end_date={effective_end_date}
          icType={icType}
          premiumBearingObj={premiumBearingObj}
          premiumBearableFields={premiumBearableFields}
          baseRule={baseRule}
          setValue={setValue}
          resetRespectiveAmountField={resetRespectiveAmountField}
          files={files}
          setFiles={setFiles}
          fieldSlugForExcel={fieldSlugForExcel}
          handleFieldSlugForExcel={handleFieldSlugForExcel}
          downloadMaster={downloadMaster}
          premiumAffectFields={premiumAffectFields}
          selectedBrokerageAffectedFields={selectedBrokerageAffectedFields}
          isDisabled={isDisabled}
          selectedBrokerageType={selectedBrokerageType}
          rulesLength={rulesLength}
          cloneButton={cloneButton}
          cardInFocus={cardInFocus}
          handleFocus={handleFocus}
          handleRuleDelete={handleRuleDelete}
          steps={steps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleSubmit={handleSubmit}
          throttledSubmit={throttledSubmit}
          trigger={trigger}
          errors={errors}
          handleSave={handleSave}
          operator={operator}
          multipleBrokerage={multipleBrokerage}
          multipleOperators={multipleOperators}
          selectedBrokerage={selectedBrokerage}
          single={single}
          bundle={bundle}
          allSingleCheckedValues={allSingleCheckedValues}
          allBundleCheckedValues={allBundleCheckedValues}
          isTransactionFieldFetching={isTransactionFieldFetching}
        />
        {/* <BrokerageBasicConfig
                    register={register}
                    control={control}
                    customStyles={customStyles}
                    lobId={lobId}
                    LOB_Obj={LOB_Obj}
                    transactionData={transactionData}
                    companyData={companyData}
                /> */}
      </StyledBg>
      {/* {lobId?.lobId && (
                <StyledBg>
                    <BrokeragePremiumFields
                        register={register}
                        control={control}
                        handleRemove={handleRemove}
                        lobId={lobId}
                        transactionType={transactionType}
                        effective_start_date={effective_start_date}
                        effective_end_date={effective_end_date}
                        icType={icType}
                        premiumBearingObj={premiumBearingObj}
                        customStyles={customStyles}
                        premiumBearableFields={premiumBearableFields}
                        baseRule={baseRule}
                        resetRespectiveAmountField={resetRespectiveAmountField}
                    />
                </StyledBg>
            )} */}
      {/* {isLobPresent && lobId?.lobId && (
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Checkbox
                            name="conditonalBrokerage"
                            label="Enable Conditional Brokerage"
                            checkboxFirst={false}
                            control={control}
                        />
                    </Grid>
                </Grid>
            )} */}
      {conditonalBrokerage && (
        <StyledBg>
          <ConditionalBrokerage
            control={control}
            handleRemove={handleRemove}
            regionsObj={regionsObj}
            items={items}
            files={files}
            setFiles={setFiles}
            downloadMaster={downloadMaster}
            handleDragEnd={handleDragEnd}
          />
        </StyledBg>
      )}
    </InputFrame>
  ) : (
    <AccessDenied />
  );
}

export default BrokerageConfigurator;

const StyledBg = styled.div`
  padding: ${(props) => (props.noPaddingTop ? "0 0 28px 0" : "28px 0")};
  margin-bottom: 15px;
`;
