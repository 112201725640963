import { useQuery } from "@tanstack/react-query";
import HttpClient from "../api/httpClient";

const useGetLOBMaster = () => {
  const { data, isFetching } = useQuery({
    queryKey: ["LOB"],
    queryFn: () => HttpClient("lob/list"),
  });

  return { data, isFetching };
};

export default useGetLOBMaster;
