import { useState } from "react";
import styled from "styled-components";
import { Typography } from "../../components";
import { BrokerOfficeForm } from "./b-office-form/b-office-form";
import { BrokerOfficeTable } from "./b-office-table/b-office-table";
import BulkUpload from "./modify-location/bulkUpload";
import { ModifyLocation } from "./modify-location/modify-location";
import GlobalModal from "../../components/Modal/modal";

//Required Typography
const { StyledH1 } = Typography;

export const BrokerOfficeConfig = () => {
  const [isOpen, setOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [bulkmodal, setBulkmodal] = useState(false);
  const [preFillData, setPrefillData] = useState({});
  const [searchData, setSearchData] = useState({
    page: 0,
    size: 0,
    sortBy: "brokerOfficeLocationRelationId",
    sortOrder: "DESC",
    officeCode: "",
    pincode: "",
  });

  return (
    <>
      <StyledDiv>
        <StyledBg>
          <StyledH1
            themeType="theme1"
            partialU
            color={"rgb(23, 142, 128)"}
            align={"left"}
            style={{ marginBottom: "12px" }}
          >
            Broker Office Configuration
          </StyledH1>
          <BrokerOfficeForm
            searchData={searchData}
            setSearchData={setSearchData}
          />
        </StyledBg>
        <TableFrame>
          <BrokerOfficeTable
            setBulkmodal={setBulkmodal}
            setPrefillData={setPrefillData}
            setModalTitle={setModalTitle}
            setOpen={setOpen}
            searchData={searchData}
          />
        </TableFrame>
      </StyledDiv>

      <GlobalModal
        open={isOpen}
        title={modalTitle + " Location"}
        onClose={() => {
          setOpen(false);
        }}
      >
        <ModifyLocation
          preFillData={preFillData}
          modalTitle={modalTitle}
          isOpen={isOpen}
          handleClose={() => {
            setOpen(false);
          }}
        />
      </GlobalModal>

      <GlobalModal
        open={bulkmodal}
        title="Broker Bulk Upload"
        onClose={() => {
          setBulkmodal(false);
        }}
      >
        <BulkUpload
          BulkModal={bulkmodal}
          handleClose={() => {
            setBulkmodal(false);
          }}
        />
      </GlobalModal>
    </>
  );
};

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledBg = styled.div`
  background: #fff;
  padding: 24px 16px;
  margin-right: 7px;
`;

const TableFrame = styled.div`
  display: flex;
  position: relative;
  align-items: ${({ position }) => (position ? "start" : "center")};
  flex-direction: column;
  font-family: "Nunito";
  box-shadow: 0px 22px 45px -15px #dddde2;
  background-color: #ffffff;
  margin-top: 15px;
  padding: 20px;
  max-width: 99.5% !important;
`;
