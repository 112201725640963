import { useState } from "react";
import styled from "styled-components";
import { Typography } from "../../components";
import { useDeleteDocumentData } from "./service";
import { DocumentManagementModal } from "./document-management-modal/document-management-modal";
import { DocManagementForm } from "./doc-form/Doc-form";
import { DocManagementTable } from "./doc-table/Doc-table";
import GlobalModal from "../../components/Modal/modal";

//Required Typography
const { StyledH1 } = Typography;

export const DocumentManagementConfig = () => {
  const { mutate: deleteDocument } = useDeleteDocumentData();
  const [isOpen, setOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [preFillData, setPrefillData] = useState({});
  const [searchData, setSearchData] = useState({
    page: 0,
    size: 0,
    sortBy: "documentManagementConfigurationId",
    sortOrder: "DESC",
    documentName: "",
    documentCode: "",
    documentType: "",
  });

  const handleClose = () => {
    setOpen(false);
  };
  const handleUserDeletion = (id) => {
    deleteDocument(id);
  };
  return (
    <StyledDiv>
      <StyledBg>
        <StyledH1
          partialU
          color={"rgb(23, 142, 128)"}
          align={"left"}
          style={{ marginBottom: "12px" }}
        >
          Document Management Configuration
        </StyledH1>
        <DocManagementForm
          searchData={searchData}
          setSearchData={setSearchData}
          setModalTitle={setModalTitle}
          setOpen={setOpen}
        />
      </StyledBg>
      <TableFrame>
        <DocManagementTable
          handleUserDeletion={handleUserDeletion}
          setPrefillData={setPrefillData}
          setModalTitle={setModalTitle}
          setOpen={setOpen}
          searchData={searchData}
        />
      </TableFrame>
      <GlobalModal
        open={isOpen}
        title={modalTitle + " Document"}
        onClose={handleClose}
        overflowVisible={true}
      >
        <DocumentManagementModal
          preFillData={preFillData}
          modalTitle={modalTitle}
          isOpen={isOpen}
          handleClose={handleClose}
        />
      </GlobalModal>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
`;

const TableFrame = styled.div`
  display: flex;
  position: relative;
  align-items: ${({ position }) => (position ? "start" : "center")};
  flex-direction: column;
  font-family: "Nunito";
  box-shadow: 0px 22px 45px -15px #dddde2;
  background-color: #ffffff;
  margin-top: 15px;
  padding: 20px;
  max-width: 99.5% !important;
`;

const StyledBg = styled.div`
  background: #fff;
  padding: 24px 16px;
  margin-right: 7px
`;
