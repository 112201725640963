import styled from "styled-components";
import Form from "./Form/Form";
import ConfigurationDetails from "./configuration-details/ConfigurationDetails";
import DownForm from "./downForm/DownForm";

const DashboardController = () => {
  return (
    <StyledDiv>
      <Form />
      <ConfigurationDetails />
      <DownForm />
    </StyledDiv>
  );
};

const StyledDiv = styled.div``;

export default DashboardController;

//transiton border first then shadow
