import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import useGetOperations from "../../../../hooks/useGetOperations";
// import { Tooltip } from "chart.js";

const CustomerDocumentTable = ({
  rows,
  setId,
  setModalTitle,
  setOpen,
  setRowData = () => {},
}) => {
  function isValidURL(text) {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return urlRegex.test(text);
  }
  const handleFilePreview = (url) => {
    if (isValidURL(url)) {
      window.open(url, "_blank");
    } else {
      Swal.fire({
        text: "Invalid URL",
        icon: "warning",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        allowOutsideClick: false,
        focusCancel: false,
        scrollbarPadding: false,
      });
    }
  };
  const location = useLocation();

  const { allowedOperations } = useGetOperations(location.pathname);
  const columns = [
    {
      field: "docName",
      headerName: "Document Name",
      flex: 1,
      background: "#E4E4E4",
    },
    {
      field: "name",
      headerName: "Document Link",
      flex: 1,
      background: "#E4E4E4",
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <CellContainer>
            <CellStyled>
              {params?.row?.name}
              <StyledIcon>
                <ArrowOutwardIcon
                  onClick={() => handleFilePreview(params.row?.name)}
                />
              </StyledIcon>
            </CellStyled>
          </CellContainer>
        );
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      headerAlign: "left",
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <ActionContainer>
            <PreviewOutlinedIcon
              id={`view_${params.row.id}`}
              onClick={() => {
                setModalTitle("View Documents For");
                setRowData(params?.row);
                setOpen(true);
                setId(params?.row?.customerId);
              }}
              index={params.row.id}
            />
            {allowedOperations.includes("Edit ") ? (
              <BorderColorOutlinedIcon
                id={`edit_${params.row.customerId}`}
                onClick={() => {
                  setModalTitle("Modify Documents For");
                  setOpen(true);
                  setRowData(params?.row);
                  setId(params?.row?.customerId);
                }}
                index={params.row.id}
              />
            ) : (
              <></>
            )}

            {/* <Tooltip title="Delete Customer" arrow> */}
            {allowedOperations.includes("Edit ") ||
            allowedOperations.includes("Delete") ? (
              <DeleteForeverOutlinedIcon
                id={`delete_${params.row.id}`}
                onClick={() => {
                  Swal.fire({
                    title: "Delete record?",
                    text: "Confirmation required to delete this record.",
                    icon: "question",
                    confirmButtonText: "Delete",
                    confirmButtonColor: "#DC004E",
                    showCancelButton: true,
                    cancelButtonText: "Cancel",
                    reverseButtons: true,
                    focusConfirm: false,
                    focusCancel: false,
                    scrollbarPadding: false,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      //   handleCustomerDeletion(params.row.id);
                      Swal.fire("Deleted!", "Deleted Successfully", "success");
                    }
                  });
                }}
                index={params.row.id}
              />
            ) : (
              <></>
            )}

            {/* </Tooltip> */}
          </ActionContainer>
        );
      },
    },
  ];

  const getRowId = (row) => {
    return row.id;
  };
  return (
    <>
      <TableHeading>
        <Heading>List of Customer</Heading>
      </TableHeading>
      <TableContainer>
        <DataGrid
          rows={rows}
          columns={columns}
          size="small"
          fontSize={12}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 20 },
            },
          }}
          pageSizeOptions={[20, 40]}
          getRowId={getRowId}
          sx={{
            ".MuiDataGrid-cellContent": {
              fontSize: "14px",
            },
            ".MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "&.MuiDataGrid-root": {
              border: "none",
            },
            ".MuiDataGrid-columnHeaders": {
              background: "#D2F8E9",
              color: "#11555F",
              fontSize: "14px",
              fontWeight: 500,
              fontFamily: "Montserrat-Medium",
            },
            ".MuiTablePagination-root p": {
              fontSize: "14px",
            },
          }}
        />
      </TableContainer>
    </>
  );
};

export default CustomerDocumentTable;

const TableHeading = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 12px;
`;

const StyledIcon = styled.p`
  margin: 0;
  margin-right: 5px;
  svg {
    color: #178e80;
    font-size: 20px;
  }
`;
const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  gap: 4px;
  svg {
    color: #178e80;
    font-size: 20px;
  }
`;

const CellStyled = styled.p`
  display: flex;
  gap: 8%;
  margin: 5px;
  border: 0.25px solid;
  border-radius: 5px;
  padding: 5px 20px;
`;

export const Heading = styled.h2`
  margin-top: -3px;
  margin-bottom: 0;
  display: flex;
  color: #178e80;
  justify-content: space-between;
  font-family: "Montserrat-SemiBold";
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
`;

const TableContainer = styled.div`
  background: #fff;
  width: 100%;
`;

const CellContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;
