import { useGetAccessRightsMenu } from "../Layout/Sidebar/service";

const useGetOperations = (path) => {
  const isMenusPresent =
    sessionStorage.getItem("menus") &&
    JSON.parse(sessionStorage.getItem("menus"));

  const { data } = useGetAccessRightsMenu();

  const handleOperations = (array) => {
    return Array.isArray(array)
      ? array?.find((value) => path === value?.menu_data?.menu_slug || "#")
      : [];
  };

  const relevantMenu = isMenusPresent
    ? handleOperations(isMenusPresent?.data?.return_data)
    : handleOperations(data?.data?.return_data);

  const allowedOperations = relevantMenu?.menu_data?.operation_id
    ? Object.values(relevantMenu.menu_data.operation_id)
    : [];

  return { allowedOperations };
};

export default useGetOperations;
