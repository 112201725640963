import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Button } from "../../../components";
import img1 from "../../../utils/Accident.png";
import {
  setHolidayErrorAction,
  setHolidaySuccessAction,
} from "../holiday-configuration.slice";
import { useGetHolidayDetails } from "../service";
import {
  ActionContainer,
  Heading,
  ParentDate,
  SameMonthParentDate,
  TableContainer,
  TableHeaderContainer,
} from "../style/holida-style";
import { useLocation } from "react-router-dom";
import useGetOperations from "../../../hooks/useGetOperations";

export const HolidayTable = ({
  setOpen,
  setModalTitle,
  setBulkmodal,
  setID,
  handleEventDelete,
}) => {
  const location = useLocation();
  const { allowedOperations } = useGetOperations(location.pathname);
  const { data, isLoading } = useGetHolidayDetails();

  const dispatch = useDispatch();

  let rows = data?.data?.data?.map((value) => {
    return {
      id: value.broker_holiday_config_id,
      event_date: value.event_date,
      start_date: value.start_date,
      end_date: value.end_date,
      festival: value.event_name,
      image: img1,
      office_relation_id: value?.broker_office_location_relation_id,
    };
  });

  const columns = [
    {
      field: "Date",
      background: "#fff",
      flex: 3,
      renderCell: (params) => {
        if (
          moment(params.row.start_date).isSame(
            moment(params.row.end_date),
            "day"
          )
        ) {
          return (
            <ParentDate>
              {new Date(params.row.start_date).getDate()}{" "}
              {new Date(params.row.start_date).toLocaleString(undefined, {
                month: "long",
              })}
              , {new Date(params.row.start_date).getFullYear()}
            </ParentDate>
          );
        } else if (
          new Date(params.row.start_date).getMonth() ===
          new Date(params.row.end_date).getMonth()
        ) {
          return (
            <SameMonthParentDate className="same_month">
              {new Date(params.row.start_date).getDate()}{" "}
              {new Date(params.row.start_date)
                .toLocaleString(undefined, {
                  month: "long",
                })
                .slice(0, 3)}{" "}
              - {new Date(params.row.end_date).getDate()}{" "}
              {new Date(params.row.end_date)
                .toLocaleString(undefined, {
                  month: "long",
                })
                .slice(0, 3)}
              , {new Date(params.row.end_date).getFullYear()}
            </SameMonthParentDate>
          );
        } else {
          return (
            <SameMonthParentDate className="same_month">
              {new Date(params.row.start_date).getDate()}{" "}
              {new Date(params.row.start_date)
                .toLocaleString(undefined, {
                  month: "long",
                })
                .slice(0, 3)}{" "}
              - {new Date(params.row.end_date).getDate()}{" "}
              {new Date(params.row.end_date)
                .toLocaleString(undefined, {
                  month: "long",
                })
                .slice(0, 3)}
              , {new Date(params.row.end_date).getFullYear()}
            </SameMonthParentDate>
          );
        }
      },
    },
    {
      field: "Day Difference/Weekday",
      flex: 3,
      background: "red",
      renderCell: (params) => {
        const dayDifference = moment(params.row.start_date).isSame(
          moment(params.row.end_date),
          "day"
        )
          ? moment(params.row.start_date, "YYYY-MM-DD").format("dddd")
          : moment(params.row.end_date).diff(params.row.start_date, "days") +
            1 +
            " days";

        return <>{dayDifference}</>;
      },
    },
    {
      field: "Event",
      flex: 3,
      background: "red",
      renderCell: (params) => {
        return (
          <>
            <>{params.row.festival}</>
          </>
        );
      },
    },
    {
      field: "Office Relation ID",
      flex: 3,
      background: "#fff",
      renderCell: (params) => {
        return <div>{params.row.office_relation_id}</div>;
      },
    },
    {
      field: "Actions",
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      align: "flex-end",
      renderCell: (params) => {
        return (
          <ActionContainer>
            {(allowedOperations.includes("View") ||
              allowedOperations.includes("Edit") ||
              allowedOperations.includes("Delete")) && (
              <PreviewOutlinedIcon
                id={`view_${params.row.id}`}
                index={params.row.id}
                onClick={() => {
                  dispatch(setHolidaySuccessAction(null));
                  setModalTitle("View");
                  setOpen(params.row.id);
                  setID(params.row.id);
                }}
              />
            )}
            {allowedOperations.includes("Edit") && (
              <BorderColorOutlinedIcon
                id={`edit_${params.row.id}`}
                index={params.row.id}
                onClick={() => {
                  dispatch(setHolidaySuccessAction(null));
                  setModalTitle("Modify");
                  setOpen(params.row.id);
                  setID(params.row.id);
                }}
              />
            )}
            {(allowedOperations.includes("Delete") ||
              allowedOperations.includes("Edit")) && (
              <DeleteForeverOutlinedIcon
                id={`delete_${params.row.id}`}
                onClick={() => handleEventDelete(params.row.id)}
                index={params.row.id}
              />
            )}
          </ActionContainer>
        );
      },
    },
  ];

  return (
    <>
      <TableHeaderContainer>
        <Heading> Upcoming Holiday</Heading>
        {allowedOperations.includes("Edit") && (
          <div>
            <Button
              bgColor="transparent"
              textColor="#178e80"
              variant="outlined"
              borderColor="#178e80"
              id="bulk_btn"
              startIcon={<AddBoxOutlinedIcon />}
              onClick={() => {
                setModalTitle("Add");
                setOpen(true);
                dispatch(
                  setHolidaySuccessAction(null),
                  setHolidayErrorAction(null)
                );
              }}
            >
              Add Holiday
            </Button>
            <Button
              bgColor="#1E7D8A"
              textColor="#fff"
              variant="outlined"
              borderColor="#fff"
              id="bulk_btn"
              onClick={() => {
                setBulkmodal(true);
              }}
            >
              Bulk Upload
            </Button>
          </div>
        )}
      </TableHeaderContainer>

      <TableContainer>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <DataGrid
            rows={rows ? rows : []}
            rowHeight={75}
            columns={columns}
            size="small"
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
            }}
            pageSizeOptions={[5, 20]}
            sx={{
              ".MuiDataGrid-columnSeparator": {
                display: "none",
              },
              "&.MuiDataGrid-root": {
                border: "none",
              },
              ".MuiDataGrid-columnHeaders": {
                background: "#D2F8E9",
                color: "#11555F",
                fontSize: "14px",
                fontWeight: 500,
                fontFamily: "Montserrat-Medium",
              },
              ".MuiTablePagination-root p": {
                fontSize: "14px",
              },
              ".MuiDataGrid-row.": {
                minHeight: "100px !important",
              },
              ".MuiDataGrid-cell:focus": {
                outline: "none",
              },
            }}
          />
        )}
      </TableContainer>
    </>
  );
};
