import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import * as yup from "yup";
import useGetLOBMaster from "../../hooks/useGetLOBMaster";
import useGetMasterTableData from "../../hooks/useGetMasterTableData";
import { useGetStateCityFromPincode } from "../broker-configuration/service";
import {
  setCustomerErrorAction,
  setCustomerSuccessAction,
} from "./customer.slice";
import {
  useAddAdditionalDetails,
  useAddVehicleDetails,
  useCreateCustomer,
  useGetCustomerList,
  // useGetCustomerRecordsById,
  useGetDocumentDataForCustomer,
  useUpdateAdditionalDetails,
} from "./service";
import { useGetMasterDetailsById } from "../MasterConfig/service";
const useCustomer = () => {
  const navigate = useNavigate();
  const yupValidate = yup.object({
    firstName: yup.string().required("First Name is Required"),
    lastName: yup.string().required("Last Name is Required"),
    dob: yup
      .date()
      .required("Date of Birth is required")
      .typeError("Invalid Date")
      .max(new Date(), "Cannot be greater than current date"),
    gender: yup.mixed().required("Gender is required"),
    email: yup
      .string()
      .email("Please enter valid email id")
      .min(5, "Please enter valid email id")
      .max(50, "Must be less than 50 chars")
      .required("Email id is required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
        "Please enter valid email id"
      )
      .trim(),
    alternateEmail: yup.lazy((value) => {
      if (value) {
        return yup
          .string()
          .trim()
          .email("Please enter valid email id")
          .min(5, "Please enter valid email id")
          .max(50, "Must be less than 50 chars")
          .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
            "Please enter valid email id"
          )
          .notOneOf([yup.ref("email")], "Alternate Email cannot be same");
      }
      return yup.mixed().notRequired();
    }),
    mobileNo: yup
      .string()
      .required("Mobile No. is required")
      .matches(/^[6-9][0-9]{9}$/, "Invalid mobile number")
      .min(10, "Mobile No. should be 10 digits")
      .max(10, "Mobile No. should be 10 digits"),
    alternateMobileNo: yup.lazy((value) => {
      if (value) {
        return yup
          .string()
          .matches(/^[6-9][0-9]{9}$/, "Invalid mobile number")
          .min(10, "Mobile No. should be 10 digits")
          .max(10, "Mobile No. should be 10 digits")
          .notOneOf([yup.ref("mobileNo")], "Alternate no. cannot be same");
      }
      return yup.mixed().notRequired();
    }),
    panNo: yup.lazy((value) => {
      if (value) {
        return yup
          .string()
          .matches(
            /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
            "PAN number invalid"
          );
      }
      return yup.mixed().notRequired();
    }),
    aadharNo: yup.lazy((value) => {
      if (value) {
        return yup
          .string()
          .matches(/^\d{4}\d{4}\d{4}$/, "Adhaar Number Invalid");
      }
      return yup.mixed().notRequired();
    }),
  });
  const { data: educationData } = useGetMasterDetailsById({ id: 21 });
  const { data: leadSourceData } = useGetMasterDetailsById({ id: 39 });

  const educationObj =
    educationData &&
    educationData?.data?.data?.masterData?.map((item) => {
      return {
        label: item.education,
        value: item.id,
      };
    });
  const leadSourceObj =
    leadSourceData &&
    leadSourceData?.data?.data?.masterData?.map((item) => {
      return {
        label: item.lead_source,
        value: item.lead_source_master_id,
      };
    });
  const GenderObj = [
    { id: "MALE", label: "Male", value: "Male" },
    { id: "FEMALE", label: "Female", value: "Female" },
    { id: "OTHERS", label: "Others", value: "Others" },
  ];

  const YesNoObj = [
    { id: "Y", label: "Yes", value: "Yes" },
    { id: "N", label: "No", value: "No" },
  ];

  const {
    handleSubmit,
    register,
    control,
    trigger,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yupValidate),
    mode: "all",
    reValidateMode: "onChange",
  });

  const [pincode, setPincode] = useState("");
  const [modalTitle, setModalTitle] = useState();
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState();
  const occupationFeild = [
    { masterSlug: "occupation_mapping", fieldSlug: "occupation" },
  ];
  const { customerError, customerSuccess } = useSelector(
    (state) => state.customer
  );

  const dispatch = useDispatch();

  const { data } = useGetStateCityFromPincode(pincode);

  const { data: lobMaster } = useGetLOBMaster();
  const { data: OccupationData } = useGetMasterTableData(occupationFeild);
  const { mutate: createCustomer } = useCreateCustomer();
  const { data: CustomerListData } = useGetCustomerList();
  // const { data: customerRecordById } = useGetCustomerRecordsById(rowData?.id);
  const { mutate: getvehicleDetails } = useAddVehicleDetails();
  const { mutate: getAdditionalDocDetails } = useAddAdditionalDetails();
  const { mutate: updateAdditionalDocDetails, isError } =
    useUpdateAdditionalDetails();
  const { data: docData } = useGetDocumentDataForCustomer();

  // const prefillData = customerRecordById?.data?.data;

  const OccupationOBJ = OccupationData?.[0]?.data?.data?.[0]?.data?.map(
    (item) => {
      return {
        value: item?.optionValue,
        label: item?.optionKey,
        id: item?.optionValue,
      };
    }
  );

  const docDataForUploadDocumentsOBJ = docData?.data?.data?.map((item) => {
    return {
      value: item?.documentType,
      label: item?.documentName,
      id: item?.documentManagementConfigurationId,
    };
  });

  const LOB_Obj = lobMaster?.data?.data?.map((value) => {
    return {
      value: value.lob,
      label: value.lob,
      lobId: value.lob_id,
    };
  });

  const rows = Array.isArray(CustomerListData?.data?.data)
    ? CustomerListData?.data?.data?.map((item) => {
        return {
          id: item?.customerId,
          firstName: item?.firstName,
          lastName: item?.lastName,
          emailId: item?.emailId,
          mobile_number: item?.mobile_number,
          gender: item?.gender,
          dob: item?.dob,
          education: item?.education,
          occupation: item?.occupation,
          referenceId: item?.customerRefId,
        };
      })
    : [];

  const handleClose = () => {
    setOpen(false);
  };

  // useEffect(() => {
  //   if (modalTitle === "Add Family Member For") {
  //     setValue("firstName", prefillData?.firstName);
  //     setValue("lastName", prefillData?.lastName);
  //     setValue("email", prefillData?.emailId);
  //     setValue("alternateEmail", prefillData?.alternateEmailId);
  //     setValue("mobileNo", prefillData?.mobile_number);
  //     setValue("alternateMobileNo", prefillData?.alternateMobileNumber);
  //     // setValue("gender", {
  //     //   id: prefillData?.gender?.toUpperCase(),
  //     //   value: prefillData?.gender,
  //     //   label: prefillData?.gender,
  //     // });
  //     // setValue('dob', new Date(prefillData?.dob))
  //     // setValue('anniversaryDate', new Date(prefillData?.anniversaryDate))
  //     setValue("education", prefillData?.education);
  //     // setValue('occupation', prefillData?.occupation)
  //     setValue("designation", prefillData?.designation);
  //     setValue("annualIncome", prefillData?.annualIncome);
  //     setValue("panNo", prefillData?.panNumber);
  //     setValue("aadharNo", prefillData?.aadharNumber);
  //     setValue("uccNo", prefillData?.uccNo);
  //     setValue("businessChannel", prefillData?.businessChannel);
  //     setValue("pincode", prefillData?.pinCode);
  //     setValue("city", prefillData?.city);
  //     setValue("state", prefillData?.state);
  //     setValue("remark", prefillData?.remark);
  //   }
  // }, [prefillData]);

  useEffect(() => {
    if (isError) {
      Swal.fire({
        text: "Modification Failed !!",
        icon: "warning",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        allowOutsideClick: false,
        focusCancel: false,
        scrollbarPadding: false,
      });
    }
  }, [isError]);
  useEffect(() => {
    if (customerError) {
      Swal.fire({
        text: customerError,
        icon: "warning",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        allowOutsideClick: false,
        focusCancel: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(setCustomerErrorAction(null));
        }
      });
    }
  }, [customerError, dispatch]);

  useEffect(() => {
    if (customerSuccess) {
      Swal.fire({
        text: customerSuccess,
        icon: "success",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        focusCancel: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(setCustomerSuccessAction(null));
          handleClose();
          navigate("/customer");
        }
      });
    }
  }, [customerSuccess, dispatch, navigate]);

  return {
    GenderObj,
    YesNoObj,
    pincode,
    setPincode,
    setRowData,
    rowData,
    OccupationData,
    data,
    LOB_Obj,
    createCustomer,
    OccupationOBJ,
    modalTitle,
    setModalTitle,
    open,
    setOpen,
    setValue,
    trigger,
    handleSubmit,
    register,
    errors,
    control,
    getAdditionalDocDetails,
    updateAdditionalDocDetails,
    handleClose,
    getvehicleDetails,
    rows,
    docDataForUploadDocumentsOBJ,
    educationObj,
    leadSourceObj,
  };
};

export default useCustomer;
