import { useMutation, useQueryClient } from "@tanstack/react-query";
import httpClient from "../../../../api/httpClient";
import {
  removeAllFieldsFromMaster,
  setActiveTab,
  setAddNewMasterErrorAction,
  setAddNewMasterSuccessAction,
} from "../AddNewMaster.slice";
import { useDispatch } from "react-redux";

export const useCreateNewMaster = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate, data, isSuccess } = useMutation(
    (data) => {
      return httpClient("/master_data_manager/add", {
        method: "POST",
        data: data,
      });
    },
    {
      onSuccess: (data) => {
        if (!data?.data?.status) {
          dispatch(setAddNewMasterErrorAction(data.message));
          return;
        }
        queryClient.invalidateQueries(["all_masters"]);
        dispatch(setAddNewMasterSuccessAction(data.message));
        dispatch(setActiveTab(1));
      },
    }
  );
  return {
    createNewMaster: mutate,
    masterId: data?.data?.data[0]?.masterId,
    isSuccess: isSuccess && data?.data?.status,
  };
};

export const useCreateFieldsForMaster = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate, isSuccess, data } = useMutation(
    (data) => {
      return httpClient("/master_data_manager_field_relation/add", {
        method: "POST",
        data: data,
      });
    },
    {
      onSuccess: (data) => {
        if (!data?.data?.status) {
          dispatch(setAddNewMasterErrorAction(data.message));
          return;
        }
        queryClient.invalidateQueries(["all_masters"]);
        dispatch(setAddNewMasterSuccessAction(data.message));
        dispatch(removeAllFieldsFromMaster([]))
      },
    }
  );
  return {
    createFieldsForMaster: mutate,
    isSuccess: isSuccess && data?.data?.status,
  };
};
