import React from "react";
import { DisplayContainer, InputFrame } from "./style";
import { Grid } from "@mui/material";
import { Typography } from "../../components";
import _ from "lodash";
import { formatLocalDate } from "../dashboard/helper";
import moment from "moment";

const { StyledH1 } = Typography;

const LeadDataForm = ({ leadData }) => {
  const convertCamelCaseToNormal = (str) => {
    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const normalizedLeadData = Object.entries(leadData).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [convertCamelCaseToNormal(key)]: value,
    }),
    {}
  );

  const modifyString = value => {
    return value.replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  }

  return (
    <>
      <InputFrame>
        <StyledH1>Lead Data</StyledH1>
        <Grid
          style={{ marginBottom: "10px" }}
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          {Object.entries(normalizedLeadData)
            .filter(
              ([key]) =>
                ![
                  "Lead Id",
                  "Broker Id",
                  "Lob Id",
                  "User Id",
                  "Role Id",
                  "Created At",
                  "Updated At",
                  "Status",
                ].includes(key)
            )
            .map(([label, value]) => (
              <Grid
                item
                sx={{ position: "relative" }}
                xs={12}
                sm={12}
                md={6}
                lg={3}
                key={label}
              >
                <DisplayContainer>
                  <label>{label}</label>
                  <div className="doc-display">
                    <label>
                      {console.log(label)}
                      {["Lead Generation Date", "Last Activity"].includes(label)
                        ? moment(value).format("DD/MM/YYYY")
                        : label === "Lead Status" && value === "IN_PROGRESS" ? modifyString(value) : value}
                    </label>
                  </div>
                </DisplayContainer>
              </Grid>
            ))}
        </Grid>
      </InputFrame>
    </>
  );
};

export default LeadDataForm;
