import { createSlice } from "@reduxjs/toolkit";
import { serializeError } from "../../utils";

const reportConfig = createSlice({
    name: "reportConfig",
    initialState: {
        reportError: null,
        reportSuccess: null,
    },
    reducers: {
        setReportSuccessAction: (state, { payload }) => {
            state.reportSuccess = payload;
            state.reportError = null;
        },
        setReportErrorAction: (state, { payload }) => {
            state.reportError = serializeError(payload);
            state.reportSuccess = null;
        },
    },
});

export const { setReportSuccessAction, setReportErrorAction } =
    reportConfig.actions;
export default reportConfig.reducer;
