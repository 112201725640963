import { CallMade } from "@mui/icons-material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import Swal from "sweetalert2";
import { Button } from "../../../components";
import PNGImage from "../../../utils/png.png";

const AdditionalDocFilePicker = ({
  control,
  name,
  mainArrayIndex,
  setValue,
  watch,
  unregister,
  additionalFile,
  document,
  trigger,
  isReadOnly,
}) => {
  const [isFileSelected, setIsFileSelected] = useState(false);

  useEffect(() => {
    if (Array.isArray(document) && document.length > 0) {
      const newExtensionFormat = {};
      document?.forEach((doc) => {
        if (doc?.docType?.value === "doc") {
          newExtensionFormat["application/msword"] = [".doc"];
        } else if (doc?.docType?.value === "Excel") {
          newExtensionFormat["application/vnd.ms-excel"] = [".xls"];
          newExtensionFormat[
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ] = [".xlsx"];
        } else if (doc?.docType?.value === "pdf") {
          newExtensionFormat["text/plain"] = [".pdf"];
        } else if (doc?.docType?.value === "png") {
          newExtensionFormat["image/png"] = [".png"];
        }
      });
    }
  }, [document]);
  const onDrop = async (acceptedFiles, rejectedFiles) => {
    if (!_.isEmpty(rejectedFiles)) {
      // Show SweetAlert error for invalid file type
      Swal.fire({
        text: "Document Format Not Supported",
        icon: "error",
      });
      return;
    }

    if (isFileSelected) {
      // Show SweetAlert error for adding more than one file
      Swal.fire({
        text: "You can only upload one file",
        icon: "error",
      });
      return;
    }

    const base64Files = await Promise.all(
      acceptedFiles.map(async (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve({
              name: file.name,
              base64: reader.result.split(",")[1], // Extract the Base64 data
            });
          };

          reader.onerror = (error) => {
            reject(error);
          };

          reader.readAsDataURL(file);
        });
      })
    );

    const firstDocType = document.length > 0 ? document[0].docType : null;

    // Get the id from the first docType object
    const docTypeId = firstDocType?.id || null;
    setValue(name, {
      ...base64Files[0],
      docManagementConfigId: docTypeId,
    });

    setIsFileSelected(true);
    trigger([name]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
    },
  });
  const removeSelection = (fileObject) => {
    setIsFileSelected(false);
    unregister(name);
    setValue(name, null);
  };

  const selectedFile =
    Array.isArray(additionalFile) && additionalFile.length
      ? additionalFile.find((_, index) => index === mainArrayIndex)
      : [];
  const isValidURL = (text) => {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return urlRegex.test(text);
  };
  const handleRealFilePreview = (url) => {
    if (url) {
      window.open(url, "_blank");
    }
  };
  const PreviewButton = (
    <RealPreviewStyled readOnlyStyles={isReadOnly}>
      <Button
        bgColor="transparent"
        textColor="#11555F"
        id="add_broker"
        variant="outlined"
        borderColor="#11555F"
        endIcon={<CallMade />}
        onClick={() => handleRealFilePreview(watch(name))}
      >
        Preview an Uploaded File
      </Button>
    </RealPreviewStyled>
  );
  return isReadOnly ? (
    PreviewButton
  ) : (
    <FilePickerContainerStyled>
      <div>
        <Controller
          name={name}
          control={control}
          render={() => (
            <div
              {...getRootProps()}
              id="customerAddDocFilePicker"
              className="file-picker"
            >
              <input {...getInputProps()} />
              <FileUploadOutlinedIcon />
              <div>
                <p className="upload-img">
                  <b>Browse Image</b>
                </p>
                <p className="warnings">
                  File Formats: (.png .jpeg) - Max File Size: 1MB
                </p>
                {/* {error && <p className="warnings">File size too large</p>} */}
              </div>
            </div>
          )}
        />
      </div>
      <div>
        {selectedFile?.files && typeof selectedFile?.files !== "string" ? (
          <SelectedFileStyled>
            <p>Uploaded Image :</p>
            <FileStyled>
              <div>
                <img src={PNGImage} alt="file logo" />
                <p>{selectedFile?.files?.name?.substring(0, 25)}</p>
              </div>
              <CloseOutlinedIcon
                onClick={() => removeSelection(mainArrayIndex)}
              />
            </FileStyled>
          </SelectedFileStyled>
        ) : isValidURL(watch(name)) ? (
          <>{PreviewButton}</>
        ) : (
          <></>
        )}
      </div>
    </FilePickerContainerStyled>
  );
};

export default AdditionalDocFilePicker;

const FilePickerContainerStyled = styled.div`
  display: flex;
  gap: 20px;
  & > div:first-of-type {
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 2px dashed rgb(228, 228, 228);
    padding: 6px;
    & > div > p {
      font-weight: 600;
      font-size: 15px;
      margin: 0 0 0 20px;
    }
    .warnings {
      font-weight: 500;
      margin: 0px 0px 0px 0px;
      font-size: 12px;
      color: red;
    }
  }
  svg {
    font-size: 1.9rem;
  }
  b {
    color: rgb(23, 142, 128);
    text-decoration: underline;
  }

  .upload-img {
    margin: 0;
  }

  .file-picker {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
`;

const SelectedFileStyled = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 8px 0px 8px p {
    margin: 0;
  }
  & > p {
    padding: 10px;
    font-family: Montserrat-Medium;
    color: rgb(17, 85, 95);
    line-height: 18px;
    font-size: 13px;
    font-weight: 500;
  }
`;

const FileStyled = styled.div`
  border-radius: 10px;
  border: 0.25px solid #e4e4e4;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div {
    display: flex;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
    p {
      margin: 5px;
      text-transform: capitalize;
      font-size: 12px;
      letter-spacing: 0.75px;
    }
  }
  svg {
    cursor: pointer;
    font-size: 20px;
    margin-left: 50px;
    color: #000;
  }
`;
export const RealPreviewStyled = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${(props) =>
    props.isInDatagrid || props.readOnlyStyles ? "0" : "30px"};
  margin-right: 20px;
  justify-content: ${(props) =>
    props.readOnlyStyles ? "flex-start" : "flex-end"};
  margin-left: ${(props) => (props.readOnlyStyles ? "10px" : "0")};
  position: ${(props) => (props.readOnlyStyles ? "absolute" : "0")};
  right: ${(props) => (props.readOnlyStyles ? "10px" : "0")};
  bottom: ${(props) => (props.readOnlyStyles ? "10px" : "0")};
`;
