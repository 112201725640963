import { createSlice } from "@reduxjs/toolkit";
import { serializeError } from "../../utils";

const brokerOfficeConfig = createSlice({
    name: "brokerOfficeConfig",
    initialState: {
        officeError: null,
        officeSuccess: null,
    },
    reducers: {
        setOfficeSuccessAction: (state, { payload }) => {
            state.officeSuccess = payload;
            state.officeError = null;
        },
        setOfficeErrorAction: (state, { payload }) => {
            state.loading = null;
            state.officeError = serializeError(payload);
            state.officeSuccess = null;
        },
    },
});

export const { setOfficeSuccessAction, setOfficeErrorAction } =
    brokerOfficeConfig.actions;
export default brokerOfficeConfig.reducer;
