import { FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';

const GlobalRadio = ({ name, control, onChange, options, char, label, radioSpace, radioDown, disabled, defaultValue }) => {
    return (
        <GlobalRadioStyled radioSpace={radioSpace} radioDown={radioDown} disabled={disabled}>
            <FormLabel component="label">{label}</FormLabel>
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <RadioGroup
                        aria-label={label}
                        name={name}
                        value={field.value}
                        defaultValue={defaultValue ? defaultValue : null}
                        onChange={(e) => {
                            onChange && onChange()
                            char
                                ? field.onChange(e.target.value.substring(0, 1))
                                : field.onChange(e.target.value)
                        }
                        }
                    >
                        {options.map((option) => (
                            <FormControlLabel
                                key={option}
                                value={option}
                                control={<Radio sx={{ fontSize: "14px" }} disabled={disabled} />}
                                label={option}
                                sx={{
                                    ".MuiFormControlLabel-label": {
                                        fontSize: "14px",
                                        color: "rgba(23, 142, 128, 1)"

                                    },
                                    ".MuiSvgIcon-root": {
                                        width: "20px",
                                        height: "20px"
                                    },
                                }}
                            />
                        ))}
                    </RadioGroup>
                )}
            />
        </GlobalRadioStyled>
    );
};

export default GlobalRadio;

const GlobalRadioStyled = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: ${props => props.radioSpace && "space-between"};
  & > label {
    width: ${props => props.radioDown && "100%"};
    color: ${props => props.disabled ? "rgba(0, 0, 0, 0.35)" : "rgba(23, 142, 128, 1)"};
    font-size: 16px;
    font-weight: 500;
  }
  & > div {
    margin-left: ${props => props.radioDown ? 0 : "26px"};
    flex-direction: row;
    gap: 15px;
  }
`;
