import Routes from "./routes";
import "./App.css";
import ErrorBoundry from "./hoc/ErrorBoundry";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import store from "./app/store";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createGlobalStyle } from "styled-components";

const queryClient = new QueryClient(
  {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: 5000,  //5 seconds
      },
    },
  }
);

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: "Montserrat-Regular";
  src: url("../../public/assets/fonts/Montserrat/Montserrat-Regular.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("../../public/assets/fonts/Montserrat/Montserrat-Medium.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../../public/assets/fonts/Montserrat/Montserrat-SemiBold.ttf")
    format("truetype");
  font-weight: 600;
  letter-spacing: 0.15px;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("../../public/assets/fonts/Montserrat/Montserrat-Bold.ttf")
    format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* Roboto */

@font-face {
  font-family: "Roboto-Regular";
  src: url("../../public/assets/fonts/Roboto/Roboto-Regular.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../../public/assets/fonts/Roboto/Roboto-Medium.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

  body {
    font-family: Roboto-Regular !important;
  }
`;

function App() {
  return (
    <ErrorBoundry>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <GlobalStyle />
          <div className="App">
            <Routes />
          </div>
        </Provider>
        <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
      </QueryClientProvider>
    </ErrorBoundry>
  );
}

export default App;
