import { createSlice } from "@reduxjs/toolkit";

const globalSlice = createSlice({
    name: "globalSlice",
    initialState: {
        selectedItem: "",
        policyRenewalRedirectionData: null
    },
    reducers: {
        setSelectedItemAction: (state, { payload }) => {
            state.selectedItem = payload;
        },
        setPolicyRenewalRedirectionDataAction: (state, { payload }) => {
            state.policyRenewalRedirectionData = payload
        }
    },
});

export const { setSelectedItemAction, setPolicyRenewalRedirectionDataAction } =
    globalSlice.actions;

export default globalSlice.reducer;
