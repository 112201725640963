import React from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ActivityTable from "./activityTable";
import { useActivityList, useDeleteLeadActivity } from "../service";
import { TableFrame, ActionContainer } from "../style";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import Swal from "sweetalert2";

const LeadActivityListing = () => {
  const navigate = useNavigate();

  const { data } = useActivityList();
  const { mutate: deleteLeadActivity } = useDeleteLeadActivity();

  const activityListData = !_.isEmpty(data?.data?.data) && data?.data?.data;
  const rows =
    !_.isEmpty(activityListData) &&
    activityListData?.map((item) => {
      return {
        id: item?.leadActivityMasterId,
        leadActivity: item?.leadActivity,
        leadStage: item?.leadStage,
        leadId: item?.leadId,
      };
    });

  const columns = [
    {
      field: "leadActivity",
      headerName: "Lead Activity",
      flex: 1,
      background: "#D2F8E9",
    },
    {
      field: "leadStage",
      headerName: "Lead stage",
      flex: 1,
      background: "#D2F8E9",
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 80,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <ActionContainer>
            <BorderColorOutlinedIcon
              onClick={() => {
                navigate(
                  `/lead-activity-update?id=${params.row.id}&leadId=${params.row.id}`
                );
              }}
            />
            <DeleteForeverOutlinedIcon
              onClick={() =>
                Swal.fire({
                  title: "Delete record?",
                  text: "Confirmation required to delete this record.",
                  icon: "question",
                  confirmButtonText: "Delete",
                  confirmButtonColor: "#DC004E",
                  showCancelButton: true,
                  cancelButtonText: "Cancel",
                  reverseButtons: true,
                  focusConfirm: false,
                  focusCancel: false,
                  scrollbarPadding: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    Swal.fire("Deleted!", "Deleted Successfully", "success");
                    deleteLeadActivity(params.row.id);
                  }
                })
              }
            />
          </ActionContainer>
        );
      },
    },
  ];

  return (
    <>
      <TableFrame>
        <ActivityTable rows={rows} columns={columns} />
      </TableFrame>
    </>
  );
};

export default LeadActivityListing;
