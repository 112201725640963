import React from "react";
import { Grid } from "@mui/material";

const CurdChebox = ({ ChekboxComponent, title }) => {
  return (
    <>
      <Grid item xs={2} sm={2} md={2} lg={2}>
        {ChekboxComponent(`${title?.split(" ")?.join("_")}_view`)}
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2}>
        {ChekboxComponent(`${title?.split(" ")?.join("_")}_create`)}
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2}>
        {ChekboxComponent(`${title?.split(" ")?.join("_")}_edit`)}
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2}>
        {ChekboxComponent(`${title?.split(" ")?.join("_")}_delete`)}
      </Grid>
    </>
  );
};

export default CurdChebox;
