import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { Button, Input, Typography } from "../../../components";
import {
  preventCharactersInInput,
  verifyValidPincode,
} from "../../../HelperFunctions/HelperFunctions";
import { getBrokerOffice } from "../../broker-configuration/service";
import SkeletonLoader from "../../../components/SkeletonLoader/SkeletonLoader";

export const BrokerOfficeForm = ({ setSearchData, searchData }) => {
  const { Error } = Typography;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();

  const { refetch, isFetching } = getBrokerOffice(searchData);

  const resetFields = () => {
    setTimeout(() => {
      reset();
      refetch();
    }, 500);

    setSearchData({
      page: 0,
      size: 0,
      sortBy: "brokerOfficeLocationRelationId",
      sortOrder: "DESC",
      officeCode: "",
      pincode: "",
    });
  };

  const onSubmit = (data) => {
    setTimeout(() => {
      refetch();
    }, 500);

    setSearchData({
      page: 0,
      size: 0,
      sortBy: "brokerOfficeLocationRelationId",
      sortOrder: "DESC",
      officeCode: data.office_code,
      pincode: data.broker_pincode,
    });
  };

  const handlePincodeChange = (value) => {
    const sanitizedValue = value.replace(/^0+|[^0-9]/g, "");

    if (sanitizedValue.length > 0 && sanitizedValue.length < 6) {
      setError("broker_pincode", {
        message: "Pincode must be at least 6 digits",
      });
    } else {
      clearErrors("broker_pincode");
    }
  };

  if (isFetching) return <SkeletonLoader arraySize={3} isNormalForm={true} />
  return (
    <InputFrame onSubmit={handleSubmit(onSubmit)}>
      <Grid
        style={{ marginBottom: "10px", paddingBottom: "0px" }}
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <StyledGrid item xs={10} md={3} sm={10} lg={3}>
          <Input
            themeType="theme1"
            label="Pincode"
            name="broker_pincode"
            id="broker_pincode"
            minLength="6"
            maxLength="6"
            placeholder="Enter Pincode"
            autoComplete="none"
            onChange={(e) => {
              verifyValidPincode(e);
              handlePincodeChange(e.target.value);
            }}
            defaultValue={""}
            required={false}
            inputRef={register("broker_pincode")}
            testId="broker_office"
          />
          {!!errors?.broker_pincode && (
            <Error top="5px" left="12px">
              {errors.broker_pincode.message}
            </Error>
          )}
        </StyledGrid>
        <StyledGrid item xs={10} md={3} sm={10} lg={3}>
          <Input
            themeType="theme1"
            label="Office Name"
            name="office_name"
            id="office_name"
            maxLength="50"
            placeholder="Enter Office Name"
            autoComplete="none"
            defaultValue={""}
            required={false}
            inputRef={register("office_name")}
            testId="broker_office"
          />
          {!!errors?.office_name && (
            <Error top="5px" left="12px">
              {errors.office_name.message}
            </Error>
          )}
        </StyledGrid>
        <StyledGrid item xs={10} md={3} sm={10} lg={3} style={{ top: "18px" }}>
          <Button
            type="submit"
            themeType={"theme1"}
            id="search_btn"
            bgColor={"#11555F"}
          >
            Search
          </Button>
          <Button
            themeType={"theme1"}
            btnBack={"tertiary"}
            id="reset_btn"
            round
            textColor="#11555F"
            bgColor="transparent"
            borderColor="none"
            onClick={resetFields}
          >
            Reset
          </Button>
        </StyledGrid>
      </Grid>
    </InputFrame>
  );
};

const StyledGrid = styled(Grid)`
  position: relative;
`;

const InputFrame = styled.form`
  width: 100%;
  .css-10mzr36-MuiGrid-root {
    padding-bottom: 10px;
  }
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    background: white;
    padding-bottom: 30px;
  }
  .MuiButtonBase-root {
    margin-right: 10px;
  }
`;

const ButtonFrame = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  @media (max-width: 767px) {
    justify-content: flex-start;
  }
`;
