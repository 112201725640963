import React from 'react';
import styled, { keyframes } from 'styled-components';

const bubble = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-15px); }
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const LoaderOverlay = styled.div`
  background: white;
  width: 375px;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
   p {
    margin-bottom: 39px;
    font-size: 24px;
    font-weight: 600;
    font-family: Montserrat
   }
`;

const LoaderBubbles = styled.div`
  display: flex;
  align-items: center;
`;

const Bubble = styled.div`
  width: 20px;
  height: 20px;
  background: #3498db;
  border-radius: 50%;
  margin: 0 5px;
  animation: ${bubble} 0.5s ease-in-out infinite;
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

const ReconLoader = ({ customText = "Processing", isLoading = true }) => {
  return isLoading ? (
    <Container>
      <LoaderOverlay>
        <p>{customText}</p>
        <LoaderBubbles>
          <Bubble />
          <Bubble />
          <Bubble />
        </LoaderBubbles>
      </LoaderOverlay>
    </Container>
  ) : null;
};

export default ReconLoader;
