import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import { Typography } from '../../components';
import BrokerageBasicConfig from './BrokerageBasicConfig';
import BrokerageListing from './StandardBrokerage/BrokerageListing';
import StandardBrokeragePremiumFields from './StandardBrokerage/StandardBrokeragePremiumFields';
import { useState } from 'react';
import ConditionalBrokerageFields from './ConditionalBrokerage/ConditionalBrokerageFields';

export default function BrokerageStepper({
    register,
    control,
    customStyles,
    lobId,
    LOB_Obj,
    regionsObj,
    companyData,
    transactionData,
    transactionType,
    effective_start_date,
    effective_end_date,
    icType,
    premiumBearingObj,
    baseRule,
    premiumBearableFields,
    handleRemove,
    resetRespectiveAmountField,
    files,
    setFiles,
    fieldSlugForExcel,
    setValue,
    handleFieldSlugForExcel,
    downloadMaster,
    premiumAffectFields,
    selectedBrokerageAffectedFields,
    isDisabled,
    selectedBrokerageType,
    cloneButton,
    rulesLength,
    cardInFocus,
    handleFocus,
    steps,
    activeStep,
    setActiveStep,
    trigger,
    errors,
    handleSubmit,
    throttledSubmit,
    handleSave,
    operator,
    multipleBrokerage,
    multipleOperators,
    handleRuleDelete,
    selectedBrokerage,
    single,
    bundle,
    allSingleCheckedValues,
    allBundleCheckedValues,
    isTransactionFieldFetching
}) {
    const { StyledH1 } = Typography;

    const [completed, setCompleted] = useState({});

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps()
    };

    const redirectToBasicDetails = () => {
        setActiveStep(0);
    }

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleStep = (step) => async () => {
        if (completed[step]) {
            setActiveStep(step);
            return;
        }
        const validationPassed = await trigger();
        if (!validationPassed) {
            return;
        }

        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        setActiveStep(step);
        allStepsCompleted();

    };
    const handleComplete = async () => {
        const validationPassed = await trigger();
        if (!validationPassed) {
            return;
        }

        if (activeStep === totalSteps() - 2) {
            handleSubmit(throttledSubmit)();
        }
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
        allStepsCompleted()
    };

    return (
        <Box sx={{ width: '100%', marginTop: "14px", }}>
            <Box sx={{
                background: "#fff",
                padding: "28px 0",
                marginBottom: "16px",
                ".stepperHeader": {
                    padding: "0 16px"
                }
            }}>
                <StyledH1 className='stepperHeader' themeType="theme1" partialU color={"rgb(23, 142, 128)"} align={"left"}>
                    Brokerage Configurator
                </StyledH1>
                <Stepper nonLinear activeStep={activeStep} sx={{
                    padding: "0 10px",
                    height: "63px",
                    "& .MuiStepConnector-line": {
                        borderTopWidth: "3px",
                        borderColor: "rgba(17, 85, 95, 1)"
                    },
                    "& svg.MuiStepIcon-root": {
                        color: "rgba(203, 249, 231, 1)"
                    },
                    "& svg.MuiStepIcon-root text": {
                        fill: "rgba(17, 85, 95, 1)"
                    },
                    "& svg.Mui-active": {
                        color: "rgba(17, 85, 95, 1)"
                    },
                    "& svg.Mui-active text": {
                        fill: "rgba(203, 249, 231, 1)"
                    },
                    "& svg.Mui-completed": {
                        color: "rgba(17, 85, 95, 1)"
                    }
                }}>
                    {steps.map((label, index) => (
                        <Step key={label} completed={completed[index]}>
                            <StepButton color="inherit" onClick={handleStep(index)}>
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <div>
                <>
                    {activeStep === 0 && (
                        <BrokerageBasicConfig
                            register={register}
                            control={control}
                            customStyles={customStyles}
                            lobId={lobId}
                            transactionType={transactionType}
                            effective_start_date={effective_start_date}
                            effective_end_date={effective_end_date}
                            icType={icType}
                            LOB_Obj={LOB_Obj}
                            transactionData={transactionData}
                            companyData={companyData}
                            errors={errors}
                        />
                    )}
                    {activeStep === 1 && (
                        <StandardBrokeragePremiumFields
                            register={register}
                            control={control}
                            handleRemove={handleRemove}
                            lobId={lobId}
                            transactionType={transactionType}
                            effective_start_date={effective_start_date}
                            effective_end_date={effective_end_date}
                            icType={icType}
                            premiumBearingObj={premiumBearingObj}
                            customStyles={customStyles}
                            premiumBearableFields={premiumBearableFields}
                            baseRule={baseRule}
                            resetRespectiveAmountField={resetRespectiveAmountField}
                            errors={errors}
                            isTransactionFieldFetching={isTransactionFieldFetching}
                        />
                    )}
                    {activeStep === 2 && (
                        <ConditionalBrokerageFields
                            register={register}
                            control={control}
                            errors={errors}
                            handleRemove={handleRemove}
                            lobId={lobId}
                            transactionType={transactionType}
                            effective_start_date={effective_start_date}
                            effective_end_date={effective_end_date}
                            icType={icType}
                            premiumBearingObj={premiumBearingObj}
                            customStyles={customStyles}
                            premiumBearableFields={premiumBearableFields}
                            baseRule={baseRule}
                            resetRespectiveAmountField={resetRespectiveAmountField}
                            files={files}
                            setFiles={setFiles}
                            regionsObj={regionsObj}
                            fieldSlugForExcel={fieldSlugForExcel}
                            handleFieldSlugForExcel={handleFieldSlugForExcel}
                            downloadMaster={downloadMaster}
                            premiumAffectFields={premiumAffectFields}
                            selectedBrokerageAffectedFields={selectedBrokerageAffectedFields}
                            isDisabled={isDisabled}
                            selectedBrokerageType={selectedBrokerageType}
                            rulesLength={rulesLength}
                            cloneButton={cloneButton}
                            cardInFocus={cardInFocus}
                            handleFocus={handleFocus}
                            setValue={setValue}
                            handleSave={handleSave}
                            operator={operator}
                            handleRuleDelete={handleRuleDelete}
                            multipleBrokerage={multipleBrokerage}
                            multipleOperators={multipleOperators}
                            selectedBrokerage={selectedBrokerage}
                            single={single}
                            bundle={bundle}
                            allSingleCheckedValues={allSingleCheckedValues}
                            allBundleCheckedValues={allBundleCheckedValues}
                            isTransactionFieldFetching={isTransactionFieldFetching}
                        />
                    )}
                    {activeStep === 3 && (
                        <BrokerageListing
                            lobId={lobId}
                            effective_start_date={effective_start_date}
                            effective_end_date={effective_end_date}
                            icType={icType}
                            transactionType={transactionType}
                            redirectToBasicDetails={redirectToBasicDetails}
                        />
                    )}
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: "center",
                        padding: "28px 10px",
                        background: "#fff",
                        borderTop: ".25px solid rgba(217, 217, 217, 1)"
                    }}>
                        {activeStep === steps.length - 1
                            ? <></>
                            : (
                                <Button onClick={handleComplete} sx={{
                                    mr: 1,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    padding: "6px 16px",
                                    borderRadius: "4px",
                                    color: "#fff",
                                    background: "rgba(17, 85, 95, 1)",
                                    "&:hover": {
                                        color: "rgba(17, 85, 95, 1)",
                                        background: "#fff",
                                        border: ".25px solid rgba(17, 85, 95, 1)"
                                    }
                                }}>
                                    Submit & Proceed
                                </Button>
                            )}
                    </Box>
                </>
            </div>
        </Box>
    );
}