import React from "react";
import { Wrapper } from "../styles/styles";
import { Grid } from "@mui/material";
import moment from "moment";

const SummaryCard = ({ values, currentUrl }) => {
  const labelsValue = [
    "User Name",
    "Password",
    "First Name",
    "Middle Name",
    "Last Name",
    "Gender",
    "Department",
    "User Status",
    "Role",
    "User Type",
    "Channel",
    "IRDAI License No",
    "Branch Name",
    "License Start Date",
    "License End Date",
    "Aadhar",
    "Pancard",
    "Zone",
  ];
  return (
    <>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        {labelsValue.map((item) => (
          <Grid item xs={12} md={3} sm={3} lg={3}>
            <Wrapper>
              <label htmlFor="">{item}</label>
              {values && item === "LOB" ? (
                <span>{values[1]?.lob_id?.label}</span>
              ) : item === "User Name" ? (
                <span>{values[1]?.username}</span>
              ) : item === "Password" ? (
                <span>
                  {currentUrl === "/user-configuration" ? "****" : "****"}
                </span>
              ) : item === "First Name" ? (
                <span>{values[1]?.first_name}</span>
              ) : item === "Middle Name" ? (
                <span>{values[1]?.middle_name}</span>
              ) : item === "Last Name" ? (
                <span>{values[1]?.last_name}</span>
              ) : item === "Gender" ? (
                <span>{values[1]?.gender?.label}</span>
              ) : item === "Department" ? (
                <span>{values[1]?.department?.label}</span>
              ) : item === "User Status" ? (
                <span>
                  {values[1]?.user_status === "Y" ? "Active" : "Inactive"}
                </span>
              ) : item === "Role" ? (
                <span>{values[1]?.roleId?.label}</span>
              ) : item === "User Type" ? (
                <span>{values[1]?.user_type?.label}</span>
              ) : item === "Channel" ? (
                <span>{values[1]?.channel?.label}</span>
              ) : item === "IRDAI License No" ? (
                <span>{values[1]?.irdai_license_no}</span>
              ) : item === "Branch Name" ? (
                <span>{values[1]?.branch_name?.label}</span>
              ) : item === "License Start Date" ? (
                <span>
                  {moment(values[1]?.start_date).format("DD /MM/ YYYY")}
                </span>
              ) : item === "License End Date" ? (
                <span>
                  {moment(values[1]?.end_date).format("DD /MM/ YYYY")}
                </span>
              ) : item === "Aadhar" ? (
                <span>{values[1]?.aadhaar_number}</span>
              ) : item === "Pancard" ? (
                <span>{values[1]?.pancard}</span>
              ) : item === "Zone" ? (
                <span>{values[1]?.zone?.label}</span>
              ) : (
                <noscript />
              )}
            </Wrapper>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
export default SummaryCard;
