import { createSlice } from "@reduxjs/toolkit";
import { serializeError } from "../../utils";

const brokerConfig = createSlice({
  name: "brokerConfig",
  initialState: {
    error: null,
    success: null,
    master: [],
    addedBrokerData: null,
  },
  reducers: {
    master: (state, { payload }) => {
      state.master = payload;
    },
    success: (state, { payload }) => {
      state.success = payload;
      state.error = null;
    },
    error: (state, { payload }) => {
      state.loading = null;
      state.error = serializeError(payload);
      state.success = null;
    },
    setAddedBrokerData: (state, { payload }) => {
      state.addedBrokerData = payload;
    },
  },
});

export const { master, success, error, setAddedBrokerData } =
  brokerConfig.actions;
export default brokerConfig.reducer;
