import Grid from "@mui/material/Grid";
import { useDropzone } from "react-dropzone";
import Swal from "sweetalert2";
import { Button, Typography } from "../../../components";
import Dropdown from "../../../components/Dropdown/Dropdown";
import GlobalModal from "../../../components/Modal/modal";
import ExcelImage from "../../../utils/excel.png";
import { ExcelUploadStyled, FileDropAreaStyled } from "../Brokerage.style";
import { useState } from "react";
import _ from "lodash";

function ExcelUpload({
    open,
    handleClose,
    control,
    files,
    lobId,
    premiumBearableFields,
    setFiles,
    fieldSlug,
    downloadMaster,
    selectedBrokerageType,
    cardInFocus,
    multipleOperators,
}) {
    const { Error } = Typography;

    const [operatorError, setOperatorError] = useState({});

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            "application/vnd.ms-excel": [".xls"],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
                ".xlsx",
            ],
        },

        onDrop: async (acceptedFiles, rejectedFiles) => {
            const base64Promises = acceptedFiles.map(
                (file) =>
                    new Promise((resolve) => {
                        const reader = new FileReader();
                        reader.onload = () => {
                            const base64String = reader.result.split(",")[1];
                            resolve({ file, base64String });
                        };
                        reader.readAsDataURL(file);
                    })
            );

            const base64Files = await Promise.all(base64Promises);
            if (!_.isEmpty(rejectedFiles)) {
                Swal.fire({
                    text: "Only Excel Files are supported",
                    icon: "error",
                });
            } else {
                if (selectedBrokerageType === "Single") {
                    setFiles({
                        ...files,
                        single: {
                            ...files?.single,
                            [cardInFocus?.single - 1]: {
                                ...files?.single?.[cardInFocus?.single - 1],
                                [fieldSlug.value]: base64Files,
                            },
                        },
                    });
                } else {
                    setFiles({
                        ...files,
                        bundle: {
                            ...files?.bundle,
                            [cardInFocus?.bundle - 1]: {
                                ...files?.bundle?.[cardInFocus?.bundle - 1],
                                [fieldSlug.value]: base64Files,
                            },
                        },
                    });
                }
            }
        },
    });

    const previewFile = { single: [], bundle: [] };
    if (selectedBrokerageType === "Single") {
        for (let key in files?.single?.[cardInFocus.single - 1]) {
            if (fieldSlug.value === key) {
                previewFile.single.push(
                    files?.single?.[cardInFocus.single - 1][key][0].file
                );
            }
        }
    }

    if (selectedBrokerageType === "Bundled") {
        for (let key in files?.bundle?.[cardInFocus.bundle - 1]) {
            if (fieldSlug.value === key) {
                previewFile?.bundle?.push(
                    files?.bundle?.[cardInFocus.bundle - 1]?.[key]?.[0]?.file
                );
            }
        }
    }

    const handleExcelValidation = () => {
        if (
            selectedBrokerageType === "Bundled" &&
            !multipleOperators?.[cardInFocus.bundle - 1]?.operator?.[fieldSlug.value]
        ) {
            setOperatorError({ [fieldSlug.value]: true });
            return;
        } else {
            setOperatorError({ [fieldSlug.value]: false });
        }
        if (
            selectedBrokerageType === "Single"
                ? !previewFile.single.length
                : !previewFile.bundle.length
        ) {
            Swal.fire({
                text: "Please upload a file",
                icon: "warning",
                confirmButtonText: "OK",
                confirmButtonColor: "#DC004E",
                reverseButtons: true,
                focusConfirm: false,
                allowOutsideClick: false,
                focusCancel: false,
                scrollbarPadding: false,
            });
        } else {
            Swal.fire({
                text: "Success! Your selection has been saved.",
                icon: "success",
                confirmButtonText: "OK",
                confirmButtonColor: "#DC004E",
                reverseButtons: true,
                focusConfirm: false,
                focusCancel: false,
                scrollbarPadding: false,
            });
            handleClose();
        }
    };

    const stdBrokerageSlugs = premiumBearableFields?.map((value) => value.value);

    const handleRemoveFile = (slug) => {
        if (selectedBrokerageType === "Bundled") {
            const filesObject = { ...files };
            delete filesObject?.bundle?.[cardInFocus.bundle - 1]?.[slug];
            setFiles(filesObject);
        } else {
            const filesObject = { ...files };
            delete filesObject?.single?.[cardInFocus.single - 1]?.[slug];
            setFiles(filesObject);
        }
    };

    return (
        <GlobalModal open={open} title={"Add Data"} onClose={handleClose}>
            <ExcelUploadStyled>
                {selectedBrokerageType === "Bundled" && (
                    <>
                        <Grid
                            container
                            sx={{
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "24px 0",
                                marginLeft: 0,
                            }}
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        >
                            <Grid item sm={3} md={3} lg={3}>
                                <p>Select Operators</p>
                            </Grid>
                            <Grid item sx={{ position: "relative" }} sm={3} md={3} lg={3}>
                                <Dropdown
                                    id={`multipleOperators.${cardInFocus.bundle - 1}.operator.${fieldSlug.value
                                        }`}
                                    control={control}
                                    selectObj={[
                                        {
                                            label: "EQUALS",
                                            value: "EQUALS",
                                        },
                                        {
                                            label: "RANGE",
                                            value: "RANGE",
                                        },
                                        {
                                            label: "EXCLUDED",
                                            value: "EXCLUDED",
                                        },
                                    ]}
                                />
                                {operatorError?.[fieldSlug.value] && (
                                    <Error top="3px" left="3px" bottom="-18px">
                                        Please select a operator
                                    </Error>
                                )}
                            </Grid>
                        </Grid>
                        <hr />
                    </>
                )}
                <FileDropAreaStyled>
                    <div {...getRootProps()} className="dropzone button_action">
                        <input {...getInputProps()} />
                        <div className="fileDrop">
                            <svg
                                width="124"
                                height="124"
                                viewBox="0 0 124 124"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    opacity="0.2"
                                    d="M100.75 42.625V54.25H33.7948C32.9815 54.2499 32.1888 54.5058 31.529 54.9813C30.8691 55.4569 30.3757 56.1279 30.1184 56.8995L15.5 100.75V31C15.5 29.9723 15.9083 28.9867 16.635 28.26C17.3617 27.5333 18.3473 27.125 19.375 27.125H45.2067C46.0452 27.125 46.861 27.3969 47.5317 27.9L60.9683 37.975C61.639 38.4781 62.4548 38.75 63.2933 38.75H96.875C97.9027 38.75 98.8883 39.1583 99.615 39.885C100.342 40.6117 100.75 41.5973 100.75 42.625Z"
                                    fill="#11555F"
                                />
                                <path
                                    d="M118.672 53.5912C117.953 52.5941 117.007 51.7824 115.912 51.2232C114.817 50.6639 113.604 50.3732 112.375 50.375H104.625V42.625C104.625 40.5696 103.808 38.5983 102.355 37.1449C100.902 35.6915 98.9304 34.875 96.875 34.875H63.2933L49.8616 24.8C48.5178 23.7982 46.8876 23.2548 45.2116 23.25H19.375C17.3196 23.25 15.3483 24.0665 13.8949 25.5199C12.4415 26.9733 11.625 28.9446 11.625 31V100.75C11.625 101.778 12.0333 102.763 12.76 103.49C13.4867 104.217 14.4723 104.625 15.5 104.625H102.252C103.065 104.625 103.858 104.369 104.517 103.894C105.177 103.418 105.671 102.747 105.928 101.975L119.728 60.5759C120.116 59.411 120.223 58.1708 120.039 56.9567C119.855 55.7427 119.387 54.5894 118.672 53.5912ZM45.2116 31L58.6433 41.075C59.9867 42.0774 61.6171 42.6208 63.2933 42.625H96.875V50.375H33.7948C32.1682 50.3749 30.5827 50.8866 29.2631 51.8377C27.9434 52.7887 26.9565 54.1309 26.442 55.6741L19.375 76.8703V31H45.2116ZM99.4616 96.875H20.8766L33.7948 58.125H112.375L99.4616 96.875Z"
                                    fill="#11555F"
                                />
                            </svg>
                            <p className="file_drop_text">
                                Drop Your File Here or <b>Browse</b> From Computer
                            </p>
                        </div>
                    </div>
                </FileDropAreaStyled>

                {selectedBrokerageType === "Single" ? (
                    previewFile.single.length ? (
                        <div className="preview">
                            {previewFile.single.map((file) => (
                                <div key={file.name}>
                                    <img src={ExcelImage} alt="file logo" />
                                    <p className="file_name">{file.name}</p>
                                    <p>{Math.floor(file.size / 1024)} KB</p>
                                </div>
                            ))}
                            <p
                                className="remove_file"
                                onClick={() => handleRemoveFile(fieldSlug.value)}
                            >
                                X
                            </p>
                        </div>
                    ) : (
                        <></>
                    )
                ) : previewFile?.bundle?.length ? (
                    <div className="preview">
                        {Array.isArray(previewFile?.bundle) && previewFile?.bundle?.map((file) => (
                            <div key={file?.name}>
                                <img src={ExcelImage} alt="file logo" />
                                <p className="file_name">{file?.name}</p>
                                <p>{Math.floor(file?.size / 1024)} KB</p>
                            </div>
                        ))}
                        <p
                            className="remove_file"
                            onClick={() => handleRemoveFile(fieldSlug?.value)}
                        >
                            X
                        </p>
                    </div>
                ) : (
                    <></>
                )}

                <div className="btn_action_container">
                    <Button
                        bgColor="transparent"
                        textColor="rgb(23, 142, 128)"
                        id="download"
                        variant="outlined"
                        borderColor="rgb(23, 142, 128)"
                        onClick={() =>
                            downloadMaster({
                                masterSlug: fieldSlug.slug,
                                fieldSlug: stdBrokerageSlugs,
                                ruleType:
                                    selectedBrokerageType === "Single" ? "SINGLE" : "BUNDLED",
                                lobId: lobId?.lobId
                            })
                        }
                    >
                        Download Master
                    </Button>
                    <Button
                        themeType={"theme1"}
                        onClick={handleExcelValidation}
                        bgColor="rgba(17, 85, 95, 1)"
                    >
                        Save Data
                    </Button>
                </div>
            </ExcelUploadStyled>
        </GlobalModal>
    );
}

export default ExcelUpload;
