import _ from "lodash";
import moment from "moment";

export const formatLocalDate = (dateString) => {
  if (_.isDate(dateString)) {
    const momentDate = moment(dateString);
    return momentDate.format("YYYY-MM-DD");
  }
};
export const getBackgroundColor = (index) => {
  return `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
    Math.random() * 256
  )}, ${Math.floor(Math.random() * 256)}, 0.9)`;
};

export const getBorderColor = (index) => {
  return `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
    Math.random() * 256
  )}, ${Math.floor(Math.random() * 256)}, 1)`;
};

export const generateDynamicColors = (numColors) => {
  const dynamicColors = [];
  for (let i = 0; i < numColors; i++) {
    dynamicColors.push(
      `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
        Math.random() * 256
      )}, ${Math.floor(Math.random() * 256)}, 0.9)`
    );
  }
  return dynamicColors;
};
