import { Box, Chip, Grid } from "@mui/material";
import React from "react";
import { Button, GlobalRadio, Input, Typography } from "../../../../components";
import { useSelector, useDispatch } from "react-redux";
import { removeFieldFromMaster } from "../AddNewMaster.slice";
import Dropdown from "../../../../components/Dropdown/Dropdown";

const CollectedFields = ({
  addColumnRegister,
  addColumnControl,
  addColumnErrors,
  handleAddFieldsForMasterFormSubmit,
  fieldTypeOptions,
  validationGroupOptions,
  addColumnWatch,
}) => {
  const dispatch = useDispatch();
  const { fieldsForMaster } = useSelector((state) => state.AddNewMasterSlice);
  const { StyledH1, Error } = Typography;
  const isMasterApiAvailable = addColumnWatch("isMasterApiAvailable");
console.log("qqq", isMasterApiAvailable)
  return (
    <>
      <Grid
        container
        sx={{ paddingInline: "24px" }}
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid item sx={{ position: "relative" }} xs={12} sm={12} md={6} lg={3}>
          <Input
            themeType={"theme1"}
            label="Field Name"
            name="fieldName"
            id="fieldName"
            maxLength="100"
            placeholder="Enter Field Name"
            autoComplete="none"
            // onChange={(e) => preventSpecialAndNumbersInInput(e)}
            defaultValue={""}
            required={false}
            inputRef={addColumnRegister("fieldName")}
          />
          {!!addColumnErrors?.fieldName && (
            <Error top="5px" left="3px">
              {addColumnErrors.fieldName.message}
            </Error>
          )}
        </Grid>
        <Grid item sx={{ position: "relative" }} xs={12} sm={12} md={6} lg={3}>
          <Input
            themeType={"theme1"}
            label="Field Slug"
            name="fieldSlug"
            id="fieldSlug"
            maxLength="100"
            placeholder="Enter Field Slug"
            autoComplete="none"
            // onChange={(e) => preventSpecialAndNumbersInInput(e)}
            defaultValue={""}
            required={false}
            inputRef={addColumnRegister("fieldSlug")}
            readOnly={true}
          />
          {!!addColumnErrors?.fieldSlug && (
            <Error top="5px" left="3px">
              {addColumnErrors.fieldSlug.message}
            </Error>
          )}
        </Grid>
        <Grid item sx={{ position: "relative" }} xs={12} sm={12} md={6} lg={3}>
          <Dropdown
            id="fieldType"
            label="Field Type"
            control={addColumnControl}
            selectObj={fieldTypeOptions}
            maxMenuHeight={200}
          />
          {!!addColumnErrors?.fieldType && (
            <Error top="5px" left="3px">
              {addColumnErrors.fieldType.message}
            </Error>
          )}
        </Grid>
        <Grid item sx={{ position: "relative" }} xs={12} sm={12} md={6} lg={3}>
          <Input
            themeType={"theme1"}
            label="Field Default Value"
            name="fieldDefault"
            id="fieldDefault"
            maxLength="100"
            placeholder="Enter Field Default Value"
            autoComplete="none"
            // onChange={(e) => preventSpecialAndNumbersInInput(e)}
            defaultValue={""}
            required={false}
            inputRef={addColumnRegister("fieldDefault")}
          />
          {!!addColumnErrors?.fieldDefault && (
            <Error top="5px" left="3px">
              {addColumnErrors.fieldDefault.message}
            </Error>
          )}
        </Grid>
        <Grid item sx={{ position: "relative" }} xs={12} sm={12} md={6} lg={3}>
          <GlobalRadio
            name="isMandatory"
            label="Is Mandatory ?"
            options={["Y", "N"]}
            control={addColumnControl}
          />
          {!!addColumnErrors?.isMandatory && (
            <Error top="5px" left="3px">
              {addColumnErrors.isMandatory.message}
            </Error>
          )}
        </Grid>
        <Grid item sx={{ position: "relative" }} xs={12} sm={12} md={6} lg={3}>
          <GlobalRadio
            name="isMasterApiAvailable"
            label="Is Master Api Available ?"
            options={["Y", "N"]}
            control={addColumnControl}
          />
          {!!addColumnErrors?.isMasterApiAvailable && (
            <Error top="5px" left="3px">
              {addColumnErrors.isMasterApiAvailable.message}
            </Error>
          )}
        </Grid>
        {isMasterApiAvailable == "Y" && (
          <Grid
            item
            sx={{ position: "relative" }}
            xs={12}
            sm={12}
            md={6}
            lg={3}
          >
            <Input
              themeType={"theme1"}
              label="Master Slug"
              name="masterSlug"
              id="masterSlug"
              maxLength="100"
              placeholder="Enter Field Default Value"
              autoComplete="none"
              // onChange={(e) => preventSpecialAndNumbersInInput(e)}
              defaultValue={""}
              required={false}
              inputRef={addColumnRegister("masterSlug")}
            />
            {!!addColumnErrors?.masterSlug && (
              <Error top="5px" left="3px">
                {addColumnErrors.masterSlug.message}
              </Error>
            )}
          </Grid>
        )}
        <Grid item sx={{ position: "relative" }} xs={12} sm={12} md={6} lg={3}>
          <Input
            themeType={"theme1"}
            label="Field Sequence"
            name="fieldSequence"
            id="fieldSequence"
            maxLength="100"
            placeholder="Enter Field Default Value"
            autoComplete="none"
            // onChange={(e) => preventSpecialAndNumbersInInput(e)}
            defaultValue={""}
            required={false}
            inputRef={addColumnRegister("fieldSequence")}
          />
          {!!addColumnErrors?.fieldSequence && (
            <Error top="5px" left="3px">
              {addColumnErrors.fieldSequence.message}
            </Error>
          )}
        </Grid>
        <Grid item sx={{ position: "relative" }} xs={12} sm={12} md={6} lg={3}>
          <Dropdown
            id="validationGroup"
            label="Validation Group"
            control={addColumnControl}
            selectObj={validationGroupOptions}
            maxMenuHeight={200}
          />
          {!!addColumnErrors?.validationGroup && (
            <Error top="5px" left="3px">
              {addColumnErrors.validationGroup.message}
            </Error>
          )}
        </Grid>
        <Grid
          item
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "end",
            marginBottom: "10px",
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <Button
            type="submit"
            themeType={"theme1"}
            id={"save_btn"}
            bgColor={"#11555F"}
          >
            Add Field
          </Button>
        </Grid>
      </Grid>
      {!!fieldsForMaster?.length && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            borderTop: "1px solid rgb(204, 204, 204)",
            padding: "10px 24px 10px 8px",
          }}
        >
          <Box sx={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
            {fieldsForMaster?.map((item, index) => {
              return (
                <Box
                  sx={{
                    padding: "5px 10px",
                  }}
                >
                  <Chip
                    label={item?.fieldName}
                    onDelete={() => {
                      dispatch(removeFieldFromMaster(index));
                    }}
                  />
                </Box>
              );
            })}
          </Box>

          <Button
            type="submit"
            themeType={"theme1"}
            id={"save_btn"}
            bgColor={"#11555F"}
            sx={{ height: "fit-content", marginTop: "auto" }}
            onClick={handleAddFieldsForMasterFormSubmit}
          >
            Submit
          </Button>
        </Box>
      )}
    </>
  );
};

export default CollectedFields;
