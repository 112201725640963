import styled from "styled-components";
import { Typography } from "../../components";
import { CustomisationForm } from "./customisation-form/customisation-form";
import { CustomisationTable } from "./customisation-table/customisation-table";

//Required Typography
const { StyledH1 } = Typography;

export const PageCustomisation = () => {
  return (
    <StyledDiv>
      <StyledH1 themeType={"theme1"} partialU color={"rgb(23, 142, 128)"} align={"left"}>
        Page Customisation
      </StyledH1>
      <CustomisationForm />
      <TableFrame>
        <CustomisationTable />
      </TableFrame>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 30px;
`;

const TableFrame = styled.div`
  max-width: 99.5%;
`;
