import { yupResolver } from "@hookform/resolvers/yup";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Swal from "sweetalert2";
import * as yup from "yup";
import useGetLOBMaster from "../../hooks/useGetLOBMaster";
import useGetMasterTableData from "../../hooks/useGetMasterTableData";
import { setLeadErrorAction } from "../lead/Lead.slice";
import {
  setProductMasterErrorAction,
  setProductMasterSuccessAction,
} from "./product.slice";
import {
  useAddProduct,
  useDeleteProduct,
  useGetProductById,
  useGetProductsList,
  useUpdateProduct,
} from "./service";

const masterTableFields = [
  {
    masterSlug: "transaction_type_master_by_id",
    fieldSlug: "transaction_type_master_by_id",
  },
  {
    masterSlug: "master_company",
    fieldSlug: "master_company",
  },
];

function useProduct() {
  const [isProductModalOpen, setIsProductModalOpen] = useState({
    state: false,
    title: "",
    productId: null,
  });

  const { productMasterSuccess, productMasterError } = useSelector(
    (state) => state.productSlice
  );
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const yupValidate = yup.object({
    productName: yup.string().required("Product Name is required"),
    productCode: yup.string().required("Product Code is required"),
    uin: yup.string().required("UIN is required"),
    description: yup.string().required("Description is required"),
    lob: yup.mixed().required("Line of Business is required"),
    businessType: yup.mixed().required("Business Type is required"),
    company: yup.mixed().required("Company is required"),
    brochure: yup.mixed().required("Please upload PDF"),
    policyWording: yup.mixed().required("Please upload PDF"),
    claimForm: yup.mixed().required("Please upload PDF"),
  });

  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    trigger,
    unregister,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yupValidate),
    mode: "all",
    reValidateMode: "all",
  });

  const { lob } = watch();

  const masterTableFieldsWithLobId = lob?.lobId
    ? masterTableFields.map((value) => ({ ...value, lobId: lob?.lobId }))
    : masterTableFields;

  const {
    data: products,
    isFetching: isProductsFetching,
    isError: isProductsError,
  } = useGetProductsList();
  const {
    mutate: addProduct,
    isLoading: isAddProductLoading,
    isError: isAddProductError,
  } = useAddProduct();
  const {
    mutate: updateProduct,
    isLoading: isUpdateProductLoading,
    isError: isUpdateProductError,
  } = useUpdateProduct();
  const { data: productDataById } = useGetProductById(
    isProductModalOpen.productId
  );
  const { mutate: deleteProduct } = useDeleteProduct();

  const { data: lobData } = useGetLOBMaster();
  const data = useGetMasterTableData(
    lob?.lobId ? masterTableFieldsWithLobId : []
  );

  const LOB_Obj = lobData?.data?.data?.map((value) => {
    return {
      value: value.lob,
      label: value.lob,
      lobId: value.lob_id,
    };
  });

  const businessType_Obj = data?.data[0]?.data?.data?.[0]?.data?.map(
    (value) => {
      return {
        value: value.optionValue,
        label: value.optionValue,
        businessId: value.optionKey,
      };
    }
  );

  const company_Obj = data?.data[1]?.data?.data?.[0]?.data?.map((value) => {
    return {
      value: value.optionValue,
      label: value.optionValue,
      companyId: value.optionKey,
    };
  });

  const rows = Array.isArray(products?.data?.data)
    ? products.data.data.map((value, index) => {
        const {
          productId: id,
          productCode,
          productName,
          description,
          uinNumber,
        } = value;
        return {
          id,
          productCode,
          productName,
          description,
          uinNumber,
        };
      })
    : [];

  const columns = [
    {
      field: "productCode",
      headerName: "Product Code",
      flex: 1,
      background: "#D2F8E9",
    },
    {
      field: "productName",
      headerName: "Product Name",
      flex: 1,
      background: "#D2F8E9",
    },
    {
      field: "uinNumber",
      headerName: "UIN Number",
      flex: 1,
      background: "#D2F8E9",
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      background: "#D2F8E9",
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <ActionContainer>
            <PreviewOutlinedIcon
              id={`view-${params.row.id}`}
              index={params.row.id}
              onClick={() => {
                queryClient.invalidateQueries(["productById", params.row.id]);
                setIsProductModalOpen(() => ({
                  state: true,
                  title: "View",
                  productId: params.row.id,
                }));
                dispatch(setProductMasterErrorAction(null));
                dispatch(setProductMasterSuccessAction(null));
              }}
            />
            <BorderColorOutlinedIcon
              id={`edit-${params.row.id}`}
              index={params.row.id}
              onClick={() => {
                queryClient.invalidateQueries(["productById", params.row.id]);
                setIsProductModalOpen(() => ({
                  state: true,
                  title: "Update",
                  productId: params.row.id,
                }));
                dispatch(setProductMasterErrorAction(null));
                dispatch(setProductMasterSuccessAction(null));
              }}
            />
            <DeleteForeverOutlinedIcon
              id={`delete-${params.row.id}`}
              onClick={() => {
                Swal.fire({
                  title: "Delete record?",
                  text: "Confirmation required to delete this record.",
                  icon: "question",
                  confirmButtonText: "Delete",
                  confirmButtonColor: "#DC004E",
                  showCancelButton: true,
                  cancelButtonText: "Cancel",
                  reverseButtons: true,
                  focusConfirm: false,
                  focusCancel: false,
                  scrollbarPadding: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    deleteProduct(params.row.id);
                    Swal.fire("Deleted!", "Deleted Successfully", "success");
                  }
                });
              }}
              index={params.row.id}
            />
          </ActionContainer>
        );
      },
    },
  ];

  const handleOpen = (title) => {
    dispatch(setProductMasterSuccessAction(null));
    dispatch(setProductMasterErrorAction(null));
    setIsProductModalOpen((prevState) => ({
      ...prevState,
      title,
      state: true,
    }));
  };

  const handleClose = () => {
    setIsProductModalOpen((prevState) => ({ ...prevState, state: false }));
  };

  useEffect(() => {
    if (productMasterError) {
      Swal.fire({
        text: productMasterError,
        icon: "warning",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        allowOutsideClick: false,
        focusCancel: false,
        scrollbarPadding: false,
      });
    }
    return () => {
      dispatch(setLeadErrorAction(null));
    };
  }, [dispatch, productMasterError]);

  useEffect(() => {
    if (productMasterSuccess) {
      Swal.fire({
        text: productMasterSuccess,
        icon: "success",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        focusCancel: false,
        scrollbarPadding: false,
      });
      handleClose();
    }
  }, [productMasterSuccess]);

  useEffect(() => {
    if (isAddProductError || isUpdateProductError || isProductsError) {
      Swal.fire({
        text: "Error",
        icon: "warning",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        allowOutsideClick: false,
        focusCancel: false,
        scrollbarPadding: false,
      });
    }
  }, [isAddProductError, isUpdateProductError, isProductsError]);

  return {
    isProductModalOpen,
    addProduct,
    isAddProductLoading,
    updateProduct,
    isUpdateProductLoading,
    LOB_Obj,
    businessType_Obj,
    company_Obj,
    handleOpen,
    handleClose,
    productDataById,
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    errors,
    rows,
    columns,
    isProductsFetching,
    unregister,
    watch,
    trigger,
  };
}

export default useProduct;

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  svg {
    color: #178e80;
    font-size: 20px;
  }
`;
