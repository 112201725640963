import { useQueries } from "@tanstack/react-query";
import HttpClient from "../api/httpClient";

const useGetMasterTableData = (tableName) => {
    const fetchMenuMasterData = (tableName) => {
        return HttpClient(
            `get_data/${tableName?.masterSlug}/${tableName.fieldSlug}?lobId=${tableName.lobId}`
        ).then((response) => response.data);
    };

    const data = useQueries({
        queries: tableName.map((tableName) => {
            return {
                queryKey: [
                    "master-table",
                    tableName?.lobId
                        ? tableName?.masterSlug + tableName?.fieldSlug + tableName?.lobId
                        : tableName?.masterSlug + tableName?.fieldSlug,
                ],
                queryFn: () => fetchMenuMasterData(tableName),
            };
        }),
    });
    return { data };
};

export default useGetMasterTableData;
