import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CommunicationForm from "./communicationForm";
import CommunicationTable from "./communicationTable";
import GlobalModal from "../../components/Modal/modal";
import CommunicationModal from "./communicationModal";
import { useSelector } from "react-redux";
import {
  setCommunicationErrorAction,
  setCommunicationSuccessAction,
} from "./communication-config.slice";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import {
  useCreateTemplateData,
  useDeleteTemplateData,
  useGetTemplateDataById,
  useGetTemplateMasterList,
  useUpdateTemplateData,
} from "./service";

const Communication = () => {
  const templateEnumOBJ = [
    { id: "EMAIL", value: "EMAIL", label: "EMAIL" },
    { id: "SMS", value: "SMS", label: "SMS" },
    { id: "NOTIFICATION", value: "NOTIFICATION", label: "NOTIFICATION" },

    { id: "WHATSAPP", value: "WHATSAPP", label: "WHATS APP" },
  ];

  const transactionTypeOBJ = [
    { id: "POLICY", value: "POLICY", label: "POLICY" },
    { id: "ENDORSEMENT", value: "ENDORSEMENT", label: "ENDORSEMENT" },
    { id: "CLAIMS", value: "CLAIMS", label: "CLAIMS" },

    { id: "USERS", value: "USERS", label: "USERS" },
    { id: "LEAD", value: "LEAD", label: "LEAD" },
  ];

  const dispatch = useDispatch();
  const [modalTitle, setModalTitle] = useState("");
  const [id, setId] = useState();
  const [open, setOpen] = useState(false);

  const { mutate: createTemplate } = useCreateTemplateData();

  const { mutate: updateTemplate } = useUpdateTemplateData();
  const { mutate: deleteTemplateData } = useDeleteTemplateData();
  const { data: templateMasterList, isFetching } = useGetTemplateMasterList();
  const { data: prefillTemplateData } = useGetTemplateDataById(id);

  const { communicationError, communicationSuccess } = useSelector(
    (state) => state.communicationConfig
  );

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (communicationError) {
      Swal.fire({
        text: communicationError,
        icon: "warning",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        allowOutsideClick: false,
        focusCancel: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(setCommunicationErrorAction(null));
        }
      });
    }
  }, [communicationError]);

  useEffect(() => {
    if (communicationSuccess) {
      Swal.fire({
        text: communicationSuccess,
        icon: "success",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        focusCancel: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          handleClose();
          dispatch(setCommunicationSuccessAction(null));
        }
      });
    }
  }, [communicationSuccess]);

  return (
    <StyledDiv>
      <StyledHeading>
        <StyledH1
          themeType="theme1"
          partialU
          color={"rgb(23, 142, 128)"}
          align={"left"}
        >
          Communication
        </StyledH1>
        <CommunicationForm templateEnumOBJ={templateEnumOBJ} />
      </StyledHeading>
      <TableFrame>
        <CommunicationTable
          modalTitle={modalTitle}
          setOpen={setOpen}
          setModalTitle={setModalTitle}
          templateMasterList={templateMasterList}
          isFetching={isFetching}
          deleteTemplateData={deleteTemplateData}
          setId={setId}
        />
      </TableFrame>
      <GlobalModal
        open={open}
        title={modalTitle + " Communication"}
        onClose={handleClose}
        overflowVisible={true}
      >
        <CommunicationModal
          modalTitle={modalTitle}
          prefillTemplateData={prefillTemplateData}
          templateEnumOBJ={templateEnumOBJ}
          createTemplate={createTemplate}
          updateTemplate={updateTemplate}
          transactionTypeOBJ={transactionTypeOBJ}
        />
      </GlobalModal>
    </StyledDiv>
  );
};

export default Communication;

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledHeading = styled.div`
  background: rgb(255, 255, 255);
  padding: 20px 16px;
  margin-right: 7px;
`;

const StyledH1 = styled.h1`
  font-family: Montserrat-SemiBold, sans-serif;
  display: inline-block;
  padding-bottom: 5px;
  position: relative;
  font-size: 24px;
  margin: 0;
  line-height: 36px;
  width: 100%;
  font-weight: 600;
  color: rgb(23, 142, 128);
  text-align: left;
`;

const TableFrame = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  font-family: "Nunito";
  box-shadow: 0px 22px 45px -15px #dddde2;
  background-color: #ffffff;
  margin-top: 15px;
  padding: 20px;
  max-width: 99.5% !important;
`;
