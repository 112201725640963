import { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router';
import styled from 'styled-components';
import CloudOffIcon from '@mui/icons-material/CloudOff';

function Offline() {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const location = useLocation();

    const previousPage = location.state.from.pathname + location.state.from.search;

    useEffect(() => {
        window.addEventListener('online', handleOnlineStatusChange);

        return () => {
            window.removeEventListener('online', handleOnlineStatusChange);
        };
    }, []);

    const handleOnlineStatusChange = () => {
        setIsOnline(navigator.onLine);
    };
    if (isOnline) {
        return <Navigate to={previousPage} replace />
    }
    return <OfflineStyled>
        <CloudOffIcon />
        <h3>You are currently offline</h3>
        <p>We could not load the content. Check your internet connection and try again</p>
    </OfflineStyled>
}

export default Offline

const OfflineStyled = styled.div`
width: 100%;
height: 100vh;
background: #fff;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
svg {
        font-size: 300px;
        color: #FFD166
}
h3 {
    font-size: 3rem;
    margin-bottom: 1rem;
    margin-top: 0;
}
p {
    margin: 0;
    width: 500px;
    text-align: center;
}
`