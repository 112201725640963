import styled from "styled-components";

export const StyledBg = styled.div`
  background: #fff;
  padding: 24px 16px;
  margin-right: 7px;
`;

export const Heading = styled.h2`
  margin-top: -3px;
  margin-bottom: 0;
  display: flex;
  color: #178e80;
  justify-content: space-between;
  font-family: "Montserrat-SemiBold";
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
`;

export const TableContainer = styled.div`
  background: #fff;
  width: 100%;
`;

export const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 12px;
`;

export const TableFrame = styled.div`
  display: flex;
  position: relative;
  align-items: ${({ position }) => (position ? "start" : "center")};
  flex-direction: column;
  box-shadow: 0px 22px 45px -15px #dddde2;
  background-color: #ffffff;
  margin-top: 15px;
  padding: 16px;
  max-width: 99.5% !important;
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.justifyCenter ? "space-between" : "center"};
  align-items: center;
  cursor: pointer;
  width: 100%;
  svg {
    color: #178e80;
    font-size: 20px;
  }
`;

export const MasterConfigModalStyled = styled.div`
  background: #fff;
  border-radius: 10px;
  hr {
    margin-top: 10px;
    height: 0.25px;
    background-color: rgba(117, 117, 117, 0.5);
    border: none;
    width: 97%;
  }
  .button_container {
    margin-top: 10px;
    background-color: #fff;
    box-shadow: 0 0 1px #000;
    padding: 8px 18px 16px 0;
    width: 100%;
    margin-left: 0;
  }
`;

export const InputFrame = styled.form`
  width: 100%;
`;

export const StyledHeading = styled.h3`
  margin-left: 2px;
  margin-top: 8px;
  margin-bottom: 32px;
  font-size: 16px;
  font-family: "Montserrat-SemiBold";
  color: #178e80;
  font-weight: 600;
`;

export const HeadingStyled = styled.h3`
  margin: 0 0 5px 14px;
  width: 100%;
  color: rgb(17, 85, 95);
  font-size: 14px;
  font-weight: 500;
  font-family: Montserrat-SemiBold;
  span {
    color: red;
    position: relative;
    left: 0px;
    top: -5px;
    font-size: 12px;
  }
`;

export const FilePickerContainerStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 2px dashed #e4e4e4;
  padding: 13px 0;
  margin-top: 5px;
  font-size: 1.25rem;
  margin-bottom: ${(props) => (props.readOnlyStyles ? "10px" : "0")};
  #menu_file {
    display: flex;
    align-items: center;
    p {
      font-size: 14px;
      font-weight: 600;
      font-family: Montserrat-SemiBold;
      margin-left: 10px;
    }
    .warnings {
      color: red;
      font-size: 12px;
      margin-top: -10px;
    }
  }
  svg {
    color: #178e80;
    font-size: 2.5rem;
    margin-left: 10px;
  }
  b {
    color: rgb(23, 142, 128);
    text-decoration: underline;
  }
`;

export const SelectedFileStyled = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0 0 17px;
  p {
    margin: 0;
  }
  & > p {
    padding: 10px 10px 10px 0;
    font-size: 14px;
    font-weight: 600;
  }
`;

export const FileStyled = styled.div`
  border-radius: 10px;
  border: 0.25px solid #e4e4e4;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  & > div {
    display: flex;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
    p {
      margin-left: 10px;
      text-transform: capitalize;
      font-size: 12px;
      letter-spacing: 0.75px;
    }
  }
  svg {
    cursor: pointer;
    font-size: 20px;
    margin-left: 50px;
    color: #000;
  }
`;

export const FilePickerAreaStyled = styled.div`
  width: 50%;
  cursor: pointer;
`;

export const FilePreviewStyled = styled.div`
  width: 50%;
  .preview_container {
    position: relative;
    margin-right: 20px;
    border: 0.25px solid;
    border-radius: 5px;
    padding: 5px;
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    font-family: Montserrat-SemiBold;
    & > * {
      margin: 0;
    }
    .cancel_file {
      background: #fff;
      position: absolute;
      top: -13px;
      left: 97%;
      border-radius: 50%;
      border: 0.25px solid;
      width: 25px;
      height: 25px;
      text-align: center;
      cursor: pointer;
    }
  }
`;

export const RealPreviewStyled = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${(props) =>
    props.isInDatagrid || props.readOnlyStyles ? "0" : "30px"};
  margin-right: 20px;
  justify-content: ${(props) =>
    props.readOnlyStyles ? "flex-start" : "flex-end"};
  margin-left: ${(props) => (props.readOnlyStyles ? "10px" : "0")};
`;
