import styled from "styled-components";
import { Typography } from "../../../components";
import { Grid } from "@mui/material";
const { StyledH4 } = Typography;
export const StyledDiv = styled.div`
  position: relative;
  padding: 40px 50px 20px;
  margin-bottom: 10px;
  box-shadow: rgb(224, 224, 224) 2px 2px 15px;
  background: rgb(255, 255, 255);
  .address {
    margin-left: 25px;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    position: relative;
    color: #178e80;
  }
  .floating_buttons {
    position: absolute;
    right: 0;
    top: 10px;
    display: flex;
    width: 120px;
    justify-content: space-between;
    p {
      display: flex;
      font-size: 17px;
      cursor: pointer;
      padding: 5px 10px;
      color: rgb(43, 185, 168);
      border: 0.5px solid;
      border-radius: 5px;
      svg {
        margin-left: 10px;
      }
    }
  }
  h3 {
    margin: 0 0 15px 0;
    display: flex;
    align-items: center;
    color: #178e80;
    font-size: 24px;
    border-radius: 10px;
  }
`;
export const SectionHeading = styled(StyledH4)`
  position: relative;
  font-weight: 600;
  padding: 0px 15px 0px;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 6px;
    background-color: ${({ theme }) => theme?.theme1?.primary || "red"};
    border-radius: 20px;
  }
`;
export const InputFrame = styled.form`
  width: 100%;
`;

export const RowGrid = styled(Grid)`
  ${({ padding }) =>
    padding
      ? `padding-top: 30px !important; display: flex; justify-content: center;`
      : ""}
`;

export const ColumnGrid = styled(Grid)`
  position: relative;
`;

export const StyledGrid = styled(Grid)`
  position: relative;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 90px;
  margin-top: 20px;
`;
export const SummaryWrapper = styled.div``;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 0 10px 0;
  label {
    font-size: 14px;
    color: rgb(17, 85, 95);
    font-weight: bold;
  }
  span {
    fonst-size: 12px;
  }
`;
export const ToggleWrap = styled.div`
  position: relative;
  bottom: 3px;
  margin-bottom: 11px;
`;
export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
`;

export const ToggleInput = styled.input`
  display: none;
  &:checked + label {
    background: rgb(23, 142, 128);
    color: white;
  }
`;

export const ToggleLabel = styled.label`
  text-align: center;
  cursor: pointer;
  transition: background 0.3s;
  padding: 4px;
  width: 100%;
  height: 32px;
  border: 0.25px solid rgb(169, 167, 167);
  margin: 2px;
  border-radius: 4px;
  font-size: 14px;
`;
export const Head = styled.label`
  font-size: 14px;
  position: relative;
  left: 2px;
  color: rgb(17, 85, 95);
  span {
    color: red;
  }
`;
export const UserStyles = styled.div`
  margin-bottom: 200px;
`;
