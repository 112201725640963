import React, { useState } from "react";
import { Label, TextInput } from "./style";

const Input = ({
  blockDetails,
  editDetails,
  id,
  label,
  required,
  onChange,
  onFocus,
  onBlur,
  name,
  value,
  error,
  inputRef,
  disabled,
  type = "text",
  defaultValue,
  isRequired,
  onEdit,
  icon,
  labelStyle,
  noFocusChange,
  proposalRead,
  fontSize,
  themeType,
  readOnly,
  testId,
  capitalize,
  disableMarginTop,
  ...rest
}) => {

  const handleInputChange = (e) => {
    const value = e.target.value;
    e.target.value = capitalize
      ? value.charAt(0).toUpperCase() + value.slice(1)
      : value;
  };

  const handleCombinedBlur = (e) => {
    inputRef.onBlur(e);
    if (onBlur && typeof onBlur === 'function') {
      onBlur(e);
    }

  };

  return (
    <div
      style={{
        position: "relative",
        cursor: `${blockDetails && "not-allowed"}`,
        marginTop: `${disableMarginTop ? "0" : "20px"}`,
      }}
    >
      <TextInput
        {...inputRef}
        editDetails
        blockDetails={blockDetails}
        name={name}
        type={type}
        disabled={disabled}
        required={required}
        onKeyUp={(e) => handleInputChange(e)}
        onChange={
          (onChange && onChange) || (inputRef.onChange && inputRef.onChange)
        }
        placeholder=" "
        id={id}
        // type={focus || noFocusChange ? type : "text"}
        onFocus={onFocus}
        onBlur={handleCombinedBlur}
        defaultValue={defaultValue}
        value={value}
        error={error}
        readOnly={readOnly}
        proposalRead={proposalRead}
        data-testid={testId}
        {...rest}
      />
      <Label
        themeType={themeType}
        htmlFor={name}
        style={labelStyle}
        editDetails
        readOnly={readOnly}
        blockDetails={blockDetails}
      >
        {label}
        {isRequired ? <sup> *</sup> : null}
      </Label>

      {icon && (
        <i
          className={icon}
          style={{
            position: "absolute",
            top: "30px",
            right: "35px",
            fontSize: fontSize ? `calc(15px + ${fontSize - 92}%)` : "15px",
            cursor: "pointer",
          }}
          onClick={() => {
            onEdit && onEdit();
          }}
        ></i>
      )}
    </div>
  );
};

export default Input;
