import { yupResolver } from "@hookform/resolvers/yup";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";
import * as yup from "yup";
import {
  defaultDateFormat,
  preventCharactersInInput,
} from "../../HelperFunctions/HelperFunctions";
import { Button, Input, Typography } from "../../components";
import Dropdown from "../../components/Dropdown/Dropdown";

const CommunicationModal = ({
  modalTitle,
  prefillTemplateData,
  templateEnumOBJ,
  createTemplate,
  updateTemplate,
  transactionTypeOBJ,
}) => {
  const [editorHtml, setEditorHtml] = useState("");
  const quillRef = useRef(null);
  const { Error } = Typography;
  const yupValidate = yup.object({
    templateTitle: yup.string().required("Template Title is Required"),
    createdBy: yup.string().required("Created By is Required"),
    templateEnum: yup.mixed().required("Template type is Required"),
    transactionType: yup.mixed().required("Template type is Required"),
    vendorTemplateNumber: yup
      .string()
      .required("Vendor Template No is Required"),
    alias: yup.string().required("Alias is Required"),
    content: yup.string().required("Content is Required"),
  });
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yupValidate),
    mode: "all",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (modalTitle === "Modify" || modalTitle === "View") {
      setValue("templateTitle", prefillTemplateData?.data?.data?.templateTitle);
      setValue("createdBy", prefillTemplateData?.data?.data?.createdBy);
      setValue("templateEnum", {
        label: prefillTemplateData?.data?.data?.templateEnum,
        id: prefillTemplateData?.data?.data?.templateEnum,
        value: prefillTemplateData?.data?.data?.templateEnum,
      });
      if (prefillTemplateData?.data?.data?.transactionType) {
        setValue("transactionType", {
          label: prefillTemplateData?.data?.data?.transactionType,
          id: prefillTemplateData?.data?.data?.transactionType,
          value: prefillTemplateData?.data?.data?.transactionType,
        });
      } else {
        setValue("transactionType", null);
      }
      setValue(
        "vendorTemplateNumber",
        prefillTemplateData?.data?.data?.vendorTemplateNumber
      );
      setValue("alias", prefillTemplateData?.data?.data?.alias);
      setValue("content", prefillTemplateData?.data?.data?.content);
    }
  }, [prefillTemplateData, setValue]);

  const onSubmit = (formData) => {
    if (modalTitle !== "Create") {
      updateTemplate({
        templateTitle: formData?.templateTitle,
        createdBy: parseInt(formData?.createdBy),
        templateEnum: formData?.templateEnum?.value,
        vendorTemplateNumber: formData?.vendorTemplateNumber,
        alias: formData?.alias,
        content: formData?.content,
        transactionType: formData?.transactionType?.value,
        templateId: prefillTemplateData?.data?.data?.templateId,
        Status: "Y",
      });
    } else {
      createTemplate({
        templateTitle: formData?.templateTitle,
        createdBy: parseInt(formData?.createdBy),
        templateEnum: formData?.templateEnum?.value,
        vendorTemplateNumber: formData?.vendorTemplateNumber,
        alias: formData?.alias,
        content: formData?.content,
        transactionType: formData?.transactionType?.value,
        Status: "Y",
      });
    }
  };

  useEffect(() => {
    setEditorHtml(prefillTemplateData?.data?.data?.content);
  }, [prefillTemplateData?.data?.data?.content]); // Empty dependency array ensures this effect runs once after initial render

  const handleChange = (html) => {
    setEditorHtml(html);
  };

  const openInNewTab = () => {
    // to get raw text from editor with out editor defined html tags
    const text = quillRef.current.getEditor().getText();

    // Create a Blob containing the HTML content
    const blob = new Blob([text], { type: "text/html" });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Open the URL in a new tab
    window.open(url, "_blank");

    // Revoke the URL to free up resources
    URL.revokeObjectURL(url);
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        className="grid-container"
        sx={{
          padding: "14px 20px 10px",
        }}
      >
        <Grid item xs={10} md={3} sm={10} lg={4} sx={{ position: "relative" }}>
          <Input
            themeType={"theme1"}
            label="Template Tilte"
            name="templateTitle"
            id="templateTitle"
            maxLength="100"
            placeholder="Enter Template Tilte"
            autoComplete="none"
            required={false}
            inputRef={register("templateTitle")}
            proposalRead={modalTitle === "View" ? true : false}
            readOnly={modalTitle === "View" ? true : false}
          />
          {!!errors?.templateTitle && (
            <Error top="3px" left="3px">
              {errors.templateTitle.message}
            </Error>
          )}
        </Grid>
        <Grid item xs={10} md={3} sm={10} lg={4} sx={{ position: "relative" }}>
          <Input
            themeType={"theme1"}
            label="Created By"
            name="createdBy"
            id="createdBy"
            maxLength="100"
            placeholder="Enter Created By"
            autoComplete="none"
            required={false}
            onChange={(e) => preventCharactersInInput(e)}
            inputRef={register("createdBy")}
            proposalRead={modalTitle === "View" ? true : false}
            readOnly={modalTitle === "View" ? true : false}
          />
          {!!errors?.createdBy && (
            <Error top="3px" left="3px">
              {errors.createdBy.message}
            </Error>
          )}
        </Grid>
        <Grid item xs={10} md={3} sm={10} lg={4} sx={{ position: "relative" }}>
          <Dropdown
            control={control}
            maxMenuHeight={200}
            id="templateEnum"
            placeholder="Template Type"
            selectObj={templateEnumOBJ}
            label={"Template Type"}
            isRequired={true}
            proposalRead={modalTitle === "View" ? true : false}
          />
          {!!errors?.templateEnum && (
            <Error top="3px" left="3px">
              {errors.templateEnum.message}
            </Error>
          )}
        </Grid>
        <Grid item xs={10} md={3} sm={10} lg={4} sx={{ position: "relative" }}>
          <Dropdown
            control={control}
            maxMenuHeight={200}
            id="transactionType"
            placeholder="Transaction Type"
            selectObj={transactionTypeOBJ}
            label={"Transaction Type"}
            isRequired={true}
            proposalRead={modalTitle === "View" ? true : false}
          />
          {!!errors?.transactionType && (
            <Error top="3px" left="3px">
              {errors.transactionType.message}
            </Error>
          )}
        </Grid>
        <Grid item xs={10} md={3} sm={10} lg={4} sx={{ position: "relative" }}>
          <Input
            themeType={"theme1"}
            label="Vendor Template Number"
            name="vendorTemplateNumber"
            id="vendorTemplateNumber"
            maxLength="100"
            placeholder="Enter Vendor Template Number"
            autoComplete="none"
            required={false}
            inputRef={register("vendorTemplateNumber")}
            proposalRead={modalTitle === "View" ? true : false}
            readOnly={modalTitle === "View" ? true : false}
          />
          {!!errors?.vendorTemplateNumber && (
            <Error top="3px" left="3px">
              {errors.vendorTemplateNumber.message}
            </Error>
          )}
        </Grid>
        <Grid item xs={10} md={3} sm={10} lg={4} sx={{ position: "relative" }}>
          <Input
            themeType={"theme1"}
            label="Alias"
            name="alias"
            id="alias"
            maxLength="100"
            placeholder="Enter Alias"
            autoComplete="none"
            required={false}
            inputRef={register("alias")}
            proposalRead={modalTitle === "View" ? true : false}
            readOnly={modalTitle === "View" ? true : false}
          />
          {!!errors?.alias && (
            <Error top="3px" left="3px">
              {errors.alias.message}
            </Error>
          )}
        </Grid>
        <Grid item xs={10} md={3} sm={10} lg={12} sx={{ position: "relative" }}>
          <ReactQuill
            ref={quillRef}
            theme="snow"
            value={editorHtml}
            onChange={handleChange}
            style={{
              height: "200px",
              overflow: "auto",
            }}
          />
          <PreviewWrap>
            <PreviewOutlinedIcon onClick={openInNewTab} />
          </PreviewWrap>
        </Grid>
        {/* <Grid item xs={10} md={3} sm={10} lg={4} sx={{ position: "relative" }}>
          <Dropdown
            control={control}
            maxMenuHeight={200}
            id="status"
            placeholder="Status"
            selectObj={Status}
            label={"Status"}
            isRequired={true}
          />
        </Grid>
        <Grid item xs={10} md={3} sm={10} lg={4} sx={{ position: "relative" }}>
          <DatePickerInput
            themeType="theme1"
            label="Created At"
            name="createdAt"
            id="createdAt"
            inputRef={register("createdAt")}
            placeholder="Enter Created Date"
            control={control}
            isRequired={modalTitle === "View" ? false : true}
            proposalRead={modalTitle === "View" ? true : false}
            readOnly={modalTitle === "View" ? true : false}
          />
        </Grid>
        <Grid item xs={10} md={3} sm={10} lg={4} sx={{ position: "relative" }}>
          <DatePickerInput
            themeType="theme1"
            label="Updated At"
            name="updatedAt"
            id="updatedAt"
            inputRef={register("updatedAt")}
            placeholder="Enter Updated Date"
            isRequired={modalTitle === "View" ? false : true}
            proposalRead={modalTitle === "View" ? true : false}
            readOnly={modalTitle === "View" ? true : false}
            control={control}
          />
        </Grid> */}
      </Grid>
      {modalTitle !== "View" ? (
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          className="button_container"
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <ButtonWrap>
              <Button
                type="submit"
                btnBack={"secondary"}
                bgColor={"#11555F"}
                themeType={"theme1"}
              >
                {modalTitle === "Modify" ? "Update" : "Add"}
              </Button>
            </ButtonWrap>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </StyledForm>
  );
};

export default CommunicationModal;

const StyledForm = styled.form`
  .button_container {
    margin-top: 10px;
    background-color: #fff;
    box-shadow: 0 0 1px #000;
    padding: 8px 18px 16px 0;
    width: 100%;
    margin-left: 0;
  }
`;
const ButtonWrap = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
`;
const PreviewWrap = styled.div`
  display: flex;
  justify-content: right;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 17px;
  margin-right: 10px;
  color: #11555f;
  cursor: pointer;
`;
