import { createSlice } from "@reduxjs/toolkit";
import { serializeError } from "../../utils";

const productMaster = createSlice({
    name: "productMaster",
    initialState: {
        productMasterError: null,
        productMasterSuccess: null,
    },
    reducers: {
        setProductMasterSuccessAction: (state, { payload }) => {
            state.productMasterSuccess = payload;
            state.productMasterError = null;
        },
        setProductMasterErrorAction: (state, { payload }) => {
            state.loading = null;
            state.productMasterError = serializeError(payload);
            state.productMasterSuccess = null;
        },
    },
});

export const { setProductMasterSuccessAction, setProductMasterErrorAction } =
    productMaster.actions;
export default productMaster.reducer;