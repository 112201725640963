import { createSlice } from "@reduxjs/toolkit";
import { serializeError } from "../../utils";

const bankMasterConfig = createSlice({
  name: "bankMasterConfig",
  initialState: {
    bankMasterError: null,
    bankMasterSuccess: null,
  },
  reducers: {
    setBankMasterSuccessAction: (state, { payload }) => {
      state.bankMasterSuccess = payload;
      state.bankMasterError = null;
    },
    setBankMasterErrorAction: (state, { payload }) => {
      state.loading = null;
      state.bankMasterError = serializeError(payload);
      state.bankMasterSuccess = null;
    },
  },
});

export const { setBankMasterSuccessAction, setBankMasterErrorAction } =
  bankMasterConfig.actions;
export default bankMasterConfig.reducer;
