import CallMade from "@mui/icons-material/CallMade";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import _ from "lodash";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Controller } from "react-hook-form";
import Swal from "sweetalert2";
import { Button } from "../../../components";
import {
  FilePickerAreaStyled,
  FilePickerContainerStyled,
  FilePreviewStyled,
  HeadingStyled,
  RealPreviewStyled,
} from "../MasterConfig.style";

function MasterFilePicker({
  label,
  name,
  control,
  watch,
  setValue,
  unregister,
  allowedDocFormat,
  isReadOnly,
}) {
  const allowedExtensions = Array.isArray(allowedDocFormat?.data?.data)
    ? allowedDocFormat?.data?.data?.map((value) => value.extension)
    : [];

  let extensionFormat = {};

  if (allowedExtensions.length) {
    if (allowedExtensions.includes("doc")) {
      extensionFormat = {
        ...extensionFormat,
        "application/msword": [".doc"],
      };
    }
    if (allowedExtensions?.includes("xlsx")) {
      extensionFormat = {
        ...extensionFormat,
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
      };
    }
    if (allowedExtensions?.includes("pdf")) {
      extensionFormat = {
        ...extensionFormat,
        "text/plain": [".pdf"],
      };
    }
    if (allowedExtensions?.includes("png")) {
      extensionFormat = { ...extensionFormat, "image/png": [".png"] };
    }
  }

  const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
    if (!_.isEmpty(rejectedFiles)) {
      Swal.fire({
        text: "File Format not supported",
        icon: "warning",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        allowOutsideClick: false,
        focusCancel: false,
        scrollbarPadding: false,
      });
      return;
    }
    const base64Promises = acceptedFiles.map(
      (file) =>
        new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = () => {
            const base64String = reader.result.split(",")[1];
            resolve({ file, base64String });
          };
          reader.readAsDataURL(file);
        })
    );

    const base64Files = await Promise.all(base64Promises);

    setValue(name, base64Files);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: extensionFormat,
  });

  const handleRemove = (name) => {
    unregister(name);
    setValue(name, null);
  };

  function isValidURL(text) {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return urlRegex.test(text);
  }

  const handleRealFilePreview = (url) => {
    if (url) {
      window.open(url, "_blank");
    }
  };

  return (
    <>
      <FilePickerContainerStyled readOnlyStyles={isReadOnly}>
        {!isReadOnly ? (
          <HeadingStyled>
            Select file for {label} <span className="required-label">*</span>
          </HeadingStyled>
        ) : (
          <HeadingStyled>{label}</HeadingStyled>
        )}

        {!isReadOnly ? (
          <FilePickerAreaStyled>
            <Controller
              name={name}
              control={control}
              render={() => (
                <div {...getRootProps()} id="menu_file">
                  <input {...getInputProps()} />
                  <FileUploadOutlinedIcon />
                  <div>
                    <p>
                      Drag your file here or <b>Browse</b>
                    </p>

                    {Array.isArray(allowedExtensions) ? (
                      <p className="warnings">
                        Accepted File Formats: {allowedExtensions.join(", ")}
                      </p>
                    ) : null}
                  </div>
                </div>
              )}
            />
          </FilePickerAreaStyled>
        ) : null}

        <FilePreviewStyled>
          {Array.isArray(watch(name)) && watch(name)?.length ? (
            <div class="preview_container">
              <p className="file_name">
                {watch(name)?.[0]?.file?.name.length < 25
                  ? watch(name)?.[0]?.file?.name
                  : watch(name)?.[0]?.file?.name.substring(0, 25)}
              </p>
              <p>{Math.floor(watch(name)?.[0]?.file?.size / 1024)} KB</p>
              <p className="cancel_file" onClick={() => handleRemove(name)}>
                X
              </p>
            </div>
          ) : isValidURL(watch(name)) ? (
            <RealPreviewStyled readOnlyStyles={isReadOnly}>
              <Button
                bgColor="transparent"
                textColor="#11555F"
                id="add_broker"
                variant="outlined"
                borderColor="#11555F"
                endIcon={<CallMade />}
                onClick={() => handleRealFilePreview(watch(name))}
              >
                Preview an Uploaded File
              </Button>
            </RealPreviewStyled>
          ) : (
            <></>
          )}
        </FilePreviewStyled>
      </FilePickerContainerStyled>
    </>
  );
}

export default MasterFilePicker;
