import { Button, GlobalRadio, Typography } from "../../../components";
import { RulesStyled } from "../Brokerage.style";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  allowPercentageNumbers,
  preventZeroAsInitial,
} from "../../../HelperFunctions/HelperFunctions";

function Rules({
  register,
  control,
  selectedBrokerageAffectedFields,
  cloneButton,
  rulesLength,
  handleFocus,
  selectedBrokerageType,
  handleSave,
  mainIndex,
  cardInFocus,
  multipleBrokerage,
  handleRuleDelete,
  handleModal,
  handleFieldSlugForExcel,
  selectedBrokerage,
  allSingleCheckedValues,
  allBundleCheckedValues,
  errors,
  // conditionalBrokerageErrors
}) {
  const { Error } = Typography;

  const isFocused =
    selectedBrokerageType === "Single"
      ? mainIndex === cardInFocus.single - 1
      : mainIndex === cardInFocus.bundle - 1;
  const dynamicType =
    selectedBrokerageType === "Single"
      ? allSingleCheckedValues
      : allBundleCheckedValues;
  const isDisabled =
    dynamicType.length === 0 || dynamicType[mainIndex]?.length === 0;

  return (
    <RulesStyled
      isDisabled={isDisabled}
      isFocused={isFocused}
      onClick={() =>
        handleFocus(
          selectedBrokerageType === "Single"
            ? { focus: rulesLength, type: "single" }
            : { focus: rulesLength, type: "bundle" }
        )
      }
    >
      <div className="rules_header">
        <p>Rule {mainIndex + 1}</p>
        <Button
          bgColor="transparent"
          textColor="#11555F"
          id="add_broker"
          variant="outlined"
          borderColor="#11555F"
          onClick={() =>
            handleRuleDelete(
              selectedBrokerageType === "Single"
                ? {
                  deleteCardIndex: cardInFocus.single - 1,
                  selectedBrokerageType,
                }
                : {
                  deleteCardIndex: cardInFocus.bundle - 1,
                  selectedBrokerageType,
                }
            )
          }
        >
          Delete Rule
        </Button>
      </div>
      <div>
        <div className="select_fields">
          {dynamicType?.[mainIndex]?.length === 0 ? (
            <p>Select The Fields From The Box Above To Create A Rule</p>
          ) : (
            <>
              {dynamicType?.[mainIndex]?.map((value) => {
                return (
                  <div
                    className="premium_affecting_fields"
                    onClick={(e) => {
                      handleModal(e);
                      handleFieldSlugForExcel(e, {
                        value: value.slug,
                        slug: value.masterSlug,
                      });
                    }}
                  >
                    <p>{value.label} </p>
                    <EditOutlinedIcon />
                  </div>
                );
              })}
            </>
          )}
        </div>
        {selectedBrokerageType === "Bundled" &&
          allBundleCheckedValues?.[mainIndex]?.length > 0 &&
          multipleBrokerage?.[mainIndex]?.conditionalPremiumBearableFields?.map(
            (value, fieldIndex) => {
              return (
                <div className="brokerage_options">
                  <div className="payout_container">
                    <GlobalRadio
                      name={`selectedBrokerage.${mainIndex}.${value.value}.brokerageType`}
                      label={`${value.label}:`}
                      options={["VARIABLE", "FLAT"]}
                      defaultValue="VARIABLE"
                      control={control}
                      radioSpace={true}
                      disabled={isDisabled}
                    />
                    <div className="payout">
                      <p>Payout:</p>
                      <div className="input_nd_error_wrapper">
                        <input
                          type="text"
                          placeholder={`Enter Payout`}
                          disabled={isDisabled}
                          {...register(
                            `selectedBrokerage.${mainIndex}.${value.value}.amount`
                          )}
                          onChange={
                            selectedBrokerage?.[mainIndex]?.[value.value]
                              ?.brokerageType === "VARIABLE"
                              ? (e) => allowPercentageNumbers(e)
                              : (e) => preventZeroAsInitial(e)
                          }
                        />
                        <div className="error_container">
                          {!!errors?.selectedBrokerage?.[mainIndex]?.[
                            value.value
                          ]?.amount && (
                              <Error top="3px" left="3px" bottom="-15px">
                                Amount is Required
                              </Error>
                            )}
                        </div>
                      </div>
                      {selectedBrokerage?.[mainIndex]?.[value.value]
                        ?.brokerageType === "VARIABLE" || !(selectedBrokerage?.[mainIndex]?.[value.value]
                        ?.brokerageType) ? (
                        <p>Percentage %</p>
                      ) : (
                        <p>Rupees ₹</p>
                      )}
                    </div>
                  </div>
                </div>
              );
            }
          )}
        <div className="btn_actions">
          <Button
            themeType={"theme1"}
            onClick={() =>
              handleSave(selectedBrokerageType, selectedBrokerage, cardInFocus)
            }
            bgColor={
              isDisabled || !isFocused ? "#C9CDD4" : "rgba(17, 85, 95, 1)"
            }
          >
            Add New Rule
          </Button>
          {/* {!isDisabled && <Button
                        className="demo"
                        bgColor="transparent"
                        textColor="rgb(23, 142, 128)"
                        id="add_broker"
                        variant="outlined"
                        borderColor="rgb(23, 142, 128)"
                    >
                        Verify Calculation
                    </Button>}
                    <Button
                        className="demo"
                        bgColor="transparent"
                        textColor={isDisabled ? "#C9CDD4" : "rgb(23, 142, 128)"}
                        id="add_broker"
                        variant="outlined"
                        borderColor={isDisabled ? "#C9CDD4" : "rgb(23, 142, 128)"}
                    >
                        Rearrange & Edit
                    </Button>
                    <div className="icon_container" onClick={() => cloneButton(rulesLength)}>
                        <ContentCopyIcon />
                    </div> */}
        </div>
      </div>
    </RulesStyled>
  );
}

export default Rules;
