import React from "react";
import { Input, Button, Select } from "../../../components";
import styled from "styled-components";
import { Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import CachedIcon from "@mui/icons-material/Cached";

export const CustomisationForm = () => {
  const optionsData = [
    {
      id: "",
      name: "Select",
      value: "",
    },
    {
      id: 2,
      name: "Data",
      value: "data",
    },
  ];
  return (
    <InputFrame>
      <Grid
        style={{ marginBottom: "10px" }}
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <StyledGrid item xs={10} md={3} sm={10} lg={3}>
          <Select
            themeType={"theme1"}
            name="select_process"
            id="1"
            value="select_process"
            label="Select Process"
            options={optionsData}
          />
        </StyledGrid>
        <StyledGrid item xs={10} md={3} sm={10} lg={3}>
          <Select
            themeType={"theme1"}
            name="select_lob"
            id="2"
            value="select_lob"
            label="Select LOB"
            options={optionsData}
          />
        </StyledGrid>
        <StyledGrid item xs={10} md={3} sm={10} lg={3}>
          <Select
            themeType={"theme1"}
            name="select_insurer"
            id="3"
            value="select_insurer"
            label="Select Insurer"
            options={optionsData}
          />
        </StyledGrid>
        <StyledGrid item xs={10} md={3} sm={10} lg={3}>
          <Select
            themeType={"theme1"}
            name="select_product"
            id="4"
            value="select_product"
            label="Select Product"
            options={optionsData}
          />
        </StyledGrid>
      </Grid>
      <Grid
        container
        style={{ marginBottom: "10px", paddingBottom: "0px" }}
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <StyledGrid item xs={10} md={3} sm={10} lg={3}>
          <Select
            themeType={"theme1"}
            name="select_page"
            id="5"
            value="select_page"
            label="Select Page"
            options={optionsData}
          />
        </StyledGrid>
      </Grid>
      <Grid
        container
        style={{
          marginBottom: "10px",
          paddingBottom: "0px",
          position: "absolute",
          bottom: "-10px",
        }}
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid item xs={10} sm={10} md={9} lg={12}>
          <ButtonFrame>
            <Button
              themeType={"theme1"}
              style={{ marginLeft: "10px" }}
              round
              startIcon={<SearchIcon />}
            >
              Search
            </Button>
            <Button
              themeType={"theme1"}
              btnBack="secondary"
              style={{ marginLeft: "10px" }}
              round
              startIcon={<AddIcon />}
            >
              Add Role
            </Button>
            <Button
              themeType={"theme1"}
              btnBack="tertiary"
              style={{ marginLeft: "10px" }}
              bgColor="#FF4646"
              round
              startIcon={<CachedIcon />}
            >
              Reset
            </Button>
          </ButtonFrame>
        </Grid>
      </Grid>
    </InputFrame>
  );
};

const StyledGrid = styled(Grid)`
  position: relative;
  .MuiButtonBase-root {
    margin-left: 10px;
  }
  .buttonMargin {
    margin-left: 10px !important;
  }
`;

const InputFrame = styled.form`
  position: relative;
  margin-top: 30px;
  width: 100%;
`;

const ButtonFrame = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  @media (max-width: 767px) {
    justify-content: flex-start;
  }
`;
