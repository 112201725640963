import { useEffect, useState } from "react";
import {
  useCreateFieldsForMaster,
  useCreateNewMaster,
} from "./components/service";
import {
  removeAllFieldsFromMaster,
  setAddNewMasterErrorAction,
  setAddNewMasterSuccessAction,
} from "./AddNewMaster.slice";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import * as yup from "yup";

function useAddNewMaster() {
  const dispatch = useDispatch();
  const [newMasterModal, setNewMasterModal] = useState({
    state: false,
    title: "",
  });
  useEffect(() => {
    !newMasterModal?.state && dispatch(removeAllFieldsFromMaster());
  }, [JSON.stringify(newMasterModal)]);
  const { addNewMasterSuccess, addNewMasterError } = useSelector(
    (state) => state.AddNewMasterSlice
  );

  const addNewMasterValidation = yup.object().shape({
    masterTitle: yup.string().nullable().required("Master title is required"),
    masterSlug: yup.string().nullable().required("Master slug is required"),
    masterTable: yup.string().nullable().required("Master table is required"),
    uniqueKey: yup.string().nullable().required("Unique key is required"),
    // isLobSpecific: yup.object().required("Is lob specific is required"),
  });

  const addFieldsToMasterValidation = yup.object().shape({
    fieldName: yup.string().nullable().required("Field name is required"),
    fieldSlug: yup.string().nullable().required("Field slug is required"),
    fieldDefault: yup.string().nullable(),
    isMandatory: yup.string().nullable().required("Is mandatory is required"),
    isMasterApiAvailable: yup
      .string()
      .required("Is master api available is required"),
    masterSlug: yup.string().nullable(),
    fieldSequence: yup
      .string()
      .nullable()
      .required("Field sequence is required"),
    // validationGroup: yup.object().required("Validation group is required"),
    fieldType: yup.object().required("Field type is required"),
  });
  const fieldTypeOptions = [
    {
      label: "INT",
      value: "INT",
    },
    {
      label: "VARCHAR",
      value: "VARCHAR",
    },
    {
      label: "UPLOAD",
      value: "UPLOAD",
    },
    {
      label: "TEXT",
      value: "TEXT",
    },
    {
      label: "DROPDOWN",
      value: "DROPDOWN",
    },
  ];
  const validationGroupOptions = [
    {
      label: "TEXT",
      value: "TEXT",
    },
    {
      label: "ALPHABETIC",
      value: "ALPHABETIC",
    },
    {
      label: "PINCODE",
      value: "PINCODE",
    },
    {
      label: "ALPHANUMERiC",
      value: "ALPHANUMERiC",
    },
    {
      label: "MOBILE",
      value: "MOBILE",
    },
    {
      label: "EMAIL",
      value: "EMAIL",
    },
    {
      label: "ADHAAR",
      value: "ADHAAR",
    },
    {
      label: "PAN",
      value: "PAN",
    },
    {
      label: "NUMERIC",
      value: "NUMERIC",
    },
    {
      label: "INT",
      value: "INT",
    },
    {
      label: "PERCENTAGE",
      value: "PERCENTAGE",
    },
    {
      label: "VARCHAR",
      value: "VARCHAR",
    },
    {
      label: "IFSC",
      value: "IFSC",
    },
  ];

  useEffect(() => {
    if (addNewMasterError) {
      Swal.fire({
        text: addNewMasterError,
        icon: "warning",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        allowOutsideClick: false,
        focusCancel: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(setAddNewMasterErrorAction(null));
        }
      });
    }
    if (addNewMasterSuccess) {
      Swal.fire({
        text: addNewMasterSuccess,
        icon: "success",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        focusCancel: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(setAddNewMasterSuccessAction(null));
        }
      });
    }
  }, [addNewMasterSuccess, addNewMasterError]);

  const {
    createNewMaster,
    masterId,
    isSuccess: isCreateMasterSuccess,
  } = useCreateNewMaster();
  const { createFieldsForMaster, isSuccess: isCreateFieldSuccess } =
    useCreateFieldsForMaster();

  return {
    newMasterModal,
    setNewMasterModal,
    createNewMaster,
    masterId,
    createFieldsForMaster,
    isCreateMasterSuccess,
    isCreateFieldSuccess,
    fieldTypeOptions,
    validationGroupOptions,
    addFieldsToMasterValidation,
    addNewMasterValidation,
  };
}

export default useAddNewMaster;
