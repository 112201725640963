import React, { useState } from "react";
import styled from "styled-components";
import { Typography } from "../../components";
import { RoleForm } from "./role-form/role-form";
import { RoleTable } from "./role-table/role-table";
import { RoleConfiguration } from "./role-configuration/role-configuration";

//Required Typography
const { StyledH1 } = Typography;

export const RolePage = () => {
  const [isOpen, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <StyledDiv>
        <StyledH1 themeType="theme2" partialU color={"rgb(23, 142, 128)"} align={"left"}>
          Role
        </StyledH1>
        <RoleForm />
        <RoleTable setOpen={setOpen} />
      </StyledDiv>
      {
        <RoleConfiguration
          modalTitle={"Role Configuration"}
          isOpen={isOpen}
          handleClose={handleClose}
        />
      }
    </>
  );
};

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
`;
