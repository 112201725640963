import { yupResolver } from "@hookform/resolvers/yup";
import _, { debounce } from "lodash";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { Button, Typography } from "../../components";
import AccessDenied from "../../components/AccessComponents/accessAllowed";
import useGetOperations from "../../hooks/useGetOperations";
import ContactDetails from "./ContactDetails/ContactDetails";
import PermanentAddress from "./PermanentAddress/PermanentAddress";
import UserDetails from "./UserDetails/UserDetails";
import { addressSchema, contactSchema, schema } from "./helperfunc";
import {
  addUserDraft,
  finalUserSubmit,
  getUserDataId,
  updateUserController,
  updateUserDraft,
  useGetBranches,
  useGetChannel,
  useGetDepartment,
  useGetPincode,
  useGetRoles,
  useGetUserName,
  useGetUserType,
} from "./serviceAPI";
import { ButtonWrapper, UserStyles } from "./styles/styles";
import { setUpdateId } from "./userConfig.slice";

const UserConfiguration = () => {
  const [isUserNameAvail, setIsUserNameAvail] = useState(false);
  const [userNameVal, setUserNameVal] = useState("");

  //state for card logic
  const [activeStep, setActiveStep] = useState(1);
  const [summaryStep, setSummaryStep] = useState({
    user: false,
    address: false,
    contact: false,
  });

  // state for data storing
  const [user, setUser] = useState({});
  const [permanentAddress, setPermanentAddress] = useState({});
  const [contact, setContact] = useState({});
  const { updateId } = useSelector((state) => state.userConfig);
  const location = useLocation();
  const { allowedOperations } = useGetOperations(location.pathname);

  const currentUrl = window.location.pathname;

  // validation for username for checking of the username existence
  const userVal = yup.object({
    username: yup
      .string()
      .required("Username field is required")
      .matches(
        /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]+$/,
        "Requires both letter and number"
      )
      .test({
        name: "username-availability",
        message: "Username already exists",
        test: function (value) {
          if (isUserNameAvail && currentUrl !== "/user-configupdate") {
            return this.createError({
              path: "username",
              message: "Username already exists",
            });
          }
          return true;
        },
      }),
  });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
    reset,
    trigger,
  } = useForm({
    resolver: yupResolver(
      activeStep === 1
        ? schema(currentUrl, userVal)
        : activeStep === 2
        ? addressSchema
        : contactSchema
    ),
    mode: "all",
    reValidateMode: "all",
  });
  const navigate = useNavigate();
  const url = new URLSearchParams(location.search);
  const usersDataId = url.get("id");
  const draftUpdate = url.get("mongoId");
  const { pincode, username, start_date } = watch();
  const dispatch = useDispatch();
  const { mutate, data } = addUserDraft();
  const { mutate: finalSubmit, data: userData } = finalUserSubmit();
  const { mutate: updateUserDrafts, data: userUpdateData } = updateUserDraft();
  const { data: roles } = useGetRoles();
  const { data: userType } = useGetUserType();
  const { data: channel } = useGetChannel();
  const { data: department } = useGetDepartment();
  const { mutate: updateDrafts } = updateUserController();
  const { StyledH1 } = Typography;
  const { data: datas } = getUserDataId(usersDataId);
  const { data: pin } = useGetPincode(pincode);

  const { data: usernameExists } = useGetUserName(username);
  const { data: branchData } = useGetBranches();
  const userNameDebounce = useMemo(
    () => debounce((e) => setUserNameVal(e.target.value), 400),
    []
  );
  useEffect(() => {
    if (usernameExists?.data?.status !== undefined) {
      setIsUserNameAvail(usernameExists.data.status);
    }
  }, [usernameExists?.data?.status]);

  // dropDown Data
  const rolesData = roles?.data?.data?.map((item) => {
    return {
      value: item.roleid,
      label: item.role_name,
    };
  });

  // const branchData = branch?.data?.data?.map((item) => {
  //   return {
  //     value: item.masterCompanyBranchLocationId,
  //     label: item.branchName,
  //   };
  // });

  const branches =
    !_.isEmpty(branchData?.data?.data) &&
    branchData?.data?.data?.map((item) => {
      return {
        value: item?.brokerOfficeLocationRelationId,
        label: item?.city,
      };
    });

  const userTypeData = userType?.data?.data?.map((item) => {
    return {
      value: item.userTypeId,
      label: item.userTypes,
    };
  });

  const channelData = channel?.data?.data?.map((item) => {
    return {
      value: item.channelId,
      label: item.channels,
    };
  });

  const departmentData = department?.data?.data?.map((item) => {
    return {
      value: item.departmentId,
      label: item.departments,
    };
  });

  // dispatching id to redux
  const updateid = data?.data?.data?.user_temp_id;
  dispatch(setUpdateId(updateid));

  const dataFill = datas?.data?.data;
  const fieldNames = datas?.data?.data ? Object.keys(datas.data.data) : [];
  const toggleInputYesRef = useRef(null);
  const toggleInputNoRef = useRef(null);
  const userRef = useRef(null);

  // useEffect(() => {
  //   if (data && updateId && currentUrl === "/user-configuration") {
  //     navigate(`?tid=${updateId}`);
  //   }
  // }, [updateId, currentUrl]);

  useEffect(() => {
    if (
      (currentUrl === "/user-configuration" &&
        (Object.values(user) || Object.values(permanentAddress))) ||
      Object.values(contact)
    ) {
      reset();
      setActiveStep(1);
      setSummaryStep({ user: false, address: false, contact: false });
    }
  }, [currentUrl]);

  useEffect(() => {
    if (usersDataId) {
      fieldNames?.forEach((fieldName) => {
        const isValidDate = moment(
          dataFill[fieldName],
          moment.ISO_8601,
          true
        ).isValid();
        const isValidNumber = /^[0-9]+$/.test(dataFill[fieldName]);
        if (isValidDate && !isValidNumber) {
          setValue(fieldName, new Date(dataFill[fieldName]));
        } else if (
          [
            "lob_id",
            "gender",
            "department",
            "roleId",
            "user_type",
            "channel",
            "branch_name",
            "zone",
          ].includes(fieldName)
        ) {
          switch (fieldName) {
            case "lob_id":
              setValue(fieldName, {
                label: dataFill["lob_name"],
                value: dataFill[fieldName],
              });
              break;
            case "gender":
              setValue(fieldName, {
                label: dataFill["gender"],
                value: dataFill[fieldName],
              });
              break;
            case "department":
              setValue(fieldName, {
                label: dataFill["department_name"],
                value: dataFill[fieldName],
              });
              break;
            case "roleId":
              setValue(fieldName, {
                label: dataFill["roleName"],
                value: dataFill[fieldName],
              });
              break;
            case "user_type":
              setValue(fieldName, {
                label: dataFill["user_type_name"],
                value: dataFill[fieldName],
              });
              break;
            case "channel":
              setValue(fieldName, {
                label: dataFill["channel_name"],
                value: dataFill[fieldName],
              });
              break;
            case "branch_name":
              setValue(fieldName, {
                label: dataFill["branch_label"],
                value: dataFill[fieldName],
              });
              break;
            case "zone":
              setValue(fieldName, {
                label: dataFill["zone"],
                value: dataFill[fieldName],
              });
              break;
            default:
              break;
          }
        } else if (["user_status"].includes(fieldName)) {
          setValue(fieldName, dataFill[fieldName]);
          if (dataFill[fieldName] === "Y") {
            // If the API response data is 'Y', trigger the click event for the "Active" input
            if (toggleInputYesRef.current) {
              toggleInputYesRef.current.click();
            }
          } else if (dataFill[fieldName] === "N") {
            // If the API response data is 'N', trigger the click event for the "In Active" input
            if (toggleInputNoRef.current) {
              toggleInputNoRef.current.click();
            }
          }
        } else {
          if (!["start_date", "end_date"].includes(fieldName)) {
            setValue(fieldName, dataFill[fieldName]);
          }
        }
      });
    }
  }, [datas]);

  useEffect(() => {
    if (userData && userData?.data?.status) {
      Swal.fire(userData?.data?.message, "Form Submitted ", "success").then(
        (result) => {
          if (result.isConfirmed) {
            navigate("/user-controller");
          }
        }
      );
    }
    if (userData && !userData?.data?.status) {
      Swal.fire(userData?.data?.message, "Error ", "error");
    }
  }, [userData]);

  useEffect(() => {
    if (userUpdateData && !userUpdateData?.data?.status) {
      Swal.fire(userUpdateData?.data?.message, "Error", "error");
    }
    if (userUpdateData && userUpdateData?.data.status) {
      Swal.fire(userUpdateData?.data?.message, "Form Updated ", "success").then(
        (result) => {
          if (result.isConfirmed) {
            navigate("/user-controller");
          }
        }
      );
    }
  }, [userUpdateData]);

  useEffect(() => {
    if (pincode) {
      if (pincode.length === 6) {
        setValue("state", pin?.data?.data[0]?.state?.stateName);
        setValue("city", pin?.data?.data[0]?.city?.cityName);
        trigger("state");
        trigger("city");
      } else {
        setValue("state", "");
        setValue("city", "");
      }
    }
  }, [pin, pincode]);

  useEffect(() => {
    if (userRef.current && activeStep === 2) {
      const aboveComponentHeight = userRef.current.scrollHeight - 435;
      window.scrollTo({
        top: aboveComponentHeight,
        behavior: "smooth",
      });
    }
    if (userRef.current && activeStep === 1) {
      const aboveComponentHeight = userRef.current.scrollHeight;
      window.scrollTo({
        top: -aboveComponentHeight,
        behavior: "smooth",
      });
    }
    // if (userRef.current && activeStep === 3) {
    //   const aboveComponentHeight = userRef.current.scrollHeight - 360;
    //   window.scrollTo({
    //     top: aboveComponentHeight,
    //     behavior: "smooth",
    //   });
    // }
    // if (userRef.current && activeStep === 0) {
    //   const aboveComponentHeight = userRef.current.scrollHeight;
    //   window.scrollTo({
    //     top: aboveComponentHeight,
    //     behavior: "smooth",
    //   });
    // }
  }, [activeStep]);

  // submit and edit functions
  const handleSaveProfile = (data) => {
    const arrayOfObjects = Object.keys(data).map((key) => ({
      [key]: data[key],
    }));
    const formattedData = {};
    arrayOfObjects?.forEach((item) => {
      const key = Object.keys(item)[0];
      const value = item[key];
      if (typeof value === "object" && value?.value) {
        formattedData[key] = value.value;
      } else {
        formattedData[key] = value;
      }
    });
    setUser({ 0: formattedData, 1: data });
    setActiveStep(2);
    setSummaryStep((prevSummaryStep) => ({
      ...prevSummaryStep,
      user: true,
    }));
    mutate({
      data: formattedData,
    });
  };

  const handleEditProfile = () => {
    setActiveStep(1);
    setSummaryStep({
      user: false,
      address: false,
      contact: false,
    });
  };

  const handleSavePermanentAdd = (data) => {
    setPermanentAddress(data);
    setActiveStep(3);
    setSummaryStep((prevSummaryStep) => ({
      ...prevSummaryStep,
      address: true,
    }));
    const arrayOfObjects = Object.keys(data).map((key) => ({
      [key]: data[key],
    }));
    const formattedData = {};
    arrayOfObjects?.forEach((item) => {
      const key = Object.keys(item)[0];
      const value = item[key];
      if (typeof value === "object" && value?.value) {
        formattedData[key] = value.value;
      } else {
        formattedData[key] = value;
      }
    });
    updateDrafts({
      id: updateId,
      data: { ...user[0], ...formattedData },
    });
  };

  const handleEditPermanentAdd = () => {
    setActiveStep(2);
    setSummaryStep((prevSummaryStep) => ({
      ...prevSummaryStep,
      address: false,
      contact: false,
    }));
  };

  const handleSaveContact = (data) => {
    setContact(data);
    setActiveStep(0);
    setSummaryStep((prevSummaryStep) => ({
      ...prevSummaryStep,
      contact: true,
    }));
    const arrayOfObjects = Object.keys(data).map((key) => ({
      [key]: data[key],
    }));
    const formattedData = {};
    arrayOfObjects?.forEach((item) => {
      const key = Object.keys(item)[0];
      const value = item[key];
      if (typeof value === "object" && value?.value) {
        formattedData[key] = value.value;
      } else {
        formattedData[key] = value;
      }
    });
    updateDrafts({
      id: updateId,
      data: { ...user[0], ...permanentAddress, ...formattedData },
    });
  };

  const handleEditContact = () => {
    setActiveStep(3);
    setSummaryStep((prevSummaryStep) => ({
      ...prevSummaryStep,
      contact: false,
    }));
  };

  //mongo id for update
  const draftID = draftUpdate || "";

  // final submit/update
  const successSubmit = () => {
    // condition for the update or create
    if (draftID !== "") {
      const structuredData = { ...user[0], ...permanentAddress, ...contact }; // dont change the order
      const arrayOfObjects = Object.keys(structuredData).map((key) => ({
        [key]: structuredData[key],
      }));
      const formattedData = {};
      arrayOfObjects?.forEach((item) => {
        const key = Object.keys(item)[0];
        const value = item[key];
        if (typeof value === "object" && value?.value) {
          formattedData[key] = value.value;
        } else {
          formattedData[key] = value;
        }
      });
      updateUserDrafts({
        id: draftID,
        data: formattedData,
      });
    } else {
      finalSubmit({
        id: updateId,
      });
    }
  };

  return allowedOperations.includes("Edit") ? (
    <UserStyles ref={userRef}>
      <StyledH1 partialU color={"rgb(23, 142, 128)"} align={"left"}>
        User Configuration
      </StyledH1>
      <UserDetails
        user={user}
        setUser={setUser}
        onEdit={handleEditProfile}
        onSave={handleSaveProfile}
        active={activeStep}
        summary={summaryStep}
        newUpdateID={usersDataId}
        rolesData={rolesData}
        branchData={branches}
        userTypeData={userTypeData}
        channelData={channelData}
        departmentData={departmentData}
        handleSubmit={handleSubmit}
        register={register}
        errors={errors}
        control={control}
        toggleInputYesRef={toggleInputYesRef}
        toggleInputNoRef={toggleInputNoRef}
        Controller={Controller}
        userRef={userRef}
        minDate={start_date}
        debounceFn={userNameDebounce}
        currentUrl={currentUrl}
      />
      <PermanentAddress
        address={permanentAddress}
        onEdit={handleEditPermanentAdd}
        onSave={handleSavePermanentAdd}
        active={activeStep}
        summary={summaryStep}
        newUpdateID={usersDataId}
        handleSubmit={handleSubmit}
        register={register}
        errors={errors}
      />
      <ContactDetails
        contact={contact}
        onEdit={handleEditContact}
        onSave={handleSaveContact}
        active={activeStep}
        summary={summaryStep}
        newUpdateID={usersDataId}
        handleSubmit={handleSubmit}
        register={register}
        errors={errors}
      />
      {activeStep === 0 && (
        <ButtonWrapper>
          <Button onClick={successSubmit} bgColor={"rgb(23, 142, 128)"}>
            {!_.isEmpty(draftID) ? "Update Form" : "SUBMIT FORM"}
          </Button>
        </ButtonWrapper>
      )}
    </UserStyles>
  ) : (
    <AccessDenied />
  );
};

export default UserConfiguration;
