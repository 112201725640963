import { React, useEffect } from "react";
import { Input, Button, Typography } from "../../../components";
import { useForm } from "react-hook-form";
import SummaryCard from "../Summary/summaryContact";
import { getUserDataId } from "../serviceAPI";
import {
  StyledDiv,
  InputFrame,
  RowGrid,
  ColumnGrid,
  StyledGrid,
} from "../styles/styles";

const ContactDetails = ({
  contact,
  onEdit,
  onSave,
  active,
  summary,
  handleSubmit,
  register,
  errors,
}) => {
  const { Error } = Typography;

  return (
    <StyledDiv>
      <h3 style={{ display: "flex", justifyContent: "space-between" }}>
        Contact Details{" "}
      </h3>
      {active === 3 ? (
        <InputFrame onSubmit={handleSubmit(onSave)}>
          <RowGrid
            xs={12}
            sm={10}
            md={9}
            lg={12}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <StyledGrid item xs={10} md={3} sm={10} lg={3}>
              <Input
                themeType="theme1"
                label="Mobile number"
                name="mobile_no"
                id="mobile_number"
                maxLength="10"
                placeholder="Enter mobile number"
                autoComplete="none"
                defaultValue={""}
                isRequired={true}
                inputRef={register("mobile_no")}
              />{" "}
              {errors.mobile_no && (
                <Error left="2px">{errors.mobile_no?.message}</Error>
              )}
            </StyledGrid>
            <StyledGrid item xs={10} md={3} sm={10} lg={3}>
              <Input
                themeType="theme1"
                label="Alternate Mobile number"
                name="alternate_mobile_no"
                id="alternate_mobile_no"
                maxLength="10"
                placeholder="Enter mobile number"
                autoComplete="none"
                defaultValue={""}
                inputRef={register("alternate_mobile_no")}
              />
              {errors.alternate_mobile_no && (
                <Error left="2px">{errors.alternate_mobile_no?.message}</Error>
              )}
            </StyledGrid>
            <StyledGrid item xs={10} md={3} sm={10} lg={3}>
              <Input
                themeType="theme1"
                label="Email ID "
                name="email_id"
                id="email_id"
                maxLength="50"
                placeholder="Enter email"
                autoComplete="none"
                defaultValue={""}
                isRequired={true}
                inputRef={register("email_id")}
              />
              {errors.email_id && (
                <Error left="2px">{errors.email_id?.message}</Error>
              )}
            </StyledGrid>

            <ColumnGrid item xs={10} md={3} sm={10} lg={3}>
              <Input
                themeType="theme1"
                label="Alternate Email ID "
                name="alternate_email_id"
                id="email_2"
                maxLength="50"
                placeholder="Enter Email"
                autoComplete="none"
                defaultValue={""}
                inputRef={register("alternate_email_id")}
              />
              {errors.alternate_email_id && (
                <Error left="2px">{errors.alternate_email_id?.message}</Error>
              )}
            </ColumnGrid>
          </RowGrid>
          <RowGrid padding>
            <Button type="submit" bgColor={"rgb(23, 142, 128)"}>
              Proceed
            </Button>
          </RowGrid>
        </InputFrame>
      ) : summary.contact ? (
        <div>
          <RowGrid>
            <SummaryCard values={contact} />
          </RowGrid>
          <RowGrid padding>
            <Button
              type={"submit"}
              onClick={onEdit}
              bgColor={"rgb(23, 142, 128)"}
            >
              edit
            </Button>
          </RowGrid>
        </div>
      ) : (
        <noscript />
      )}
    </StyledDiv>
  );
};

export default ContactDetails;
