import styled from "styled-components";
import useCustomer from "../useCustomer";
import CustomerAddForm from "./customerAddForm";

const CustomerAdd = () => {
  const {
    OccupationOBJ,
    createCustomer,
    pincode,
    setPincode,
    data,
    GenderObj,
    YesNoObj,
    setValue,
    trigger,
    handleSubmit,
    register,
    errors,
    control,
    educationObj,
    leadSourceObj,
  } = useCustomer();
  return (
    <StytledContainer>
      <StyledH1
        themeType="theme1"
        partialU
        color={"rgb(23, 142, 128)"}
        align={"left"}
      >
        Customer Add
      </StyledH1>
      <CustomerAddForm
        OccupationOBJ={OccupationOBJ}
        GenderObj={GenderObj}
        createCustomer={createCustomer}
        pincode={pincode}
        setPincode={setPincode}
        YesNoObj={YesNoObj}
        data={data}
        setValue={setValue}
        trigger={trigger}
        handleSubmit={handleSubmit}
        register={register}
        errors={errors}
        control={control}
        educationObj={educationObj}
        leadSourceObj={leadSourceObj}
      />
    </StytledContainer>
  );
};

export default CustomerAdd;

const StytledContainer = styled.div`
  position: relative;
  padding: 40px 16px 20px;
  margin-bottom: 10px;
  box-shadow: rgb(224, 224, 224) 2px 2px 15px;
  background: rgb(255, 255, 255);
`;

const StyledH1 = styled.h1`
  font-family: Montserrat-SemiBold, sans-serif;
  display: inline-block;
  padding-bottom: 5px;
  position: relative;
  font-size: 24px;
  margin: 0px 0px 15px;
  padding-left: 20px;
  line-height: 36px;
  width: 100%;
  font-weight: 600;
  color: rgb(23, 142, 128);
  text-align: left;
`;
