import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import CallMade from "@mui/icons-material/CallMade";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import * as yup from "yup";
import { Button } from "../../components";
import useGetMasterTableData from "../../hooks/useGetMasterTableData";
import { useGetClaimDocsFormatUsingDocId } from "../claims/Service";
import {
  setMasterConfigErrorAction,
  setMasterConfigSuccessAction,
} from "./MasterConfig.slice";
import { ActionContainer, RealPreviewStyled } from "./MasterConfig.style";
import {
  useAddDataInMaster,
  useGetAllMastersList,
  useGetMasterDetailsById,
  useGetUniqueMasterDataById,
  useInactiveDataInMaster,
  useUpdateDataInMaster,
} from "./service";
import { yupResolver } from "@hookform/resolvers/yup";
import useGetOperations from "../../hooks/useGetOperations";
import { useLocation } from "react-router";

function useMasterConfig() {
  const [modalState, setModalState] = useState({
    title: "",
    state: false,
  });
  const [mergedSchema, setMergedSchema] = useState(null);
  const [uniqueMasterData, setUniqueMasterData] = useState({
    masterId: null,
    uniqueKey: null,
    uniqueId: null,
  });
  const [docManagementID, setDocManagementID] = useState([]);

  const location = useLocation();

  const { allowedOperations } = useGetOperations(location.pathname);

  const dispatch = useDispatch();
  const { masterConfigError, masterConfigSuccess } = useSelector(
    (state) => state.MasterConfigSlice
  );

  const yupValidate = yup.object().shape({});

  const {
    register,
    control,
    setValue,
    watch,
    handleSubmit,
    unregister,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(mergedSchema),
    mode: "all",
    reValidateMode: "all",
  });

  const { masterList } = watch();

  const {
    data: allMasters,
    isFetching: isAllMastersFetching,
    isError: isAllMastersError,
  } = useGetAllMastersList();
  const {
    data: masterDetailsById,
    isFetching: isMasterDetailsByIdFetching,
    isError: isMasterDetailsByIdError,
  } = useGetMasterDetailsById({
    id: masterList?.id,
  });
  const {
    data: uniqueMasterDetailsById,
    isFetching: isUniueMasterDetailsByIdFetching,
    isError: isUniqueMasterDetailsByIdError,
  } = useGetUniqueMasterDataById({
    masterId: uniqueMasterData.masterId,
    uniqueKey: uniqueMasterData.uniqueId,
  });
  const { mutate: addDataInMaster, isLoading: isAddDataInMasterLoading } =
    useAddDataInMaster();
  const { mutate: updateDataInMaster, isLoading: isUpdateDataInMasterLoading } =
    useUpdateDataInMaster({ masterId: null, uniqueKey: null });
  const { mutate: inactiveDataInMaster } = useInactiveDataInMaster();
  const { data: docFormats } = useGetClaimDocsFormatUsingDocId(docManagementID);

  const allMaster_Obj = Array.isArray(allMasters?.data?.data)
    ? allMasters.data.data.map((value) => {
        return {
          id: value.masterId,
          label: value.masterTitle,
          value: value.masterSlug,
        };
      })
    : [];

  const columnInformation = Array.isArray(
    masterDetailsById?.data?.data?.columnData
  )
    ? masterDetailsById.data.data.columnData[0]
    : [];

  const fieldValues = Array.isArray(masterDetailsById?.data?.data?.fieldData)
    ? masterDetailsById.data.data.fieldData.map((value) => ({
        fieldSlug: value.field_slug,
        fieldName: value.field_name,
        fieldType: value.field_type,
        mandatory: value.is_mandatory,
        validationGroup: value.validation_group,
        isMasterAvailable: value.is_master_api_available,
        masterSlug: value.master_slug,
      }))
    : [];

  const masterTableFields = fieldValues
    .filter((value) => value.isMasterAvailable === "Y")
    .map((value) => ({
      masterSlug: value.masterSlug,
      fieldSlug: value.fieldSlug,
    }));

  const { data: masterTableData } = useGetMasterTableData(masterTableFields);

  const dynamicColumns = Array.isArray(masterDetailsById?.data?.data?.fieldData)
    ? [
        ...masterDetailsById.data.data.fieldData.map((value) => {
          if (value.field_type === "UPLOAD") {
            return {
              field: value.field_slug,
              headerName: value.field_name,
              flex: 1,
              background: "#D2F8E9",
              renderCell: (params) => {
                function isValidURL(text) {
                  const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
                  return urlRegex.test(text);
                }
                const handleRealFilePreview = (url) => {
                  if (isValidURL(url)) {
                    window.open(url, "_blank");
                  } else {
                    Swal.fire({
                      text: "Invalid URL",
                      icon: "warning",
                      confirmButtonText: "OK",
                      confirmButtonColor: "#DC004E",
                      reverseButtons: true,
                      focusConfirm: false,
                      allowOutsideClick: false,
                      focusCancel: false,
                      scrollbarPadding: false,
                    });
                  }
                };
                return (
                  <RealPreviewStyled isInDatagrid={true}>
                    <Button
                      bgColor="transparent"
                      textColor="#11555F"
                      id="add_broker"
                      variant="outlined"
                      borderColor="#11555F"
                      endIcon={<CallMade />}
                      onClick={() =>
                        handleRealFilePreview(params.row[value.field_slug])
                      }
                    >
                      Preview an Uploaded File
                    </Button>
                  </RealPreviewStyled>
                );
              },
            };
          }
          return {
            field: value.field_slug,
            headerName: value.field_name,
            flex: 1,
            background: "#D2F8E9",
          };
        }),
        {
          field: "actions",
          headerName: "Actions",
          sortable: false,
          width: 100,
          disableClickEventBubbling: true,
          renderCell: (params) => {
            const handleUniqueMasterData = () => {
              setUniqueMasterData(() => ({
                masterId: masterList?.id,
                uniqueKey: columnInformation.unique_key,
                uniqueId: params.row[columnInformation.unique_key],
              }));
            };

            const handleDelete = () => {
              const dataObj = {
                masterId: masterList?.id,
                uniqueKey: columnInformation.unique_key,
                uniqueId: params.row[columnInformation.unique_key],
              };

              Swal.fire({
                title: "Delete record?",
                text: "Confirmation required to delete this record.",
                icon: "question",
                confirmButtonText: "Delete",
                confirmButtonColor: "#DC004E",
                showCancelButton: true,
                cancelButtonText: "Cancel",
                reverseButtons: true,
                focusConfirm: false,
                focusCancel: false,
                scrollbarPadding: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  const payload = {
                    masterId: dataObj.masterId,
                    data: [
                      {
                        unique_key: dataObj.uniqueKey,
                        value: dataObj.uniqueId,
                        status: "N",
                      },
                    ],
                  };
                  inactiveDataInMaster(payload);
                }
              });
            };
            return (
              <ActionContainer justifyCenter={params.row.broker_id}>
                <PreviewOutlinedIcon
                  id={`view-${params.row.id}`}
                  index={params.row.id}
                  onClick={() => {
                    handleOpen("View");
                    handleUniqueMasterData();
                  }}
                />
                {allowedOperations.includes("Edit") && params.row.broker_id ? (
                  <BorderColorOutlinedIcon
                    id={`edit-${params.row.id}`}
                    index={params.row.id}
                    onClick={() => {
                      handleOpen("Modify");
                      handleUniqueMasterData();
                    }}
                  />
                ) : null}
                {(allowedOperations.includes("Delete") ||
                  allowedOperations.includes("Edit")) &&
                params.row.broker_id ? (
                  <DeleteForeverOutlinedIcon
                    id={`delete-${params.row.id}`}
                    onClick={() => {
                      handleDelete();
                    }}
                    index={params.row.id}
                  />
                ) : null}
              </ActionContainer>
            );
          },
        },
      ]
    : [];

  const dynamicRows =
    Array.isArray(masterDetailsById?.data?.data?.masterData) &&
    dynamicColumns.length
      ? masterDetailsById.data.data.masterData.map((value, index) => ({
          id: index + 1,
          ...value,
        }))
      : [];

  const handleOpen = (title) => {
    setModalState(() => ({
      title,
      state: true,
    }));
  };

  const handleClose = () => {
    setModalState(() => ({ title: "", state: false }));
  };

  useEffect(() => {
    if (modalState.title === "Add") {
      fieldValues.forEach((value) => {
        setValue(value.fieldSlug, null);
      });
    } else if (
      uniqueMasterDetailsById &&
      ["View", "Modify"].includes(modalState.title)
    ) {
      const dataToPrefill =
        uniqueMasterDetailsById?.data?.data?.masterData?.[0];
      const objectKeys = Object.keys(dataToPrefill);
      const objectValues = Object.values(dataToPrefill);

      //filters all fields that need to be prefilled
      const fieldsToPrefill = fieldValues
        .filter((value) => !["DROPDOWN", "DATE"].includes(value.fieldType))
        .map((value) => value.fieldSlug);

      objectKeys.forEach((value, index) => {
        if (fieldsToPrefill.includes(value)) {
          setValue(value, objectValues[index]);
        }
      });
    }
  }, [modalState.title, uniqueMasterDetailsById]);

  useMemo(() => {
    if (allMaster_Obj.length) {
      const firstMaster = allMaster_Obj[0];
      setValue("masterList", firstMaster);
    }
  }, [allMaster_Obj.length]);

  useEffect(() => {
    const shape = {};
    fieldValues.forEach((value) => {
      if (value.fieldType === "VARCHAR") {
        shape[value.fieldSlug] = yup
          .string()
          .required(`${value.fieldName} is required`);
      }
    });
    const dynamicYupValidate = yup.object().shape(shape);
    setMergedSchema(yupValidate.concat(dynamicYupValidate));
  }, [fieldValues.length]);

  useEffect(() => {
    const filteredDocID = fieldValues
      .filter((value) => !isNaN(value.validationGroup))
      .map((value) => value.validationGroup);
    setDocManagementID(filteredDocID);
  }, [JSON.stringify(fieldValues)]);

  useEffect(() => {
    if (masterConfigSuccess) {
      Swal.fire({
        text: masterConfigSuccess,
        icon: "success",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        focusCancel: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(setMasterConfigSuccessAction(null));
          handleClose();
        }
      });
    }
  }, [masterConfigSuccess]);

  if (masterConfigError) {
    Swal.fire({
      text: masterConfigError,
      icon: "warning",
      confirmButtonText: "OK",
      confirmButtonColor: "#DC004E",
      reverseButtons: true,
      focusConfirm: false,
      allowOutsideClick: false,
      focusCancel: false,
      scrollbarPadding: false,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(setMasterConfigErrorAction(null));
      }
    });
  }

  if (
    isAllMastersError ||
    isMasterDetailsByIdError ||
    isUniqueMasterDetailsByIdError
  ) {
    Swal.fire({
      text: "Error",
      icon: "warning",
      confirmButtonText: "OK",
      confirmButtonColor: "#DC004E",
      reverseButtons: true,
      focusConfirm: false,
      allowOutsideClick: false,
      focusCancel: false,
      scrollbarPadding: false,
    });
  }

  const handleFormSubmit = (value) => {
    const fieldSlugsFiltered = fieldValues
      .filter((value) => !["UPLOAD, DROPDOWN"].includes(value.fieldType))
      .map((value) => value.fieldSlug);
    const fieldTypeFiles = fieldValues
      .filter((value) => value.fieldType === "UPLOAD")
      .map((value) => value.fieldSlug);
    const fieldTypeDropdown = fieldValues
      .filter((value) => value.fieldType === "DROPDOWN")
      .map((value) => value.fieldSlug);

    const fieldsToSend = {};
    for (let key in value) {
      if (fieldSlugsFiltered.includes(key)) {
        fieldsToSend[key] = value[key];
      }
    }

    const fileFieldsToSend = {};
    for (let key in value) {
      if (fieldTypeFiles.includes(key)) {
        fileFieldsToSend[key] = value[key];
      }
    }

    const dropdownFieldsToSend = {};
    for (let key in value) {
      if (fieldTypeDropdown.includes(key)) {
        dropdownFieldsToSend[key] = value[key]?.id ? value[key].id : null;
      }
    }

    const fileFieldsPayload = {};
    for (let key in fileFieldsToSend) {
      fileFieldsPayload[key] = {
        data: fileFieldsToSend[key]?.[0].base64String
          ? fileFieldsToSend[key]?.[0].base64String
          : "",
        filename: fileFieldsToSend[key]?.[0].file.path
          ? fileFieldsToSend[key]?.[0].file.path
          : "",
      };
    }

    if (modalState.title === "Add") {
      const payload = {
        masterId: masterList?.id,
        data: {
          ...fieldsToSend,
          ...fileFieldsPayload,
          ...dropdownFieldsToSend,
        },
      };

      addDataInMaster(payload);
      return;
    }
    const payload = {
      masterId: uniqueMasterData.masterId,
      data: [
        {
          unique_key: uniqueMasterData.uniqueKey,
          value: uniqueMasterData.uniqueId,
          updates: {
            ...fieldsToSend,
            ...fileFieldsPayload,
            ...dropdownFieldsToSend,
          },
        },
      ],
    };
    updateDataInMaster(payload);
  };

  return {
    register,
    control,
    errors,
    watch,
    unregister,
    setValue,
    allMaster_Obj,
    isAllMastersFetching,
    isMasterDetailsByIdFetching,
    dynamicRows,
    dynamicColumns,
    masterList,
    modalState,
    handleOpen,
    handleClose,
    fieldValues,
    handleSubmit,
    handleFormSubmit,
    isAddDataInMasterLoading,
    setUniqueMasterData,
    isUpdateDataInMasterLoading,
    docFormats,
    masterTableData,
    allowedOperations,
  };
}

export default useMasterConfig;
