import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "../../../components";
import NoData from "../../../components/NoData/NoData";
import TableSkeleton from "../../../components/SkeletonLoader/TableSkeleton";
import {
  Heading,
  TableContainer,
  TableHeaderContainer,
} from "../MasterConfig.style";

function MasterConfigTable({
  isMasterDetailsByIdFetching,
  dynamicRows,
  dynamicColumns,
  masterList,
  handleOpen,
  allowedOperations,
}) {
  const getRowId = (row) => {
    return row.id;
  };

  if (isMasterDetailsByIdFetching) return <TableSkeleton />;

  return (
    <>
      <TableHeaderContainer>
        <Heading>{masterList?.label}</Heading>
        {allowedOperations.includes("Edit") && (
          <Button
            bgColor="transparent"
            textColor="#11555F"
            id="add_broker"
            variant="outlined"
            borderColor="#11555F"
            startIcon={<AddBoxOutlinedIcon />}
            onClick={() => handleOpen("Add")}
          >
            Add New Entries
          </Button>
        )}
      </TableHeaderContainer>
      {!dynamicRows.length ? (
        <NoData />
      ) : (
        <TableContainer>
          <DataGrid
            rows={dynamicRows}
            columns={dynamicColumns}
            size="small"
            fontSize={12}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
            }}
            pageSizeOptions={[5, 20]}
            getRowId={getRowId}
            sx={{
              ".MuiDataGrid-cellContent": {
                fontSize: "14px",
              },
              ".MuiDataGrid-columnSeparator": {
                display: "none",
              },
              "&.MuiDataGrid-root": {
                border: "none",
              },
              ".MuiDataGrid-columnHeaders": {
                background: "#D2F8E9",
                color: "#11555F",
                fontSize: "14px",
                fontWeight: 500,
                fontFamily: "Montserrat-Medium",
              },
              ".MuiTablePagination-root p": {
                fontSize: "14px",
              },
            }}
          />
        </TableContainer>
      )}
    </>
  );
}

export default MasterConfigTable;
