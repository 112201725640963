import styled from "styled-components";

export const Label = styled.label`
  position: absolute;
  transition: all 0.3s;
  cursor: ${(props) => (props.blockDetails ? "not-allowed" : "text")};
  pointer-events: ${(props) =>
    (props.blockDetails || props.readOnly) && "none"};
  top: ${(props) => (props.userViewInput ? "-30px" : "-22px")};
  left: ${(props) => (props.userViewInput ? "12px" : "3px")};
  font-family: ${({ theme }) =>
    theme?.regularFont ? theme?.regularFont : "sans-serif"};
  color: ${({ theme, themeType }) =>
    themeType && theme ? theme?.[themeType]?.inputLabel : "#11555F"};
  color: ${(props) => props.userViewInput && "#000"};
  line-height: 18px;
  font-size: ${({ theme: { fontSize } }) =>
    fontSize ? `calc(1rem + ${fontSize - 92}%)` : "12px"};
    font-weight: 500;
  & > span {
    font-size: ${({ theme: { fontSize } }) =>
      fontSize ? `calc(1.5rem + ${fontSize - 92}%)` : "1.5rem"};
  }
  & > sup {
    color: red;
  position: absolute;
  left: 103%;
  top: -14%;
  }
  }
`;

export const TextInput = styled.input`
  min-height: ${(props) => (props.userViewInput ? "40px" : "32px")};
  margin-bottom: ${(props) => props.userViewInput && "20px"};
  cursor: text;
  width: 100%;
  border-radius: 4px;
  transition: all 0.15s linear;
  // min-width: 180px;
  outline: none;
  color: #000000;
  padding: ${(props) => (props.proposalRead ? "0" : "0 8px")};
  font-size: ${({ theme: { fontSize } }) =>
    fontSize ? `calc(1rem + ${fontSize - 92}%)` : "12px"};
  border: ${({ error }) => (error ? "2px solid red" : ".25px solid #A9A7A7")};
  background: ${(props) =>
    props.blockDetails ? "rgba(247,244,244,1)" : "#F7F8FA"};
  background: ${(props) => props.proposalRead && "#fff"};
  background: ${(props) => props.bgColor};
  cursor: ${(props) => props.blockDetails && "not-allowed"};
  pointer-events: ${(props) =>
    (props.blockDetails || props.proposalRead) && "none"};
  font-weight: ${(props) => props.proposalRead && "700"};
  font-size: ${(props) => props.proposalRead && "14px"};
  margin-top: ${(props) => props.proposalRead && "0"};
  margin-left: ${(props) => props.proposalRead && "3px"};
  margin-bottom: 15px;
  border: ${(props) => props.proposalRead && "0"};
  text-align: left;
  &:focus {
    outline: none;
    border: 2px solid ${({ error }) => (error ? "red" : "#2bb9a8")};
    border: ${(props) => props.proposalRead && "0"};
  }
  ::placeholder {
    font-size: ${({ theme: { fontSize } }) =>
      fontSize ? `calc(15px + ${fontSize - 92}%)` : "12px"};
    opacity: 0.5;
    letter-spacing: 0.5px;
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
    /* Firefox */
  }
  @media (max-width: 767px) {
    min-width: 353px;
    max-width: inherit;
  }
  @media (max-width: 450px) {
    min-width: 195px;
    max-width: inherit;
  }
`;
