import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components";
import { Button } from "../../components";
import TableSkeleton from "../../components/SkeletonLoader/TableSkeleton";

function ProductTable({ handleOpen, rows, columns, isProductsFetching }) {
  const getRowId = (row) => {
    return row.id;
  };

  if (isProductsFetching) return <TableSkeleton />;

  return (
    <>
      <TableHeaderContainer>
        <Heading>List of Products</Heading>
        <Button
          bgColor="transparent"
          textColor="#11555F"
          id="add_broker"
          variant="outlined"
          borderColor="#11555F"
          startIcon={<AddBoxOutlinedIcon />}
          onClick={() => handleOpen("Add")}
        >
          Add Product
        </Button>
      </TableHeaderContainer>

      <TableContainer>
        <DataGrid
          rows={rows}
          columns={columns}
          size="small"
          fontSize={12}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 20 },
            },
          }}
          pageSizeOptions={[5, 20]}
          getRowId={getRowId}
          sx={{
            ".MuiDataGrid-cellContent": {
              fontSize: "14px",
            },
            ".MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "&.MuiDataGrid-root": {
              border: "none",
            },
            ".MuiDataGrid-columnHeaders": {
              background: "#D2F8E9",
              color: "#11555F",
              fontSize: "14px",
              fontWeight: 500,
              fontFamily: "Montserrat-Medium",
            },
            ".MuiTablePagination-root p": {
              fontSize: "14px",
            },
          }}
        />
      </TableContainer>
    </>
  );
}

export default ProductTable;

const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 12px;
`;

const Heading = styled.h2`
  margin-top: -3px;
  margin-bottom: 0;
  display: flex;
  color: #178e80;
  justify-content: space-between;
  font-family: "Montserrat-SemiBold";
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
`;

const TableContainer = styled.div`
  background: #fff;
  width: 100%;
`;
