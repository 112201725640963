import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import styled from "styled-components";
import { Button } from "../../../components";

const columns = [
  {
    field: "processName",
    headerName: "Process Name",
    flex: 1,
    background: "#E4E4E4",
  },
  {
    field: "lob",
    headerName: "LOB",
    flex: 1,
    background: "#E4E4E4",
  },
  {
    field: "insurer",
    headerName: "Insurer",
    flex: 1,
    background: "#E4E4E4",
  },
  {
    field: "product",
    headerName: "Product",
    flex: 1,
    background: "#E4E4E4",
  },
  {
    field: "page",
    headerName: "Page",
    flex: 1,
    background: "#E4E4E4",
  },

  {
    field: "E",
    headerName: "Activity",
    sortable: false,
    width: 140,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return (
        <ActionContainer>
          <Button
            themeType={"theme1"}
            textColor={"#ffff"}
            round
            index={params.row.id}
            startIcon={<RemoveRedEyeIcon />}
          >
            {" "}
            View
          </Button>
        </ActionContainer>
      );
    },
  },
  {
    field: "actions",
    headerName: "Actions",
    sortable: false,
    width: 100,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return (
        <ActionContainer>
          <BorderColorRoundedIcon index={params.row.id} />
          <DeleteIcon index={params.row.id} />
        </ActionContainer>
      );
    },
  },
];

//prettier-ignore
const rows = [
  { id : 1, processName : "Pending",lob  :  "Fire", insurer : "Lorem somethin", product : "Health", page : "Ops_Approval_10Lacs" , Edit : 1,Edit : 1  },
  { id : 2, processName : "Pending",lob  :  "Fire", insurer : "Lorem somethin", product : "AVP", page : "CC_Motors_2Lacs" , Edit : 1,Edit : 1  },
  { id : 3, processName : "Pending",lob  :  "Fire", insurer : "Lorem somethin", product : "Executive", page : "UW_Motors_2Lacs" , Edit : 1,Edit : 1  },
  { id : 4, processName : "Pending",lob  :  "Fire", insurer : "Lorem somethin", product : "senior Executive", page : "CC_Motors_2Lacs" , Edit : 1,Edit : 1  },
 
];

export const CustomisationTable = () => {
  return (
    <>
      <TableContainer>
        <DataGrid
          rows={rows}
          columns={columns}
          size="small"
          paginationModel={{ page: 0, pageSize: 20 }}
          // getRowId={(row) => row.id}
          sx={{
            ".MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "&.MuiDataGrid-root": {
              border: "none",
            },
            ".MuiDataGrid-columnHeaders": {
              background: "#E4E4E4",
            },
          }}
        />
      </TableContainer>
    </>
  );
};

const TableContainer = styled.div`
  padding-top: 10px;
  border-top: 2px solid rgba(224, 224, 224, 1);
  margin-top: 30px;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
`;
