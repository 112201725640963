import React from "react";
import { Input, Button, Select, Typography } from "../../../components";

import styled from "styled-components";
import { Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import CachedIcon from "@mui/icons-material/Cached";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const ProductCoverageForm = () => {
  const { Error } = Typography;
  const yupValidate = yup.object({
    document_name: yup.string().required("Document Name is Required"),
    document_code: yup.string().required("Document Code is Required"),
    process: yup.string().required("Process is Required"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yupValidate),
    mode: "all",
    reValidateMode: "onChange",
  });
  const onSubmit = (value) => console.log(value);
  return (
    <InputFrame onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        style={{ marginBottom: "10px", paddingBottom: "0px" }}
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <StyledGrid item xs={10} md={3} sm={10} lg={3}>
          <Input
            themeType="theme4"
            label="Document Name"
            name="document_name"
            id="document_name"
            maxLength="50"
            placeholder="Enter Document Name"
            autoComplete="none"
            defaultValue={""}
            required={false}
            inputRef={register("document_name")}
          />
          {!!errors?.document_name && (
            <Error top="5px" left="23px">
              {errors.document_name.message}
            </Error>
          )}
        </StyledGrid>
        <StyledGrid item xs={10} md={3} sm={10} lg={3}>
          <Input
            themeType="theme4"
            label="Document Code"
            name="document_code"
            id="document_code"
            maxLength="50"
            placeholder="Enter Document Code"
            autoComplete="none"
            defaultValue={""}
            required={false}
            inputRef={register("document_code")}
          />
          {!!errors?.document_code && (
            <Error top="5px" left="23px">
              {errors.document_code.message}
            </Error>
          )}
        </StyledGrid>
        <StyledGrid item xs={10} md={3} sm={10} lg={3}>
          <Select
            themeType="theme4"
            name="Date Format Configration"
            id="1"
            value="Date Format Configration"
            label="Branch Type"
            options={[
              {
                id: 1,
                name: "select",
                value: "Date Format Configration",
              },
            ]}
          />
        </StyledGrid>
        <StyledGrid item xs={10} md={3} sm={10} lg={3}>
          <Input
            themeType="theme4"
            label="Process"
            name="process"
            id="process"
            maxLength="50"
            placeholder="New Business"
            autoComplete="none"
            defaultValue={""}
            required={false}
            inputRef={register("process")}
          />
          {!!errors?.process && (
            <Error top="5px" left="23px">
              {errors.process.message}
            </Error>
          )}
        </StyledGrid>
      </Grid>

      <Grid
        container
        style={{ marginBottom: "10px", paddingBottom: "0px" }}
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid item xs={10} sm={10} md={9} lg={12}>
          <ButtonFrame>
            <Button
              bgColor="#5A2C88"
              type=" submit"
              round
              startIcon={<SearchIcon />}
            >
              Search
            </Button>
            <Button bgColor="#FFAA06" round startIcon={<ControlPointIcon />}>
              Add New
            </Button>
            <Button
              bgColor="#FF4646"
              type="reset"
              round
              startIcon={<CachedIcon />}
            >
              Reset
            </Button>
          </ButtonFrame>
        </Grid>
      </Grid>
    </InputFrame>
  );
};

const StyledGrid = styled(Grid)`
  position: relative;
`;

const InputFrame = styled.form`
  width: 100%;
  .css-10mzr36-MuiGrid-root {
    padding-bottom: 10px;
  }
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    background: white;
    padding-bottom: 30px;
  }
  .MuiButtonBase-root {
    margin-right: 10px;
  }
`;

const ButtonFrame = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  @media (max-width: 767px) {
    justify-content: flex-start;
  }
`;
