import React from "react";
import "./loading.css";

const LoadingComponent = () => {
  return (
    <div className="loading-container">
      <div className="loading-animation">
        <span className="loading-text">BroCore</span>
      </div>
    </div>
  );
};

export default LoadingComponent;
