import { yupResolver } from "@hookform/resolvers/yup";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import { Button, Input, Typography } from "../../../components";
import SkeletonLoader from "../../../components/SkeletonLoader/SkeletonLoader";
import { getNomenclature } from "../../broker-configuration/service";

export const SearchForm = ({ searchData, setSearchData }) => {
  const { Error } = Typography;

  const yupValidate = yup.object({
    office_code: yup.string().required("Office Name Is Required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yupValidate),
    mode: "all",
    reValidateMode: "onChange",
  });

  const { isFetching, refetch } = getNomenclature(searchData);

  const resetFields = () => {
    setTimeout(() => {
      reset();
      refetch();
    }, 500);

    setSearchData({
      page: 0,
      size: 0,
      sortBy: "brokerOfficeNomenclaturesid",
      sortOrder: "DESC",
      searchValue: "",
    });
  };


  const onSubmit = (value) => {
    setTimeout(() => {
      refetch();
    }, 500);

    setSearchData({
      page: 0,
      size: 0,
      sortBy: "brokerOfficeNomenclaturesid",
      sortOrder: "DESC",
      searchValue: value?.office_code,
    });
  }

  if (isFetching) return <SkeletonLoader arraySize={1} isNormalForm={true} />;

  return (
    <InputFrame onSubmit={handleSubmit(onSubmit)}>
      <Grid
        style={{ paddingBottom: "0px" }}
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        xs={12}
        md={3}
        sm={10}
        lg={12}
      >
        <StyledGrid
          sx={{ position: "relative" }}
          item
          xs={10}
          md={3}
          sm={10}
          lg={3}
        >
          <Input
            themeType="theme1"
            label="Office Name"
            name="office_code"
            id="office_code"
            maxLength="50"
            placeholder="Enter Office Name"
            autoComplete="none"
            defaultValue={""}
            required={false}
            inputRef={register("office_code")}
          />
          {!!errors?.office_code && (
            <Error top="3px" left="3px">
              {errors.office_code.message}
            </Error>
          )}
        </StyledGrid>
        <StyledGrid item xs={10} md={3} sm={10} lg={3}>
          <ButtonFrame>
            <Button
              type="submit"
              themeType={"theme1"}
              id="search_btn"
              bgColor={"#11555F"}
            >
              Search
            </Button>
            <Button
              themeType={"theme1"}
              btnBack={"tertiary"}
              id="reset_btn"
              textColor="#11555F"
              bgColor="transparent"
              borderColor="none"
              onClick={resetFields}
            >
              Reset
            </Button>
          </ButtonFrame>
        </StyledGrid>
      </Grid>

      {/* </Grid> */}
    </InputFrame>
  );
};

const StyledGrid = styled(Grid)`
  position: relative;
`;

const InputFrame = styled.form`
  position: relative;
  // margin-top: 30px;
  width: 100%;
  .css-10mzr36-MuiGrid-root {
    padding-bottom: 10px;
  }
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    background: white;
    padding-bottom: 30px;
  }
  .MuiButtonBase-root {
    margin-right: 10px;
  }
`;

const ButtonFrame = styled(Grid)`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  top: 18px;
  gap: 15px;
`;
