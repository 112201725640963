import { Grid } from "@mui/material";
import { Controller } from "react-hook-form";
import Checkbox from "../../../components/form-components/checkbox/checkbox";
import { useEffect } from "react";

function DynamicFields({
    value,
    control,
    handleModal,
    handleFieldSlugForExcel,
    isDisabled,
    setValue,
    cardInFocus,
    selectedBrokerageType,
    single,
    bundle,
}) {

    useEffect(() => {
        if (!single) {
            //initialize object structure to prevent performance issues
            setValue(`single.${[cardInFocus?.single - 1]}.premiumAffectFields.${[value.value]}.label`, value?.label)
            setValue(`single.${[cardInFocus?.single - 1]}.premiumAffectFields.${[value.value]}.id`, value?.id)
            setValue(`single.${[cardInFocus?.single - 1]}.premiumAffectFields.${[value.value]}.masterSlug`, value?.value)
            setValue(`single.${[cardInFocus?.single - 1]}.premiumAffectFields.${[value.value]}.value`, false)
        } else {
            setValue(`single.${[cardInFocus?.single - 1]}.premiumAffectFields.${[value.value]}.value`, single?.[cardInFocus?.single - 1]?.premiumAffectFields[value.value]?.value)
        }

        if (!bundle) {
            //initialize object structure to prevent performance issues
            setValue(`bundle.${[cardInFocus?.bundle - 1]}.premiumAffectFields.${[value.value]}.label`, value?.label)
            setValue(`bundle.${[cardInFocus?.bundle - 1]}.premiumAffectFields.${[value.value]}.id`, value?.id)
            setValue(`bundle.${[cardInFocus?.bundle - 1]}.premiumAffectFields.${[value.value]}.masterSlug`, value?.value)
            setValue(`bundle.${[cardInFocus?.bundle - 1]}.premiumAffectFields.${[value.value]}.value`, false)
        } else {
            setValue(`bundle.${[cardInFocus?.bundle - 1]}.premiumAffectFields.${[value.value]}.value`, bundle?.[cardInFocus?.bundle - 1]?.premiumAffectFields[value.value]?.value)
        }
    }, [cardInFocus,
        single?.[cardInFocus?.single - 1]?.premiumAffectFields,
        bundle?.[cardInFocus?.bundle - 1]?.premiumAffectFields,
        selectedBrokerageType
    ])



    if (selectedBrokerageType === "Single") {
        return (
            <Grid
                key={value.id}
                item xs={2.2}
            >
                <Controller
                    name={`single.${[cardInFocus?.single - 1]}.premiumAffectFields.${[value.value]}.label`}
                    control={control}
                    defaultValue={value.label}
                    render={({ field }) => (
                        <input
                            {...field}
                            type="hidden"
                        />
                    )}
                />
                <Controller
                    name={`single.${[cardInFocus?.single - 1]}.premiumAffectFields.${[value.value]}.id`}
                    control={control}
                    defaultValue={value.id}
                    render={({ field }) => (
                        <input
                            {...field}
                            type="hidden"
                        />
                    )}
                />
                <Controller
                    name={`single.${[cardInFocus?.single - 1]}.premiumAffectFields.${[value.value]}.masterSlug`}
                    control={control}
                    defaultValue={value.value}
                    render={({ field }) => (
                        <input
                            {...field}
                            type="hidden"
                        />
                    )}
                />
                <Checkbox
                    name={`single.${[cardInFocus?.single - 1]}.premiumAffectFields.${[value.value]}.value`}
                    label={value.label}
                    checkboxFirst={false}
                    control={control}
                    squaredBorders={true}
                    isDisabled={isDisabled}
                    onClick={(e) => {
                        if (!single?.[cardInFocus?.single - 1]?.premiumAffectFields[value.value].value) {
                            handleModal(e);
                        }
                        handleFieldSlugForExcel(e, { value: value.value, slug: value.slug }, cardInFocus?.single - 1, selectedBrokerageType)
                    }}
                />

            </Grid>
        )
    } else {
        return (
            <Grid
                key={value.id}
                item xs={2.2}
            >
                <Controller
                    name={`bundle.${[cardInFocus?.bundle - 1]}.premiumAffectFields.${[value.value]}.label`}
                    control={control}
                    defaultValue={value.label}
                    render={({ field }) => (
                        <input
                            {...field}
                            type="hidden"
                        />
                    )}
                />
                <Controller
                    name={`bundle.${[cardInFocus?.bundle - 1]}.premiumAffectFields.${[value.value]}.id`}
                    control={control}
                    defaultValue={value.id}
                    render={({ field }) => (
                        <input
                            {...field}
                            type="hidden"
                        />
                    )}
                />
                <Controller
                    name={`bundle.${[cardInFocus?.bundle - 1]}.premiumAffectFields.${[value.value]}.masterSlug`}
                    control={control}
                    defaultValue={value.value}
                    render={({ field }) => (
                        <input
                            {...field}
                            type="hidden"
                        />
                    )}
                />
                <Checkbox
                    name={`bundle.${[cardInFocus?.bundle - 1]}.premiumAffectFields.${[value.value]}.value`}
                    label={value.label}
                    checkboxFirst={false}
                    control={control}
                    squaredBorders={true}
                    isDisabled={isDisabled}
                    onClick={(e) => {
                        if (!bundle?.[cardInFocus?.bundle - 1]?.premiumAffectFields[value.value].value) {
                            handleModal(e);
                        }
                        handleFieldSlugForExcel(e, { value: value.value, slug: value.slug }, cardInFocus?.bundle - 1)
                    }}
                />

            </Grid>
        )
    }
}

export default DynamicFields