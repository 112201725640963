import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { BsFiletypeXls, BsImages } from "react-icons/bs";
import styled from "styled-components";
import { Button } from "../../../components";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

export const Previews = ({ themeType, editDetails }) => {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          alt="file"
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledContainer className="container">
      <PickerWrap themeType={themeType} editDetails AddDeleteFiles>
        <div {...getRootProps({ className: "dropzone" })}>
          <BsImages className="ImageIcon" />
          <input {...getInputProps()} />
          <p
            style={{
              //   height: "250px",
              margin: "0px",
              textAlign: "center",
              padding: "5px",
            }}
          >
            Drag and drop an Image to upload or
          </p>
          <Button themeType={themeType}>Browse Computer</Button>
          <ul>
            <li>You can upload .png .jpg files</li>
            <li>Maximum file size is 10MB</li>
            <li>Select only XLS Files</li>
          </ul>
        </div>
        {!editDetails && (
          <XlsAndButtonWrap>
            <div style={{ display: "flex" }}>
              <BsFiletypeXls color={"#2ca92a"} className="ImageIcon" />
              <a href="#">Click to Download Sample File</a>
            </div>

            <Button
              themeType={themeType}
              borderRadius={"35px !important"}
              padding={"20px"}
              round
            >
              Upload File
            </Button>
          </XlsAndButtonWrap>
        )}
        <aside style={thumbsContainer}>{thumbs}</aside>
      </PickerWrap>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  /* background-color: red; */
`;
const PickerWrap = styled.div`
  margin-bottom: ${(props) => (props.editDetails ? "5px" : "50px")};
  padding: 20px;
  border-radius: 10px;
  background: #fcfcff;

  width: ${(props) => (props.AddDeleteFiles ? "100%" : "95%")};
  box-shadow: 1px 1px 5px gray;

  /* border: 1px solid red; */
  .dropzone {
    cursor: pointer;
    background: #fcfcff;
    border: 2px dashed #a4a4cb;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
  }
  .ImageIcon {
    font-size: 60px;
    color: ${({ themeType, theme }) =>
      themeType && theme ? theme?.[themeType]?.litePrimary : "#24aa9a"};
  }
  ul {
    font-size: 14px;
  }
`;

const XlsAndButtonWrap = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  a {
    margin: auto;
    color: ${({ themeType, theme }) =>
      themeType && theme ? theme?.[themeType]?.litePrimary : "#24aa9a"};
    margin-left: 10px;
    font-weight: 600;
  }
`;
