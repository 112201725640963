import { FormControlLabel, Grid, Switch } from "@mui/material";
import { isBoolean } from "lodash";
import styled from "styled-components";
import {
  Button,
  DatePickerInput,
  Input,
  Typography,
} from "../../../components";
import {
  preventCharactersInInput,
  preventSpecialAndNumbersInInput,
  preventWhiteSpaceInInput,
  verifyValidEmail,
  verifyValidNumbers,
  verifyValidPincode,
} from "../../../HelperFunctions/HelperFunctions";
import useModifyLocation from "./useModifyLocation";

export const ModifyLocation = ({ preFillData, modalTitle, handleClose }) => {
  const { Error } = Typography;

  const {
    handleSubmit,
    addLocation,
    errors,
    register,
    pincode,
    data,
    handlePincodeChange,
    handleDate,
    control,
    showDate,
    previousDate,
    previousMaxDate,
  } = useModifyLocation({ preFillData, modalTitle, handleClose });

  return (
    <ModifyLocationStyled>
      <form onSubmit={handleSubmit(addLocation)}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ padding: "0 20px" }}
        >
          <Grid
            item
            sx={{ position: "relative" }}
            xs={12}
            sm={12}
            md={6}
            lg={4}
          >
            <Input
              themeType={"theme1"}
              labelStyle={{ fontSize: "13px" }}
              label="Office Code"
              name="office_code"
              id="office_code"
              maxLength="100"
              placeholder={`Enter Office Code`}
              autoComplete="none"
              required={false}
              proposalRead={modalTitle === "View" ? true : false}
              readOnly={modalTitle === "View" ? true : false}
              onChange={(e) => preventCharactersInInput(e)}
              inputRef={register("office_code")}
              testId="office_input"
              isRequired={modalTitle === "View" ? false : true}
            />
            {!!errors?.office_code && (
              <Error top="3px" left="3px">
                {errors.office_code.message}
              </Error>
            )}
          </Grid>
          <Grid
            item
            sx={{ position: "relative" }}
            xs={12}
            sm={12}
            md={6}
            lg={4}
          >
            <Input
              themeType={"theme1"}
              labelStyle={{ fontSize: "13px" }}
              label="Pincode"
              name="pincode"
              id="pincode"
              maxLength="6"
              onChange={(e) => {
                handlePincodeChange(e.target.value);
                verifyValidPincode(e);
              }}
              placeholder={`Enter Pincode`}
              autoComplete="none"
              required={false}
              proposalRead={modalTitle === "View" ? true : false}
              readOnly={modalTitle === "View" ? true : false}
              inputRef={register("pincode")}
              testId="office_input"
              isRequired={modalTitle === "View" ? false : true}
            />
            {!data?.success && !errors?.pincode && isBoolean(data?.success) ? (
              <Error top="3px" left="3px">
                Pincode not available
              </Error>
            ) : (
              <></>
            )}
            {!!errors?.pincode && (
              <Error top="3px" left="3px">
                {errors?.pincode.message}
              </Error>
            )}
          </Grid>
          <Grid
            item
            sx={{ position: "relative" }}
            xs={12}
            sm={12}
            md={6}
            lg={4}
          >
            <Input
              themeType={"theme1"}
              labelStyle={{ fontSize: "13px" }}
              label="State"
              name="state"
              id="state"
              proposalRead={modalTitle === "View" ? true : false}
              readOnly={["Modify", "View"].includes(modalTitle) ? true : false}
              disabled={true}
              placeholder={`Enter State`}
              value={
                pincode.length === 6
                  ? data?.data?.data[0]?.length !== 0
                    ? data?.data?.data[0]?.state?.stateName
                    : ""
                  : ""
              }
              autoComplete="none"
              defaultValue={""}
              required={false}
              inputRef={register("state")}
              testId="office_input"
              isRequired={modalTitle === "View" ? false : true}
            />
            {!!errors?.state && (
              <Error top="3px" left="3px">
                {errors?.state.message}
              </Error>
            )}
          </Grid>
          <Grid
            item
            sx={{ position: "relative" }}
            xs={12}
            sm={12}
            md={6}
            lg={4}
          >
            <Input
              themeType={"theme1"}
              labelStyle={{ fontSize: "13px" }}
              label="City"
              name="city"
              id="city"
              proposalRead={modalTitle === "View" ? true : false}
              readOnly={["Modify", "View"].includes(modalTitle) ? true : false}
              disabled={true}
              placeholder={`Enter City`}
              value={
                pincode.length === 6
                  ? data?.data?.data[0]?.length !== 0
                    ? data?.data?.data[0]?.city?.cityName
                    : ""
                  : ""
              }
              autoComplete="none"
              defaultValue={""}
              required={false}
              inputRef={register("city")}
              testId="office_input"
              isRequired={modalTitle === "View" ? false : true}
            />
            {!!errors?.city && (
              <Error top="3px" left="3px">
                {errors?.city.message}
              </Error>
            )}
          </Grid>
          <Grid
            item
            sx={{ position: "relative" }}
            xs={12}
            sm={12}
            md={6}
            lg={4}
          >
            <Input
              themeType={"theme1"}
              labelStyle={{ fontSize: "13px" }}
              label="Head Office Address"
              name="office_address"
              id="office_address"
              maxLength="100"
              placeholder={`Enter Head Office Address`}
              autoComplete="none"
              defaultValue={""}
              required={false}
              proposalRead={modalTitle === "View" ? true : false}
              readOnly={modalTitle === "View" ? true : false}
              inputRef={register("office_address")}
              testId="office_input"
              isRequired={modalTitle === "View" ? false : true}
            />
            {!!errors?.office_address && (
              <Error top="3px" left="3px">
                {errors.office_address.message}
              </Error>
            )}
          </Grid>
          <Grid
            item
            sx={{ position: "relative" }}
            xs={12}
            sm={12}
            md={6}
            lg={4}
          >
            <Input
              themeType={"theme1"}
              labelStyle={{ fontSize: "13px" }}
              label="Contact Person"
              name="contact_person_name"
              id="contact_person_name"
              maxLength="100"
              onChange={(e) => preventSpecialAndNumbersInInput(e)}
              placeholder={`Enter Contact Person`}
              autoComplete="none"
              defaultValue={""}
              required={false}
              proposalRead={modalTitle === "View" ? true : false}
              readOnly={modalTitle === "View" ? true : false}
              inputRef={register("contact_person_name")}
              testId="office_input"
              isRequired={modalTitle === "View" ? false : true}
            />
            {!!errors?.contact_person_name && (
              <Error top="3px" left="3px">
                {errors.contact_person_name.message}
              </Error>
            )}
          </Grid>
          <Grid
            item
            sx={{ position: "relative" }}
            xs={12}
            sm={12}
            md={6}
            lg={4}
          >
            <Input
              themeType={"theme1"}
              labelStyle={{ fontSize: "13px" }}
              label="Contact Number"
              name="mobile_no"
              id="mobile_no"
              maxLength="10"
              onChange={(e) => verifyValidNumbers(e)}
              placeholder={`Enter Contact Number`}
              autoComplete="none"
              defaultValue={""}
              required={false}
              proposalRead={modalTitle === "View" ? true : false}
              readOnly={modalTitle === "View" ? true : false}
              inputRef={register("mobile_no")}
              testId="office_input"
              isRequired={modalTitle === "View" ? false : true}
            />
            {!!errors?.mobile_no && (
              <Error top="3px" left="3px">
                {errors.mobile_no.message}
              </Error>
            )}
          </Grid>
          <Grid
            item
            sx={{ position: "relative" }}
            xs={12}
            sm={12}
            md={6}
            lg={4}
          >
            <Input
              themeType={"theme1"}
              labelStyle={{ fontSize: "13px" }}
              label="Email ID"
              name="email_id"
              id="email_id"
              maxLength="100"
              onChange={(e) => {
                verifyValidEmail(e);
                preventWhiteSpaceInInput(e);
              }}
              placeholder={`Enter Email ID`}
              autoComplete="none"
              defaultValue={""}
              required={false}
              proposalRead={modalTitle === "View" ? true : false}
              readOnly={modalTitle === "View" ? true : false}
              inputRef={register("email_id")}
              testId="office_input"
              isRequired={modalTitle === "View" ? false : true}
            />
            {!!errors?.email_id && (
              <Error top="3px" left="3px">
                {errors.email_id.message}
              </Error>
            )}
          </Grid>
          {modalTitle !== "View" && (
            <Grid
              item
              sx={{ position: "relative" }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <FormControlLabel
                value=""
                control={<Switch color="primary" sx={{ marginLeft: "30px" }} />}
                label="Is this your permanent address ?"
                className="address_toggle"
                labelPlacement="start"
                onChange={handleDate}
                checked={showDate}
              />
              <span className="noOption">No</span>
              <span className="yesOption">Yes</span>
            </Grid>
          )}
          {!showDate ? (
            <>
              <Grid
                item
                sx={{ position: "relative" }}
                xs={12}
                sm={12}
                md={6}
                lg={4}
              >
                <DatePickerInput
                  themeType="theme1"
                  label="Effective From Date"
                  name="effective_from_date"
                  id="effective_from_date"
                  control={control}
                  inputRef={register("effective_from_date")}
                  placeholder="Enter From Date"
                  testId="office_input"
                  isRequired={modalTitle === "View" ? false : true}
                  proposalRead={modalTitle === "View" ? true : false}
                  readOnly={modalTitle === "View" ? true : false}
                />
                {!!errors?.effective_from_date && (
                  <Error top="3px" left="3px">
                    {errors.effective_from_date.message}
                  </Error>
                )}
              </Grid>
              <Grid
                item
                sx={{ position: "relative" }}
                xs={12}
                sm={12}
                md={6}
                lg={4}
              >
                <DatePickerInput
                  themeType="theme1"
                  label="Effective To Date"
                  name="effective_to_date"
                  id="effective_to_date"
                  control={control}
                  minDate={previousMaxDate}
                  inputRef={register("effective_to_date")}
                  placeholder="Enter To Date"
                  testId="office_input"
                  isRequired={modalTitle === "View" ? false : true}
                  proposalRead={modalTitle === "View" ? true : false}
                  readOnly={modalTitle === "View" ? true : false}
                />
                {!!errors?.effective_to_date && (
                  <Error top="3px" left="3px">
                    {errors.effective_to_date.message}
                  </Error>
                )}
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Grid>

        {modalTitle !== "View" ? (
          <Grid item sx={{ position: "relative" }} className="update_btn">
            <Button
              type="submit"
              themeType={"theme1"}
              id={modalTitle === "Modify" ? "update_btn" : "save_btn"}
              bgColor={"#11555F"}
              // disabled={modalTitle === "View" ? true : false}
            >
              {modalTitle === "Modify" ? "Update" : "Save"}
            </Button>
          </Grid>
        ) : (
          <></>
        )}
      </form>
    </ModifyLocationStyled>
  );
};

const ModifyLocationStyled = styled.div`
  background: #fff;
  border-radius: 10px;
  .address_toggle {
    margin: 0;
    & > span:last-of-type {
      font-weight: 500;
    }
  }
  .yesOption {
    position: relative;
    right: 15px;
  }

  .noOption {
    position: relative;
    right: 78px;
  }

  form {
    padding-top: 20px;
  }

  .update_btn {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
    margin-top: 10px;
    background: #fff;
    border-top: 1px solid #ccc;
    position: sticky;
    bottom: 0;
    right: 0;
    z-index: 10;
    padding: 8px 18px 16px 0;
  }
`;
