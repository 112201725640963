import { combineReducers } from "@reduxjs/toolkit";
import GlobalSlice from "../globalSlice/GlobalSlice.slice";
import brokerageConfig from "../modules/Brokerage/Brokerage.slice";
import billingFetchingSlice from "../modules/FetchBillingReports/billing-fetching.slice";
import reconConfig from "../modules/Reconciliation/reconciliation.slice";
import reportConfig from "../modules/Reports/report.slice";
import brokerConfigSlice from "../modules/broker-configuration/broker-config.slice";
import holidayConfigurationSlice from "../modules/broker-holiday-configuration/holiday-configuration.slice";
import brokerOfficeConfigSlice from "../modules/broker-office-configuration/office.slice";
import companyMasterSlice from "../modules/company-master/company-master.slice";
import docConfigSlice from "../modules/doc-configuration/doc-config.slice";
import endorsementSlice from "../modules/endorsementAdd/enorsement.slice";
import IcReportConfig from "../modules/icReports/report.slice";
import LeadSlice from "../modules/lead/Lead.slice";
import menuMasterSlice from "../modules/menu-master/menu-master.slice";
import proposalConfigSlice from "../modules/search-and-proceed/proposal.slice";
import userConfigSlice from "../modules/user-configuration/userConfig.slice.js";
import bankMasterConfigSlice from "../modules/Bank-Master/bank-master-config.slice";
import nomenclatureSlice from "../modules/Nomenclature/nomenclature.slice";
import communicationConfigSlice from "../modules/Communication/communication-config.slice";
import productSlice from "../modules/ProductMaster/product.slice";
import customerSlice from "../modules/Customer/customer.slice";
import MasterConfigSlice from "../modules/MasterConfig/MasterConfig.slice";
import AddNewMasterSlice from "../modules/MasterConfig/addNewMaster/AddNewMaster.slice";

export default combineReducers({
  globalSlice: GlobalSlice,
  brokerConfig: brokerConfigSlice,
  brokerOfficeConfig: brokerOfficeConfigSlice,
  userConfig: userConfigSlice,
  proposalConfig: proposalConfigSlice,
  holidayConfig: holidayConfigurationSlice,
  documentConfig: docConfigSlice,
  companyMaster: companyMasterSlice,
  menuMaster: menuMasterSlice,
  reportConfig: reportConfig,
  brokerageConfig: brokerageConfig,
  reconConfig: reconConfig,
  endorsement: endorsementSlice,
  icHeadersSlice: IcReportConfig,
  leadConfig: LeadSlice,
  billingFetching: billingFetchingSlice,
  bankMasterConfig: bankMasterConfigSlice,
  nomenclature: nomenclatureSlice,
  communicationConfig: communicationConfigSlice,
  productSlice: productSlice,
  customer: customerSlice,
  MasterConfigSlice: MasterConfigSlice,
  AddNewMasterSlice: AddNewMasterSlice,
});
