import React from "react";
import { DialogBox, Input, Select } from "../../components";
import { Grid, FormControlLabel, Checkbox } from "@mui/material";

export const PlanConfiguration = () => {
  const Inputs = () => (
    <Grid
      container
      style={{ marginBottom: "10px", paddingBottom: "0px" }}
      rowSpacing={1}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    >
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Input
          labelStyle={{ fontSize: "14px", color: "#2C0C4D" }}
          label="Branch Code"
          name="broker_code"
          id="broker_code"
          maxLength="50"
          placeholder="Enter"
          autoComplete="none"
          defaultValue={""}
          required={false}
        />
      </Grid>{" "}
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Input
          labelStyle={{ fontSize: "14px", color: "#2C0C4D" }}
          label="Channel Name"
          name="channel_name"
          id="channel_name"
          maxLength="50"
          placeholder="Enter "
          autoComplete="none"
          defaultValue={""}
          required={false}
        />
      </Grid>{" "}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <FormControlLabel
          size="small"
          value="start"
          control={<Checkbox size="small" />}
          label="Is  Sub Plan Applicable "
          labelPlacement="start"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Input
          labelStyle={{ fontSize: "14px", color: "#2C0C4D" }}
          label="Sub Plan Code"
          name="sub_plan_code"
          id="sub_plan_code"
          maxLength="50"
          placeholder="Enter Sub Plan Code"
          autoComplete="none"
          defaultValue={""}
          required={false}
        />
      </Grid>{" "}
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Input
          labelStyle={{ fontSize: "14px", color: "#2C0C4D" }}
          label="Sub Plan Name"
          name="sub_plan_name"
          id="sub_plan_name"
          maxLength="50"
          placeholder="Enter Sub Plan Name"
          autoComplete="none"
          defaultValue={""}
          required={false}
        />
      </Grid>{" "}
    </Grid>
  );
  const handleClickOpen = () => {
    // setOpen(true);
  };
  const handleClose = () => {
    // setOpen(false);
  };

  return (
    <DialogBox
      TitleText={"Plan Configuration"}
      MainBody={Inputs}
      buttonText={"Save"}
      buttonBG={"#2C0C4D"}
      Isopen={true}
      handleClickOpen={handleClickOpen}
      handleClose={handleClose}
    />
  );
};
