import { createSlice } from "@reduxjs/toolkit";
import { serializeError } from "../../utils";

const brokerageConfig = createSlice({
    name: "brokerageConfig",
    initialState: {
        brokerageError: null,
        brokerageSuccess: null,
    },
    reducers: {
        setBrokerageSuccessAction: (state, { payload }) => {
            state.brokerageSuccess = payload;
            state.brokerageError = null;
        },
        setBrokerageErrorAction: (state, { payload }) => {
            state.loading = null;
            state.brokerageError = serializeError(payload);
            state.brokerageSuccess = null;
        },
    },
});

export const { setBrokerageSuccessAction, setBrokerageErrorAction } =
    brokerageConfig.actions;
export default brokerageConfig.reducer;
