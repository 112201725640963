import React, { useEffect } from "react";
import { Typography } from "../../components";
import styled from "styled-components";
import { Previews } from "./FilePicker/file-picker";
//Required Typography
const { StyledH1 } = Typography;

export const BranchHierarchy = () => {
  useEffect(() => {
    document.getElementById("layoutBG").style.backgroundImage =
      "url('/assets/layout/header-green.png')";
  }, []);
  return (
    <StyledDiv>
      <StyledH1 themeType={"theme1"} partialU color={"rgb(23, 142, 128)"} align={"left"}>
        Branch Hierarchy Mapping
      </StyledH1>
      <Previews themeType={"theme1"} />
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
`;
