import { React } from "react";
import { Input, Button, Typography } from "../../../components";
import SummaryCard from "../Summary/summaryAddress";
import {
  StyledDiv,
  InputFrame,
  RowGrid,
  ColumnGrid,
  StyledGrid,
} from "../styles/styles";

const PermanentAddress = ({
  address,
  onEdit,
  onSave,
  active,
  summary,
  handleSubmit,
  register,
  errors,
}) => {
  const { Error } = Typography;

  return (
    <StyledDiv id="addressSection">
      <h3 style={{ display: "flex", justifyContent: "space-between" }}>
        Permanent Address
      </h3>
      {active === 2 ? (
        <InputFrame onSubmit={handleSubmit(onSave)}>
          <RowGrid
            xs={12}
            sm={10}
            md={9}
            lg={12}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <StyledGrid item xs={10} md={3} sm={10} lg={3}>
              <Input
                themeType="theme1"
                label="Pincode"
                name="pincode"
                id="pincode"
                maxLength="6"
                placeholder="Enter Pincode"
                autoComplete="none"
                defaultValue={""}
                isRequired={true}
                inputRef={register("pincode")}
              />
              {errors.pincode && (
                <Error left="2px">{errors.pincode?.message}</Error>
              )}
            </StyledGrid>
            <StyledGrid item xs={10} md={3} sm={10} lg={3}>
              <Input
                themeType="theme1"
                label="State"
                name="state"
                id="state"
                maxLength="50"
                placeholder="Enter State"
                autoComplete="none"
                defaultValue={""}
                isRequired={true}
                readOnly={true}
                inputRef={register("state")}
              />
              {errors.state && (
                <Error left="2px">{errors.state?.message}</Error>
              )}
            </StyledGrid>
            <StyledGrid item xs={10} md={3} sm={10} lg={3}>
              <Input
                themeType="theme1"
                label="City"
                name="city"
                id="city"
                maxLength="50"
                placeholder="Enter City"
                autoComplete="none"
                defaultValue={""}
                isRequired={true}
                readOnly={true}
                inputRef={register("city")}
              />{" "}
              {errors.city && <Error left="2px">{errors.city?.message}</Error>}
            </StyledGrid>

            <ColumnGrid item xs={10} md={3} sm={10} lg={3}>
              <Input
                themeType="theme1"
                label="Landmark"
                name="landmark"
                id="landmark"
                maxLength="50"
                placeholder="Enter Landmark"
                autoComplete="none"
                defaultValue={""}
                isRequired={false}
                inputRef={register("landmark")}
              />
            </ColumnGrid>
            <ColumnGrid item xs={10} md={3} sm={10} lg={3}>
              <Input
                themeType="theme1"
                label="Address Line 1 "
                name="address_line1"
                id="address_line1"
                maxLength="50"
                placeholder="Enter"
                autoComplete="none"
                defaultValue={""}
                isRequired={true}
                inputRef={register("address_line1")}
              />
              {errors.address_line1 && (
                <Error left="2px">{errors.address_line1?.message}</Error>
              )}
            </ColumnGrid>
            <ColumnGrid item xs={10} md={3} sm={10} lg={3}>
              <Input
                themeType="theme1"
                label="Address Line 2"
                name="address_line2"
                id="address_line2"
                maxLength="50"
                placeholder="Enter"
                autoComplete="none"
                defaultValue={""}
                isRequired={false}
                inputRef={register("address_line2")}
              />
            </ColumnGrid>
          </RowGrid>
          <RowGrid padding>
            <Button type="submit" bgColor={"rgb(23, 142, 128)"}>
              Proceed
            </Button>
          </RowGrid>
        </InputFrame>
      ) : summary.address ? (
        <div>
          <RowGrid>
            <SummaryCard values={address} />
          </RowGrid>
          <RowGrid padding>
            <Button
              type={"submit"}
              onClick={onEdit}
              bgColor={"rgb(23, 142, 128)"}
            >
              edit
            </Button>
          </RowGrid>
        </div>
      ) : (
        <noscript />
      )}
    </StyledDiv>
  );
};

export default PermanentAddress;
