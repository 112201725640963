import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import HttpClient from "../../api/httpClient";
import httpClient from "../../api/httpClient";
import Swal from "sweetalert2";
import { boolean } from "yup";

export const useGetClaimTypeMaster = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["CLAIMS"],
    queryFn: () => HttpClient("claim_type_master/list"),
  });

  return { data, isLoading };
};

export const useGetClaimStageMaster = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["claimStage"],
    queryFn: () => HttpClient("claim_stage_master/list"),
  });
  return { data, isLoading };
};

export const useGetClaimDocs = (id) => {
  const { data } = useQuery({
    queryKey: ["claimDocs", id],
    queryFn: () =>
      HttpClient(`claim_supporting_documents/list/${id}`, {
        method: "GET",
      }),
    enabled: id ? true : false,
    onSuccess: (response) => {
      if (!response || !response?.data?.data?.length) {
        Swal.fire({
          title: "No Data!",
          text: "No data available.",
          icon: "warning",
        });
      }
    },
  });
  return { data };
};

export const useGetClaimDocsFormatUsingDocId = (
  documentManagementConfigurationId
) => {
  const fetchDocFromatData = (documentManagementConfigurationId) => {
    return HttpClient(
      `document_management/list/extensions/${documentManagementConfigurationId}`
    ).then((response) => response.data);
  };
  const data = useQueries({
    queries: documentManagementConfigurationId?.map(
      (documentManagementConfigurationId) => {
        return {
          queryKey: ["Doc-Format", documentManagementConfigurationId],
          queryFn: () => fetchDocFromatData(documentManagementConfigurationId),
        };
      }
    ),
  });
  return { data };
};

export const useAddClaims = () => {
  const queryClient = useQueryClient();
  const { mutate, data } = useMutation(
    (payload) => {
      return HttpClient("/claim_details/add", {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["addClaims"]);
      },
    }
  );
  return { mutate, data };
};

export const useGetClaimsList = () => {
  const { data, isFetching } = useQuery({
    queryKey: ["claimsList"],
    queryFn: () => httpClient("/claim_details/list"),
  });

  return { data, isFetching };
};

export const useDeleteClaims = () => {
  const queryClient = useQueryClient();
  const { data, mutate } = useMutation(
    (id) => {
      return httpClient(`claim_details/delete/${id}`, {
        method: "DELETE",
      });
    },
    {
      onSuccess: () => queryClient.invalidateQueries("ClaimsList"),
    }
  );
  return { mutate, data };
};

export const getClaimSearchData = (payload) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, refetch, isFetching } = useQuery({
    queryKey: ["claimSearcch"],
    queryFn: () =>
      HttpClient("/claim_details/listPaging", {
        method: "POST",
        data: payload,
      }),
  });
  return { data, refetch, isFetching };
};

export const useGetClaimById = (id) => {
  const { data } = useQuery(
    ["claimsListById", id],
    () => {
      return httpClient(`claim_details/list/${id}`, { method: "GET" });
    },
    {
      enabled: Boolean(id),
    }
  );
  return { data };
};

export const useUpdateClaims = () => {
  const { mutate, data } = useMutation((updatedData) => {
    return httpClient(`claim_details/update/${updatedData.id}`, {
      method: "PUT",
      data: updatedData,
    });
  });
  return { mutate, data };
};

export const useGetMultiClaims = (id) => {
  const { data } = useQuery({
    queryKey: ["multiClaims", id],
    queryFn: () =>
      httpClient(`claim_type_master/mulitpleListing/byId`, {
        method: "POST",
        data: id,
      }),
    enabled: Boolean(id),
  });
  return { data };
};

export const useGetClaimTypesByLob = (payload) => {
  const { data } = useQuery({
    queryKey: ["getClaimsByLob"],
    queryFn: () =>
      httpClient(`/claim_type_master/search`, {
        method: "POST",
        data: payload,
      }),
    enabled: Boolean(payload),
  });
  return { data };
};
