import styled from "styled-components";
import { Typography } from "../../components";
import { ProductCoverageForm } from "./product-coverage-form/ProductCoverageForm";
import { ProductCoverageTable } from "./product-coverage-table/ProductCoverageTable";

//Required Typography
const { StyledH1 } = Typography;

export const ProductCoverageConfiguration = () => {
  return (
    <StyledDiv>
      <StyledH1 partialU color={"rgb(23, 142, 128)"} align={"left"}>
        Product Configuration
      </StyledH1>
      <ProductCoverageForm />
      <TableFrame>
        <ProductCoverageTable />
      </TableFrame>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
`;

const TableFrame = styled.div`
  max-width: 99.5%;
  padding-top: 10px;
`;
