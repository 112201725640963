import React from "react";
import "./hamburger-style.css";

export const HamburgerMenu = ({ setOpen, open, setOpenIndex }) => {
  return (
    <label
      onClick={() => [setOpen(!open), setOpenIndex(null)]}
      className="container"
    >
      <div
        className={`hamburger ${open ? "is-active" : "not"}`}
        id="hamburger-1"
      >
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </div>
    </label>
  );
};
