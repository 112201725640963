import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { useLocation, useNavigate } from "react-router-dom";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import styled from "styled-components";
import Swal from "sweetalert2";
import useGetOperations from "../../hooks/useGetOperations";
import { Button } from "../../components";
import { Tooltip } from "@mui/material";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { useDeleteCustomer } from "./service";
const CustomerTable = ({
  setModalTitle,
  setOpen,
  handleCustomerDeletion,
  rows,
  setRowData,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { mutate } = useDeleteCustomer();
  const { allowedOperations } = useGetOperations(location.pathname);
  const columns = [
    {
      field: "referenceId",
      headerName: "Reference ID",
      flex: 1,
      background: "#D2F8E9",
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      background: "#D2F8E9",
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      background: "#D2F8E9",
    },
    {
      field: "emailId",
      headerName: "Email",
      flex: 1,
      background: "#D2F8E9",
    },
    {
      field: "mobile_number",
      headerName: "Mobile Number",
      flex: 1,
      background: "#D2F8E9",
    },
    {
      field: "gender",
      headerName: "Gender",
      flex: 1,
      background: "#D2F8E9",
    },
    {
      field: "dob",
      headerName: "DOB",
      flex: 1,
      background: "#D2F8E9",
    },
    {
      field: "Actions",
      headerName: "Actions",
      headerAlign: "left",
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <ActionContainer>
            <Tooltip title="Add Additional Document" arrow>
              <PostAddIcon
                onClick={() => {
                  setModalTitle("Add Additional Document For");
                  setOpen(true);
                  setRowData(params?.row);
                }}
              />
            </Tooltip>
            <Tooltip title="Add Vehicle Details" arrow>
              <CarRepairIcon
                onClick={() => {
                  setModalTitle("Add Vehicle Details For");
                  setOpen(true);
                  setRowData(params?.row);
                }}
              />
            </Tooltip>
            <Tooltip title="Add Family Member" arrow>
              <PeopleAltIcon
                onClick={() => {
                  setModalTitle("Add Family Member For");
                  setOpen(true);
                  setRowData(params?.row);
                }}
              />
            </Tooltip>
            <Tooltip title="Delete Customer" arrow>
              <DeleteForeverOutlinedIcon
                id={`delete_${params.row.id}`}
                onClick={() => {
                  Swal.fire({
                    title: "Delete record?",
                    text: "Confirmation required to delete this record.",
                    icon: "question",
                    confirmButtonText: "Delete",
                    confirmButtonColor: "#DC004E",
                    showCancelButton: true,
                    cancelButtonText: "Cancel",
                    reverseButtons: true,
                    focusConfirm: false,
                    focusCancel: false,
                    scrollbarPadding: false,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      mutate(params.row.id);
                      Swal.fire("Deleted!", "Deleted Successfully", "success");
                    }
                  });
                }}
                index={params.row.id}
              />
            </Tooltip>
          </ActionContainer>
        );
      },
    },
    {
      field: "List",
      headerName: "List",
      headerAlign: "left",
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <ActionContainer>
            <Tooltip title="Vehicle Listing">
              <ListAltIcon
                onClick={() =>
                  navigate(`/customer-vehicle-lists?id=${params.row.id}`)
                }
              />
            </Tooltip>
            <Tooltip title="Family Listing">
              <ListAltIcon
                onClick={() =>
                  navigate(`/customer-family-lists?id=${params.row.id}`)
                }
              />
            </Tooltip>
          </ActionContainer>
        );
      },
    },
  ];

  const getRowId = (row) => {
    return row.id;
  };
  return (
    <>
      <TableHeading>
        <Heading>List of Customer</Heading>
        <Button
          onClick={() => {
            navigate("/customer-add");
          }}
          bgColor="transparent"
          textColor="#11555F"
          id="add_btn"
          variant="outlined"
          borderColor="#11555F"
          startIcon={<AddBoxOutlinedIcon />}
          style={{
            position: "absolute",
            right: "20px",
          }}
        >
          Add New
        </Button>
      </TableHeading>
      <TableContainer>
        {" "}
        <DataGrid
          rows={rows ? rows : []}
          columns={columns}
          size="small"
          fontSize={12}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 20 },
            },
          }}
          pageSizeOptions={[5, 20]}
          getRowId={getRowId}
          sx={{
            ".MuiDataGrid-cellContent": {
              fontSize: "14px",
            },
            ".MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "&.MuiDataGrid-root": {
              border: "none",
            },
            ".MuiDataGrid-columnHeaders": {
              background: "#D2F8E9",
              color: "#11555F",
              fontSize: "14px",
              fontWeight: 500,
              fontFamily: "Montserrat-Medium",
            },
            ".MuiTablePagination-root p": {
              fontSize: "14px",
            },
          }}
        />
      </TableContainer>
    </>
  );
};

export default CustomerTable;

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  gap: 4px;
  svg {
    color: #178e80;
    font-size: 20px;
  }
`;

const TableHeading = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 12px;
`;

export const Heading = styled.h2`
  margin-top: -3px;
  margin-bottom: 0;
  display: flex;
  color: #178e80;
  justify-content: space-between;
  font-family: "Montserrat-SemiBold";
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
`;

const TableContainer = styled.div`
  background: #fff;
  width: 100%;
`;
