import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import Grid from "@mui/material/Grid";
import { Controller } from "react-hook-form";
import Select from "react-select";
import {
  allowPercentageNumbers,
  defaultDateFormat,
  handleFloatInput,
  preventCharactersInInput,
  preventZeroAsInitial,
} from "../../../HelperFunctions/HelperFunctions";
import { GlobalRadio, Typography } from "../../../components";
import {
  BasicDetailsSummaryStyled,
  ErrorStyled,
  InputStyled,
  LOBContainerStyled,
  PremiumBearableStyled,
  StyledBg,
} from "../Brokerage.style";
import ReconLoader from "../../Reconciliation/components/ReconLoader";

function StandardBrokeragePremiumFields({
  register,
  control,
  lobId,
  transactionType,
  effective_start_date,
  effective_end_date,
  icType,
  premiumBearingObj,
  baseRule,
  premiumBearableFields,
  handleRemove,
  resetRespectiveAmountField,
  errors,
  isTransactionFieldFetching,
}) {
  const { Error } = Typography;

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "103%",
      border: "0.25px solid rgb(169, 167, 167)",
      fontSize: "12px",
      boxShadow: state.isFocused ? "0 0 0 0.2rem transparent" : "none",
      backgroundColor: "rgb(247, 248, 250)",
      marginTop: "5px",
      padding: "0 5px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      border: 0,
      padding: 0,
    }),
    option: (styles, { isFocused, isSelected, isDisabled }) => ({
      ...styles,
      fontWeight: 600,
      letterSpacing: "0.5px",
      fontSize: "14px",
      fontFamily: "Montserrat-SemiBold",
      borderBottom: ".25px solid",
      backgroundColor: isSelected ? "#D1F8E8" : isFocused ? "#fff" : "#fff",
      color: isDisabled ? "#e4e4e4" : "#2BB9A8",
      cursor: isDisabled ? "not-allowed" : "normal",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: 0,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
    }),
    multiValue: (provided) => ({
      ...provided,
      padding: "5px 10px",
      color: "#2BB9A8",
      borderRadius: "5px",
      margin: "5px",
      background: "#22bdaa30",
      fontSize: "14px",
      fontWeight: "600",
    }),
  };
  return (
    <>
      <StyledBg differentSpacing={true}>
        <p className="card_header">Basic Details Summary</p>
        <BasicDetailsSummaryStyled>
          <div>
            <p>
              LOB: <span>{lobId?.value}</span>
            </p>
            <p>
              Business Type: <span>{transactionType?.label}</span>
            </p>
            <p>
              Insurance Company: <span>{icType?.value}</span>
            </p>
          </div>
          <div>
            <p>
              Effective Start Date:{" "}
              <span>{defaultDateFormat(effective_start_date)}</span>
            </p>
            <p className="end_date">
              Effective End Date:{" "}
              <span>{defaultDateFormat(effective_end_date)}</span>
            </p>
          </div>
        </BasicDetailsSummaryStyled>
      </StyledBg>

      <StyledBg>
        <p className="card_header">Standard Brokerage</p>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid
            sx={{ position: "relative" }}
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <LOBContainerStyled>
              <label htmlFor="premiumBearableFields">Select Fields</label>
              <Controller
                control={control}
                name="premiumBearableFields"
                render={({ field }) => (
                  <Select
                    {...field}
                    isMulti
                    closeMenuOnSelect={true}
                    maxMenuHeight={200}
                    id="premiumBearableFields"
                    placeholder="Select Fields"
                    options={premiumBearingObj}
                    styles={customStyles}
                  />
                )}
              />
            </LOBContainerStyled>
            {errors?.premiumBearableFields?.message && (
              <Error top="0" left="3px" bottom="-14px">
                Select a field
              </Error>
            )}
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {premiumBearableFields?.map((value, index) => {
            return (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <PremiumBearableStyled>
                  <div className="heading">
                    <h3>{value.label}</h3>
                    <DeleteForeverOutlinedIcon
                      onClick={() =>
                        handleRemove({
                          type: "BrokeragePremium",
                          id: value.id,
                        })
                      }
                    />
                  </div>
                  <Controller
                    name={`baseRule[${index}].transactionFieldMasterId`}
                    control={control}
                    defaultValue={value.id}
                    render={({ field }) => <input {...field} type="hidden" />}
                  />
                  <GlobalRadio
                    name={`baseRule[${index}].brokerageType`}
                    label="Select Brokerage Type:"
                    options={["VARIABLE", "FLAT"]}
                    control={control}
                    defaultValue="VARIABLE"
                    radioSpace={true}
                    onChange={() => resetRespectiveAmountField(index)}
                  />
                  <div className="brokerageInput">
                    <p>
                      Enter Brokerage{" "}
                      {baseRule?.[index]?.brokerageType === "VARIABLE"
                        ? "Percentage"
                        : "Amount"}
                      :
                    </p>
                    {baseRule?.[index]?.brokerageType === "VARIABLE" ? (
                      <InputStyled>
                        <input
                          type="text"
                          placeholder={`Enter Value`}
                          {...register(`baseRule[${index}].amount`)}
                          onChange={(e) => allowPercentageNumbers(e)}
                        />
                        <span>%</span>
                      </InputStyled>
                    ) : (
                      <InputStyled>
                        <input
                          type="text"
                          placeholder={`Enter Value`}
                          {...register(`baseRule[${index}].amount`)}
                          onChange={(e) => { handleFloatInput(e); }}
                        />
                        <span>₹</span>
                      </InputStyled>
                    )}
                    <ErrorStyled>
                      {errors?.baseRule?.[index]?.amount && (
                        <Error top="3px" left="3px">
                          {errors.baseRule[index].amount.message}
                        </Error>
                      )}
                    </ErrorStyled>
                  </div>
                </PremiumBearableStyled>
              </Grid>
            );
          })}
        </Grid>
      </StyledBg>

      <ReconLoader isLoading={isTransactionFieldFetching} />
    </>
  );
}

export default StandardBrokeragePremiumFields;
