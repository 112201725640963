import { lazy, Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import styled from "styled-components";
//modules
// import BrokerConfig from "./modules/broker-configuration/broker-configuration";
// import { BrokerAdmin } from "./modules/broker-admin-config/broker-admin";
import _, { cloneDeep, isEmpty } from "lodash";
import Loader from "./components/loader/loader";
import Error404 from "./ErrorPages/error404";
import Error500 from "./ErrorPages/error500";
import Offline from "./ErrorPages/Offline";
import OnlineStatusChecker from "./ErrorPages/OnlineStatusChecker";
import ErrorBoundary from "./hoc/ErrorBoundry";
import AuthRoute from "./Layout/Sidebar/AuthRoute";
import { _renderMenu } from "./Layout/Sidebar/helperFunc";
import { useGetAccessRightsMenu } from "./Layout/Sidebar/service";
import { BranchHierarchy } from "./modules/branch-hierarchy-mapping/branch-hierarchy";
import { BrokerHolidayConfiguration } from "./modules/broker-holiday-configuration/holiday-configuration";
import { BrokerOfficeConfig } from "./modules/broker-office-configuration/broker-office-config";
import BrokerageConfigurator from "./modules/Brokerage";
import Communication from "./modules/Communication";
import Customer from "./modules/Customer";
import CustomerAdd from "./modules/Customer/customerAdd";
import CustomerDocumentListing from "./modules/Customer/customerAdditionalDoc/customerDocListing";
import CustomerFamilyListing from "./modules/Customer/customerFamilyListing/customerFamilyListing";
import VehicleListing from "./modules/Customer/vehicleListing/vehicleListing";
import DashboardController from "./modules/dashboard-controllers/DashboardController";
import { DocumentManagementConfig } from "./modules/doc-configuration/Doc-config";
import LeadActivity from "./modules/leadActivity/leadActivity";
import LeadActivityListing from "./modules/leadActivity/leadActivityListing/leadActivityListing";
import ResetPassword from "./modules/login/resetPassword";
import MasterConfig from "./modules/MasterConfig";
import { Nomenclature } from "./modules/Nomenclature/nomenclature";
import { PageCustomisation } from "./modules/page-customisation/page-customisation";
import { PlanConfiguration } from "./modules/plan-configuration-modal/plan-configuration";
import { ProductCoverageConfiguration } from "./modules/product-coverage-configuration/ProductCoverageConfiguration";
import ProductMaster from "./modules/ProductMaster";

import AssignRole from "./modules/role-access/role-access";
import { RolePage } from "./modules/role-page/role";
import UserConfiguration from "./modules/user-configuration/UserConfiguration";

const Sidebar = lazy(() => import("./Layout/Sidebar/Sidebar"));
const BrokerAdmin = lazy(() =>
  import("./modules/broker-admin-config/broker-admin")
);
const BrokerConfig = lazy(() =>
  import("./modules/broker-configuration/broker-configuration")
);
const LoginPage = lazy(() => import("./modules/login/login-page"));
const ForgetPassword = lazy(() => import("./modules/login/forgetPassword"));
const Dashboard = lazy(() => import("./modules/dashboard/dashboard"));
const BrokerageList = lazy(() =>
  import("./modules/Brokerage/StandardBrokerage/BrokerageList")
);
const Claims = lazy(() => import("./modules/claims"));
const ClaimsListing = lazy(() => import("./modules/claims/ClaimListing"));
const ClaimsTypeMaster = lazy(() =>
  import("./modules/ClaimTypesMaster/claimsTypeMaster")
);
const CompanyMaster = lazy(() =>
  import("./modules/company-master/CompanyMaster")
);
const ComparePage = lazy(() => import("./modules/dedupe/ComparePage"));
const Dedupe = lazy(() => import("./modules/dedupe/SearchCustomer/index"));
const EndorsmentAdd = lazy(() => import("./modules/endorsementAdd/endorsment"));
const EndorsementList = lazy(() =>
  import("./modules/endorsementListing/endorsementList")
);
const Endorsement = lazy(() =>
  import("./modules/Endorsements/CompanyEndorsementDetails/endorsement")
);
const EndorsementSummary = lazy(() =>
  import("./modules/Endorsements/EndorsementSummary.jsx")
);
const InitiateEndorse = lazy(() =>
  import("./modules/Endorsements/InitiateEndorsement/InitiateEndorse")
);
const ModifyEndorsement = lazy(() =>
  import("./modules/Endorsements/ModifyEndorsement")
);
const UserView = lazy(() => import("./modules/Endorsements/UserView/UserView"));
const EndorsementType = lazy(() =>
  import("./modules/EndorsementType/endorsementType")
);
const FetchBillingReport = lazy(() => import("./modules/FetchBillingReports"));
const Billing = lazy(() => import("./modules/FetchBillingReports/billing"));
const BillingFetchingInsight = lazy(() =>
  import("./modules/FetchBillingReports/billingFetchingInsight")
);
const ReportUploaderConfigurator = lazy(() =>
  import("./modules/icReports/ICReportUploader")
);
const Lead = lazy(() => import("./modules/lead"));
const MenuMaster = lazy(() => import("./modules/menu-master"));
const Reconciliation = lazy(() => import("./modules/Reconciliation"));
const ReconResolve = lazy(() =>
  import("./modules/Reconciliation/ReconResolve/ReconResolve")
);
const ReportConfigurator = lazy(() =>
  import("./modules/Reports/ReportConfigurator")
);
const ReportDownload = lazy(() => import("./modules/Reports/ReportDownload"));
const ReportListing = lazy(() =>
  import("./modules/Reports/ReportListing/ReportListing")
);
const ReportUploader = lazy(() =>
  import("./modules/ReportUploader/SystemReportUploader")
);
const RoleMaster = lazy(() => import("./modules/role-master/RoleMaster"));
const RoleAccess = lazy(() => import("./modules/role-page/role-access/index"));
const RoleConfig = lazy(() => import("./modules/roleConfigurator"));
const ProposalCreate = lazy(() =>
  import("./modules/search-and-proceed/ProposalCreate")
);
const ProposalEdit = lazy(() =>
  import("./modules/search-and-proceed/ProposalEdit")
);
const ProposalSummary = lazy(() =>
  import("./modules/search-and-proceed/ProposalSummary")
);
const SearchProceed = lazy(() =>
  import("./modules/search-and-proceed/search-and-proceed")
);
const UserData = lazy(() => import("./modules/user-data-page/userData"));

const BankMaster = lazy(() => import("./modules/Bank-Master/index"));
const ProposalDraftListing = lazy(() =>
  import("./modules/ProposalDraftListing")
);
const RewardMaster = lazy(() => import("./modules/RewardMaster"));

const definedRoutes = [
  {
    path: "/offline",
    element: <Offline />,
  },
  {
    element: <OnlineStatusChecker />,
    children: [
      {
        path: "/login",
        errorElement: <ErrorBoundary />,
        element: <LoginPage />,
      },
      {
        path: "/forget-password",
        element: <ForgetPassword />,
      },
      {
        path: "/reset_password/:id",
        element: <ResetPassword />,
      },
      {
        element: <AuthRoute />,
        children: [
          {
            path: "/broker-admin",
            element: <BrokerAdmin />,
          },
          {
            path: "/",
            element: (
              <>
                <Sidebar />
              </>
            ),
            children: [
              {
                path: "/",
                element: <Dashboard />,
              },
              {
                path: "/role",
                element: <RolePage />,
              },
              {
                path: "/assign-role",
                element: <AssignRole />,
              },
              {
                path: "/broker-configuration",
                element: <BrokerConfig />,
              },
              {
                path: "/page-customisation",
                element: <PageCustomisation />,
              },
              {
                path: "/nomenclature",
                element: <Nomenclature />,
              },
              {
                path: "/broker-office-config",
                element: <BrokerOfficeConfig />,
              },
              {
                path: "/branch-hierarchy",
                element: <BranchHierarchy />,
              },
              {
                path: "/broker-admin",
                element: <BrokerAdmin />,
              },
              {
                path: "/document-config",
                element: <DocumentManagementConfig />,
              },
              {
                path: "/product-coverage",
                element: <ProductCoverageConfiguration />,
              },
              { path: "/user-controller", element: <UserData /> },
              { path: "/user-configupdate", element: <UserConfiguration /> },
              {
                path: "/user-configuration",
                element: <UserConfiguration />,
              },
              {
                path: "/dashboard-controller",
                element: <DashboardController />,
              },
              {
                path: "/plan-configuration",
                element: <PlanConfiguration />,
              },
              {
                path: "/search-proceed",
                element: <SearchProceed />,
              },
              {
                path: "/summary",
                element: <ProposalSummary />,
              },
              {
                path: "/edit",
                element: <ProposalEdit />,
              },
              {
                path: "/create",
                element: <ProposalCreate />,
              },
              {
                path: "/endorsement",
                element: <Endorsement />,
              },
              {
                path: "/initiate-endorsement",
                element: <InitiateEndorse />,
              },
              {
                path: "/user-view/:policy_id",
                element: <UserView />,
              },
              {
                path: "/modify-endorsement",
                element: <ModifyEndorsement />,
              },
              {
                path: "/endorsement-summary",
                element: <EndorsementSummary />,
              },
              {
                path: "/dedupe",
                element: <Dedupe />,
              },
              {
                path: "/compare",
                element: <ComparePage />,
              },
              { path: "/menu-master", element: <MenuMaster /> },
              { path: "/role-master", element: <RoleMaster /> },
              {
                path: "/holiday-configuration",
                element: <BrokerHolidayConfiguration />,
              },
              { path: "/role-access", element: <RoleAccess /> },
              { path: "/company-master", element: <CompanyMaster /> },
              { path: "/report-sheet-create", element: <ReportConfigurator /> },
              { path: "/report-listing", element: <ReportListing /> },
              { path: "/role-config", element: <RoleConfig /> },
              {
                path: "/report-update/:reportId",
                element: <ReportConfigurator />,
              },
              {
                path: "/report-download/:reportId",
                element: <ReportDownload />,
              },
              { path: "/brokerage-config", element: <BrokerageConfigurator /> },
              { path: "/brokerage-listing", element: <BrokerageList /> },
              {
                path: "/reconciliation",
                element: <Reconciliation />,
              },
              {
                path: "/reconciliation_resolve",
                element: <ReconResolve />,
              },
              {
                path: "/endorsement-add",
                element: <EndorsmentAdd />,
              },
              {
                path: "/endorsement-update",
                element: <EndorsmentAdd />,
              },
              {
                path: "/endorsement-list",
                element: <EndorsementList />,
              },
              {
                path: "/endorsement-types",
                element: <EndorsementType />,
              },
              {
                path: "/system-report-configurator",
                element: <ReportUploaderConfigurator />,
              },
              {
                path: "/ic-report-configurator",
                element: <ReportUploaderConfigurator />,
              },
              {
                path: "/claim-types",
                element: <ClaimsTypeMaster />,
              },
              {
                path: "/claims-add",
                element: <Claims />,
              },
              {
                path: "/claims-update",
                element: <Claims />,
              },
              {
                path: "/claims",
                element: <ClaimsListing />,
              },
              {
                path: "/claims-view",
                element: <Claims />,
              },
              {
                path: "/billing",
                element: <Billing />,
              },
              {
                path: "/system-uploader",
                element: <ReportUploader />,
              },
              {
                path: "/ic-uploader",
                element: <ReportUploader />,
              },
              {
                path: "/lead",
                element: <Lead />,
              },
              {
                path: "/billing-fetching",
                element: <FetchBillingReport />,
              },
              {
                path: "/billing-fetching-insight",
                element: <BillingFetchingInsight />,
              },
              {
                path: "/communication",
                element: <Communication />,
              },
              {
                path: "/bank-master",
                element: <BankMaster />,
              },
              {
                path: "/lead-activity",
                element: <LeadActivity />,
              },
              {
                path: "/customer",
                element: <Customer />,
              },
              {
                path: "/customer-add",
                element: <CustomerAdd />,
              },
              {
                path: "/master-config",
                element: <MasterConfig />,
              },
              {
                path: "/lead-activity-update",
                element: <LeadActivity />,
              },
              {
                path: "/lead-activity-listing",
                element: <LeadActivityListing />,
              },
              { path: "/product-master", element: <ProductMaster /> },
              {
                path: "/customer-doc-listing",
                element: <CustomerDocumentListing />,
              },
              {
                path: "/customer-vehicle-lists",
                element: <VehicleListing />,
              },
              {
                path: "/customer-family-lists",
                element: <CustomerFamilyListing />,
              },
              {
                path: "/proposal-draft-listing",
                element: <ProposalDraftListing />,
              },
              {
                path: "/reward-master",
                element: <RewardMaster />,
              },
            ],
          },
        ],
      },
      {
        path: "*",
        element: <Error404 />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/500",
        element: <Error500 />,
        errorElement: <ErrorBoundary />,
      },
    ],
  },
];

const Routes = () => {
  const allowedRoutes = ["/", "/reward-master"];
  const isMenusPresent =
    sessionStorage.getItem("menus") &&
    JSON.parse(sessionStorage.getItem("menus"));

  const { data, isFetching } = useGetAccessRightsMenu();

  const handleAccessRightsMenu = (array) => {
    return array?.map((value) => {
      return value.route;
    });
  };

  const menuForAccess = isMenusPresent
    ? _.flatMap(_renderMenu(isMenusPresent?.data?.return_data))
    : _.flatMap(_renderMenu(data?.data?.return_data));

  const accessRightsMenu = handleAccessRightsMenu(menuForAccess);

  if (accessRightsMenu && Array.isArray(accessRightsMenu)) {
    allowedRoutes.push(...accessRightsMenu);
  }
  //can be handed with manual spread destructure/ immer js
  const filteredRoutes = definedRoutes.map((route) => {
    return (
      !isEmpty(route.children) &&
      route.children[3].children[1].children.filter((value) =>
        allowedRoutes.includes(value.path)
      )
    );
  });

  const newRoutes = cloneDeep(definedRoutes);

  newRoutes[1].children[3].children[1].children = filteredRoutes[1];

  const router = createBrowserRouter(newRoutes);

  return (
    <>
      <Suspense fallback={<Loader />}>
        {isFetching ? (
          <Loader />
        ) : (
          <ComponentFrame>
            <RouterProvider router={router} />
          </ComponentFrame>
        )}
      </Suspense>
    </>
  );
};

const ComponentFrame = styled.div`
  min-height: 314px;
`;

export default Routes;
