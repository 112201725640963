import React from "react";
import { useLocation } from "react-router-dom";
import { useGetVehicleDetails } from "../service";
import _ from "lodash";
import VehicleTable from "./vehicleTable";
import { TableFrame } from "./styles";
const VehicleListing = () => {
  const location = useLocation();
  const url = new URLSearchParams(location.search);
  const customerID = url.get("id");
  const { data: customerVehicles } = useGetVehicleDetails(
    !_.isEmpty(customerID) && customerID
  );

  const rows =
    !_.isEmpty(customerVehicles?.data?.data) &&
    customerVehicles?.data?.data?.map((item) => {
      return {
        id: item?.customerVehicleDetailsId,
        lob: item?.lob,
        make: item?.make,
        model: item?.model,
        policyEndDate: item?.policyEndDate,
        policyStartDate: item?.policyStartDate,
      };
    });

  const columns = [
    {
      field: "lob",
      headerName: "LOB",
      flex: 1,
      background: "#D2F8E9",
    },
    {
      field: "make",
      headerName: "Make",
      flex: 1,
      background: "#D2F8E9",
    },
    {
      field: "model",
      headerName: "Model",
      flex: 1,
      background: "#D2F8E9",
    },
    {
      field: "policyStartDate",
      headerName: "Policy Start Date",
      flex: 1,
      background: "#D2F8E9",
    },
    {
      field: "policyEndDate",
      headerName: "Policy End Date",
      flex: 1,
      background: "#D2F8E9",
    },
  ];

  return (
    <TableFrame>
      <VehicleTable rows={rows} columns={columns} />
    </TableFrame>
  );
};

export default VehicleListing;
