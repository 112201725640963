import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Grid from "@mui/material/Grid";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { ConditionalBrokerageStyled, LOBContainerStyled, StyledHeading } from "./Brokerage.style";
import ExcelUpload from './ExcelUpload';

function ConditionalBrokerage({
    control,
    handleRemove,
    regionsObj,
    items,
    files,
    setFiles,
    downloadMaster,
    handleDragEnd
}) {
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: "103%",
            border: "0.25px solid rgb(169, 167, 167)",
            fontSize: "12px",
            minHeight: 32,
            boxShadow: state.isFocused ? "0 0 0 0.2rem transparent" : "none",
            backgroundColor: "rgb(247, 248, 250)",
            marginTop: "-1px",
            padding: "0 5px"
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            border: 0,
            padding: 0,
        }),
        option: (styles, { isFocused, isSelected, isDisabled }) => ({
            ...styles,
            fontWeight: 600,
            borderBottom: ".25px solid",
            backgroundColor: isSelected ? "#D1F8E8" : isFocused ? "#fff" : "#fff",
            color: isDisabled ? "#e4e4e4" : "#2BB9A8",
            cursor: isDisabled ? "not-allowed" : "normal",
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0',
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            padding: 0,
        }),
        singleValue: (provided) => ({
            ...provided,
            color: "#000"
        }),
        multiValue: (provided) => ({
            ...provided,
            padding: "5px 10px",
            color: "#2BB9A8",
            borderRadius: "5px",
            margin: "5px",
            background: "#22bdaa30",
            fontSize: "14px",
            fontWeight: "600",
        }),
    };

    return (
        <>
            <StyledHeading>Select Region</StyledHeading>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <LOBContainerStyled>
                        <label htmlFor="region">Select Region</label>
                        <Controller
                            control={control}
                            name="region"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    isMulti
                                    closeMenuOnSelect={true}
                                    maxMenuHeight={200}
                                    id="region"
                                    placeholder="Select Region"
                                    options={regionsObj}
                                    styles={customStyles}
                                />
                            )}
                        />
                    </LOBContainerStyled>
                </Grid>
            </Grid>

            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="list">
                    {(provided) => (
                        <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="list"
                        >
                            {items.map((item, index) => (
                                <Draggable key={String(item.id)} draggableId={String(item.id)} index={index}>
                                    {(provided) => (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6.2}
                                            lg={6.2}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                            className="item"
                                        >
                                            <ConditionalBrokerageStyled>
                                                <p className="priority_text">Priority {index + 1}</p>
                                                <Controller
                                                    name={`additional_filters[${index}].transactionFieldMasterId`}
                                                    control={control}
                                                    defaultValue={item.id}
                                                    render={({ field }) => (
                                                        <input
                                                            {...field}
                                                            type="hidden"
                                                        />
                                                    )}
                                                />
                                                <Controller
                                                    name={`additional_filters[${index}].priority`}
                                                    control={control}
                                                    defaultValue={index}
                                                    render={({ field }) => (
                                                        <input
                                                            {...field}
                                                            type="hidden"
                                                        />
                                                    )}
                                                />
                                                <Controller
                                                    name={`additional_filters[${index}].fieldSlug`}
                                                    control={control}
                                                    defaultValue={item.value}
                                                    render={({ field }) => (
                                                        <input
                                                            {...field}
                                                            type="hidden"
                                                        />
                                                    )}
                                                />
                                                <Controller
                                                    name={`additional_filters[${index}].data`}
                                                    control={control}
                                                    defaultValue="string"
                                                    render={({ field }) => (
                                                        <input
                                                            {...field}
                                                            type="hidden"
                                                        />
                                                    )}
                                                />
                                                <div className="heading">
                                                    <h3>{item.label}</h3>
                                                    <DeleteForeverOutlinedIcon
                                                        onClick={() => handleRemove({
                                                            type: "conditional",
                                                            id: item.id
                                                        })}
                                                    />
                                                </div>
                                                <div className="actions">
                                                    <div className="button_action" onClick={() => downloadMaster({
                                                        masterSlug: item.slug,
                                                        fieldSlug: [item.value]
                                                    })}>
                                                        <FileDownloadIcon />
                                                        <p>Download Master</p>
                                                    </div>
                                                    <ExcelUpload
                                                        fieldSlug={item.value}
                                                        files={files}
                                                        setFiles={setFiles}
                                                    />
                                                </div>
                                            </ConditionalBrokerageStyled>
                                        </Grid>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </Grid>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    )
}

export default ConditionalBrokerage