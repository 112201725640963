import React from "react";
import { Typography, Input, Button, Select } from "../../../components";
import styled from "styled-components";
import { Grid } from "@mui/material";

const DownForm = () => {
  return (
<StyledDiv>
<InputFrame>
  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
    <StyledGrid item xs={10} md={3} sm={10} lg={3}>
    <Select
     themeType="theme1"
            name="Date Format Configration"
            id="1"
            value="Date Format Configration"
            label="Date Format Configration"
            options={[
              {
                id: 1,
                name: "Date Format Configration",
                value: "Date Format Configration",
              },
            ]}
          />
    </StyledGrid>
    <StyledGrid item xs={10} md={3} sm={10} lg={3}>
    <Select
            name="Date Format Configration"
            id="1"
            value="Date Format Configration"
            label="Date Format Configration"
            options={[
              {
                id: 1,
                name: "Date Format Configration",
                value: "Date Format Configration",
              },
            ]}
          />
    </StyledGrid>
    <StyledGrid item xs={10} md={3} sm={10} lg={3}>
    <Select
            name="Date Format Configration"
            id="1"
            value="Date Format Configration"
            label="Date Format Configration"
            options={[
              {
                id: 1,
                name: "Date Format Configration",
                value: "Date Format Configration",
              },
            ]}
          />
    </StyledGrid>
  </Grid>
  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
    <Grid item xs={10} sm={10} md={9} lg={9}>
      <ButtonFrame>
        <Button bgColor="#178E80" round >
          Save
        </Button>
      </ButtonFrame>
    </Grid>
  </Grid>
</InputFrame> 
</StyledDiv>
  );
};

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  padding-left :40px;
`;

const InputFrame = styled.form`
  margin-top: 30px;
  width: 100%;
`;

const StyledGrid = styled(Grid)`
  position: relative;
`;

const ButtonFrame = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  @media(max-width: 767px){
    justify-content: flex-start;
  }
`;

export default DownForm;
