import { createSlice } from "@reduxjs/toolkit";
import { serializeError } from "../../utils";

const reconConfig = createSlice({
    name: "reconConfig",
    initialState: {
        reconError: null,
        reconSuccess: null,
        systemDataDeleteSuccess: null,
        reconPayloadForBulk: {},
        reconBasicFormPrefill: {}
    },
    reducers: {
        setReconSuccessAction: (state, { payload }) => {
            state.reconSuccess = payload;
            state.reconError = null;
        },
        setReconErrorAction: (state, { payload }) => {
            state.reconError = serializeError(payload);
            state.reconSuccess = null;
        },
        setSystemDataDeleteSuccessAction: (state, { payload }) => {
            state.systemDataDeleteSuccess = payload;
        },
        setReconPayloadAction: (state, { payload }) => {
            state.reconPayloadForBulk = payload
        },
        setReconBasicFormPrefill: (state, { payload }) => {
            state.reconBasicFormPrefill = payload
        }
    },
});

export const { setReconSuccessAction, setReconErrorAction, setReconPayloadAction, setSystemDataDeleteSuccessAction, setReconBasicFormPrefill } =
    reconConfig.actions;
export default reconConfig.reducer;
