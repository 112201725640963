import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import httpClient from "../../api/httpClient";
import {
  setCustomerErrorAction,
  setCustomerSuccessAction,
} from "./customer.slice";
import { method } from "lodash";

export const useGetCustomerList = () => {
  const { data } = useQuery({
    queryKey: ["customer_record"],
    queryFn: () =>
      httpClient("/customer_records/List", {
        method: "GET",
      }),
  });
  return { data };
};

export const useGetCustomerRecordsById = (id) => {
  const { data } = useQuery(
    ["customer_record", id],
    () => {
      return httpClient(`customer_records/List/${id}`, { method: "GET" });
    },
    {
      enabled: Boolean(id),
    }
  );
  return { data };
};

export const useCreateCustomer = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { data, mutate, isLoading, isError } = useMutation(
    (payload) => {
      return httpClient("/customer_records/add", {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        if (!data.success) {
          dispatch(setCustomerErrorAction(data.error));
          return;
        }
        queryClient.invalidateQueries(["customer_record"]);
        dispatch(setCustomerSuccessAction(data.message));
      },
    }
  );

  return { data, mutate, isLoading, isError };
};

export const useGetManufacturerDataById = (lobid) => {
  const { data, isLoading } = useQuery({
    queryKey: ["getManufacturerData", lobid],
    queryFn: () =>
      httpClient("lob_manufacturer/get-manufacturer", {
        method: "POST",
        data: { lobid },
      }),
    enabled: lobid ? true : false,
  });
  return { data, isLoading };
};

export const useGetModelDataForCustomer = () => {
  const { data, mutate, isLoading } = useMutation((payload) => {
    return httpClient("/lob_manufacturer/get-model", {
      method: "POST",
      data: payload,
    });
  });
  return { data, mutate, isLoading };
};

export const useGetVersionDataForCustomer = () => {
  const { data, mutate, isLoading } = useMutation((payload) => {
    return httpClient("/lob_manufacturer/get-version", {
      method: "POST",
      data: payload,
    });
  });
  return { data, mutate, isLoading };
};

export const useGetCustomerDocumentList = () => {
  const { data } = useQuery({
    queryKey: ["customer_doc_list"],
    queryFn: () =>
      httpClient("customer_additional_document/list", {
        method: "GET",
      }),
  });
  return { data };
};

export const useGetCustomerDocumentListById = (id) => {
  const { data } = useQuery(
    ["customer_doc_list", id],
    () => {
      return httpClient(`customer_additional_document/listByCustomerId/${id}`, {
        method: "GET",
      });
    },
    {
      enabled: Boolean(id),
    }
  );
  return { data };
};

export const useAddVehicleDetails = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { data, mutate, isLoading, isError } = useMutation(
    (payload) => {
      return httpClient("customer_vehicle_details/add", {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        if (!data.success) {
          dispatch(setCustomerErrorAction(data.error));
          return;
        }
        queryClient.invalidateQueries(["vehicle_details"]);
        dispatch(setCustomerSuccessAction(data.message));
      },
    }
  );

  return { data, mutate, isLoading, isError };
};

export const useAddAdditionalDetails = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { data, mutate, isLoading, isError } = useMutation(
    (payload) => {
      return httpClient("customer_additional_document/add", {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        if (!data.success) {
          dispatch(setCustomerErrorAction(data.error));
          return;
        }
        queryClient.invalidateQueries(["customer_doc_list"]);
        dispatch(setCustomerSuccessAction(data.message));
      },
    }
  );

  return { data, mutate, isLoading, isError };
};
export const useUpdateAdditionalDetails = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { data, mutate, isLoading, isError } = useMutation(
    (payload) => {
      const { id, ...rest } = payload;
      return httpClient(`customer_additional_document/update`, {
        method: "PUT",
        data: rest,
      });
    },
    {
      onSuccess: (data) => {
        if (!data.success) {
          dispatch(setCustomerErrorAction(data.error));
          return;
        }
        queryClient.invalidateQueries(["customer_doc_list"]);
        dispatch(setCustomerSuccessAction(data.message));
      },
    }
  );

  return { data, mutate, isLoading, isError };
};

export const useGetDocumentDataForCustomer = () => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: ["doc_data_for_customer"],
    queryFn: () =>
      httpClient("document_management/list", {
        method: "POST",
        data: {
          page: 0,
          size: 0,
          sortBy: "",
          sortOrder: "",
          documentName: "",
          documentCode: "",
          documentType: "",
        },
      }),
  });

  return { data, isFetching, refetch };
};

export const useGetVehicleDetails = (id) => {
  const { data } = useQuery(["customerVehicleDetailListing", id], () =>
    httpClient(`/customer_vehicle_details/listByCustomerId/${id}`)
  );
  return { data };
};

export const useGetFamilyListing = (id) => {
  const { data } = useQuery(["cutomerFamilyList", id], () =>
    httpClient(`customer_records/listPaging`, {
      method: "POST",
      data: {
        page: 0,
        size: 0,
        sortBy: "",
        sortOrder: "",
        search: {
          firstName: "",
          customerRefId: "",
          parentId: id,
        },
      },
    })
  );
  return { data };
};
export const useDeleteCustomer = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (id) => {
      return httpClient(`customer_records/delete/${id}`, { method: "DELETE" });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("customer_record");
      },
    }
  );
  return { mutate };
};
