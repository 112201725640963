import { createSlice } from "@reduxjs/toolkit";
import { serializeError } from "../../utils";

const billingFetching = createSlice({
  name: "billingFetching",
  initialState: {
    billingFetchingError: null,
    billingFetchingSuccess: null,
    billingFetchingInsightData: null,
    BillingFetchingFormData: null,
    billingFetchingFormValuesForPrefill: null,
    dataForListOfBilling: [],
  },
  reducers: {
    setbillingFetchingSuccessAction: (state, { payload }) => {
      state.billingFetchingSuccess = payload;
      state.billingFetchingError = null;
    },
    setbillingFetchingErrorAction: (state, { payload }) => {
      state.loading = null;
      state.billingFetchingError = serializeError(payload);
      state.billingFetchingSuccess = null;
    },
    setbillingFetchingInsightData: (state, { payload }) => {
      state.billingFetchingInsightData = payload;
    },
    setbillingFetchingFormData: (state, { payload }) => {
      state.BillingFetchingFormData = payload;
    },
    setBillingFetchingFormValuesForPrefill: (state, { payload }) => {
      state.billingFetchingFormValuesForPrefill = payload;
    },
    setDataForListOfBilling: (state, { payload }) => {
      state.dataForListOfBilling = payload;
    },
  },
});

export const {
  setbillingFetchingSuccessAction,
  setbillingFetchingErrorAction,
  setbillingFetchingInsightData,
  setbillingFetchingFormData,
  setBillingFetchingFormValuesForPrefill,
  setDataForListOfBilling,
} = billingFetching.actions;
export default billingFetching.reducer;
