import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useGetAccessRightsMenu } from "../../Layout/Sidebar/service";
import HttpClient from "../../api/httpClient";

export const useCreateUserToken = ({ locationToRedirect }) => {
  const navigate = useNavigate();
  const { refetch } = useGetAccessRightsMenu({ isMenusPresent: false });

  const { mutate, isLoading, isError } = useMutation(
    (payload) =>
      HttpClient("auth/login", {
        method: "POST",
        data: payload,
      }),
    {
      onSuccess: (response) => {
        if (response?.errorData?.status === 400) {
          Swal.fire({
            title: "Warning!",
            text: "Invalid Credentials",
            icon: "warning",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          });
          sessionStorage.removeItem();
          return;
        } else {
          if ([500, 501, 502, 503].includes(response?.errorData?.status * 1)) {
            Swal.fire({
              title: "Warning!",
              text: "Server Error",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "OK",
            });
            sessionStorage.removeItem();
            return;
          } else {
            const userData = {
              emailID: response?.data?.emailId,
              firstName: response?.data?.firstName,
              lastName: response?.data?.lastName,
              roleName: response?.data?.roleName,
            };

            const userDataString = JSON.stringify(userData);
            sessionStorage.setItem("userData", userDataString);
            sessionStorage.setItem("tokenID", response.data.data);
            locationToRedirect ? navigate(locationToRedirect) : navigate("/");

            refetch();
          }
        }
      },
    }
  );
  return { mutate, isLoading, isError };
};

export const useForgetPassword = () => {
  const { mutate } = useMutation((payload) => {
    return HttpClient(`users-draft/forget-password`, {
      method: "POST",
      data: { emailOrUsername: payload },
    });
  });
  return { mutate };
};
export const useUpdatePassword = () => {
  const { mutate, data } = useMutation((payload) => {
    return HttpClient(`users-draft/update-password`, {
      method: "POST",
      data: payload,
    });
  });
  return { mutate, data };
};
