import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import {
  Heading,
  NoDataDisplay,
  TableContainer,
  TableHeading,
} from "../vehicleListing/styles";
import _ from "lodash";

const FamilyTable = ({ rows, columns }) => {
  return (
    <>
      {" "}
      <TableHeading>
        <Heading>Customer Family List</Heading>
      </TableHeading>
      <TableContainer>
        {_.isEmpty(rows) ? (
          <NoDataDisplay>No Data Available</NoDataDisplay>
        ) : (
          <DataGrid
            rows={rows}
            columns={columns}
            size="small"
            fontSize={12}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
            }}
            pageSizeOptions={[5, 20]}
            sx={{
              ".MuiDataGrid-cellContent": {
                fontSize: "14px",
              },
              ".MuiDataGrid-columnSeparator": {
                display: "none",
              },
              "&.MuiDataGrid-root": {
                border: "none",
              },
              ".MuiDataGrid-columnHeaders": {
                background: "#D2F8E9",
                color: "#11555F",
                fontSize: "14px",
                fontWeight: 500,
                fontFamily: "Montserrat-Medium",
              },
              ".MuiTablePagination-root p": {
                fontSize: "14px",
              },
            }}
          />
        )}
      </TableContainer>
    </>
  );
};

export default FamilyTable;
