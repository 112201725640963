import { createSlice } from "@reduxjs/toolkit";
import { serializeError } from "../../utils";

const nomenclature = createSlice({
  name: "nomenclature",
  initialState: {
    nomenclatureError: null,
    nomenclatureSuccess: null,
  },
  reducers: {
    setNomenclatureSuccessAction: (state, { payload }) => {
      state.nomenclatureSuccess = payload;
      state.nomenclatureError = null;
    },
    setNomenclatureErrorAction: (state, { payload }) => {
      state.loading = null;
      state.nomenclatureError = serializeError(payload);
      state.nomenclatureSuccess = null;
    },
  },
});

export const { setNomenclatureSuccessAction, setNomenclatureErrorAction } =
  nomenclature.actions;
export default nomenclature.reducer;
