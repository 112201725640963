import React from "react";
import styled from "styled-components";

const AccessDenied = () => {
  return (
    <StyledContainer>
      <span>Access Denied</span>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  color: red;
  font-size: 20px;
  text-align: center;
  max-width: 100%;
  height: 95vh;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    border: 2px solid red;
    border-radius: 8px;
    width: 400px;
    text-transform: uppercase;
  }
`;

export default AccessDenied;
