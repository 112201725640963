import React from "react";
import Dropdown from "../../../components/Dropdown/Dropdown";
import useGetMasterTableData from "../../../hooks/useGetMasterTableData";
const UserDropDown = ({ slug, id, control, label, fieldSlug, isRequired }) => {
  const { data } = useGetMasterTableData([
    { masterSlug: slug, fieldSlug: fieldSlug },
  ]);

  const dropDaTA =
    data?.[0]?.data?.data?.[0]?.data &&
    data?.[0]?.data?.data?.[0]?.data?.map((item) => {
      return {
        value: item.optionKey,
        label: item.optionValue,
      };
    });
  return (
    <>
      <Dropdown
        id={id}
        control={control}
        selectObj={dropDaTA}
        label={label}
        maxMenuHeight={200}
        isRequired={isRequired}
      />
    </>
  );
};

export default UserDropDown;
