import { createSlice } from "@reduxjs/toolkit";
import { serializeError } from "../../../utils";

const AddNewMasterSlice = createSlice({
  name: "addNewMasterSlice",
  initialState: {
    addNewMasterError: null,
    addNewMasterSuccess: null,
    fieldsForMaster: [],
    activeTab: 0,
  },
  reducers: {
    setAddNewMasterSuccessAction: (state, { payload }) => {
      state.addNewMasterSuccess = payload;
      state.addNewMasterError = null;
    },
    setAddNewMasterErrorAction: (state, { payload }) => {
      state.addNewMasterError = serializeError(payload);
      state.addNewMasterSuccess = null;
    },
    setFieldsForMaster: (state, { payload }) => {
      state.fieldsForMaster = [...state.fieldsForMaster, payload];
    },
    removeFieldFromMaster: (state, action) => {
      state.fieldsForMaster = state.fieldsForMaster.filter(
        (item, idx) => idx !== action.payload
      );
    },
    removeAllFieldsFromMaster: (state, action) => {
      state.fieldsForMaster = [];
    },
    setActiveTab: (state, { payload }) => {
      state.activeTab = payload;
    },
  },
});

export const {
  setAddNewMasterSuccessAction,
  setAddNewMasterErrorAction,
  setFieldsForMaster,
  removeFieldFromMaster,
  removeAllFieldsFromMaster,
  setActiveTab
} = AddNewMasterSlice.actions;
export default AddNewMasterSlice.reducer;
