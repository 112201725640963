import { Previews } from "./filepicker";

const BulkUpload = ({ handleClose }) => {
  return (
    <Previews
      themeType={"theme1"}
      handleClose={handleClose}
    />
  );
};
export default BulkUpload;
