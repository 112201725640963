import styled from "styled-components"

function ViewDescription({ label, text }) {
    return (
        <ViewDescriptionStyled>
            <p>{label ? label : ""}</p>
            <p>{text ? text : ""}</p>
        </ViewDescriptionStyled>
    )
}

export default ViewDescription

const ViewDescriptionStyled = styled.div`
p {
    margin: 0;
}
p:first-child {
    font-family: Montserrat-Medium;
    color: rgb(17, 85, 95);
    line-height: 18px;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 5px;
}
p:last-child {
    font-weight: 700;
    font-size: 14px;
}
`