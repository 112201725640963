import { yupResolver } from "@hookform/resolvers/yup";
import _, { sample, throttle } from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import useGetLOBMaster from "../../hooks/useGetLOBMaster";
import useGetMasterTableData from "../../hooks/useGetMasterTableData";
import useGetTransactionFieldData from "../../hooks/useGetTransactionFieldData";
import { master } from "../broker-configuration/broker-config.slice";
import {
  setBrokerageErrorAction,
  setBrokerageSuccessAction,
} from "./Brokerage.slice";
import {
  useBrokerageData,
  useCreateBrokerage,
  useDeleteBrokerageData,
  useDownloadMaster,
} from "./service";

function useBrokerage() {
  const steps = [
    "Basic Details",
    "Standard Brokerage",
    "Conditional Brokerage",
    "View & Edit",
  ];
  const staticFields = [
    { masterSlug: "master_company", fieldSlug: "master_company" },
    {
      masterSlug: "transaction_type_master_by_id",
      fieldSlug: "transaction_type_master_by_id",
    },
  ];

  const [masterTableFields, setMasterTableFields] = useState([
    { masterSlug: "master_company", fieldSlug: "master_company" },
    {
      masterSlug: "transaction_type_master_by_id",
      fieldSlug: "transaction_type_master_by_id",
    },
  ]);
  const [items, setItems] = useState([]);
  const [resetIndex, setResetIndex] = useState(null);
  const [files, setFiles] = useState({});
  const [fieldSlugForExcel, setFieldSlugForExcel] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [rulesLength, setRulesLength] = useState({ single: [1], bundle: [1] });
  const [cardInFocus, setCardInFocus] = useState({ single: 1, bundle: 1 });
  const [mergedSchema, setMergedSchema] = useState([]);
  const [bundleValues, setBundleValues] = useState();

  const { brokerageError, brokerageSuccess } = useSelector(
    (state) => state.brokerageConfig
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const yupValidate = yup.object({
    lobId: yup.mixed().required("LOB is required"),
    transactionType: yup.mixed().required("Business Type is required"),
    effective_start_date: yup
      .date()
      .typeError("Invalid Date!")
      .required("Start date is required"),
    effective_end_date: yup
      .date()
      .typeError("Invalid Date!")
      .required("End date is required")
      .min(yup.ref("effective_start_date"), "End Date must be greater"),
    icType: yup.mixed().required("Insurance Company is required"),
  });

  const {
    register,
    control,
    watch,
    setValue,
    handleSubmit,
    getValues,
    trigger,
    unregister,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(mergedSchema),
    mode: "all",
    reValidateMode: "all",
  });

  const handleFocus = ({ focus, type }) => {
    if (type === "single") {
      setCardInFocus((cardInFocus) => ({ ...cardInFocus, single: focus }));
    } else {
      setCardInFocus((cardInFocus) => ({ ...cardInFocus, bundle: focus }));
    }
  };

  const {
    lobId,
    transactionType,
    effective_start_date,
    effective_end_date,
    icType,
    region,
    baseRule,
    premiumBearableFields,
    additional_filters,
    conditonalBrokerage,
    premiumAffectFields,
    multipleBrokerage,
    operator,
    selectedBrokerageType,
    multipleOperators,
    selectedBrokerage,
    single,
    bundle,
  } = watch();

  const validationSchema = yup.object().shape({
    premiumBearableFields:
      activeStep === 1 &&
      yup.lazy((value) => {
        if (Array.isArray(value)) {
          return yup.array().min(1, "Select a field");
        } else {
          return yup.mixed().required("Select a field");
        }
      }),
    baseRule: yup.array().of(
      yup.object().shape({
        amount: yup.string().required("Value is required"),
      })
    ),
    // multipleBrokerage: yup.array().of(
    //   yup.lazy(() => {
    //     return yup.object().shape({
    //       conditionalPremiumBearableFields: yup
    //         .array()
    //         .min(1, "Select a field"),
    //     });
    //   })
    // ),
    selectedBrokerage: yup.array().of(
      yup.lazy((keys) => {
        const allKeys = keys && Object.keys(keys);
        const shape = {};

        if (Array.isArray(allKeys)) {
          allKeys.forEach((key) => {
            shape[key] = yup.object().shape({
              amount: yup.string().required("Amount is required"),
            });
          });
        }

        return yup.object().shape(shape);
      })
    ),
  });

  useEffect(() => {
    if (premiumBearableFields) {
      premiumBearableFields?.forEach((value, index) => {
        //For some reasons state was not getting updated on value select
        setValue(
          `baseRule[${index}].brokerageType`,
          baseRule?.[index]?.brokerageType === "FLAT" ? "FLAT" : "VARIABLE"
        );
        setValue(`baseRule[${index}].transactionFieldMasterId`, value?.id);
        setValue(
          `baseRule[${index}].amount`,
          baseRule?.[index]?.amount ? baseRule?.[index]?.amount : ""
        );
      });
    }
    return () => {
      // baserule reset
      unregister("baseRule");
      setValue("baseRule", []);
    };
  }, [premiumBearableFields, activeStep]);

  useEffect(() => {
    const mergedSchema = validationSchema
      ? yupValidate.concat(validationSchema)
      : yupValidate;

    setMergedSchema(mergedSchema);
  }, [JSON.stringify(validationSchema)]);

  const { data: lobData } = useGetLOBMaster();

  const { data: brokerageData } = useBrokerageData();

  const brokerageLobWiseId = brokerageData?.data?.data?.map(
    (value) => value.lobId
  );

  const isLobPresent = brokerageLobWiseId?.find(
    (value) => lobId?.lobId === value
  );

  useEffect(() => {
    if (lobId?.lobId) {
      setMasterTableFields((prevValue) => {
        return prevValue?.map((value) => {
          return {
            ...value,
            lobId: lobId?.lobId,
          };
        });
      });
    }
  }, [lobId?.lobId]);

  const data = useGetMasterTableData(
    masterTableFields?.[0]?.lobId ? masterTableFields : []
  );

  const { mutate } = useCreateBrokerage();

  const { mutate: deleteBrokerageData } = useDeleteBrokerageData();

  const { data: excelData, mutate: downloadMaster } = useDownloadMaster();

  const { data: transactionFieldData, isFetching: isTransactionFieldFetching } =
    useGetTransactionFieldData(lobId?.lobId);

  const regionsObj =
    transactionFieldData?.data?.data &&
    Object.values(transactionFieldData?.data?.data)
      .flat()
      .filter((value) => value.is_master_api_available === "Y")
      .map((value) => ({
        value: value.field_slug,
        label: value.field_name,
        id: value.transaction_field_master_id,
        slug: value.master_slug,
      }));

  const premiumBearingObj =
    transactionFieldData?.data?.data &&
    Object.values(transactionFieldData?.data?.data)
      .flat()
      .filter((value) => value.is_premium_bearing === "Y")
      .map((value) => ({
        value: value.field_slug,
        label: value.field_name,
        id: value.transaction_field_master_id,
      }));

  useEffect(() => {
    if (excelData) {
      const blob = new Blob([excelData.data], {
        type: "application/octet-stream",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Master.xlsx";
      a.click();
      URL.revokeObjectURL(url);
      a.remove();
      Swal.fire({
        text: "Excel Successfully Downloaded.",
        icon: "success",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        focusCancel: false,
        scrollbarPadding: false,
      });
    }
  }, [excelData]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "111%",
      border: "0.25px solid rgb(169, 167, 167)",
      fontSize: "12px",
      minHeight: 20,
      boxShadow: state.isFocused ? "0 0 0 0.2rem transparent" : "none",
      backgroundColor: "rgb(247, 248, 250)",
      height: "32px",
      marginTop: "-1px",
      padding: "0 5px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      border: 0,
      padding: 0,
    }),
    option: (styles, { isFocused, isSelected, isDisabled }) => ({
      ...styles,
      fontWeight: 600,
      letterSpacing: "0.5px",
      fontSize: "14px",
      fontFamily: "Montserrat-SemiBold",
      borderBottom: ".25px solid",
      backgroundColor: isSelected ? "#D1F8E8" : isFocused ? "#fff" : "#fff",
      color: isDisabled ? "#e4e4e4" : "#2BB9A8",
      cursor: isDisabled ? "not-allowed" : "normal",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: 0,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
    }),
    multiValue: (provided) => ({
      ...provided,
      padding: "0 5px",
      color: "#2BB9A8",
      fontFamily: "Montserrat-Medium",
      borderRadius: "5px",
      marginLeft: "5px",
      background: " #22bdaa30",
      fontWeight: "600",
    }),
  };

  const LOB_Obj = lobData?.data?.data?.map((value) => {
    return {
      value: value.lob,
      label: value.lob,
      lobId: value.lob_id,
    };
  });

  const companyData = data?.data[0]?.data?.data?.[0]?.data?.map((value) => {
    return {
      value: value.optionValue,
      label: value.optionValue,
      id: value.optionKey,
    };
  });

  const transactionData = data?.data[1]?.data?.data?.[0]?.data?.map(
    (value, index) => {
      return {
        value: value.optionKey,
        label: value.optionValue,
        id: index + 1,
      };
    }
  );

  const stateData =
    data &&
    data?.data[2]?.data?.data?.map((value) => {
      return {
        value: value.optionValue,
        label: value.optionValue,
        id: value.optionKey,
      };
    });

  useEffect(() => {
    if (region) {
      setItems(region);
    }
  }, [region?.length]);

  useEffect(() => {
    if (!conditonalBrokerage) {
      setValue("region", []);
    }
  }, [conditonalBrokerage]);

  // useEffect(() => {
  //     if (!isNull(resetIndex)) {
  //         setValue(`baseRule[${resetIndex}].amount`, "")
  //     }
  // }, [baseRule?.[resetIndex]?.brokerageType])

  useEffect(() => {
    if (additional_filters) {
      const filtersArray = additional_filters?.map((value) => value.fieldSlug);
      const sortedFiles = _.sortBy(files, (file) => {
        const key = Object.keys(file)[0];
        const filterIndex = filtersArray.indexOf(key);

        return filterIndex !== -1 ? filterIndex : Infinity;
      });

      setFiles(sortedFiles);

      additional_filters?.forEach((item, index) => {
        setValue(
          `additional_filters[${index}].data`,
          files?.[index]?.[item?.fieldSlug]?.[0].base64String
        );
      });
    }
  }, [JSON.stringify(additional_filters), JSON.stringify(files)]);

  useEffect(() => {
    if (additional_filters) {
      items.forEach((value, index) => {
        setValue(
          `additional_filters[${index}].transactionFieldMasterId`,
          value.id
        );
        setValue(`additional_filters[${index}].priority`, index + 1);
        setValue(`additional_filters[${index}].fieldSlug`, value.value);
      });
    }
  }, [items]);

  let brokeragePremiumAffectFields = [];

  if (multipleBrokerage) {
    const brokerageDropdownValues = multipleBrokerage.map(
      (value) => value.conditionalPremiumBearableFields
    );
    brokeragePremiumAffectFields = brokerageDropdownValues.map(
      (dropdownObject) => {
        return dropdownObject;
      }
    );
  }

  const selectedBrokerageAffectedFields = [];

  let allSingleCheckedValues = [];
  var allBundleCheckedValues = [];

  if (single) {
    const checkboxValues = single.map((value) => value.premiumAffectFields);
    allSingleCheckedValues = checkboxValues.map((object) => {
      const tempArr = [];
      for (let key in object) {
        if (object[key].value) {
          tempArr.push({
            label: object[key].label,
            slug: key,
            id: object[key].id,
            masterSlug: object[key].masterSlug,
          });
        }
      }
      return tempArr;
    });
  }

  if (bundle) {
    const checkboxValues = bundle.map((value) => value.premiumAffectFields);
    allBundleCheckedValues = checkboxValues.map((object) => {
      const tempArr = [];
      for (let key in object) {
        if (object[key].value) {
          tempArr.push({
            label: object[key].label,
            slug: key,
            id: object[key].id,
            masterSlug: object[key].masterSlug,
          });
        }
      }
      return tempArr;
    });
  }

  if (single) {
    for (let key in single?.[0]?.premiumAffectFields) {
      if (single[0].premiumAffectFields[key].value) {
        selectedBrokerageAffectedFields.push({
          slug: key,
          label: single[0].premiumAffectFields[key].label,
          id: single[0].premiumAffectFields[key].id,
          masterSlug: single[0].premiumAffectFields[key].masterSlug,
        });
      }
    }
  }

  useEffect(() => {
    setBundleValues(allBundleCheckedValues);
  }, [JSON.stringify(allBundleCheckedValues)]);

  useEffect(() => {
    // logic for checking if user has selected the file or not before the modal is close

    if (selectedBrokerageType === "Single") {
      const checkboxValues = single?.[cardInFocus.single - 1];
      const filesWithData =
        files?.single?.[cardInFocus.single - 1] &&
        Object.keys(files?.single?.[cardInFocus.single - 1]);

      if (checkboxValues?.premiumAffectFields && filesWithData) {
        for (let key in checkboxValues.premiumAffectFields) {
          const isChecked = filesWithData.includes(key);
          setValue(
            `single.${[cardInFocus?.single - 1]}.premiumAffectFields.${[
              key,
            ]}.value`,
            isChecked
          );
        }
      } else if (checkboxValues?.premiumAffectFields) {
        for (let key in checkboxValues.premiumAffectFields) {
          setValue(
            `single.${[cardInFocus?.single - 1]}.premiumAffectFields.${[
              key,
            ]}.value`,
            false
          );
        }
      }
    }

    if (selectedBrokerageType === "Bundled") {
      const checkboxValues = bundle?.[cardInFocus.bundle - 1];
      const filesWithData =
        files?.bundle?.[cardInFocus.bundle - 1] &&
        Object.keys(files?.bundle?.[cardInFocus.bundle - 1]);

      if (checkboxValues?.premiumAffectFields && filesWithData) {
        for (let key in checkboxValues.premiumAffectFields) {
          const isChecked = filesWithData.includes(key);
          setValue(
            `bundle.${
              cardInFocus?.bundle - 1
            }.premiumAffectFields.${key}.value`,
            isChecked
          );
        }
      } else if (checkboxValues?.premiumAffectFields) {
        for (let key in checkboxValues.premiumAffectFields) {
          setValue(
            `bundle.${
              cardInFocus?.bundle - 1
            }.premiumAffectFields.${key}.value`,
            false
          );
        }
      }
    }
  }, [JSON.stringify(files), JSON.stringify(single), JSON.stringify(bundle)]);

  useEffect(() => {
    if (brokerageError) {
      Swal.fire({
        text: brokerageError,
        icon: "warning",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        allowOutsideClick: false,
        focusCancel: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(setBrokerageErrorAction(null));
        }
      });
    }
  }, [brokerageError]);

  useEffect(() => {
    if (brokerageSuccess) {
      Swal.fire({
        text: brokerageSuccess,
        icon: "success",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        focusCancel: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(setBrokerageSuccessAction(null));
          navigate("/brokerage-listing");
        }
      });
    }
  }, [brokerageSuccess]);

  useEffect(() => {
    setValue(
      `selectedBrokerageAffectedFields`,
      selectedBrokerageAffectedFields
    );
  }, [selectedBrokerageAffectedFields.length]);

  useEffect(() => {
    //generates combined response for brokeragetype="single"
    if (allSingleCheckedValues.length) {
      const mappedData = allSingleCheckedValues
        ?.map((value, index) => {
          if (!value.length) {
            return null;
          }
          return {
            ruleType: "SINGLE",
            transactionFieldMasterId: value[0]?.id,
            priority: index + 1,
            fieldSlug: value[0]?.slug,
            data: files?.single?.[index]?.[value[0]?.slug]?.[0]?.base64String,
          };
        })
        .filter((value) => value !== null);

      setValue("singleMappedData", mappedData);
    }
  }, [JSON.stringify(files), JSON.stringify(allSingleCheckedValues)]);

  useEffect(() => {
    if (selectedBrokerageType === "Bundled") {
      if (brokeragePremiumAffectFields) {
        const brokerageMappedData = brokeragePremiumAffectFields?.map(
          (value, index) => {
            const nestedMap = value?.map((value) => {
              return {
                transactionFieldMasterId: value.id,
                brokerageType: selectedBrokerage?.[index]?.[value.value]
                  ?.brokerageType
                  ? selectedBrokerage?.[index]?.[value.value]?.brokerageType
                  : "VARIABLE",
                amount: selectedBrokerage?.[index]?.[value.value].amount,
                fieldSlug: value.value,
              };
            });
            return nestedMap;
          }
        );
        setValue("brokerageMappedData", brokerageMappedData);
      }
    }
  }, [
    JSON.stringify(brokeragePremiumAffectFields),
    JSON.stringify(selectedBrokerage),
  ]);

  const isDisabled = selectedBrokerageAffectedFields.length === 0;

  const handleFieldSlugForExcel = (
    e,
    slug,
    selectedCardIndex,
    selectedBrokerageType
  ) => {
    if (e.target.tagName.toLowerCase() === "div") {
      return;
    }
    setFieldSlugForExcel(slug);

    //if condn. to allow zeros
    if (selectedCardIndex !== undefined) {
      if (selectedBrokerageType === "Single") {
        for (let key in files?.single?.[selectedCardIndex]) {
          if (files?.single?.[selectedCardIndex].hasOwnProperty(key)) {
            if (slug.value === key) {
              delete files?.single?.[selectedCardIndex]?.[key];
            }
          }
        }
      } else {
        for (let key in files?.bundle?.[selectedCardIndex]) {
          if (files?.bundle?.[selectedCardIndex].hasOwnProperty(key)) {
            if (slug.value === key) {
              delete files?.bundle?.[selectedCardIndex]?.[key];
            }
          }
        }

        for (let key in multipleOperators?.[selectedCardIndex]?.operator) {
          if (
            multipleOperators?.[selectedCardIndex]?.operator.hasOwnProperty(key)
          ) {
            if (slug.value === key) {
              delete multipleOperators?.[selectedCardIndex]?.operator[key];
            }
          }
        }
      }
    }
  };

  const resetRespectiveAmountField = (index) => {
    setResetIndex(index);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const updatedItems = [...items];
    const [reorderedItem] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, reorderedItem);

    setItems(updatedItems);
  };

  const handleRemove = ({ type, id }) => {
    if (type === "BrokeragePremium") {
      const newFields = premiumBearableFields.filter(
        (value) => value.id !== id
      );
      setValue("premiumBearableFields", newFields);
      return;
    }

    const newFields = region.filter((value) => value.id !== id);
    setValue("region", newFields);
  };

  const handleRuleDelete = ({ deleteCardIndex, selectedBrokerageType }) => {
    function removeAndAdjustFiles(obj, keyToRemove) {
      //fn for removing files and adjust their positions accordingly because files has {} structure
      if (obj.hasOwnProperty(keyToRemove)) {
        delete obj[keyToRemove];

        const adjustedObj = {};
        let newIndex = 0;

        for (const key in obj) {
          adjustedObj[newIndex++] = obj[key];
        }
        return adjustedObj;
      }
      return obj;
    }

    if (selectedBrokerageType === "Single") {
      const filteredRules = single?.filter(
        (_, index) => index !== deleteCardIndex
      );

      const newRulesLengthArray = [...rulesLength.single];

      const newFilesArray = removeAndAdjustFiles(
        { ...files.single },
        deleteCardIndex
      );

      unregister(`single.${deleteCardIndex}`);
      setValue(`single`, filteredRules);

      setFiles((files) => ({ ...files, single: newFilesArray }));

      newRulesLengthArray.pop();
      setRulesLength((rulesLength) =>
        rulesLength.single.length >= 2
          ? { ...rulesLength, single: newRulesLengthArray }
          : { ...rulesLength }
      );
    } else {
      const filteredRules = bundle?.filter(
        (_, index) => index !== deleteCardIndex
      );
      const filteredBrokerageAffectFields = multipleBrokerage?.filter(
        (_, index) => index !== deleteCardIndex
      );
      const filteredOperators = multipleOperators?.filter(
        (_, index) => index !== deleteCardIndex
      );
      const newRulesLengthArray = [...rulesLength.bundle];

      const newFilesArray = removeAndAdjustFiles(
        { ...files.bundle },
        deleteCardIndex
      );

      unregister(`bundle.${deleteCardIndex}`);
      setValue(`bundle`, filteredRules);

      unregister(`multipleBrokerage.${deleteCardIndex}`);
      setValue(`multipleBrokerage`, filteredBrokerageAffectFields);

      unregister(`multipleOperators.${deleteCardIndex}`);
      setValue(`multipleOperators`, filteredOperators);

      setFiles((files) => ({ ...files, bundle: newFilesArray }));

      newRulesLengthArray.pop();
      setRulesLength((rulesLength) =>
        rulesLength.bundle.length >= 2
          ? { ...rulesLength, bundle: newRulesLengthArray }
          : { ...rulesLength }
      );
    }
  };

  useEffect(() => {
    //generates combined response for brokerage type bundled
    if (selectedBrokerageType === "Bundled") {
      if (
        selectedBrokerageAffectedFields &&
        files &&
        allBundleCheckedValues.length
      ) {
        const mappedData = allBundleCheckedValues
          ?.map((value, index) => {
            if (!value.length) {
              return null;
            }
            const nestedMap = value?.map((value) => {
              return {
                operator:
                  multipleOperators?.[index]?.operator[value?.slug]?.value,
                transactionFieldMasterId: value?.id,
                priority: index,
                fieldSlug: value?.slug,
                data: files?.bundle?.[index]?.[value?.slug]?.[0]?.base64String,
              };
            });
            return nestedMap;
          })
          .filter((value) => value !== null);

        setValue("bundledMappedData", mappedData);
      }
    }
  }, [files, allBundleCheckedValues.length, JSON.stringify(multipleOperators)]);

  const cloneButton = (index) => {
    // //index === position of clone button in card
    // setRulesLength(rulesLength => {
    //     return [...rulesLength, rulesLength[rulesLength.length - 1] + 1]
    // })
    // const value = getValues();
    // setValue(`sampleObj.${[rulesLength.length]}`, value.sampleObj[index - 1])
  };

  const handleSave = async (
    selectedBrokerageType,
    selectedBrokerage,
    cardInFocus
  ) => {
    let validationPassed = await trigger();
    if (!validationPassed) {
      return;
    }
    if (selectedBrokerageType === "Single") {
      setRulesLength((rulesLength) => {
        return {
          ...rulesLength,
          single: [
            ...rulesLength.single,
            rulesLength.single[rulesLength.single.length - 1] + 1,
          ],
        };
      });

      const value = getValues();

      let newCheckboxObj = {};

      for (let key in value.single[0].premiumAffectFields) {
        if (value.single[0].premiumAffectFields.hasOwnProperty(key)) {
          newCheckboxObj[key] = {
            label: value.single[0].premiumAffectFields[key].label,
            id: value.single[0].premiumAffectFields[key].id,
            value: false,
          };
        }
      }
      setValue(`single.${[rulesLength.single.length]}`, {
        premiumAffectFields: newCheckboxObj,
      });
    } else {
      // if (selectedBrokerage) {
      //   const errorsArray = []
      //   selectedBrokerage.forEach((value) => {
      //     let errorsObject = {}
      //     for (let key in value) {
      //       if (value[key].amount) {
      //         errorsObject = { ...errorsObject, [key]: false }
      //       } else {
      //         errorsObject = { ...errorsObject, [key]: true }
      //       }
      //     }
      //     errorsArray.push(errorsObject)
      //   })
      //   if (errorsArray?.[cardInFocus.bundle - 1] && Object.values(errorsArray?.[cardInFocus.bundle - 1]).filter(Boolean).length) {
      //     setConditionalBrokerageErrors(errorsArray);
      //     return
      //   } else {
      //     setConditionalBrokerageErrors(errorsArray);
      //   }
      // }

      setRulesLength((rulesLength) => {
        return {
          ...rulesLength,
          bundle: [
            ...rulesLength.bundle,
            rulesLength.bundle[rulesLength.bundle.length - 1] + 1,
          ],
        };
      });

      const value = getValues();

      let newCheckboxObj = {};

      for (let key in value.bundle[0].premiumAffectFields) {
        if (value.bundle[0].premiumAffectFields.hasOwnProperty(key)) {
          newCheckboxObj[key] = {
            label: value.bundle[0].premiumAffectFields[key].label,
            id: value.bundle[0].premiumAffectFields[key].id,
            value: false,
          };
        }
      }
      setValue(`bundle.${[rulesLength.bundle.length]}`, {
        premiumAffectFields: newCheckboxObj,
      });
      setValue(
        `multipleBrokerage[${rulesLength.bundle.length}].conditionalPremiumBearableFields`,
        []
      );
    }
  };

  const onSubmit = (value) => {
    const baseRule = value?.baseRule?.map((value) => {
      return { ...value, amount: parseInt(value.amount) };
    });

    const additionalFiltersRequestBody = value?.bundledMappedData?.map(
      (item, index) => {
        return {
          ruleType: "BUNDLED",
          priority: index + 1,
          brokerage: value?.brokerageMappedData?.[index],
          fieldData: item,
        };
      }
    );

    const bundledPayload = {
      lobId: value.lobId?.lobId,
      companyId: value.icType?.id,
      transactionTypeId: value.transactionType?.value,
      effectiveStartDate: moment(value.effective_start_date).format(
        "YYYY-MM-DD"
      ),
      effectiveEndDate: moment(value.effective_end_date).format("YYYY-MM-DD"),
      paymentTerm: "ONETIME",
      baseRule: baseRule,
      additionalFilters: value.singleMappedData
        ? additionalFiltersRequestBody
          ? [...value.singleMappedData, ...additionalFiltersRequestBody]
          : [...value.singleMappedData]
        : undefined,
    };

    mutate(bundledPayload);
  };

  const throttledSubmit = useMemo(
    () => throttle(onSubmit, 5000, { trailing: false }),
    []
  );

  return {
    register,
    control,
    setValue,
    lobId,
    transactionType,
    effective_start_date,
    effective_end_date,
    icType,
    region,
    premiumBearableFields,
    baseRule,
    items,
    downloadMaster,
    files,
    setFiles,
    conditonalBrokerage,
    resetRespectiveAmountField,
    handleDragEnd,
    handleRemove,
    handleSubmit,
    throttledSubmit,
    customStyles,
    LOB_Obj,
    regionsObj,
    isLobPresent,
    premiumBearingObj,
    transactionData,
    companyData,
    stateData,
    fieldSlugForExcel,
    handleFieldSlugForExcel,
    premiumAffectFields,
    selectedBrokerageAffectedFields,
    isDisabled,
    selectedBrokerageType,
    cloneButton,
    rulesLength,
    cardInFocus,
    handleFocus,
    steps,
    activeStep,
    setActiveStep,
    trigger,
    errors,
    handleSave,
    handleRuleDelete,
    operator,
    multipleBrokerage,
    multipleOperators,
    selectedBrokerage,
    single,
    bundle,
    allSingleCheckedValues,
    allBundleCheckedValues,
    isTransactionFieldFetching,
    brokerageData,
    deleteBrokerageData,
  };
}

export default useBrokerage;

export var transactionFieldData = {
  data: {
    status: true,
    data: {
      "Policy Details": [
        {
          master_slug: "master_company",
          is_searchable: "Y",
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 141,
          created_at: "2023-09-26T15:26:37.133+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "Y",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 1,
          field_name: "Insurance Company Name",
          field_slug: "insuranceCompanyname",
          updated_at: "2023-09-26T15:26:37.133+00:00",
          field_sequence: 1,
          premium_bearing: "N",
          validation_group: null,
          field_type: "DROPDOWN",
          status: "Y",
          is_master_api_available: "Y",
        },
        {
          master_slug: "master_city",
          is_searchable: "Y",
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 143,
          created_at: "2023-09-26T16:19:52.123+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 1,
          field_name: "Insurer City",
          field_slug: "insurerCity",
          updated_at: "2023-09-26T16:19:52.123+00:00",
          field_sequence: 2,
          premium_bearing: "N",
          validation_group: null,
          field_type: "DROPDOWN",
          status: "Y",
          is_master_api_available: "Y",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 148,
          created_at: "2023-09-26T16:28:12.340+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 1,
          field_name: "Insurer Branch",
          field_slug: "insurerBranch",
          updated_at: "2023-09-26T16:28:12.340+00:00",
          field_sequence: 3,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 149,
          created_at: "2023-09-26T16:28:42.087+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 1,
          field_name: "Book Date",
          field_slug: "bookDate",
          updated_at: "2023-09-26T16:28:42.087+00:00",
          field_sequence: 4,
          premium_bearing: "N",
          validation_group: null,
          field_type: "DATE",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 152,
          created_at: "2023-09-26T16:29:35.065+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 1,
          field_name: "Policy No.",
          field_slug: "policyNo",
          updated_at: "2023-09-26T16:29:35.065+00:00",
          field_sequence: 5,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 154,
          created_at: "2023-09-26T16:29:56.120+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 1,
          field_name: "Policy Start Date",
          field_slug: "startDate",
          updated_at: "2023-09-26T16:29:56.120+00:00",
          field_sequence: 6,
          premium_bearing: "N",
          validation_group: null,
          field_type: "DATE",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 155,
          created_at: "2023-09-26T16:30:29.110+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 1,
          field_name: "Poilicy Expiry Date",
          field_slug: "expiryDate",
          updated_at: "2023-09-26T16:30:29.110+00:00",
          field_sequence: 7,
          premium_bearing: "N",
          validation_group: null,
          field_type: "DATE",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 157,
          created_at: "2023-09-26T16:30:46.402+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 1,
          field_name: "Days",
          field_slug: "days",
          updated_at: "2023-09-26T16:30:46.402+00:00",
          field_sequence: 8,
          premium_bearing: "N",
          validation_group: null,
          field_type: "INT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 159,
          created_at: "2023-09-26T16:31:00.617+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 1,
          field_name: "Is Renewable",
          field_slug: "isRenewable",
          updated_at: "2023-09-26T16:31:00.617+00:00",
          field_sequence: 9,
          premium_bearing: "N",
          validation_group: null,
          field_type: "CHAR",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "tranasction_type_master",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 162,
          created_at: "2023-09-26T16:31:35.445+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 1,
          field_name: "Policy Type",
          field_slug: "policyType",
          updated_at: "2023-09-26T16:31:35.445+00:00",
          field_sequence: 11,
          premium_bearing: "N",
          validation_group: null,
          field_type: "DROPDOWN",
          status: "Y",
          is_master_api_available: "Y",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 164,
          created_at: "2023-09-26T16:31:49.299+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 1,
          field_name: "Product Name",
          field_slug: "productName",
          updated_at: "2023-09-26T16:31:49.299+00:00",
          field_sequence: 12,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 166,
          created_at: "2023-09-26T16:32:04.326+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 1,
          field_name: "Previous Policy No.",
          field_slug: "previousPolicyNo",
          updated_at: "2023-09-26T16:32:04.326+00:00",
          field_sequence: 13,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 169,
          created_at: "2023-09-26T16:32:18.695+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 1,
          field_name: "Hypothecation",
          field_slug: "hypothecation",
          updated_at: "2023-09-26T16:32:18.695+00:00",
          field_sequence: 14,
          premium_bearing: "N",
          validation_group: null,
          field_type: "CHAR",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 171,
          created_at: "2023-09-26T16:32:38.004+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 1,
          field_name: "TPA name",
          field_slug: "tpaName",
          updated_at: "2023-09-26T16:32:38.004+00:00",
          field_sequence: 15,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 173,
          created_at: "2023-09-26T16:32:54.021+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 1,
          field_name: "TPA Branch",
          field_slug: "tpaBranch",
          updated_at: "2023-09-26T16:32:54.021+00:00",
          field_sequence: 16,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 178,
          created_at: "2023-09-26T16:33:14.591+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 1,
          field_name: "TPA %",
          field_slug: "tpa",
          updated_at: "2023-09-26T16:33:14.591+00:00",
          field_sequence: 17,
          premium_bearing: "N",
          validation_group: null,
          field_type: "DOUBLE",
          status: "Y",
          is_master_api_available: "N",
        },
      ],
      "Proposer Details": [
        {
          master_slug: "salutation_master",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 180,
          created_at: "2023-09-26T16:33:28.717+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 2,
          field_name: "Title",
          field_slug: "title",
          updated_at: "2023-09-26T16:33:28.717+00:00",
          field_sequence: 18,
          premium_bearing: "N",
          validation_group: null,
          field_type: "DROPDOWN",
          status: "Y",
          is_master_api_available: "Y",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 183,
          created_at: "2023-09-26T16:34:58.247+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "Y",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 2,
          field_name: "First Name",
          field_slug: "firstName",
          updated_at: "2023-09-26T16:34:58.247+00:00",
          field_sequence: 19,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 186,
          created_at: "2023-09-26T16:36:37.194+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 2,
          field_name: "Middle Name",
          field_slug: "middleName",
          updated_at: "2023-09-26T16:36:37.194+00:00",
          field_sequence: 20,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 190,
          created_at: "2023-09-26T16:36:54.431+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 2,
          field_name: "Last Name",
          field_slug: "lastName",
          updated_at: "2023-09-26T16:36:54.431+00:00",
          field_sequence: 21,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "gender_master",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 196,
          created_at: "2023-09-26T16:37:23.058+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 2,
          field_name: "Gender",
          field_slug: "gender",
          updated_at: "2023-09-26T16:37:23.058+00:00",
          field_sequence: 22,
          premium_bearing: "N",
          validation_group: null,
          field_type: "DROPDOWN",
          status: "Y",
          is_master_api_available: "Y",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 199,
          created_at: "2023-09-26T16:37:38.163+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 2,
          field_name: "DOB",
          field_slug: "dob",
          updated_at: "2023-09-26T16:37:38.163+00:00",
          field_sequence: 23,
          premium_bearing: "N",
          validation_group: null,
          field_type: "DATE",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 201,
          created_at: "2023-09-26T16:38:01.604+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 2,
          field_name: "Mobile No.",
          field_slug: "mobileNo",
          updated_at: "2023-09-26T16:38:01.604+00:00",
          field_sequence: 24,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 203,
          created_at: "2023-09-26T16:38:17.326+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 2,
          field_name: "Alternate Mobile No.",
          field_slug: "alternateMobileno",
          updated_at: "2023-09-26T16:38:17.326+00:00",
          field_sequence: 25,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 205,
          created_at: "2023-09-26T16:38:31.764+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 2,
          field_name: "Email ID",
          field_slug: "emailId",
          updated_at: "2023-09-26T16:38:31.764+00:00",
          field_sequence: 26,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 207,
          created_at: "2023-09-26T16:38:46.735+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "Y",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 2,
          field_name: "Aadhar Card No.",
          field_slug: "aadharCardno",
          updated_at: "2023-09-26T16:38:46.735+00:00",
          field_sequence: 27,
          premium_bearing: "N",
          validation_group: "ADHAAR",
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 214,
          created_at: "2023-09-26T16:39:02.160+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "Y",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 2,
          field_name: "Pan Card No.",
          field_slug: "panCardno",
          updated_at: "2023-09-26T16:39:02.160+00:00",
          field_sequence: 28,
          premium_bearing: "N",
          validation_group: "PAN",
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 216,
          created_at: "2023-09-26T16:39:21.375+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 2,
          field_name: "Address Line 1",
          field_slug: "addressLine",
          updated_at: "2023-09-26T16:39:21.375+00:00",
          field_sequence: 29,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 219,
          created_at: "2023-09-26T16:39:36.843+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 2,
          field_name: "Pin Code 1",
          field_slug: "pinCode",
          updated_at: "2023-09-26T16:39:36.843+00:00",
          field_sequence: 30,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "master_state",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 222,
          created_at: "2023-09-26T16:39:50.929+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 2,
          field_name: "State 1",
          field_slug: "state",
          updated_at: "2023-09-26T16:39:50.929+00:00",
          field_sequence: 31,
          premium_bearing: "N",
          validation_group: null,
          field_type: "DROPDOWN",
          status: "Y",
          is_master_api_available: "Y",
        },
        {
          master_slug: "master_city",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 227,
          created_at: "2023-09-26T16:40:05.422+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 2,
          field_name: "City 1",
          field_slug: "city",
          updated_at: "2023-09-26T16:40:05.422+00:00",
          field_sequence: 32,
          premium_bearing: "N",
          validation_group: null,
          field_type: "DROPDOWN",
          status: "Y",
          is_master_api_available: "Y",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 231,
          created_at: "2023-09-26T16:40:19.992+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 2,
          field_name: "Landmark 1",
          field_slug: "landmark",
          updated_at: "2023-09-26T16:40:19.992+00:00",
          field_sequence: 33,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 234,
          created_at: "2023-09-26T16:40:36.619+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 2,
          field_name: "Address Line 2",
          field_slug: "addressLineTwo",
          updated_at: "2023-09-26T16:40:36.619+00:00",
          field_sequence: 34,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 237,
          created_at: "2023-09-26T16:40:52.136+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 2,
          field_name: "Pin Code 2",
          field_slug: "pinCodeTwo",
          updated_at: "2023-09-26T16:40:52.136+00:00",
          field_sequence: 35,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "master_state",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 240,
          created_at: "2023-09-26T16:41:07.208+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 2,
          field_name: "State 2",
          field_slug: "stateTwo",
          updated_at: "2023-09-26T16:41:07.208+00:00",
          field_sequence: 36,
          premium_bearing: "N",
          validation_group: null,
          field_type: "DROPDOWN",
          status: "Y",
          is_master_api_available: "Y",
        },
        {
          master_slug: "master_city",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 245,
          created_at: "2023-09-26T16:41:22.345+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 2,
          field_name: "City 2",
          field_slug: "cityTwo",
          updated_at: "2023-09-26T16:41:22.345+00:00",
          field_sequence: 37,
          premium_bearing: "N",
          validation_group: null,
          field_type: "DROPDOWN",
          status: "Y",
          is_master_api_available: "Y",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 249,
          created_at: "2023-09-26T16:41:36.670+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 2,
          field_name: "Landmark 2",
          field_slug: "landmarkTwo",
          updated_at: "2023-09-26T16:41:36.670+00:00",
          field_sequence: 38,
          premium_bearing: "N",
          validation_group: "",
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "occupation_mapping",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 253,
          created_at: "2023-09-26T16:41:50.692+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 2,
          field_name: "Occupation",
          field_slug: "occupation",
          updated_at: "2023-09-26T16:41:50.692+00:00",
          field_sequence: 39,
          premium_bearing: "N",
          validation_group: null,
          field_type: "DROPDOWN",
          status: "Y",
          is_master_api_available: "Y",
        },
        {
          master_slug: "maritial_status_master",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 256,
          created_at: "2023-09-26T16:42:04.484+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 2,
          field_name: "Marital Status",
          field_slug: "maritalStatus",
          updated_at: "2023-09-26T16:42:04.484+00:00",
          field_sequence: 40,
          premium_bearing: "N",
          validation_group: null,
          field_type: "DROPDOWN",
          status: "Y",
          is_master_api_available: "Y",
        },
      ],
      "Nominee Details": [
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 258,
          created_at: "2023-09-26T16:42:18.189+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 3,
          field_name: "Nominee Full Name",
          field_slug: "nomineeFullname",
          updated_at: "2023-09-26T16:42:18.189+00:00",
          field_sequence: 41,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 263,
          created_at: "2023-09-26T16:42:34.308+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 3,
          field_name: "Nominee Age",
          field_slug: "nomineeAge",
          updated_at: "2023-09-26T16:42:34.308+00:00",
          field_sequence: 42,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 269,
          created_at: "2023-09-26T16:42:51.654+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 3,
          field_name: "Nominee Aadhar No.",
          field_slug: "nomineeAadharno",
          updated_at: "2023-09-26T16:42:51.654+00:00",
          field_sequence: 43,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 272,
          created_at: "2023-09-26T16:43:04.873+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 3,
          field_name: "Nominee Pan No.",
          field_slug: "nomineePanno",
          updated_at: "2023-09-26T16:43:04.873+00:00",
          field_sequence: 44,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "relation_master",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 275,
          created_at: "2023-09-26T16:43:21.284+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 3,
          field_name: "Nominee Relation",
          field_slug: "nomineeRelation",
          updated_at: "2023-09-26T16:43:21.284+00:00",
          field_sequence: 45,
          premium_bearing: "N",
          validation_group: null,
          field_type: "DROPDOWN",
          status: "Y",
          is_master_api_available: "Y",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 280,
          created_at: "2023-09-26T16:43:45.999+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 3,
          field_name: "Appointee Name",
          field_slug: "appointeeName",
          updated_at: "2023-09-26T16:43:45.999+00:00",
          field_sequence: 46,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "gender_master",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 286,
          created_at: "2023-09-26T16:43:59.883+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 3,
          field_name: "Nominee Gender",
          field_slug: "nomineeGender",
          updated_at: "2023-09-26T16:43:59.883+00:00",
          field_sequence: 47,
          premium_bearing: "N",
          validation_group: null,
          field_type: "DROPDOWN",
          status: "Y",
          is_master_api_available: "Y",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 291,
          created_at: "2023-09-26T16:44:20.365+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 3,
          field_name: "Nominee Address",
          field_slug: "nomineeAddress",
          updated_at: "2023-09-26T16:44:20.365+00:00",
          field_sequence: 48,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 293,
          created_at: "2023-09-26T16:44:38.719+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 3,
          field_name: "Nominee Minor",
          field_slug: "nomineeMinor",
          updated_at: "2023-09-26T16:44:38.719+00:00",
          field_sequence: 49,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
      ],
      "Payment Mode II": [
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 350,
          created_at: "2023-09-26T16:49:15.818+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 9,
          field_name: "Order ID",
          field_slug: "orderIDtwo",
          updated_at: "2023-09-26T16:49:15.818+00:00",
          field_sequence: 66,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 353,
          created_at: "2023-09-26T16:49:34.883+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 9,
          field_name: "Payer Name",
          field_slug: "payerNametwo",
          updated_at: "2023-09-26T16:49:34.883+00:00",
          field_sequence: 67,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 359,
          created_at: "2023-09-26T16:49:49.936+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 9,
          field_name: "Crs id",
          field_slug: "crsIdtwo",
          updated_at: "2023-09-26T16:49:49.936+00:00",
          field_sequence: 68,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 362,
          created_at: "2023-09-26T16:50:24.515+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 9,
          field_name: "Cheque No.",
          field_slug: "chequeNotwo",
          updated_at: "2023-09-26T16:50:24.515+00:00",
          field_sequence: 69,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 364,
          created_at: "2023-09-26T16:50:38.279+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 9,
          field_name: "Amount",
          field_slug: "amountTwo",
          updated_at: "2023-09-26T16:50:38.279+00:00",
          field_sequence: 70,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 367,
          created_at: "2023-09-26T16:50:50.670+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 9,
          field_name: "Bank Name",
          field_slug: "bankNametwo",
          updated_at: "2023-09-26T16:50:50.670+00:00",
          field_sequence: 71,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 373,
          created_at: "2023-09-26T16:51:07.983+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 9,
          field_name: "Bank Branch",
          field_slug: "bankBranchtwo",
          updated_at: "2023-09-26T16:51:07.983+00:00",
          field_sequence: 72,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 374,
          created_at: "2023-09-26T16:51:23.561+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 9,
          field_name: "Date",
          field_slug: "dateTwo",
          updated_at: "2023-09-26T16:51:23.561+00:00",
          field_sequence: 73,
          premium_bearing: "N",
          validation_group: null,
          field_type: "DATE",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 375,
          created_at: "2023-09-26T16:51:39.539+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 9,
          field_name: "Payment Mode Remark",
          field_slug: "paymentModeremarktwo",
          updated_at: "2023-09-26T16:51:39.539+00:00",
          field_sequence: 74,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
      ],
      "Subject Matter": [
        {
          master_slug: "N",
          is_searchable: null,
          field_description: "Members Insured",
          field_category: null,
          field_transaction_master_id: 534,
          created_at: "2023-09-30T09:57:59.200+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: null,
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "Vehicle Registration Number",
          field_slug: "vehicleRegistrationnumber",
          updated_at: "2023-09-30T09:57:59.200+00:00",
          field_sequence: 110,
          premium_bearing: null,
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "N",
          is_searchable: null,
          field_description: "Members Insured",
          field_category: null,
          field_transaction_master_id: 535,
          created_at: "2023-09-30T09:59:07.254+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: null,
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "Engine Number",
          field_slug: "engineNumber",
          updated_at: "2023-09-30T09:59:07.254+00:00",
          field_sequence: 111,
          premium_bearing: null,
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "N",
          is_searchable: null,
          field_description: "Members Insured",
          field_category: null,
          field_transaction_master_id: 536,
          created_at: "2023-09-30T10:02:04.305+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: null,
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "Chassis Number",
          field_slug: "chassisNumber",
          updated_at: "2023-09-30T10:02:04.305+00:00",
          field_sequence: 112,
          premium_bearing: null,
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "N",
          is_searchable: null,
          field_description: "Members Insured",
          field_category: null,
          field_transaction_master_id: 537,
          created_at: "2023-09-30T10:05:32.894+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: null,
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "Registration Date",
          field_slug: "registrationNumber",
          updated_at: "2023-09-30T10:05:32.894+00:00",
          field_sequence: 113,
          premium_bearing: null,
          validation_group: null,
          field_type: "DATE",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "N",
          is_searchable: null,
          field_description: "Members Insured",
          field_category: null,
          field_transaction_master_id: 538,
          created_at: "2023-09-30T10:09:47.534+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: null,
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "Manufacture Month",
          field_slug: "manufactureMonth",
          updated_at: "2023-09-30T10:09:47.534+00:00",
          field_sequence: 115,
          premium_bearing: null,
          validation_group: null,
          field_type: "TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "N",
          is_searchable: null,
          field_description: "Members Insured",
          field_category: null,
          field_transaction_master_id: 539,
          created_at: "2023-09-30T10:10:29.052+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: null,
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "Manufacture Year",
          field_slug: "manufactureYear",
          updated_at: "2023-09-30T10:10:29.052+00:00",
          field_sequence: 116,
          premium_bearing: null,
          validation_group: null,
          field_type: "TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "N",
          is_searchable: null,
          field_description: "Members Insured",
          field_category: null,
          field_transaction_master_id: 540,
          created_at: "2023-09-30T10:11:11.232+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: null,
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "Vehicle Colour",
          field_slug: "vehicleColour",
          updated_at: "2023-09-30T10:11:11.232+00:00",
          field_sequence: 117,
          premium_bearing: null,
          validation_group: null,
          field_type: "TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "N",
          is_searchable: null,
          field_description: "Members Insured",
          field_category: null,
          field_transaction_master_id: 541,
          created_at: "2023-09-30T10:12:23.609+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: null,
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "PUC Number",
          field_slug: "pucNumber",
          updated_at: "2023-09-30T10:12:23.609+00:00",
          field_sequence: 118,
          premium_bearing: null,
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "N",
          is_searchable: null,
          field_description: "Members Insured",
          field_category: null,
          field_transaction_master_id: 542,
          created_at: "2023-09-30T10:13:08.208+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: null,
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "PUC Expiry Date",
          field_slug: "pucExpirydate",
          updated_at: "2023-09-30T10:13:08.208+00:00",
          field_sequence: 119,
          premium_bearing: null,
          validation_group: null,
          field_type: "DATE",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "N",
          is_searchable: null,
          field_description: "Members Insured",
          field_category: null,
          field_transaction_master_id: 543,
          created_at: "2023-09-30T10:13:55.882+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: null,
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "Make",
          field_slug: "make",
          updated_at: "2023-09-30T10:13:55.882+00:00",
          field_sequence: 120,
          premium_bearing: null,
          validation_group: null,
          field_type: "DROPDOWN",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "Y",
          is_searchable: null,
          field_description: "Members Insured",
          field_category: null,
          field_transaction_master_id: 544,
          created_at: "2023-09-30T10:16:17.205+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: null,
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "Model",
          field_slug: "model",
          updated_at: "2023-09-30T10:16:17.207+00:00",
          field_sequence: 121,
          premium_bearing: null,
          validation_group: null,
          field_type: "DROPDOWN",
          status: "Y",
          is_master_api_available: "Y",
        },
        {
          master_slug: "Y",
          is_searchable: null,
          field_description: "Members Insured",
          field_category: null,
          field_transaction_master_id: 545,
          created_at: "2023-09-30T10:17:10.225+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: null,
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "Version",
          field_slug: "version",
          updated_at: "2023-09-30T10:17:10.225+00:00",
          field_sequence: 122,
          premium_bearing: null,
          validation_group: null,
          field_type: "DROPDOWN",
          status: "Y",
          is_master_api_available: "Y",
        },
        {
          master_slug: "N",
          is_searchable: null,
          field_description: "Members Insured",
          field_category: null,
          field_transaction_master_id: 546,
          created_at: "2023-09-30T10:19:39.867+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: null,
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "Is your vehicle financed?",
          field_slug: "isYourvehiclefinanced",
          updated_at: "2023-09-30T10:19:39.867+00:00",
          field_sequence: 123,
          premium_bearing: null,
          validation_group: null,
          field_type: "CHAR",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "Y",
          is_searchable: null,
          field_description: "Members Insured",
          field_category: null,
          field_transaction_master_id: 547,
          created_at: "2023-09-30T10:20:48.669+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: null,
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "Select Financer",
          field_slug: "selectFinancer",
          updated_at: "2023-09-30T10:20:48.669+00:00",
          field_sequence: 124,
          premium_bearing: null,
          validation_group: null,
          field_type: "TEXT",
          status: "Y",
          is_master_api_available: "Y",
        },
        {
          master_slug: "N",
          is_searchable: null,
          field_description: "Members Insured",
          field_category: null,
          field_transaction_master_id: 548,
          created_at: "2023-09-30T10:22:41.542+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: null,
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "Financer Aggrement Type",
          field_slug: "financerAggrementtype",
          updated_at: "2023-09-30T10:22:41.542+00:00",
          field_sequence: 125,
          premium_bearing: null,
          validation_group: null,
          field_type: "TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "Y",
          is_searchable: null,
          field_description: "Members Insured",
          field_category: null,
          field_transaction_master_id: 549,
          created_at: "2023-09-30T10:24:49.165+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: null,
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "Financer City/Branch",
          field_slug: "financerCitybranch",
          updated_at: "2023-09-30T10:24:49.165+00:00",
          field_sequence: 127,
          premium_bearing: null,
          validation_group: null,
          field_type: "TEXT",
          status: "Y",
          is_master_api_available: "Y",
        },
        {
          master_slug: "N",
          is_searchable: null,
          field_description: "Members Insured",
          field_category: null,
          field_transaction_master_id: 550,
          created_at: "2023-09-30T10:25:58.817+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: null,
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name:
            "Is your vehicle registration address is same as communication address?",
          field_slug:
            "isYourvehicleregistrationaddressissameascommunicationaddress",
          updated_at: "2023-09-30T10:25:58.817+00:00",
          field_sequence: 128,
          premium_bearing: null,
          validation_group: null,
          field_type: "CHAR",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "N",
          is_searchable: null,
          field_description: "Members Insured",
          field_category: null,
          field_transaction_master_id: 551,
          created_at: "2023-09-30T10:26:57.756+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: null,
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "Address Line 1",
          field_slug: "addresslines",
          updated_at: "2023-09-30T10:26:57.756+00:00",
          field_sequence: 129,
          premium_bearing: null,
          validation_group: null,
          field_type: "TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "N",
          is_searchable: null,
          field_description: "Members Insured",
          field_category: null,
          field_transaction_master_id: 552,
          created_at: "2023-09-30T10:27:38.616+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: null,
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "Address Line 2",
          field_slug: "addressliness",
          updated_at: "2023-09-30T10:27:38.616+00:00",
          field_sequence: 130,
          premium_bearing: null,
          validation_group: null,
          field_type: "TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "Y",
          is_searchable: null,
          field_description: "Members Insured",
          field_category: null,
          field_transaction_master_id: 553,
          created_at: "2023-09-30T10:28:51.425+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: null,
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "Pin Code",
          field_slug: "pinCodes",
          updated_at: "2023-09-30T10:28:51.425+00:00",
          field_sequence: 131,
          premium_bearing: null,
          validation_group: null,
          field_type: "TEXT",
          status: "Y",
          is_master_api_available: "Y",
        },
        {
          master_slug: "Y",
          is_searchable: null,
          field_description: "Members Insured",
          field_category: null,
          field_transaction_master_id: 554,
          created_at: "2023-09-30T10:29:24.420+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: null,
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "State",
          field_slug: "states",
          updated_at: "2023-09-30T10:29:24.420+00:00",
          field_sequence: 132,
          premium_bearing: null,
          validation_group: null,
          field_type: "TEXT",
          status: "Y",
          is_master_api_available: "Y",
        },
        {
          master_slug: "Y",
          is_searchable: null,
          field_description: "Members Insured",
          field_category: null,
          field_transaction_master_id: 555,
          created_at: "2023-09-30T10:29:54.749+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: null,
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "City",
          field_slug: "citys",
          updated_at: "2023-09-30T10:29:54.749+00:00",
          field_sequence: 133,
          premium_bearing: null,
          validation_group: null,
          field_type: "TEXT",
          status: "Y",
          is_master_api_available: "Y",
        },
        {
          master_slug: "Y",
          is_searchable: null,
          field_description: "Members Insured",
          field_category: null,
          field_transaction_master_id: 556,
          created_at: "2023-09-30T11:31:27.688+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: null,
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "CC",
          field_slug: "cc",
          updated_at: "2023-09-30T11:31:27.688+00:00",
          field_sequence: 134,
          premium_bearing: null,
          validation_group: null,
          field_type: "TEXT",
          status: "Y",
          is_master_api_available: "Y",
        },
        {
          master_slug: "business_type",
          is_searchable: "N",
          field_description: "string",
          field_category: "TEXT",
          field_transaction_master_id: 576,
          created_at: "2023-10-03T10:07:38.512+00:00",
          field_length: 100,
          is_editable: "Y",
          is_mandatory: "Y",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "Business Type",
          field_slug: "businessType",
          updated_at: "2023-10-03T10:07:38.528+00:00",
          field_sequence: 135,
          premium_bearing: "N",
          validation_group: "TEXT",
          field_type: "DROPDOWN",
          status: "Y",
          is_master_api_available: "Y",
        },
        {
          master_slug: "string",
          is_searchable: "N",
          field_description: "string",
          field_category: "TEXT",
          field_transaction_master_id: 578,
          created_at: "2023-10-03T10:16:23.107+00:00",
          field_length: 100,
          is_editable: "Y",
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "Discount",
          field_slug: "discount",
          updated_at: "2023-10-03T10:16:23.108+00:00",
          field_sequence: 146,
          premium_bearing: "N",
          validation_group: "TEXT",
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "Y",
        },
        {
          master_slug: "master_rto",
          is_searchable: "N",
          field_description: "string",
          field_category: "TEXT",
          field_transaction_master_id: 579,
          created_at: "2023-10-03T10:17:06.113+00:00",
          field_length: 100,
          is_editable: "Y",
          is_mandatory: "Y",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "RTO",
          field_slug: "rto",
          updated_at: "2023-10-03T10:17:06.114+00:00",
          field_sequence: 147,
          premium_bearing: "N",
          validation_group: "TEXT",
          field_type: "DROPDOWN",
          status: "Y",
          is_master_api_available: "Y",
        },
        {
          master_slug: "vehicle_segment",
          is_searchable: "N",
          field_description: "string",
          field_category: "TEXT",
          field_transaction_master_id: 580,
          created_at: "2023-10-03T10:19:02.544+00:00",
          field_length: 100,
          is_editable: "Y",
          is_mandatory: "Y",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "Vehicle Segment",
          field_slug: "vehicleSegment",
          updated_at: "2023-10-03T10:19:02.545+00:00",
          field_sequence: 149,
          premium_bearing: "N",
          validation_group: "TEXT",
          field_type: "DROPDOWN",
          status: "Y",
          is_master_api_available: "Y",
        },
        {
          master_slug: "string",
          is_searchable: "N",
          field_description: "string",
          field_category: "TEXT",
          field_transaction_master_id: 581,
          created_at: "2023-10-03T10:19:47.923+00:00",
          field_length: 100,
          is_editable: "Y",
          is_mandatory: "Y",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "NCB %",
          field_slug: "ncb",
          updated_at: "2023-10-03T10:19:47.925+00:00",
          field_sequence: 150,
          premium_bearing: "N",
          validation_group: "TEXT",
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "Y",
        },
        {
          master_slug: "string",
          is_searchable: "N",
          field_description: "string",
          field_category: "TEXT",
          field_transaction_master_id: 582,
          created_at: "2023-10-03T10:21:00.146+00:00",
          field_length: 100,
          is_editable: "Y",
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "Age of the Vehicle",
          field_slug: "ageoftheVehicle",
          updated_at: "2023-10-03T10:21:00.147+00:00",
          field_sequence: 151,
          premium_bearing: "N",
          validation_group: "TEXT",
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "Y",
        },
        {
          master_slug: "master_zone",
          is_searchable: "N",
          field_description: "string",
          field_category: "TEXT",
          field_transaction_master_id: 583,
          created_at: "2023-10-03T10:21:33.829+00:00",
          field_length: 100,
          is_editable: "Y",
          is_mandatory: "Y",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "Zone",
          field_slug: "zone",
          updated_at: "2023-10-03T10:21:33.830+00:00",
          field_sequence: 152,
          premium_bearing: "N",
          validation_group: "TEXT",
          field_type: "DROPDOWN",
          status: "Y",
          is_master_api_available: "Y",
        },
        {
          master_slug: "fuel_types",
          is_searchable: "N",
          field_description: "string",
          field_category: "TEXT",
          field_transaction_master_id: 585,
          created_at: "2023-10-03T10:23:05.143+00:00",
          field_length: 100,
          is_editable: "Y",
          is_mandatory: "Y",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "Fuel Type",
          field_slug: "fuelType",
          updated_at: "2023-10-03T10:23:05.145+00:00",
          field_sequence: 154,
          premium_bearing: "N",
          validation_group: "TEXT",
          field_type: "DROPDOWN",
          status: "Y",
          is_master_api_available: "Y",
        },
        {
          master_slug: "string",
          is_searchable: "N",
          field_description: "string",
          field_category: "TEXT",
          field_transaction_master_id: 587,
          created_at: "2023-10-03T10:29:10.997+00:00",
          field_length: 100,
          is_editable: "Y",
          is_mandatory: "Y",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "KWH",
          field_slug: "kwh",
          updated_at: "2023-10-03T10:29:11.020+00:00",
          field_sequence: 155,
          premium_bearing: "N",
          validation_group: "TEXT",
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "Y",
        },
        {
          master_slug: "string",
          is_searchable: "N",
          field_description: "string",
          field_category: "TEXT",
          field_transaction_master_id: 588,
          created_at: "2023-10-03T10:29:33.062+00:00",
          field_length: 100,
          is_editable: "Y",
          is_mandatory: "Y",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "GUV",
          field_slug: "guv",
          updated_at: "2023-10-03T10:29:33.063+00:00",
          field_sequence: 156,
          premium_bearing: "N",
          validation_group: "TEXT",
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "Y",
        },
        {
          master_slug: "string",
          is_searchable: "N",
          field_description: "string",
          field_category: "TEXT",
          field_transaction_master_id: 624,
          created_at: "2023-10-05T11:14:39.615+00:00",
          field_length: 100,
          is_editable: "Y",
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 10,
          field_name: "Seating Capacity",
          field_slug: "seatingCapacity",
          updated_at: "2023-10-05T11:14:39.617+00:00",
          field_sequence: 200,
          premium_bearing: "N",
          validation_group: "TEXT",
          field_type: "INT",
          status: "Y",
          is_master_api_available: "Y",
        },
      ],
      "Premium Details": [
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 295,
          created_at: "2023-09-26T16:44:56.230+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 5,
          field_name: "TP Premium",
          field_slug: "tpPremium",
          updated_at: "2023-09-26T16:44:56.230+00:00",
          field_sequence: 50,
          premium_bearing: "Y",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 303,
          created_at: "2023-09-26T16:45:11.953+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 5,
          field_name: "OD Premium (A+D-C)",
          field_slug: "odPremium",
          updated_at: "2023-09-26T16:45:11.953+00:00",
          field_sequence: 51,
          premium_bearing: "Y",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 305,
          created_at: "2023-09-26T16:45:24.745+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 5,
          field_name: "Net Premium",
          field_slug: "net",
          updated_at: "2023-09-26T16:45:24.745+00:00",
          field_sequence: 52,
          premium_bearing: "Y",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 308,
          created_at: "2023-09-26T16:45:38.341+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 5,
          field_name: "Gst/tax%",
          field_slug: "gstTax",
          updated_at: "2023-09-26T16:45:38.341+00:00",
          field_sequence: 53,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 310,
          created_at: "2023-09-26T16:45:57.507+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 5,
          field_name: "Total Premium",
          field_slug: "totalPremium",
          updated_at: "2023-09-26T16:45:57.507+00:00",
          field_sequence: 54,
          premium_bearing: "Y",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 312,
          created_at: "2023-09-26T16:46:16.108+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 5,
          field_name: "Sum Insured",
          field_slug: "sumInsured",
          updated_at: "2023-09-26T16:46:16.108+00:00",
          field_sequence: 55,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 317,
          created_at: "2023-09-26T16:46:39.655+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 5,
          field_name: "Location & Remark (if any)",
          field_slug: "locationRemark",
          updated_at: "2023-09-26T16:46:39.655+00:00",
          field_sequence: 56,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
      ],
      "Payment Mode I": [
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 319,
          created_at: "2023-09-26T16:46:52.414+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 8,
          field_name: "Order ID",
          field_slug: "orderID",
          updated_at: "2023-09-26T16:46:52.414+00:00",
          field_sequence: 57,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 323,
          created_at: "2023-09-26T16:47:06.171+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 8,
          field_name: "Payer Name",
          field_slug: "payerName",
          updated_at: "2023-09-26T16:47:06.171+00:00",
          field_sequence: 58,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 330,
          created_at: "2023-09-26T16:47:22.394+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 8,
          field_name: "Crs id",
          field_slug: "crsId",
          updated_at: "2023-09-26T16:47:22.394+00:00",
          field_sequence: 59,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 332,
          created_at: "2023-09-26T16:47:40.315+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 8,
          field_name: "Cheque No.",
          field_slug: "chequeNo",
          updated_at: "2023-09-26T16:47:40.315+00:00",
          field_sequence: 60,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 334,
          created_at: "2023-09-26T16:47:55.242+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 8,
          field_name: "Amount",
          field_slug: "amount",
          updated_at: "2023-09-26T16:47:55.242+00:00",
          field_sequence: 61,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 336,
          created_at: "2023-09-26T16:48:10.180+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "Y",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 8,
          field_name: "Bank Name",
          field_slug: "bankName",
          updated_at: "2023-09-26T16:48:10.180+00:00",
          field_sequence: 62,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 339,
          created_at: "2023-09-26T16:48:25.486+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 8,
          field_name: "Bank Branch",
          field_slug: "bankBranch",
          updated_at: "2023-09-26T16:48:25.486+00:00",
          field_sequence: 63,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 346,
          created_at: "2023-09-26T16:48:39.144+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 8,
          field_name: "Date",
          field_slug: "date",
          updated_at: "2023-09-26T16:48:39.144+00:00",
          field_sequence: 64,
          premium_bearing: "N",
          validation_group: null,
          field_type: "DATE",
          status: "Y",
          is_master_api_available: "N",
        },
        {
          master_slug: "string",
          is_searchable: null,
          field_description: "string",
          field_category: null,
          field_transaction_master_id: 348,
          created_at: "2023-09-26T16:48:56.243+00:00",
          field_length: 100,
          is_editable: null,
          is_mandatory: "N",
          created_by: 1,
          lob_id: 6,
          transaction_category_master_id: 8,
          field_name: "Payment Mode Remark",
          field_slug: "paymentModeRemark",
          updated_at: "2023-09-26T16:48:56.243+00:00",
          field_sequence: 65,
          premium_bearing: "N",
          validation_group: null,
          field_type: "FREE_TEXT",
          status: "Y",
          is_master_api_available: "N",
        },
      ],
    },
    message: "Success",
  },
};
