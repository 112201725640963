import { DataGrid } from "@mui/x-data-grid";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import { Button } from "../../../components";
import {
  BasicDetailsEditHeader,
  BasicDetailsEditStyled,
  StyledBg,
  TableContainerStyled,
} from "../Brokerage.style";
import useBrokerage from "../useBrokerage";
import moment from "moment";
import { defaultDateFormat } from "../../../HelperFunctions/HelperFunctions";
import styled from "styled-components";
import Swal from "sweetalert2";

function BrokerageListing({
  lobId,
  effective_start_date,
  transactionType,
  effective_end_date,
  icType,
  redirectToBasicDetails,
}) {
  return (
    <>
      <StyledBg>
        <p className="card_header">View & Summary</p>
        <BasicDetailsEditHeader>
          <p>Basic Details</p>
          <Button
            onClick={redirectToBasicDetails}
            className="demo"
            bgColor="transparent"
            textColor="rgb(23, 142, 128)"
            id="add_broker"
            variant="outlined"
            borderColor="rgb(23, 142, 128)"
          >
            Edit Summary
          </Button>
        </BasicDetailsEditHeader>
        <BasicDetailsEditStyled>
          <div className="summary_fields">
            <p className="label">
              LOB: <span>{lobId?.value}</span>
            </p>
            <p className="label">
              Policy Type: <span>{transactionType?.label}</span>
            </p>
            <p className="label">
              Insurance Company: <span>{icType?.value}</span>
            </p>
          </div>
          <div className="summary_fields">
            <p className="label">
              Effective Start Date:{" "}
              <span>{defaultDateFormat(effective_start_date)}</span>
            </p>
            <p className="label end_date">
              Effective End Date:{" "}
              <span>{defaultDateFormat(effective_end_date)}</span>
            </p>
          </div>
        </BasicDetailsEditStyled>
        <hr />
      </StyledBg>
    </>
  );
}

export default BrokerageListing;
