import { createSlice } from "@reduxjs/toolkit";
const userConfig = createSlice({
  name: "userConfig",
  initialState: {
    updateId: "",
  },
  reducers: {
    setUpdateId: (state, { payload }) => {
      state.updateId = payload;
    },
  },
});
export const { setUpdateId } = userConfig.actions;
export default userConfig.reducer;
