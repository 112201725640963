import { Grid } from "@mui/material";
import {
    InputFrame,
    MasterConfigModalStyled,
    StyledHeading,
} from "../MasterConfig.style";
import { Button, DatePickerInput, Input, Typography } from "../../../components";
import InlineLoader from "../../../components/loader/InlineLoader";
import MasterFilePicker from "./MasterFilePicker";
import ProposalDropdown from "../../search-and-proceed/ProposalComponents/ProposalDropdown";

function MasterConfigModal({
    masterList,
    fieldValues,
    title,
    register,
    watch,
    control,
    unregister,
    setValue,
    errors,
    handleSubmit,
    handleFormSubmit,
    isAddDataInMasterLoading,
    isUpdateDataInMasterLoading,
    docFormats,
    masterTableData
}) {
    const { Error } = Typography;

    return (
        <MasterConfigModalStyled>
            <InputFrame onSubmit={handleSubmit(handleFormSubmit)}>
                <Grid
                    container
                    sx={{ paddingInline: "24px" }}
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                    <Grid
                        item
                        sm={12}
                        md={12}
                        lg={12}
                        style={{
                            position: "relative",
                            marginBottom: "-26px",
                            marginLeft: "0px",
                        }}
                    >
                        <StyledHeading>{masterList?.label}</StyledHeading>
                    </Grid>
                    {fieldValues.map((value) => {
                        if (value.fieldType === "UPLOAD") {
                            return (
                                <Grid item sx={{ position: "relative" }} xs={12}>
                                    <MasterFilePicker
                                        label={value.fieldName}
                                        name={value.fieldSlug}
                                        watch={watch}
                                        control={control}
                                        setValue={setValue}
                                        unregister={unregister}
                                        allowedDocFormat={docFormats?.[0]}
                                        isReadOnly={title === "View" ? true : false}
                                    />
                                </Grid>
                            );
                        } else if (value.fieldType === "DATE") {
                            return (
                                <Grid item sx={{ position: "relative" }} lg={4}>
                                    <DatePickerInput
                                        themeType={"theme1"}
                                        label={value.fieldName}
                                        name={value.fieldSlug}
                                        id={value.fieldSlug}
                                        placeholder={`Enter ${value.fieldName}`}
                                        inputRef={register(value.fieldSlug)}
                                        control={control}
                                        isRequired={title === "View" ? true : false}
                                        proposalRead={title === "View" ? true : false}
                                    />

                                </Grid>
                            )
                        } else if (value.fieldType === "DROPDOWN") {
                            return <Grid item sx={{ position: "relative" }} lg={4}>
                                <ProposalDropdown
                                    id={value.fieldSlug}
                                    filter={value.fieldSlug}
                                    label={value.fieldName}
                                    control={control}
                                    setValue={setValue}
                                    masterTableData={masterTableData}
                                    placeholder={value.fieldName}
                                    proposalRead={title === "View" ? true : false}
                                    readOnly={title === "View" ? true : false}
                                />
                            </Grid>
                        }
                        return (
                            <Grid
                                item
                                sx={{ position: "relative" }}
                                xs={12}
                                sm={12}
                                md={6}
                                lg={4}
                            >
                                <Input
                                    themeType={"theme1"}
                                    label={value.fieldName}
                                    name={value.fieldSlug}
                                    id={value.fieldSlug}
                                    maxLength="100"
                                    placeholder={`Enter ${value.fieldName}`}
                                    autoComplete="none"
                                    defaultValue={""}
                                    required={false}
                                    proposalRead={title === "View" ? true : false}
                                    readOnly={title === "View" ? true : false}
                                    inputRef={register(value.fieldSlug)}
                                    testId="modal_input"
                                    isRequired={title === "View" ? false : true}
                                />
                                {!!errors?.[value.fieldSlug] && (
                                    <Error top="3px" left="3px">
                                        {errors[value.fieldSlug].message}
                                    </Error>
                                )}
                            </Grid>
                        );
                    })}
                </Grid>
                {title !== "View" ? (
                    <Grid
                        container
                        mt={1}
                        className="button_container"
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        sx={{ justifyContent: "end" }}
                    >
                        <Grid
                            item
                            sx={{
                                position: "relative",
                                display: "flex",
                                justifyContent: "end",
                            }}
                            xs={6}
                            sm={6}
                            md={6}
                            lg={6}
                        >
                            <Button
                                type="submit"
                                themeType={"theme1"}
                                id={title === "Modify" ? "update_btn" : "save_btn"}
                                bgColor={"#11555F"}
                            >
                                {title === "Add" ? (
                                    isAddDataInMasterLoading ? (
                                        <>
                                            Adding
                                            <InlineLoader />
                                        </>
                                    ) : (
                                        "Add"
                                    )
                                ) : isUpdateDataInMasterLoading ? (
                                    <>
                                        Updating
                                        <InlineLoader />
                                    </>
                                ) : (
                                    "Update"
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                ) : (
                    <></>
                )}
            </InputFrame>
        </MasterConfigModalStyled>
    );
}

export default MasterConfigModal;
