import React from "react";
import { Wrapper } from "../styles/styles";
import { Grid } from "@mui/material";
const SummaryCard = ({ values }) => {
  const labels = [
    "Pincode",
    "State",
    "City",
    "Landmark",
    "Address Line 1",
    "Address Line 2",
  ];
  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      rowSpacing={2}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    >
      {labels.map((item) => {
        return (
          <Grid item xs={12} md={3} sm={3} lg={3}>
            <Wrapper>
              <label htmlFor="">{item}</label>
              <span>
                {item === "Pincode"
                  ? values?.pincode
                  : item === "State"
                  ? values?.state
                  : item === "City"
                  ? values?.city
                  : item === "Landmark"
                  ? values?.landmark
                  : item === "Address Line 1"
                  ? values?.address_line1
                  : values?.address_line2}
              </span>
            </Wrapper>
          </Grid>
        );
      })}
    </Grid>
  );
};
export default SummaryCard;
