import React from "react";
import { useGetFamilyListing } from "../service";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import FamilyTable from "./familyTable";
import { TableFrame } from "../vehicleListing/styles";
const CustomerFamilyListing = () => {
  const location = useLocation();
  const url = new URLSearchParams(location.search);
  const customerID = url.get("id");
  const { data } = useGetFamilyListing(customerID);
  const rows =
    !_.isEmpty(data?.data?.data) &&
    data?.data?.data?.map((item) => {
      return {
        id: item?.customerRefId,
        firstName: item?.firstName,
        lastName: item?.lastName,
        gender: item?.gender,
        mobileNumber: item?.mobileNumber,
        relation: item?.relation,
      };
    });

  const columns = [
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      background: "#D2F8E9",
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      background: "#D2F8E9",
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      flex: 1,
      background: "#D2F8E9",
    },
    {
      field: "relation",
      headerName: "Relation",
      flex: 1,
      background: "#D2F8E9",
    },
  ];
  return (
    <>
      <TableFrame>
        <FamilyTable rows={rows} columns={columns} />
      </TableFrame>
    </>
  );
};

export default CustomerFamilyListing;
