import { React } from "react";
import { Button, Input, Typography } from "../../../components";
import useGetLOBMaster from "../../../hooks/useGetLOBMaster";
import SummaryUser from "../Summary/summaryUser";
import Dropdown from "../../../components/Dropdown/Dropdown";
import DatePickerInput from "../../../components/date-picker/DatePicker";
import UserDropDown from "./userDropDown";
import {
  ColumnGrid,
  Head,
  RowGrid,
  StyledDiv,
  StyledGrid,
  SummaryWrapper,
  ToggleContainer,
  ToggleInput,
  ToggleLabel,
  ToggleWrap,
} from "../styles/styles";
import _ from "lodash";
import {
  preventCharactersInInput,
  preventSpecialAndNumbersInInput,
  preventSpecialInInput,
} from "../../../HelperFunctions/HelperFunctions";

const UserDetails = ({
  user,
  onEdit,
  onSave,
  active,
  rolesData,
  branchData,
  userTypeData,
  channelData,
  departmentData,
  summary,
  handleSubmit,
  register,
  errors,
  control,
  toggleInputNoRef,
  toggleInputYesRef,
  Controller,
  minDate,
  debounceFn,
  currentUrl,
}) => {
  const { Error } = Typography;
  const { data } = useGetLOBMaster();
  const val =
    data?.data?.data?.map((value, index) => {
      return {
        id: index,
        value: value.lob_id,
        label: value.lob,
      };
    }) || [];

  return (
    <StyledDiv>
      <h3>User Details</h3>
      {active === 1 ? (
        <form onSubmit={handleSubmit(onSave)}>
          <RowGrid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {" "}
            <ColumnGrid item xs={10} md={3} sm={10} lg={3}>
              <Input
                isRequired={true}
                themeType="theme1"
                label="Username"
                name="username"
                id="user_n"
                maxLength="50"
                placeholder="Enter User Name"
                autoComplete="none"
                defaultValue={""}
                inputRef={register("username")}
                onChange={debounceFn}
              />
              {errors.username && (
                <Error left="2px">{errors.username.message}</Error>
              )}
            </ColumnGrid>
            <ColumnGrid item xs={10} md={3} sm={10} lg={3}>
              <Input
                isRequired={true}
                themeType="theme1"
                label="User Password"
                name="password"
                id="password"
                maxLength="50"
                placeholder="Enter Password"
                autoComplete="none"
                defaultValue={""}
                // readOnly={currentUrl === "/user-configupdate" ? true : false}
                type={"password"}
                inputRef={register("password")}
              />
              {errors.password && (
                <Error left="2px">{errors.password.message}</Error>
              )}
            </ColumnGrid>
            <ColumnGrid item xs={10} md={3} sm={10} lg={3}>
              <Input
                isRequired={true}
                themeType="theme1"
                label="First Name"
                name="first_name"
                id="first_name"
                maxLength="50"
                placeholder="Enter First Name"
                onChange={(e) => preventSpecialAndNumbersInInput(e)}
                autoComplete="none"
                defaultValue={""}
                inputRef={register("first_name")}
              />
              {errors.first_name && (
                <Error left="2px">{errors.first_name.message}</Error>
              )}
            </ColumnGrid>
            <ColumnGrid item xs={10} md={3} sm={10} lg={3}>
              <Input
                themeType="theme1"
                label="Middle Name"
                name="middle_name"
                id="first_name"
                maxLength="50"
                placeholder="Enter Middle Name"
                onChange={(e) => preventSpecialAndNumbersInInput(e)}
                autoComplete="none"
                defaultValue={""}
                inputRef={register("middle_name")}
              />
              {errors.middle_name && (
                <Error left="2px">{errors.middle_name.message}</Error>
              )}
            </ColumnGrid>
            <ColumnGrid item xs={10} md={3} sm={10} lg={3}>
              <Input
                isRequired={true}
                themeType="theme1"
                label="Last Name"
                name="last_name"
                id="last_name"
                maxLength="50"
                placeholder="Enter Last Name"
                onChange={(e) => preventSpecialAndNumbersInInput(e)}
                autoComplete="none"
                defaultValue={""}
                inputRef={register("last_name")}
              />{" "}
              {errors.last_name && (
                <Error left="2px">{errors.last_name.message}</Error>
              )}
            </ColumnGrid>
            <StyledGrid item xs={10} md={3} sm={10} lg={3}>
              <UserDropDown
                slug={"gender_master"}
                id={"gender"}
                control={control}
                label={"Select Gender"}
                fieldSlug={"gender"}
                isRequired={true}
              />
              {errors.gender && (
                <Error left="2px">{errors.gender.message}</Error>
              )}
            </StyledGrid>
            <ColumnGrid item xs={10} md={3} sm={10} lg={3}>
              <Dropdown
                id={"department"}
                control={control}
                selectObj={departmentData}
                label={"Select Department "}
                maxMenuHeight={200}
                isRequired={true}
              />{" "}
              {errors.department && (
                <Error left="2px">{errors.department.message}</Error>
              )}
            </ColumnGrid>
            <StyledGrid item xs={10} md={3} sm={10} lg={3}>
              <ToggleWrap>
                <Head>
                  User Status <span>*</span>
                </Head>
                <ToggleContainer>
                  <Controller
                    name="user_status"
                    control={control}
                    render={({ field }) => (
                      <>
                        <ToggleInput
                          type="radio"
                          {...field}
                          id="yes"
                          value="Y"
                          ref={toggleInputYesRef}
                        />
                        <ToggleLabel htmlFor="yes">Active</ToggleLabel>
                        <ToggleInput
                          type="radio"
                          {...field}
                          id="no"
                          value="N"
                          ref={toggleInputNoRef}
                        />
                        <ToggleLabel htmlFor="no">Inactive</ToggleLabel>
                      </>
                    )}
                  />
                </ToggleContainer>
              </ToggleWrap>
              {errors.user_status && (
                <Error left="2px">{errors.user_status.message}</Error>
              )}
            </StyledGrid>
            <StyledGrid item xs={10} md={3} sm={10} lg={3}>
              <Dropdown
                id={"roleId"}
                control={control}
                selectObj={rolesData}
                label={"Select Role "}
                maxMenuHeight={200}
                isRequired={true}
              />
              {errors.roleId && (
                <Error left="2px">{errors.roleId.message}</Error>
              )}
            </StyledGrid>
            <StyledGrid item xs={10} md={3} sm={10} lg={3}>
              <Dropdown
                id={"user_type"}
                control={control}
                selectObj={userTypeData}
                label={"Select User Type"}
                maxMenuHeight={200}
                isRequired={true}
              />
              {errors.user_type && (
                <Error left="2px">{errors.user_type.message}</Error>
              )}
            </StyledGrid>
            <StyledGrid item xs={10} md={3} sm={10} lg={3}>
              <Dropdown
                id={"channel"}
                control={control}
                selectObj={channelData}
                label={"Select Channel"}
                maxMenuHeight={200}
                isRequired={true}
              />{" "}
              {errors.channel && (
                <Error left="2px">{errors.channel.message}</Error>
              )}
            </StyledGrid>
            <StyledGrid item xs={10} md={3} sm={10} lg={3}>
              <Input
                // isRequired={true}
                themeType="theme1"
                label="IRDAI License No"
                name="irdai_license_no"
                id="IRDAI_license_No"
                maxLength="50"
                placeholder="Enter IRDAI License No "
                autoComplete="none"
                defaultValue={""}
                inputRef={register("irdai_license_no")}
              />
              {errors.irdai_license_no && (
                <Error left="2px">{errors.irdai_license_no.message}</Error>
              )}
            </StyledGrid>
            <StyledGrid item xs={10} md={3} sm={10} lg={3}>
              <Dropdown
                id={"branch_name"}
                control={control}
                selectObj={branchData}
                label={"Select Branch Name"}
                maxMenuHeight={200}
                // isRequired={true}
              />
              {errors.branch_name && (
                <Error left="2px">{errors.branch_name.message}</Error>
              )}
            </StyledGrid>
            <StyledGrid item xs={10} md={3} sm={10} lg={3}>
              <DatePickerInput
                control={control}
                label={"License Start Date"}
                name="start_date"
                id="start_date"
                inputRef={register("start_date")}
                // isRequired={true}
              />
              {errors.start_date && (
                <Error left="2px">{errors.start_date.message}</Error>
              )}
            </StyledGrid>
            <StyledGrid item xs={10} md={3} sm={10} lg={3}>
              <DatePickerInput
                control={control}
                label={"License End Date"}
                name="end_date"
                id="end_date"
                inputRef={register("end_date")}
                minDate={minDate || new Date()}
                // isRequired={true}
              />
              {errors.end_date && (
                <Error left="2px">{errors.end_date.message}</Error>
              )}
            </StyledGrid>
            <StyledGrid item xs={10} md={3} sm={10} lg={3}>
              <Input
                // isRequired={true}
                themeType="theme1"
                label="Aadhaar Card Number"
                name="aadhaar_number"
                id="adhar_number"
                maxLength="12"
                placeholder="Enter Aadhaar Card Number"
                autoComplete="none"
                defaultValue={""}
                type="numeric"
                onChange={(e) => preventCharactersInInput(e)}
                inputRef={register("aadhaar_number")}
              />{" "}
              {errors.aadhaar_number && (
                <Error left="2px">{errors.aadhaar_number.message}</Error>
              )}
            </StyledGrid>
            <StyledGrid item xs={10} md={3} sm={10} lg={3}>
              <Input
                // isRequired={true}
                themeType="theme1"
                label="PAN Number"
                name="pancard"
                id="pancard"
                maxLength="10"
                placeholder="Enter Pan Number"
                onChange={(e) => {
                  e.target.value = e.target.value.toUpperCase();
                  preventSpecialInInput(e);
                }}
                autoComplete="none"
                defaultValue={""}
                inputRef={register("pancard")}
              />
              {errors.pancard && (
                <Error left="2px">{errors.pancard.message}</Error>
              )}
            </StyledGrid>
            <StyledGrid item xs={10} md={3} sm={10} lg={3}>
              <UserDropDown
                slug={"master_zone"}
                id={"zone"}
                control={control}
                label={"Select Zone"}
                // isRequired={true}
              />
              {errors.zone && <Error left="2px">{errors.zone.message}</Error>}
            </StyledGrid>
          </RowGrid>
          <RowGrid padding>
            <Button type="submit" bgColor={"rgb(23, 142, 128)"}>
              Proceed
            </Button>
          </RowGrid>
        </form>
      ) : summary.user ? (
        <SummaryWrapper>
          <RowGrid>
            <SummaryUser values={user} currentUrl={currentUrl} />
          </RowGrid>
          <RowGrid padding>
            <Button onClick={onEdit} bgColor={"rgb(23, 142, 128)"}>
              edit
            </Button>
          </RowGrid>
        </SummaryWrapper>
      ) : (
        <noscript />
      )}
    </StyledDiv>
  );
};

export default UserDetails;
