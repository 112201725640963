import React from "react";
import { Wrapper } from "../styles/styles";
import { Grid } from "@mui/material";
const SummaryCard = ({ values }) => {
  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      rowSpacing={2}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    >
      <Grid item xs={12} md={3} sm={4} lg={3}>
        <Wrapper>
          <label htmlFor="">Mobile number</label>
          <span>{values?.mobile_no}</span>
        </Wrapper>
      </Grid>
      <Grid item xs={12} md={3} sm={4} lg={3}>
        <Wrapper>
          <label htmlFor="">Alternative Mobile number</label>
          <span>{values?.alternate_mobile_no}</span>
        </Wrapper>
      </Grid>
      <Grid item xs={12} md={3} sm={4} lg={3}>
        <Wrapper>
          <label htmlFor="">Email ID</label>
          <span>{values?.email_id}</span>
        </Wrapper>
      </Grid>
      <Grid item xs={12} md={3} sm={4} lg={3}>
        <Wrapper>
          <label htmlFor="">Alternate Email ID</label>
          <span>{values?.alternate_email_id}</span>
        </Wrapper>
      </Grid>
    </Grid>
  );
};
export default SummaryCard;
