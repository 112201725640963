import { createSlice } from "@reduxjs/toolkit";
import { serializeError } from "../../utils";

const IcReportConfig = createSlice({
  name: "reportConfig",
  initialState: {
    reportError: null,
    reportSuccess: null,
    icHeaders: [],
  },
  reducers: {
    setReportSuccessAction: (state, { payload }) => {
      state.reportSuccess = payload;
      state.reportError = null;
    },
    setReportErrorAction: (state, { payload }) => {
      state.reportError = serializeError(payload);
      state.reportSuccess = null;
    },
    setIcHeaders: (state, { payload }) => {
      state.icHeaders = payload;
    },
  },
});

export const { setReportSuccessAction, setReportErrorAction, setIcHeaders } =
  IcReportConfig.actions;
export default IcReportConfig.reducer;
