import React, { useState } from "react";
import styled from "styled-components";
import { Typography } from "../../components";
import GlobalModal from "../../components/Modal/modal";
import AddVehicleform from "./CustomerVehicleAdd/addVehicleform";
import CustomerAddForm from "./customerAdd/customerAddForm";
import CustomerDocuments from "./customerAdditionalDoc/customerDocuments";
import CustomerTable from "./customerTable";
import useCustomer from "./useCustomer";

const Customer = () => {
  const { StyledH1 } = Typography;

  const {
    setOpen,
    setModalTitle,
    rows,
    open,
    modalTitle,
    OccupationOBJ,
    createCustomer,
    pincode,
    setPincode,
    data,
    GenderObj,
    rowData,
    YesNoObj,
    setRowData,
    setValue,
    trigger,
    handleSubmit,
    register,
    errors,
    docDataForUploadDocumentsOBJ,
    LOB_Obj,
    handleClose,
    getAdditionalDocDetails,
    updateAdditionalDocDetails,
    customerDocListingByid,
    control,
    getvehicleDetails,
  } = useCustomer();

  return (
    <>
      <StyledBg>
        {" "}
        <StyledH1
          themeType="theme1"
          partialU
          color={"rgb(23, 142, 128)"}
          align={"left"}
          style={{ marginBottom: "0" }}
        >
          Customer
        </StyledH1>
        {/* <CustomerForm LOB_Obj={LOB_Obj} /> */}
      </StyledBg>
      <TableFrame>
        <CustomerTable
          setModalTitle={setModalTitle}
          setOpen={setOpen}
          rows={rows}
          setRowData={setRowData}
        />
      </TableFrame>
      <GlobalModal
        open={open}
        title={modalTitle + " Customer"}
        onClose={handleClose}
        overflowVisible={true}
      >
        {modalTitle === "Add Vehicle Details For" ? (
          <AddVehicleform
            modalTitle={modalTitle}
            LOB_Obj={LOB_Obj}
            getvehicleDetails={getvehicleDetails}
            rowData={rowData}
          />
        ) : modalTitle === "Add Additional Document For" ? (
          <CustomerDocuments
            modalTitle={modalTitle}
            customerDocListingByid={customerDocListingByid}
            rowData={rowData}
            getAdditionalDocDetails={getAdditionalDocDetails}
            updateAdditionalDocDetails={updateAdditionalDocDetails}
            docDataForUploadDocumentsOBJ={docDataForUploadDocumentsOBJ}
            setRowData={setRowData}
          />
        ) : (
          <CustomerAddForm
            modalTitle={modalTitle}
            OccupationOBJ={OccupationOBJ}
            createCustomer={createCustomer}
            pincode={pincode}
            rowData={rowData}
            setPincode={setPincode}
            data={data}
            GenderObj={GenderObj}
            YesNoObj={YesNoObj}
            setValue={setValue}
            trigger={trigger}
            handleSubmit={handleSubmit}
            register={register}
            errors={errors}
            control={control}
          />
        )}
      </GlobalModal>
    </>
  );
};

export default Customer;

const StyledBg = styled.div`
  background: #fff;
  padding: 24px 16px;
  margin-right: 7px;
`;

const TableFrame = styled.div`
  display: flex;
  position: relative;
  align-items: ${({ position }) => (position ? "start" : "center")};
  flex-direction: column;
  font-family: "Nunito";
  box-shadow: 0px 22px 45px -15px #dddde2;
  background-color: #ffffff;
  margin-top: 15px;
  padding: 20px;
  max-width: 99.5% !important;
`;
