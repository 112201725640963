import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Controller } from "react-hook-form";
import styled from "styled-components";

function DatePickerInput({
  name,
  label,
  id,
  control,
  userForm,
  proposalRead,
  readOnly,
  placeholder = "Enter date",
  minDate,
  maxDate,
  testId,
  isRequired,
}) {
  return (
    <DateWrapper userForm={userForm} proposalRead={proposalRead}>
      <label>
        {label} <span style={{ color: "red" }}>{isRequired ? "*" : null}</span>
      </label>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              id={id}
              name={name}
              control={control}
              minDate={minDate}
              maxDate={maxDate}
              readOnly={readOnly}
              value={value}
              onChange={onChange}
              views={["year", "month", "day"]}
              format="dd/MM/yyyy"
              slotProps={{
                textField: {
                  placeholder: placeholder ? placeholder : "",
                  "data-testid": testId,
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={name}
                  data-testid={testId}
                  helperText={params.error && "Please provide a valid date"}
                />
              )}
            />
            {/* <FiCalendar /> */}
          </LocalizationProvider>
        )}
      />
    </DateWrapper>
  );
}

export default DatePickerInput;

const DateWrapper = styled.div`
  pointer-events: ${(props) => props.proposalRead && "none"};
  width: 100%;
  position: relative;
  right: 5px;
  label {
    color: ${(props) => (props.userForm ? "#000" : "#11555F")};
    position: absolute;
    top: ${(props) => (props.userForm ? "-69%" : "-2px")};
    left: ${(props) => (props.proposalRead ? "4px" : "3px")};
    font-size: 12px;
    font-weight: 500;
    z-index: 1;
    font-family: ${({ theme }) =>
      theme?.regularFont ? theme?.regularFont : "sans-serif"};
  }
  svg {
    position: relative;
    top: ${(props) => (props.userForm ? "-23%" : "44%")};
    color: rgba(0, 0, 0, 0.5);
    display: ${(props) => props.proposalRead && "none"};
  }

  .MuiFormControl-root {
    width: 99%;
    height: ${(props) => (props.userForm ? "40px" : "54px")};
    margin-bottom: 14px;
    input {
      cursor: pointer;
      position: absolute;
      margin-top: 18px;
      padding: 5px 0 7px 9px;
      color: #000;
      font-size: ${(props) => (props.proposalRead ? "14px" : "12px")};
      font-family: Montserrat-Medium;
      padding-left: ${(props) => props.proposalRead && "2px"};
      font-weight: ${(props) => props.proposalRead && "600"};
      border: ${(props) =>
        props.proposalRead ? "none" : ".25px solid #A9A7A7"};
      border-radius: 4px;
      background: ${(props) => (props.proposalRead ? "#fff" : "#F7F8FA")};
      ::placeholder {
        font-size: ${({ theme: { fontSize } }) =>
          fontSize ? `calc(15px + ${fontSize - 92}%)` : "12px"};
        opacity: 0.4;
        color: #000;
      }
    }
    input + div {
      position: absolute;
      top: 33px;
      right: 0;
      display: ${(props) => props.proposalRead && "none"};
    }
    & > div {
      height: 100%;
      border-radius: 10px;
      border: solid transparent;
      padding-right: 0;
      & > fieldset {
        border: none;
      }
    }
  }
`;
