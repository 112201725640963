import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

function ExcelUpload({ fieldSlug, files, setFiles }) {

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            "text/plain": [],
            "application/vnd.ms-excel": [".xls"],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
                ".xlsx",
            ],
        },
        onDrop: async (acceptedFiles) => {
            const base64Promises = acceptedFiles.map((file) =>
                new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        const base64String = reader.result.split(',')[1];
                        resolve({ file, base64String });
                    };
                    reader.readAsDataURL(file);
                })
            );

            const base64Files = await Promise.all(base64Promises);
            setFiles([...files, { [fieldSlug]: base64Files }]);
        },
    });

    useEffect(() => {
        return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
    }, []);


    return (
        <div {...getRootProps()} className="dropzone button_action">
            <input {...getInputProps()} />
            <BackupOutlinedIcon />
            <p>Upload Master</p>
        </div>
    )
}

export default ExcelUpload