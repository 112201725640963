import React from "react";
import { InputFrame } from "./style";
import { Grid } from "@mui/material";
import { Typography, Input, Button } from "../../components";
import Dropdown from "../../components/Dropdown/Dropdown";

const { StyledH1 } = Typography;

const LeadActivityForm = ({
  register,
  control,
  onSubmit,
  handleSubmit,
  leadStageOpt,
  title,
}) => {
  return (
    <>
      <InputFrame onSubmit={handleSubmit(onSubmit)}>
        <StyledH1>{title} Activity </StyledH1>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid
            item
            sx={{ position: "relative" }}
            xs={12}
            sm={12}
            md={6}
            lg={3}
          >
            <Input
              themeType={"theme1"}
              label="Lead Activity"
              name="leadActivity"
              id="leadActivity"
              maxLength="100"
              placeholder="Enter your Lead Activity"
              autoComplete="none"
              defaultValue={""}
              required={false}
              inputRef={register("leadActivity")}
            />
          </Grid>
          <Grid
            item
            sx={{ position: "relative" }}
            xs={12}
            sm={12}
            md={6}
            lg={3}
          >
            <Dropdown
              id={"leadStage"}
              selectObj={leadStageOpt}
              label={"Select Lead Stage"}
              control={control}
              maxMenuHeight={200}
            />
          </Grid>

          <Grid
            item
            sx={{ position: "relative", top: "18px" }}
            xs={12}
            sm={12}
            md={6}
            lg={4}
          >
            <Button type="submit" themeType={"theme1"} bgColor={"#11555F"}>
              {title} Activity
            </Button>
          </Grid>
        </Grid>
      </InputFrame>
    </>
  );
};

export default LeadActivityForm;
