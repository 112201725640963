import { createSlice } from "@reduxjs/toolkit";
import { serializeError } from "../../utils";

const communicationConfig = createSlice({
  name: "communicationConfig",
  initialState: {
    communicationError: null,
    communicationSuccess: null,
  },
  reducers: {
    setCommunicationSuccessAction: (state, { payload }) => {
      state.communicationSuccess = payload;
      state.communicationError = null;
    },
    setCommunicationErrorAction: (state, { payload }) => {
      state.loading = null;
      state.communicationError = serializeError(payload);
      state.communicationSuccess = null;
    },
  },
});

export const { setCommunicationSuccessAction, setCommunicationErrorAction } =
  communicationConfig.actions;
export default communicationConfig.reducer;
