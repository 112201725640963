import React from "react";
import styled from "styled-components";
import { Button } from "../../components";
import { useLocation } from "react-router-dom";
import useGetOperations from "../../hooks/useGetOperations";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import { DataGrid } from "@mui/x-data-grid";
import NoData from "../../components/NoData/NoData";
import TableSkeleton from "../../components/SkeletonLoader/TableSkeleton";
import Swal from "sweetalert2";

const CommunicationTable = ({
  setOpen,
  setModalTitle,
  templateMasterList,
  isFetching,
  deleteTemplateData,
  setId,
}) => {
  const location = useLocation();
  const { allowedOperations } = useGetOperations(location.pathname);

  const columns = [
    {
      field: "templateTitle",
      headerName: "Template Title",
      flex: 1,
      background: "#E4E4E4",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 1,
      background: "#E4E4E4",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "templateEnum",
      headerName: "Template Type",
      flex: 1,
      background: "#E4E4E4",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "vendorTemplateNumber",
      headerName: "Vendor Template Number",
      flex: 1,
      background: "#E4E4E4",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "alias",
      headerName: "Alias",
      flex: 1,
      background: "#E4E4E4",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "content",
      headerName: "Content",
      flex: 1,
      background: "#E4E4E4",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "Actions",
      headerName: "Actions",
      headerAlign: "left",
      sortable: false,
      width: 80,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <ActionContainer>
            <PreviewOutlinedIcon
              id={`view_${params.row.id}`}
              onClick={() => {
                setModalTitle("View");
                setOpen(true);
                setId(params.row.id);
              }}
              index={params.row.id}
            />
            <BorderColorOutlinedIcon
              id={`edit_${params.row.id}`}
              onClick={() => {
                setModalTitle("Modify");
                setOpen(true);
                setId(params.row.id);
              }}
              index={params.row.id}
            />
            <DeleteForeverOutlinedIcon
              id={`delete_${params.row.id}`}
              index={params.row.id}
              onClick={() => {
                Swal.fire({
                  title: "Delete record?",
                  text: "Confirmation required to delete this record.",
                  icon: "question",
                  confirmButtonText: "Delete",
                  confirmButtonColor: "#DC004E",
                  showCancelButton: true,
                  cancelButtonText: "Cancel",
                  reverseButtons: true,
                  focusConfirm: false,
                  focusCancel: false,
                  scrollbarPadding: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    deleteTemplateData(params.row.id);
                    Swal.fire("Deleted!", "Deleted Successfully", "success");
                  }
                });
              }}
            />
          </ActionContainer>
        );
      },
    },
  ];

  const rows = Array.isArray(templateMasterList?.data?.data)
    ? templateMasterList?.data?.data?.map((item) => {
        return {
          id: item?.templateId,
          templateTitle: item?.templateTitle,
          createdBy: item?.createdBy,
          templateEnum: item?.templateEnum,
          vendorTemplateNumber: item?.vendorTemplateNumber,
          alias: item?.alias,
          content: item?.content,
        };
      })
    : [];

  if (isFetching) return <TableSkeleton />;

  return (
    <>
      <TableHeaderContainer>
        <Heading>List Of Communication</Heading>
        <Button
          bgColor="transparent"
          textColor="#11555F"
          id="add_broker"
          variant="outlined"
          borderColor="#11555F"
          startIcon={<AddBoxOutlinedIcon />}
          onClick={() => {
            setModalTitle("Create");
            setOpen(true);
          }}
        >
          Create communication
        </Button>
      </TableHeaderContainer>
      <TableContainer>
        {!rows?.length ? (
          <NoData />
        ) : (
          <DataGrid
            rows={rows}
            columns={columns}
            size="small"
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
            }}
            pageSizeOptions={[5, 20]}
            sx={{
              ".MuiDataGrid-cellContent": {
                fontSize: "14px",
              },
              ".MuiDataGrid-columnSeparator": {
                display: "none",
              },
              "&.MuiDataGrid-root": {
                border: "none",
              },
              ".MuiDataGrid-columnHeaders": {
                background: "#D2F8E9",
                color: "#11555F",
                fontSize: "14px",
                fontWeight: 500,
                fontFamily: "Montserrat-Medium",
              },
              ".MuiTablePagination-root p": {
                fontSize: "14px",
              },
            }}
          />
        )}
      </TableContainer>
    </>
  );
};

export default CommunicationTable;

const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 12px;
`;

const Heading = styled.h2`
  margin-top: -3px;
  margin-bottom: 0;
  display: flex;
  color: #178e80;
  font-family: "Montserrat-SemiBold";
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
`;

const ButtonFrame = styled.div`
  position: relative;
  left: -1px;
`;

const TableContainer = styled.div`
  // padding-top: 10px;
  background: #fff;
  width: 100%;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  svg {
    color: #178e80;
    font-size: 20px;
  }
`;
