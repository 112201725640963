import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HttpClient from "../../api/httpClient";
import { useDispatch } from "react-redux";
import { setMasterConfigErrorAction, setMasterConfigSuccessAction } from "./MasterConfig.slice";

export const useGetAllMastersList = () => {
    const { data, isFetching, isError } = useQuery({
        queryKey: ["all_masters"],
        queryFn: () => HttpClient("master_data_manager/list"),
    });
    return { data, isFetching, isError };
};

export const useGetMasterDetailsById = ({ id }) => {
    const { data, isFetching, isError } = useQuery({
        queryKey: ["master_by_id", id],
        queryFn: () =>
            HttpClient("master_data/listing", {
                method: "POST",
                data: {
                    masterId: id,
                    lobId: 0,
                },
            }),
        enabled: id ? true : false,
    });
    return { data, isFetching, isError };
};

export const useAddDataInMaster = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation(
        (payload) => {
            return HttpClient("master_data/add_record", {
                method: "POST",
                data: payload,
            });
        },
        {
            onSuccess: (data) => {
                if (!data?.data?.status) {
                    dispatch(setMasterConfigErrorAction(data?.data?.message));
                    return;
                }
                dispatch(setMasterConfigSuccessAction(data?.data?.message));
                queryClient.invalidateQueries(["master_by_id"])
            },
        }
    );
    return { mutate, isLoading };
};

export const useGetUniqueMasterDataById = ({ masterId, uniqueKey }) => {
    const { data, isFetching, isError } = useQuery({
        queryKey: ["unique_master_data_by_id", masterId + "" + uniqueKey],
        queryFn: () =>
            HttpClient("master_data/listById", {
                method: "POST",
                data: {
                    masterId,
                    uniqueKey,
                },
            }),
        enabled: masterId && uniqueKey ? true : false,
    });
    return { data, isFetching, isError };
};

export const useUpdateDataInMaster = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation(
        (payload) => {
            return HttpClient("master_data/update_record", {
                method: "POST",
                data: payload,
            });
        },
        {
            onSuccess: (data) => {
                if (!data?.data?.status) {
                    dispatch(setMasterConfigErrorAction(data?.data?.message));
                    return;
                }
                dispatch(setMasterConfigSuccessAction(data?.data?.message));
                queryClient.invalidateQueries(["master_by_id"])
                queryClient.invalidateQueries(["unique_master_data_by_id"])
            },
        }
    );
    return { mutate, isLoading };
};

export const useInactiveDataInMaster = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation(
        (payload) => {
            return HttpClient("master_data/update_status", {
                method: "POST",
                data: payload,
            });
        },
        {
            onSuccess: (data) => {
                if (!data?.data?.status) {
                    dispatch(setMasterConfigErrorAction(data?.data?.message));
                    return;
                }
                dispatch(setMasterConfigSuccessAction(data?.data?.message));
                queryClient.invalidateQueries(["master_by_id"])
            },
        }
    );
    return { mutate, isLoading };

}

