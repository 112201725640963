import React from "react";
import styled from "styled-components";
import { Typography } from "../../components";
import { RoleTable } from "./role-table/role-table";
import { RoleForm } from "./role-form/role-form";

const { StyledH1 } = Typography;

const RoleAccess = () => {
  return (
    <StyledDiv>
      <StyledH1
        themeType="theme1"
        partialU
        color={"rgb(23, 142, 128)"}
        align={"left"}
      >
        Assign Role Access
      </StyledH1>
      <RoleForm />
      <TableFrame>
        <RoleTable />
      </TableFrame>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
`;

const TableFrame = styled.div`
  max-width: 99.5%;
  padding-top: 10px;
`;

export default RoleAccess;
