import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HttpClient from "../../api/httpClient";
import { useDispatch } from "react-redux";
import { setLeadErrorAction, setLeadSuccessAction } from "./Lead.slice";
import httpClient from "../../api/httpClient";

export const useGetLead = (payload) => {
  const { data, isFetching, isError, refetch } = useQuery({
    queryKey: ["LeadListing"],
    queryFn: () =>
      HttpClient("lead_master_controller/listing", {
        method: "POST",
        data: payload,
      }),
  });
  return { data, isFetching, isError, refetch };
};

export const useGetLeadById = (id) => {
  const { data } = useQuery({
    queryKey: ["LeadById", id],
    queryFn: () => HttpClient(`lead_master_controller/list/${id}`),
    enabled: id ? true : false,
  });
  return { data };
};

export const useCreateLead = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate, isLoading, isError } = useMutation(
    (payload) => {
      return HttpClient(`lead_master_controller/add`, {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        if (data?.data?.status) {
          queryClient.invalidateQueries("LeadListing");
          dispatch(setLeadSuccessAction(data?.data?.message));
        } else {
          dispatch(setLeadErrorAction(data?.data?.message));
        }
      },
    }
  );

  return { mutate, isLoading, isError };
};

export const useDeleteLead = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate, isLoading, isError } = useMutation(
    (id) => {
      return HttpClient(`/lead_master_controller/delete/${id}`, {
        method: "DELETE",
      });
    },
    {
      onSuccess: (data) => {
        if (data?.data?.status) {
          queryClient.invalidateQueries("LeadListing");
          dispatch(setLeadSuccessAction(data?.data?.message));
        } else {
          dispatch(setLeadErrorAction(data?.data?.message));
        }
      },
    }
  );

  return { mutate, isLoading, isError };
};

export const useUpdateLead = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate, isLoading, isError } = useMutation(
    (payload) => {
      const { id, ...restPayload } = payload;
      return HttpClient(`/lead_master_controller/update/${id}`, {
        method: "PUT",
        data: restPayload,
      });
    },
    {
      onSuccess: (data) => {
        if (data?.data?.status) {
          queryClient.invalidateQueries("LeadListing");
          dispatch(setLeadSuccessAction(data?.data?.message));
        } else {
          dispatch(setLeadErrorAction(data?.data?.message));
        }
      },
    }
  );

  return { mutate, isLoading, isError };
};
export const useGetProductList = () => {
  const { data } = useQuery(["productList"], () => {
    return httpClient(`product_master/list`, {
      method: "POST",
      data: {
        page: 0,
        size: 0,
        sortBy: "",
        sortOrder: "",
        searchValue: "",
      },
    });
  });
  return { productData: data?.data?.data };
};
