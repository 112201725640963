import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { Button, Input } from "../../components";
import Dropdown from "../../components/Dropdown/Dropdown";

const CommunicationForm = ({ templateEnumOBJ }) => {
  const { register, control, handleSubmit, reset, setValue } = useForm();

  const onSubmit = (formData) => { };

  const resetFeilds = () => {
    reset();
    setValue("templateEnum", null);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Input
            themeType={"theme1"}
            label="Template Tilte"
            name="templateTitle"
            id="templateTitle"
            maxLength="100"
            placeholder="Enter Template Tilte"
            autoComplete="none"
            required={false}
            inputRef={register("templateTitle")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Input
            themeType={"theme1"}
            label="Created By"
            name="createdBy"
            id="createdBy"
            maxLength="100"
            placeholder="Enter Created By"
            autoComplete="none"
            required={false}
            inputRef={register("createdBy")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Dropdown
            control={control}
            maxMenuHeight={200}
            id="templateEnum"
            placeholder="Template Type"
            selectObj={templateEnumOBJ}
            label={"Template Type"}
            isRequired={true}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Input
            themeType={"theme1"}
            label="Vendor Template Number"
            name="vendorTemplateNumber"
            id="vendorTemplateNumber"
            maxLength="100"
            placeholder="Enter Vendor Template Number"
            autoComplete="none"
            required={false}
            inputRef={register("vendorTemplateNumber")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Input
            themeType={"theme1"}
            label="Alias"
            name="alias"
            id="alias"
            maxLength="100"
            placeholder="Enter Alias Number"
            autoComplete="none"
            required={false}
            inputRef={register("alias")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Input
            themeType={"theme1"}
            label="Content"
            name="content"
            id="content"
            maxLength="100"
            placeholder="Enter Content"
            autoComplete="none"
            required={false}
            inputRef={register("content")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <ButtonFrame>
            <Button
              type="submit"
              themeType={"theme1"}
              id="search_btn"
              bgColor={"#11555F"}
            >
              Search
            </Button>
            <Button
              themeType={"theme1"}
              btnBack={"tertiary"}
              id="reset_btn"
              textColor="#11555F"
              bgColor="transparent"
              borderColor="none"
              onClick={resetFeilds}
            >
              Reset
            </Button>
          </ButtonFrame>
        </Grid>
      </Grid>
    </form>
  );
};

export default CommunicationForm;

const ButtonFrame = styled(Grid)`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 18px;
  gap: 15px;
`;
