import React from "react";
import { Input, Button, Select } from "../../../components";
import styled from "styled-components";
import { Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import CachedIcon from "@mui/icons-material/Cached";

export const RoleForm = () => {
  const optionsData = [
    {
      id: "",
      name: "Select",
      value: "",
    },
    {
      id: 2,
      name: "Data",
      value: "data",
    },
  ];
  return (
    <InputFrame>
      <Grid
        style={{ marginBottom: "10px", paddingBottom: "0px" }}
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <StyledGrid item xs={10} md={3} sm={10} lg={3}>
          <Select
            themeType="theme2"
            name="department"
            id="1"
            value="department"
            label="Department"
            options={optionsData}
          />
        </StyledGrid>
        <StyledGrid item xs={10} md={3} sm={10} lg={3}>
          <Select
            themeType="theme2"
            name="designation"
            id="1"
            value="designation"
            label="Designation"
            options={optionsData}
          />
        </StyledGrid>
        <StyledGrid item xs={10} md={3} sm={10} lg={3}>
          <Input
            themeType="theme2"
            label="Role"
            name="role"
            id="role"
            maxLength="50"
            placeholder="Enter Role"
            autoComplete="none"
            defaultValue={""}
            required={false}
          />
        </StyledGrid>
        <StyledGrid item xs={10} md={3} sm={10} lg={3}>
          <Select
            themeType="theme2"
            name="module_name"
            id="1"
            value="module_name"
            label="Module Name"
            options={optionsData}
          />
        </StyledGrid>
      </Grid>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={10} sm={10} md={12} lg={12}>
          <ButtonFrame>
            <Button
              themeType="theme2"
              style={{ marginLeft: "10px" }}
              bgColor="#B8AD2B"
              round
              startIcon={<SearchIcon />}
            >
              Search
            </Button>
            <Button
              themeType="theme2"
              btnBack="secondary"
              style={{ marginLeft: "10px" }}
              round
              startIcon={<AddIcon />}
            >
              Add Role
            </Button>
            <Button
              themeType="theme2"
              btnBack="tertiary"
              style={{ marginLeft: "10px" }}
              round
              startIcon={<CachedIcon />}
            >
              Reset
            </Button>
          </ButtonFrame>
        </Grid>
      </Grid>
    </InputFrame>
  );
};

const StyledGrid = styled(Grid)`
  position: relative;
  .MuiButtonBase-root {
    margin-left: 10px;
  }
  .buttonMargin {
    margin-left: 10px !important;
  }
`;

const InputFrame = styled.form`
  margin-top: 30px;
  width: 100%;
`;

const ButtonFrame = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  @media (max-width: 767px) {
    justify-content: flex-start;
  }
`;
