import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import {
  Button,
  DatePickerInput,
  Typography
} from "../../../components";
import Dropdown from "../../../components/Dropdown/Dropdown";
import {
  useGetManufacturerDataById,
  useGetModelDataForCustomer,
  useGetVersionDataForCustomer,
} from "../service";

const AddVehicleform = ({
  modalTitle,
  LOB_Obj,
  getvehicleDetails,
  rowData,
}) => {
  const { Error } = Typography;
  const yupValidate = yup.object({
    lob: yup.mixed().required("Lob is required"),
    maufacturer: yup.mixed().required("Manufacturer is required"),
    modal: yup.mixed().required("Modal is required"),
    versionNo: yup.mixed().required("Version is required"),
    policyStartDate: yup
      .date()
      .typeError("Enter a valid date")
      .required("Start date is required"),
    policyEndDate: yup
      .date()
      .typeError("Enter a valid date")
      .required("End date is required")
      .min(yup.ref('policyStartDate'), 'Must be greater than policy start date')
  });
  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yupValidate),
    mode: "all",
    reValidateMode: "onChange",
  });

  const { lob, maufacturer, modal, version } = watch();
  const { data: manufacturerData } = useGetManufacturerDataById(lob?.lobId);
  const {
    data: modalData,
    mutate: getModalData,
  } = useGetModelDataForCustomer();
  const {
    data: versionData,
    mutate: getVersionData,
  } = useGetVersionDataForCustomer();

  const LOB_OBJ_Filtered = Array.isArray(LOB_Obj)
    ? LOB_Obj.filter((value) =>
      ["GCV", "PCV", "Car", "Bike"].includes(value.label)
    )
    : [];

  const manufacturerOBJ = manufacturerData?.data.map((item) => {
    return {
      id: item?.manf_id,
      value: item?.manf_name,
      label: item?.manf_name,
    };
  });

  const modalDataOBJ = modalData?.data.map((item) => {
    return {
      id: item?.model_id,
      value: item?.model_name,
      label: item?.model_name,
    };
  });
  const versionDataObj = versionData?.data.map((item) => {
    return {
      id: item?.id,
      value: item?.version_name,
      label: item?.version_name,
    };
  });

  useEffect(() => {
    if (maufacturer?.id && lob?.lobId) {
      getModalData({ lobid: lob?.lobId, manufacturer_id: maufacturer.id });
    }
    if (modal?.id && lob?.lobId) {
      getVersionData({ lobid: lob?.lobId, model_id: modal.id });
    }
  }, [maufacturer, modal, lob]);

  const addVehicleDetails = (formData) => {
    const payload = [
      {
        customer_id: rowData?.id,
        make: formData?.maufacturer?.value,
        model: formData?.modal?.value,
        version: formData?.versionNo?.value,
        lob: formData?.lob?.value,
        lob_id: formData?.lob?.lobId,
        policyStartDate: formData?.policyStartDate,
        policyEndDate: formData?.policyEndDate,
      },
    ];
    getvehicleDetails(payload);
  };
  return (
    <form onSubmit={handleSubmit(addVehicleDetails)}>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{
          padding: "10px 20px",
        }}
      >
        <Grid item sx={{ position: "relative" }} xs={12} sm={12} md={3} lg={4}>
          <DatePickerInput
            themeType="theme1"
            label="Policy Start Date"
            name="policyStartDate"
            id="policyStartDate"
            control={control}
            placeholder="Enter Policy Start Date"
            isRequired={modalTitle === "View" ? false : true}
            proposalRead={modalTitle === "View" ? true : false}
            readOnly={modalTitle === "View" ? true : false}
          />
          {!!errors?.policyStartDate && (
            <Error top="3px" left="3px">
              {errors.policyStartDate.message}
            </Error>
          )}
        </Grid>
        <Grid item sx={{ position: "relative" }} xs={12} sm={12} md={3} lg={4}>
          <DatePickerInput
            themeType="theme1"
            label="Policy End Date"
            name="policyEndDate"
            id="policyEndDate"
            control={control}
            minDate={watch('policyStartDate')}
            placeholder="Enter Date Of Birth"
            isRequired={modalTitle === "View" ? false : true}
            proposalRead={modalTitle === "View" ? true : false}
            readOnly={modalTitle === "View" ? true : false}
          />
          {!!errors?.policyEndDate && (
            <Error top="3px" left="3px">
              {errors.policyEndDate.message}
            </Error>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={4} sx={{ position: "relative" }}>
          <Dropdown
            id="lob"
            label="Line of Business"
            control={control}
            selectObj={LOB_OBJ_Filtered}
            isMulti={false}
            isRequired={true}
            maxMenuHeight={200}
            proposalRead={false}
          />
          {!!errors?.lob && (
            <Error top="3px" left="3px">
              {errors.lob.message}
            </Error>
          )}
        </Grid>

        {manufacturerData && (
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={4}
            sx={{ position: "relative" }}
          >
            <Dropdown
              id="maufacturer"
              label="Manufacturer"
              control={control}
              selectObj={manufacturerOBJ}
              isMulti={false}
              isRequired={true}
              maxMenuHeight={200}
              proposalRead={false}
            />
            {!!errors?.maufacturer && (
              <Error top="3px" left="3px">
                {errors.maufacturer.message}
              </Error>
            )}
          </Grid>
        )}
        {modalData && (
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={4}
            sx={{ position: "relative" }}
          >
            <Dropdown
              id="modal"
              label="Modal"
              control={control}
              selectObj={modalDataOBJ}
              isMulti={false}
              isRequired={true}
              maxMenuHeight={200}
              proposalRead={false}
            />
            {!!errors?.modal && (
              <Error top="3px" left="3px">
                {errors.modal.message}
              </Error>
            )}
          </Grid>
        )}
        {versionData && (
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={4}
            sx={{ position: "relative" }}
          >
            <Dropdown
              id="versionNo"
              label="Version"
              control={control}
              selectObj={versionDataObj}
              isMulti={false}
              isRequired={true}
              maxMenuHeight={200}
              proposalRead={false}
            />
            {!!errors?.versionNo && (
              <Error top="3px" left="3px">
                {errors.versionNo.message}
              </Error>
            )}
          </Grid>
        )}
      </Grid>
      {modalTitle !== "View" ? (
        <ButtonFrame modalTitle={modalTitle}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
            className="button_container"
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <NextButtonWrap>
                <Button
                  type="submit"
                  btnBack={"secondary"}
                  bgColor={"#11555F"}
                  themeType={"theme1"}
                >
                  {modalTitle === "Modify" ? "Update" : "Add"}
                </Button>
              </NextButtonWrap>
            </Grid>
          </Grid>
        </ButtonFrame>
      ) : (
        <></>
      )}
    </form>
  );
};

export default AddVehicleform;

const NextButtonWrap = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
`;

const ButtonFrame = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
  margin-top: 0;
  background: #fff;
  border-top: 1px solid #ccc;

  z-index: 10;
  padding: 12px 18px 12px 0px;
`;
