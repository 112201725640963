import { useState, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';

function OnlineStatusChecker() {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const location = useLocation();

    useEffect(() => {
        window.addEventListener('offline', handleOnlineStatusChange);

        return () => {
            window.removeEventListener('offline', handleOnlineStatusChange);
        };
    }, []);

    const handleOnlineStatusChange = () => {
        setIsOnline(navigator.onLine);
    };

    if (!isOnline) {
        return <Navigate to="/offline" state={{ from: location }} replace />
    }
    return <Outlet />
}

export default OnlineStatusChecker;
