import CallMade from "@mui/icons-material/CallMade";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import _ from "lodash";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import Swal from "sweetalert2";
import { Button } from "../../components";

function ProductFilePicker({
  label,
  name,
  control,
  watch,
  setValue,
  unregister,
  trigger,
  isReadOnly,
}) {
  const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
    if (!_.isEmpty(rejectedFiles)) {
      console.log({ rejectedFiles: rejectedFiles?.[0]?.errors?.[0]?.code });

      Swal.fire({
        text: rejectedFiles?.[0]?.errors?.[0]?.code
          ? "File Larger than 1MB"
          : "File Format not supported",
        icon: "warning",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        allowOutsideClick: false,
        focusCancel: false,
        scrollbarPadding: false,
      });
      return;
    }
    const base64Promises = acceptedFiles.map(
      (file) =>
        new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = () => {
            const base64String = reader.result.split(",")[1];
            resolve({ file, base64String });
          };
          reader.readAsDataURL(file);
        })
    );

    const base64Files = await Promise.all(base64Promises);

    setValue(name, base64Files);
    trigger([name]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "text/plain": [".pdf"],
    },
    maxSize: 1024 * 1024,
  });

  const handleRemove = (name) => {
    unregister(name);
    setValue(name, null);
  };

  function isValidURL(text) {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return urlRegex.test(text);
  }

  const handleRealFilePreview = (url) => {
    if (url) {
      window.open(url, "_blank");
    }
  };
  return (
    <>
      <FilePickerContainerStyled readOnlyStyles={isReadOnly}>
        <HeadingStyled>
          {label} <span className="required-label">*</span>
        </HeadingStyled>

        {!isReadOnly ? (
          <FilePickerAreaStyled>
            <Controller
              name={name}
              control={control}
              render={() => (
                <div {...getRootProps()} id="menu_file">
                  <input {...getInputProps()} />
                  <FileUploadOutlinedIcon />
                  <div>
                    <p>
                      Drag your file here or <b>Browse</b>
                    </p>
                    <p className="warnings">Accepted File Formats: .pdf</p>
                    <p className="warnings">File Size Must be Less than 1MB</p>
                  </div>
                </div>
              )}
            />
          </FilePickerAreaStyled>
        ) : null}

        <FilePreviewStyled>
          {Array.isArray(watch(name)) && watch(name)?.length ? (
            <div class="preview_container">
              <p className="file_name">
                {watch(name)?.[0]?.file?.name.length < 25
                  ? watch(name)?.[0]?.file?.name
                  : watch(name)?.[0]?.file?.name.substring(0, 25) + "..."}
              </p>
              <p>{Math.floor(watch(name)?.[0]?.file?.size / 1024)} KB</p>
              <p className="cancel_file" onClick={() => handleRemove(name)}>
                X
              </p>
            </div>
          ) : isValidURL(watch(name)) ? (
            <RealPreviewStyled readOnlyStyles={isReadOnly}>
              <Button
                bgColor="transparent"
                textColor="#11555F"
                id="add_broker"
                variant="outlined"
                borderColor="#11555F"
                endIcon={<CallMade />}
                onClick={() => handleRealFilePreview(watch(name))}
              >
                Preview an Uploaded File
              </Button>
            </RealPreviewStyled>
          ) : (
            <></>
          )}
        </FilePreviewStyled>
      </FilePickerContainerStyled>
    </>
  );
}

export default ProductFilePicker;

const FilePickerAreaStyled = styled.div`
  width: 50%;
  cursor: pointer;
`;

const FilePreviewStyled = styled.div`
  width: 50%;
  .preview_container {
    position: relative;
    margin-right: 20px;
    border: 0.25px solid;
    border-radius: 5px;
    padding: 5px;
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    font-family: Montserrat-SemiBold;
    & > * {
      margin: 0;
    }
    .cancel_file {
      background: #fff;
      position: absolute;
      top: -13px;
      left: 97%;
      border-radius: 50%;
      border: 0.25px solid;
      width: 25px;
      height: 25px;
      text-align: center;
      cursor: pointer;
    }
  }
`;

const RealPreviewStyled = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${(props) =>
    props.isInDatagrid || props.readOnlyStyles ? "0" : "30px"};
  margin-right: 20px;
  justify-content: ${(props) =>
    props.readOnlyStyles ? "flex-start" : "flex-end"};
  margin-left: ${(props) => (props.readOnlyStyles ? "10px" : "0")};
`;

const FilePickerContainerStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 2px dashed #e4e4e4;
  padding: 13px 0;
  margin-top: 5px;
  font-size: 1.25rem;
  margin-bottom: ${(props) => (props.readOnlyStyles ? "10px" : "0")};
  #menu_file {
    display: flex;
    align-items: center;
    p {
      font-size: 14px;
      font-weight: 600;
      font-family: Montserrat-SemiBold;
      margin-left: 10px;
    }
    .warnings {
      color: red;
      font-size: 12px;
      margin-top: -10px;
    }
  }
  svg {
    color: #178e80;
    font-size: 2.5rem;
    margin-left: 10px;
  }
  b {
    color: rgb(23, 142, 128);
    text-decoration: underline;
  }
`;

const HeadingStyled = styled.h3`
  margin: 0 0 5px 14px;
  width: 100%;
  color: rgb(17, 85, 95);
  font-size: 14px;
  font-weight: 500;
  font-family: Montserrat-SemiBold;
  span {
    color: red;
    position: relative;
    left: 0px;
    top: -5px;
    font-size: 12px;
  }
`;
