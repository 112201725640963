import { createSlice } from "@reduxjs/toolkit";
import { serializeError } from "../../utils";

const documentConfig = createSlice({
    name: "documentConfig",
    initialState: {
        documentError: null,
        documentSuccess: null,
    },
    reducers: {
        setDocumentSuccessAction: (state, { payload }) => {
            state.documentSuccess = payload;
            state.documentError = null;
        },
        setDocumentErrorAction: (state, { payload }) => {
            state.loading = null;
            state.documentError = serializeError(payload);
            state.documentSuccess = null;
        },
    },
});

export const { setDocumentSuccessAction, setDocumentErrorAction } =
documentConfig.actions;
export default documentConfig.reducer;
