import { createSlice } from "@reduxjs/toolkit";
import { serializeError } from "../../utils";

const customer = createSlice({
  name: "customer",
  initialState: {
    customerError: null,
    customerSuccess: null,
  },
  reducers: {
    setCustomerSuccessAction: (state, { payload }) => {
      state.customerSuccess = payload;
      state.customerError = null;
    },
    setCustomerErrorAction: (state, { payload }) => {
      state.loading = null;
      state.customerError = serializeError(payload);
      state.customerSuccess = null;
    },
  },
});

export const { setCustomerSuccessAction, setCustomerErrorAction } =
  customer.actions;
export default customer.reducer;
