import httpClient from "../../api/httpClient";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

export const useGetLeadStage = () => {
  const { data } = useQuery({
    queryKey: ["leadStageData"],
    queryFn: () => httpClient(`lead_stage_master/list`),
  });
  return { data };
};

export const useAddLeadActivity = () => {
  const queryClient = useQueryClient();
  const { mutate, data } = useMutation(
    (payload) => {
      return httpClient(`lead_activity_master/add`, {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        if (data?.data?.status) {
          queryClient.invalidateQueries("leadActivityByLeadId");
        }
      },
    }
  );
  return { mutate, data };
};

export const useActivityList = () => {
  const { data } = useQuery({
    queryKey: ["activityList"],
    queryFn: () => httpClient(`lead_activity_master/list`),
  });
  return { data };
};

export const useDeleteLeadActivity = () => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    (id) => {
      return httpClient(`lead_activity_master/delete/${id}`, {
        method: "DELETE",
      });
    },
    {
      onSuccess: (data) => {
        if (data?.data?.status) {
          queryClient.invalidateQueries("activityList");
          queryClient.invalidateQueries("leadActivityByLeadId");
        }
      },
    }
  );
  return { mutate };
};

export const useGetLeadActivityById = (id) => {
  const { data } = useQuery({
    queryKey: ["leadActivity", id],
    queryFn: () => httpClient(`lead_activity_master/list/${id}`),
    enabled: id ? true : false,
  });

  return { data };
};

export const useUpdataLeadActivity = () => {
  const queryClient = useQueryClient();
  const { mutate, data } = useMutation(
    (payload) => {
      return httpClient(`lead_activity_master/update/${payload.id}`, {
        method: "PUT",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        if (data?.data?.status) {
          queryClient.invalidateQueries("leadActivityByLeadId");
        }
      },
    }
  );
  return { mutate, data };
};

export const useGetLeadActivityByLeadId = (id) => {
  const { data } = useQuery({
    queryKey: ["leadActivityByLeadId", id],
    queryFn: () => httpClient(`/lead_activity_master/findByLeadId/${id}`),
    enabled: id ? true : false,
  });
  return { data };
};
