import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HttpClient from "../../api/httpClient";
import httpClient from "../../api/httpClient";

// User Controller
export const getUserData = () => {
  const params = new URLSearchParams(); //data is static as of now
  params.append("page", "1");
  params.append("size", "100");
  params.append("sortBy", "user_id");
  params.append("sortOrder", "DESC");
  params.append("searchValue", "");

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, refetch } = useQuery({
    queryKey: ["usercontroller"],
    queryFn: () =>
      HttpClient(`users/list/Paging_Sorting?${params.toString()}`, {
        method: "POST",
      }),
  });
  return { data, refetch };
};
export const getUserDataId = (id) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data } = useQuery(
    ["userData", id],
    () => HttpClient(`users/list/${id}`, { method: "GET" }),
    {
      enabled: id === null || "" ? false : true,
    }
  );

  return { data };
};
export const userControllerDelete = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const queryClient = useQueryClient();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate } = useMutation(
    (id) => HttpClient(`users/delete/${id}`, { method: "DELETE" }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["usercontroller"]);
      },
    }
  );
  return { mutate };
};
export const addUserDraft = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate, data } = useMutation((payload) =>
    HttpClient("users-draft/update-draft", { method: "POST", data: payload })
  );
  return { mutate, data };
};
export const updateUserController = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate } = useMutation((payload) => {
    return HttpClient(`users-draft/${payload.id}`, {
      method: "PUT",
      data: payload,
    });
  });
  return { mutate };
};
export const finalUserSubmit = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate, data } = useMutation((payload) =>
    HttpClient("users-draft/final-submit", { method: "POST", data: payload })
  );
  return { mutate, data };
};

export const updateUserDraft = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate, data } = useMutation((payload) => {
    return HttpClient(`users-draft/final-submit/update/${payload.id}`, {
      method: "PUT",
      data: payload,
    });
  });
  return { mutate, data };
};
export const useGetRoles = () => {
  const { data } = useQuery({
    queryKey: ["roles"],
    queryFn: () =>
      HttpClient("role/list", {
        method: "GET",
      }),
  });
  return { data };
};
export const useGetBranch = () => {
  const { data } = useQuery({
    queryKey: ["Branch"],
    queryFn: () =>
      HttpClient("masterBranch/list", {
        method: "GET",
      }),
  });
  return { data };
};

export const useGetBranches = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, refetch, isFetching } = useQuery({
    queryKey: ["brokerlocation"],
    queryFn: () =>
      HttpClient("location/list", {
        method: "POST",
        data: {
          page: 0,
          size: 0,
          sortBy: "",
          sortOrder: "",
          officeCode: "",
          pincode: "",
        },
      }),
  });
  return { data, refetch, isFetching };
};
export const useGetUserType = () => {
  const { data } = useQuery({
    queryKey: ["user_type"],
    queryFn: () =>
      HttpClient("user_type_controller/list", {
        method: "GET",
      }),
  });
  return { data };
};
export const useGetChannel = () => {
  const { data } = useQuery({
    queryKey: ["channel"],
    queryFn: () =>
      HttpClient("Channel_master_controller/list", {
        method: "GET",
      }),
  });
  return { data };
};
export const useGetDepartment = () => {
  const { data } = useQuery({
    queryKey: ["department"],
    queryFn: () =>
      HttpClient("department_master/list", {
        method: "GET",
      }),
  });
  return { data };
};
export const useGetPincode = (pincode) => {
  const { data } = useQuery({
    queryKey: ["pincode", pincode],
    queryFn: () => HttpClient(`pincode/list/${pincode}`),
    enabled: pincode && pincode.length === 6 ? true : false,
  });
  return { data };
};

export const useGetUserDraftById = (id) => {
  const { data } = useQuery({
    queryKey: ["userDraftData", id],
    queryFn: () => httpClient(`users-draft/${id}`),
    enabled: Boolean(id),
  });
  return { data };
};
export const useGetUserName = (id) => {
  const { data } = useQuery({
    queryKey: ["userName", id],
    queryFn: () => httpClient(`/users-draft/userNameExists/${id}`),
    enabled: Boolean(id),
  });
  return { data };
};
