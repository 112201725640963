import { yupResolver } from "@hookform/resolvers/yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Button, Grid, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import * as yup from "yup";
import { Typography } from "../../components";
import fyntune from "../../utils/fyntune.png";
import { useUpdatePassword } from "./service";
const { Error } = Typography;

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const token = pathSegments[pathSegments.length - 1];
  const schema = yup.object().shape({
    newPassword: yup
      .string()
      .required("New Password is required")
      .min(8, "Password must be at least 8 characters"),
    confirmPassword: yup
      .string()
      .required("Confirm Password is required")
      .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "all",
    mode: "all",
  });
  const { mutate, data } = useUpdatePassword();
  useEffect(() => {
    if (data && data?.data.status) {
      Swal.fire({ text: data?.data.message, icon: "success" }).then(
        (result) => {
          if (result.isConfirmed) {
            navigate("/");
          }
        }
      );
    }
    if (data && !data?.data.status) {
      Swal.fire({ text: data?.data.message, icon: "error" });
    }
  }, [data]);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const confirmShowPassword = () => {
    setConfirmShow(!confirmShow);
  };
  const onSubmit = (data) => {
    const payload = {
      new_password: data.confirmPassword,
      token: token,
    };
    mutate(payload);
  };

  return (
    <Container>
      <ContaierChild>
        <StyledImage src={fyntune} alt={"fyntune"} />
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Heading>Update Password</Heading>
            <Grid container rowSpacing={2} columnSpacing={2}>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={12}
                sx={{ position: "relative" }}
              >
                <TextField
                  {...register("newPassword")}
                  label="New Password"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        aria-label="toggle password visibility"
                        onClick={togglePasswordVisibility}
                      >
                        <IconButton>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {!!errors.newPassword && (
                  <Error bottom="-5px" left="3px">
                    {errors.newPassword.message}
                  </Error>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={12}
                sx={{ position: "relative" }}
              >
                <TextField
                  {...register("confirmPassword")}
                  label="Confirm Password"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type={confirmShow ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        aria-label="toggle password visibility"
                        onClick={confirmShowPassword}
                      >
                        <IconButton>
                          {confirmShow ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {!!errors.confirmPassword && (
                  <Error bottom="-5px" left="3px">
                    {errors.confirmPassword.message}
                  </Error>
                )}
              </Grid>
            </Grid>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              style={{ marginTop: "16px" }}
            >
              Update Password
            </Button>
          </form>
        </Grid>
      </ContaierChild>
    </Container>
  );
};
const StyledImage = styled.img`
  width: auto;
  height: 38px;
  margin-bottom: 15px;
  @media (max-width: 767px) {
    height: 32px;
  }
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh !important;
`;
const ContaierChild = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 30px;
  border: 1px solid lightgrey;
  box-shadow: 0px 22px 45px -15px #dddde2;
`;
const Heading = styled.h3`
  margin: 10px 0 6px;
  font-size: 21px;
  font-weight: 100;
`;
export default ResetPassword;
