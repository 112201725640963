import { yupResolver } from "@hookform/resolvers/yup";
import { FormControlLabel, Grid, Switch } from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import Swal from "sweetalert2";
import * as yup from "yup";
import { Button, Input, Typography } from "../../../components";
import {
  addNomenclature,
  updateNomenclature,
} from "../../broker-configuration/service";
import { useGetNomenclatureById } from "../services";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setNomenclatureErrorAction } from "../nomenclature.slice";
import _ from "lodash";

export const NomenclatureForm = ({
  nomenclatureId,
  modalTitle,
  handleClose,
}) => {
  const { Error } = Typography;

  const yupValidate = yup.object({});

  const InputArray = [
    {
      label: "Head Office",
      name: "officeName",
    },
    {
      label: "Office 1",
      name: "officeName",
    },
    {
      label: "Office 2",
      name: "officeName",
    },
    {
      label: "Office 3",
      name: "officeName",
    },
    {
      label: "Office 4",
      name: "officeName",
    },
    {
      label: "Office 5",
      name: "officeName",
    },
    {
      label: "Office 6",
      name: "officeName",
    },
    {
      label: "Office 7",
      name: "officeName",
    },
  ];

  const { mutate } = addNomenclature();
  const { mutate: updateNomen } = updateNomenclature();
  let nomenClatureData = useGetNomenclatureById(nomenclatureId);

  useEffect(() => {
    if (modalTitle === "Modify" || modalTitle === "View") {
      setValue("officeName", nomenClatureData?.officeName);
    }
  }, [nomenClatureData, modalTitle]);

  const { register, handleSubmit, setValue, control } = useForm({
    resolver: yupResolver(yupValidate),
    mode: "all",
    reValidateMode: "onChange",
  });

  const addBrokerNomenclature = (value) => {
    if (modalTitle === "Modify") {
      updateNomen([
        {
          brokerOfficeNomenclaturesid:
            nomenClatureData?.brokerOfficeNomenclaturesid,
          officeName: value.officeName,
          isHeadOffice: value.isHeadOffice,
        },
      ]);
    } else {
      const response = value.response.filter(
        (item) => !_.isEmpty(item.officeName)
      );
      mutate(response);
    }
    handleClose();
  };

  return (
    <NomenclatureModal className="demo">
      {/* <div> */}
      <form onSubmit={handleSubmit(addBrokerNomenclature)}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {modalTitle === "Modify" || modalTitle === "View" ? (
            nomenClatureData?.isHeadOffice === "Y" ? (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Input
                    themeType={"theme1"}
                    labelStyle={{ fontSize: "13px" }}
                    label="Head Office"
                    name="officeName"
                    id="officeName"
                    placeholder="Enter Head Office"
                    autoComplete="none"
                    required={false}
                    inputRef={register("officeName")}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    textAlign: "left",
                    fontSize: "20px",
                    padding: "10px 10px 10px 13px !important",
                    marginTop: "5px",
                    color: "#20b2aa",
                    position: "relative",
                    left: "15px",
                  }}
                >
                  This is Your Head Office
                </Grid>
                <input type="hidden" value="Y" {...register(`isHeadOffice`)} />
              </>
            ) : (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Input
                    themeType={"theme1"}
                    labelStyle={{ fontSize: "13px" }}
                    label="Office Name"
                    name="officeName"
                    id="officeName"
                    placeholder={
                      modalTitle === "View" ? "" : "Enter Office Name"
                    }
                    autoComplete="none"
                    required={false}
                    inputRef={register("officeName")}
                    readOnly={modalTitle === "View" ? true : false}
                    proposalRead={modalTitle === "View" ? true : false}
                  />
                </Grid>
                {modalTitle !== "View" && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{ textAlign: "center", marginTop: "8px" }}
                  >
                    <Controller
                      name="isHeadOffice"
                      control={control}
                      defaultValue="N"
                      render={({ field }) => (
                        <Grid sx={{ textAlign: "left" }}>
                          <FormControlLabel
                            value="start"
                            sx={{ marginLeft: "0" }}
                            control={
                              <Switch
                                color="primary"
                                checked={field.value === "Y"}
                                onChange={() =>
                                  field.onChange(
                                    field.value !== "Y" ? "Y" : "N"
                                  )
                                }
                              />
                            }
                            labelPlacement="start"
                            label={
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  padding: "5px",
                                  color: "#6495ed",
                                }}
                              >
                                Do you want to change this office to Head Office
                                ?
                              </span>
                            }
                          />
                        </Grid>
                      )}
                    />
                  </Grid>
                )}
              </>
            )
          ) : (
            InputArray.map((item, index) => {
              return (
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Input
                    themeType={"theme1"}
                    labelStyle={{ fontSize: "13px" }}
                    label={item.label}
                    name={`response[${index}].${item.name}`}
                    id={item.name}
                    placeholder={`Enter ${item.label}`}
                    autoComplete="none"
                    defaultValue={""}
                    required={false}
                    inputRef={register(`response[${index}].${item.name}`)}
                  />
                  <input
                    type="hidden"
                    value={item.label === "Head Office" ? "Y" : "N"}
                    {...register(`response[${index}].isHeadOffice`)}
                  />
                </Grid>
              );
            })
          )}
        </Grid>
        {modalTitle !== "View" && (
          <Grid className="update_btn">
            <Button themeType={"theme1"} type="submit">
              {modalTitle === "Modify" ? "Update" : "Add"}
            </Button>
          </Grid>
        )}
      </form>
      {/* </div> */}
    </NomenclatureModal>
  );
};

const NomenclatureModal = styled.div`
  display: flex;
  background: #fff;
  flex-direction: column;
  z-index: 140000 !important;
  & > form {
    padding: 10px 10px 0 10px;
  }
  .update_btn {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
    margin-top: 10px;
    background: #fff;
    border-top: 1px solid #ccc;
    // padding: 8px 18px 8px 0px;
    position: sticky;
    bottom: 0;
    right: 0;
    z-index: 10;
  }
`;
