import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components";
import Swal from "sweetalert2";

import { useDispatch } from "react-redux";
import { Button } from "../../../components";
import NoData from "../../../components/NoData/NoData";
import TableSkeleton from "../../../components/SkeletonLoader/TableSkeleton";
import useGetOperations from "../../../hooks/useGetOperations";
import {
  deleteBrokerOffice,
  getBrokerOffice,
} from "../../broker-configuration/service";
import { setOfficeErrorAction, setOfficeSuccessAction } from "../office.slice";
import { useLocation } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { PanToolOutlined } from "@mui/icons-material";

export const BrokerOfficeTable = ({
  setPrefillData,
  setModalTitle,
  searchData,
  setOpen,
  setBulkmodal,
}) => {
  const { data: rowData, isFetching } = getBrokerOffice(searchData);

  const location = useLocation();

  const { allowedOperations } = useGetOperations(location.pathname);

  const dispatch = useDispatch();

  const { mutate: deleteBrokerOffices } = deleteBrokerOffice();

  const myFun = (id) => {
    deleteBrokerOffices(id);
  };

  const columns = [
    {
      field: "OfficeCode",
      flex: 2,
      headerName: "Office Code",
      background: "#E4E4E4",
    },
    {
      field: "Office_Address",
      flex: 2,
      headerName: "Office Address",
      background: "#E4E4E4",
    },
    {
      field: "mobile_no",
      flex: 2,
      headerName: "Mobile Number",
      background: "#E4E4E4",
    },
    {
      field: "PINCode",
      flex: 2,
      headerName: "Pincode",
      background: "#E4E4E4",
    },
    {
      field: "emailid",
      flex: 2,
      headerName: "Email ID",
      background: "#E4E4E4",
    },
    {
      field: "contact_person_name",
      flex: 2,
      headerName: "Contact Person Name",
      background: "#E4E4E4",
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <ActionContainer>
            {(allowedOperations.includes("View") ||
              allowedOperations.includes("Edit") ||
              allowedOperations.includes("Delete")) && (
              <Tooltip title="View">
                <PreviewOutlinedIcon
                  id={`view-${params.row.id}`}
                  onClick={() => {
                    dispatch(setOfficeSuccessAction(null));
                    setModalTitle("View");
                    setOpen(params.row.id);
                    setPrefillData({
                      office_code: params.row.OfficeCode,
                      id: params.row.id,
                      pincode: params.row.PINCode,
                      state: params.row.state,
                      city: params.row.city,
                      office_address: params.row.Office_Address,
                      contact_person_name: params.row.contact_person_name,
                      mobile_no: params.row.mobile_no,
                      email_id: params.row.emailid,
                      effective_from_date: params.row.effective_from_date
                        ? new Date(params.row.effective_from_date)
                        : "",
                      effective_to_date: params.row.efective_to_date
                        ? new Date(params.row.efective_to_date)
                        : "",
                    });
                  }}
                  index={params.row.id}
                />
              </Tooltip>
            )}
            {allowedOperations.includes("Edit") && (
              <Tooltip title="Edit">
                <BorderColorOutlinedIcon
                  id={`edit-${params.row.id}`}
                  onClick={() => {
                    dispatch(setOfficeSuccessAction(null));
                    setModalTitle("Modify");
                    setOpen(params.row.id);
                    setPrefillData({
                      office_code: params.row.OfficeCode,
                      id: params.row.id,
                      pincode: params.row.PINCode,
                      state: params.row.state,
                      city: params.row.city,
                      office_address: params.row.Office_Address,
                      contact_person_name: params.row.contact_person_name,
                      mobile_no: params.row.mobile_no,
                      email_id: params.row.emailid,
                      effective_from_date: params.row.effective_from_date
                        ? new Date(params.row.effective_from_date)
                        : "",
                      effective_to_date: params.row.efective_to_date
                        ? new Date(params.row.efective_to_date)
                        : "",
                    });
                  }}
                  index={params.row.id}
                />
              </Tooltip>
            )}
            {(allowedOperations.includes("Delete") ||
              allowedOperations.includes("Edit")) && (
              <Tooltip title="Delete">
                <DeleteForeverOutlinedIcon
                  id={`delete-${params.row.id}`}
                  onClick={() => {
                    Swal.fire({
                      title: "Delete record?",
                      text: "Confirmation required to delete this record.",
                      icon: "question",
                      confirmButtonText: "Delete",
                      confirmButtonColor: "#DC004E",
                      showCancelButton: true,
                      cancelButtonText: "Cancel",
                      reverseButtons: true,
                      focusConfirm: false,
                      focusCancel: false,
                      scrollbarPadding: false,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        myFun(params.row.id);
                        Swal.fire(
                          "Deleted!",
                          "Deleted Successfully",
                          "success"
                        );
                      }
                    });
                  }}
                  index={params.row.id}
                />
              </Tooltip>
            )}
          </ActionContainer>
        );
      },
    },
  ];

  const rows = rowData?.data?.data?.map((item) => ({
    id: item.brokerOfficeLocationRelationId,
    OfficeCode: item.officeCode,
    Office_Address: item.officeAddress,
    mobile_no: item.mobileNo,
    PINCode: item.pincode,
    state: item.state,
    city: item.city,
    emailid: item.emailId,
    effective_from_date: item.effectiveFromDate,
    efective_to_date: item.effectiveToDate,
    contact_person_name: item.contactPersonName,
    Edit: 1,
    Delete: 2,
  }));

  const getRowId = (row) => {
    return row.id;
  };

  if (isFetching) return <TableSkeleton />;

  return (
    <>
      <TableHeaderContainer>
        <Heading>Broker Table</Heading>
        {allowedOperations.includes("Edit") && (
          <div>
            <Button
              bgColor="transparent"
              textColor="#11555F"
              variant="outlined"
              borderColor="#11555F"
              id="add_location"
              startIcon={<AddBoxOutlinedIcon />}
              onClick={() => {
                setModalTitle("Add");
                setOpen(true);
                dispatch(
                  setOfficeSuccessAction(null),
                  setOfficeErrorAction(null)
                );
              }}
              style={{
                marginRight: "22px",
              }}
            >
              Add Location
            </Button>
            <Button
              bgColor="#1E7D8A"
              textColor="#fff"
              variant="outlined"
              id="bulk_btn"
              borderColor="#fff"
              onClick={() => {
                setBulkmodal(true);
              }}
            >
              Bulk Upload
            </Button>
          </div>
        )}
      </TableHeaderContainer>
      {rows ? (
        <TableContainer>
          {rows.length === 0 ? (
            <NoData />
          ) : (
            <DataGrid
              rows={rows}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 20 },
                },
              }}
              pageSizeOptions={[5, 20]}
              columns={columns}
              getRowId={getRowId}
              size="small"
              sx={{
                ".MuiDataGrid-columnSeparator": {
                  display: "none",
                },
                "&.MuiDataGrid-root": {
                  border: "none",
                  fontSize: "14px",
                },
                ".MuiDataGrid-columnHeaders": {
                  background: "#D2F8E9",
                  color: "#11555F",
                  fontSize: "14px",
                  fontWeight: 500,
                  fontFamily: "Montserrat-Medium",
                },
                ".MuiTablePagination-root p": {
                  fontSize: "14px",
                },
              }}
            />
          )}
        </TableContainer>
      ) : (
        <></>
      )}
    </>
  );
};

const TableContainer = styled.div`
  background: #fff;
  width: 100%;
`;

const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 12px;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  svg {
    color: rgb(23, 142, 128);
    font-size: 20px;
    gap: 2px;
  }
`;
export const Heading = styled.h2`
  margin-top: -3px;
  margin-bottom: 0;
  display: flex;
  color: #178e80;
  justify-content: space-between;
  font-family: "Montserrat-SemiBold";
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  .bulk-upload-btn {
    position: absolute;
    right: 17%;
  }
`;
