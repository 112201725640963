import moment from "moment";

export const preventNumbersInInput = (e) => {
  const value = e.target.value;
  const sanitizedValue = value.replace(/\d/g, "");
  e.target.value = sanitizedValue;
};

export const preventSpecialInInput = (e) => {
  const value = e.target.value;
  const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, "");
  e.target.value = sanitizedValue;
};

export const preventSpecialAndNumbersInInput = (e) => {
  const value = e.target.value;
  if (value === " ") {
    const sanitizedValue = value.replace(/\s/g, "");
    e.target.value = sanitizedValue;
    return;
  }
  const sanitizedValue = value.replace(/[^a-zA-Z\s]+/g, "");
  e.target.value = sanitizedValue;
};

export const preventCharactersInInput = (e) => {
  const value = e.target.value;
  const sanitizedValue = value.replace(/\D/g, "");
  e.target.value = sanitizedValue;
};

export const verifyValidPincode = (e) => {
  const value = e.target.value;
  const sanitizedValue = value.replace(/^0+|[^0-9]/g, "");
  e.target.value = sanitizedValue;
};

export const verifyValidNumbers = (e) => {
  const value = e.target.value;
  const sanitizedValue = value.replace(/^[0-5][0-9]*$|\D/g, "");
  e.target.value = sanitizedValue;
};

export const allowPercentageNumbersUpto100 = (e) => {
  const value = e.target.value;

  // Replace non-digit characters except for a dot
  let sanitizedValue = value.replace(/[^\d.]/g, "");

  // If there's more than one dot, keep only the first one
  const dotIndex = sanitizedValue.indexOf(".");
  if (dotIndex !== -1) {
    sanitizedValue =
      sanitizedValue.substring(0, dotIndex + 1) +
      sanitizedValue.substring(dotIndex + 1).replace(/\./g, "");
  }

  // Ensure the value is a valid number and limit it to 99
  if (sanitizedValue > 100) {
    sanitizedValue = "100";
  }

  e.target.value = sanitizedValue;
};

export const allowPercentageNumbers = (e) => {
  const value = e.target.value;

  // Replace non-digit characters except for a dot
  let sanitizedValue = value.replace(/[^\d.]/g, "");

  // If there's more than one dot, keep only the first one
  const dotIndex = sanitizedValue.indexOf(".");
  if (dotIndex !== -1) {
    sanitizedValue =
      sanitizedValue.substring(0, dotIndex + 1) +
      sanitizedValue.substring(dotIndex + 1).replace(/\./g, "");
  }

  // Ensure the value is a valid number and limit it to 99
  if (sanitizedValue > 99) {
    sanitizedValue = 99;
  }

  e.target.value = sanitizedValue;
};

export const preventZeroAsInitial = (e) => {
  const value = e.target.value;
  let sanitizedValue = value.replace(/^[0]*$|\D/g, "");
  e.target.value = sanitizedValue;
};

export const preventWhiteSpaceInInput = (e) => {
  const sanitizedValue = e.target.value.replace(/\s/g, "");
  e.target.value = sanitizedValue;
};

export const handleFloatInput = (e) => {
  let value = e.target.value;

  // Replace non-digit characters except for a dot
  let sanitizedValue = value.replace(/[^\d.]/g, "");

  // If there's more than one dot, keep only the first one
  const dotIndex = sanitizedValue.indexOf(".");
  if (dotIndex !== -1) {
    sanitizedValue =
      sanitizedValue.substring(0, dotIndex + 1) +
      sanitizedValue.substring(dotIndex + 1).replace(/\./g, "");
  }

  // Limit to two decimal places
  const decimalIndex = sanitizedValue.indexOf(".");
  if (decimalIndex !== -1) {
    const decimalPart = sanitizedValue.substring(
      decimalIndex + 1,
      decimalIndex + 3
    );
    sanitizedValue =
      sanitizedValue.substring(0, decimalIndex + 1) + decimalPart;
  }

  e.target.value = sanitizedValue;
};

export const verifyValidEmail = (e) => {
  //all custom email validation here
  return e.target.value;
};

export const handleInput = (e) => {
  //use this fn if input looses focus when typing
  //only use this if task is priority else try to resolve the error
  return e.target.value;
};

export const allowAlphabetsAndNumbersInInput = (e) => {
  const value = e.target.value;
  const sanitizedValue = value.replace(/[^a-zA-Z0-9\s]+/g, "");
  e.target.value = sanitizedValue;
};

export const verifyValidAadhar = (e) => {
  const value = e.target.value;
  const sanitizedValue = value.replace(/\D/g, "").substr(0, 12);
  e.target.value = sanitizedValue;
};

export const verifyValidPAN = (e) => {
  const value = e.target.value.toUpperCase();
  const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, "");
  e.target.value = sanitizedValue;
};

export const defaultDateFormat = (date) => {
  if (!date) {
    return moment(new Date()).format("DD-MM-YYYY");
  }
  const formattedDate = moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
  return formattedDate;
};
export const allowOnlyNumbers = (e) => {
  e.target.value = e.target.value.replace(/[^0-9]/g, "");
};

export const allowLowerCaseAndUnderscore = (e) => {
  const value = e.target.value.toLowerCase();
  const sanitizedValue = value.replace(/[^a-z_]/g, "");
  e.target.value = sanitizedValue;
};
export const allowOnlySlug = (e) => {
  e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/gi, "");
};

export const allCapitalize = (e) => {
  const cursorPosition = e.target.selectionStart;
  e.target.value = e.target.value.toUpperCase();
  e.target.setSelectionRange(cursorPosition, cursorPosition);
};

