export const themeObj = {
  theme1: {
    primary: "#11555F",
    litePrimary: "#178E80",
    secondary: "#F9AE2B",
    tertiary: "#FF4646",
    forth: "#005478",
    fifth: "#65C0B5",
    inputLabel: "#11555F",
    // hBarColor: "#F9AE2B",
  },
  // Role Page
  theme2: {
    primary: "#898121",
    litePrimary: "#B8AD2B",
    secondary: "#FFAA06",
    tertiary: "#FF4646",
    inputLabel: "#11555F",
  },
  // House Bank Mapping
  theme3: {
    primary: "#ff8c04",
    litePrimary: "#FF8D08",
    secondary: "#FFAA06",
    tertiary: "#FF4646",
    inputLabel: "#ff8c04",
  },
  theme4: {
    primary: "#2C0C4D",
    secondary: "",
    tertiary: "",
  },
  theme5: {
    primary:
      "linear-gradient(71.86deg, #0084D7 20.56%, #0084D7 63.32%, #00CFFF 99.71%)",
    litePrimary: "#0096F1",
    secondary: "#FFAA06",
    tertiary: "#FF4646",
    inputLabel: "#006EB1",
  },
  headingFont: "Montserrat",
  regularFont: "Montserrat-Medium",
};
