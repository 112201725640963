import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import {
  preventCharactersInInput,
  preventSpecialAndNumbersInInput,
  preventWhiteSpaceInInput,
  verifyValidAadhar,
  verifyValidEmail,
  verifyValidNumbers,
  verifyValidPAN,
  verifyValidPincode,
} from "../../../HelperFunctions/HelperFunctions";
import {
  Button,
  DatePickerInput,
  Input,
  Typography,
} from "../../../components";
import Dropdown from "../../../components/Dropdown/Dropdown";
import useGetMasterTableData from "../../../hooks/useGetMasterTableData";
import CustomerFilePicker from "./customerFilepicker";

const CustomerAddForm = ({
  modalTitle,
  open,
  OccupationOBJ,
  createCustomer,
  pincode,
  setPincode,
  data,
  GenderObj,
  setValue,
  trigger,
  handleSubmit,
  rowData,
  register,
  errors,
  control,
  educationObj,
  leadSourceObj,
}) => {
  const { Error } = Typography;

  const location = useLocation();

  const isCustomerAddRoute = location.pathname === "/customer-add";

  const relationFeild = [
    { masterSlug: "relation_master", fieldSlug: "nomineeRelation" },
  ];
  const { data: relationData } = useGetMasterTableData(relationFeild);
  const RelationOBJ = relationData?.[0]?.data?.data?.[0]?.data?.map((item) => {
    return {
      value: item?.optionValue,
      label: item?.optionKey,
      id: item?.optionValue,
    };
  });
  const [files, setFiles] = useState([]);

  const onSubmit = (formData) => {
    const payload = {
      firstName: formData?.firstName,
      middleName: formData?.middleName,
      lastName: formData?.lastName,
      emailId: formData?.email,
      alternateEmailId: formData?.alternateEmail,
      mobile_number: formData?.mobileNo,
      alternateMobileNumber: formData?.alternateMobileNo,
      gender: formData?.gender?.id,
      dob: formData?.dob,
      anniversaryDate: formData?.anniversaryDate,
      education: formData?.education?.value,
      occupation: formData?.occupation?.id,
      designation: formData?.designation,
      annualIncome: formData?.annualIncome,
      panNumber: formData?.panNo,
      aadharNumber: formData?.aadharNo,
      uccNumber: formData?.uccNo,
      businessChannel: formData?.businessChannel?.value,
      pinCode: formData?.pincode,
      city: formData?.city,
      state: formData?.state,
      remark: formData?.remark,
      relation: isCustomerAddRoute ? "Self" : formData?.relation?.id,
      parentId: rowData?.id,
      imageData: formData?.base64Files?.[0]?.base64String
        ? formData?.base64Files?.[0]?.base64String
        : null,
      hasChild: isCustomerAddRoute ? "Y" : "N",
      isParent: isCustomerAddRoute ? "Y" : "N",
      status: "Y",
    };
    createCustomer(payload);
  };

  const handlePincodeChange = (value) => {
    const sanitizedValue = value.replace(/^0+|[^0-9]/g, "");
    setPincode(sanitizedValue);
  };

  useEffect(() => {
    const state = data?.data?.data[0]?.state?.stateName;
    const city = data?.data?.data[0]?.city?.cityName;
    if (state) {
      setValue("state", state);
      trigger("state");
    }
    if (city) {
      setValue("city", city);
      trigger("city");
    }
  }, [data]);

  return (
    <InputFrame onSubmit={handleSubmit(onSubmit)}>
      <StyledContainerGrid
        container
        rowSpacing={1}
        modalTitle={modalTitle}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ padding: "23px 20px" }}
      >
        {isCustomerAddRoute ? null : (
          <Grid
            item
            sx={{ position: "relative" }}
            xs={10}
            md={3}
            sm={10}
            lg={modalTitle === undefined ? 3 : 4}
          >
            <Dropdown
              id="relation"
              label={`Relation with ${rowData?.firstName}`}
              control={control}
              selectObj={RelationOBJ}
              isMulti={false}
              maxMenuHeight={200}
              proposalRead={false}
            />
          </Grid>
        )}
        <Grid
          item
          sx={{ position: "relative" }}
          xs={10}
          md={3}
          sm={10}
          lg={modalTitle === undefined ? 3 : 4}
        >
          <Input
            themeType={"theme1"}
            label={"First Name"}
            name={"firstName"}
            id={"firstName"}
            onChange={(e) => preventSpecialAndNumbersInInput(e)}
            maxLength="100"
            placeholder={`Enter First Name`}
            autoComplete="none"
            defaultValue={""}
            required={false}
            inputRef={register("firstName")}
            proposalRead={modalTitle === "View" ? true : false}
            readOnly={modalTitle === "View" ? true : false}
            isRequired={modalTitle === "View" ? false : true}
          />
          {!!errors?.firstName && (
            <Error top="3px" left="3px">
              {errors.firstName.message}
            </Error>
          )}
        </Grid>
        <Grid
          item
          sx={{ position: "relative" }}
          xs={10}
          md={3}
          sm={10}
          lg={modalTitle === undefined ? 3 : 4}
        >
          <Input
            themeType={"theme1"}
            label={"Middle Name"}
            name={"middleName"}
            id={"middleName"}
            onChange={(e) => preventSpecialAndNumbersInInput(e)}
            maxLength="100"
            placeholder={`Enter Middle Name`}
            autoComplete="none"
            defaultValue={""}
            required={false}
            inputRef={register("middleName")}
            proposalRead={modalTitle === "View" ? true : false}
            readOnly={modalTitle === "View" ? true : false}
          />
          {!!errors?.middleName && (
            <Error top="3px" left="3px">
              {errors.middleName.message}
            </Error>
          )}
        </Grid>
        <Grid
          item
          sx={{ position: "relative" }}
          xs={10}
          md={3}
          sm={10}
          lg={modalTitle === undefined ? 3 : 4}
        >
          <Input
            themeType={"theme1"}
            label={"Last Name"}
            name={"lastName"}
            id={"lastName"}
            onChange={(e) => preventSpecialAndNumbersInInput(e)}
            maxLength="100"
            placeholder={`Enter Last Name`}
            autoComplete="none"
            defaultValue={""}
            required={false}
            inputRef={register("lastName")}
            proposalRead={modalTitle === "View" ? true : false}
            readOnly={modalTitle === "View" ? true : false}
            isRequired={modalTitle === "View" ? false : true}
          />
          {!!errors?.lastName && (
            <Error top="3px" left="3px">
              {errors.lastName.message}
            </Error>
          )}
        </Grid>
        <Grid
          item
          sx={{ position: "relative" }}
          xs={10}
          md={3}
          sm={10}
          lg={modalTitle === undefined ? 3 : 4}
        >
          <Input
            themeType={"theme1"}
            label={"Email"}
            name={"email"}
            id={"email"}
            maxLength="100"
            placeholder={`Enter Email`}
            autoComplete="none"
            defaultValue={""}
            onChange={(e) => {
              verifyValidEmail(e);
              preventWhiteSpaceInInput(e);
            }}
            required={false}
            inputRef={register("email")}
            proposalRead={modalTitle === "View" ? true : false}
            readOnly={modalTitle === "View" ? true : false}
            isRequired={modalTitle === "View" ? false : true}
          />
          {!!errors?.email && (
            <Error top="3px" left="3px">
              {errors.email.message}
            </Error>
          )}
        </Grid>
        <Grid
          item
          sx={{ position: "relative" }}
          xs={10}
          md={3}
          sm={10}
          lg={modalTitle === undefined ? 3 : 4}
        >
          <Input
            themeType={"theme1"}
            label={"Alternate Email"}
            name={"alternateEmail"}
            id={"alternateEmail"}
            maxLength="100"
            placeholder={`Enter Alternate Email`}
            autoComplete="none"
            defaultValue={""}
            onChange={(e) => {
              verifyValidEmail(e);
              preventWhiteSpaceInInput(e);
            }}
            required={false}
            inputRef={register("alternateEmail")}
            proposalRead={modalTitle === "View" ? true : false}
            readOnly={modalTitle === "View" ? true : false}
          />
          {!!errors?.alternateEmail && (
            <Error top="3px" left="3px">
              {errors.alternateEmail.message}
            </Error>
          )}
        </Grid>
        <Grid
          item
          sx={{ position: "relative" }}
          xs={10}
          md={3}
          sm={10}
          lg={modalTitle === undefined ? 3 : 4}
        >
          <Input
            themeType={"theme1"}
            label={"Mobile No"}
            name={"mobileNo"}
            id={"mobileNo"}
            onChange={(e) => verifyValidNumbers(e)}
            maxLength="10"
            placeholder={`Enter Mobile No`}
            autoComplete="none"
            defaultValue={""}
            required={false}
            inputRef={register("mobileNo")}
            proposalRead={modalTitle === "View" ? true : false}
            readOnly={modalTitle === "View" ? true : false}
            isRequired={modalTitle === "View" ? false : true}
          />
          {!!errors?.mobileNo && (
            <Error top="3px" left="3px">
              {errors.mobileNo.message}
            </Error>
          )}
        </Grid>
        <Grid
          item
          sx={{ position: "relative" }}
          xs={10}
          md={3}
          sm={10}
          lg={modalTitle === undefined ? 3 : 4}
        >
          <Input
            themeType={"theme1"}
            label={"Alternate Mobile No"}
            name={"alternateMobileNo"}
            id={"alternateMobileNo"}
            onChange={(e) => verifyValidNumbers(e)}
            maxLength="10"
            placeholder={`Enter Alternate Mobile No`}
            autoComplete="none"
            defaultValue={""}
            required={false}
            inputRef={register("alternateMobileNo")}
            proposalRead={modalTitle === "View" ? true : false}
            readOnly={modalTitle === "View" ? true : false}
          />
          {!!errors?.alternateMobileNo && (
            <Error top="3px" left="3px">
              {errors.alternateMobileNo.message}
            </Error>
          )}
        </Grid>
        <Grid
          item
          sx={{ position: "relative" }}
          xs={10}
          md={3}
          sm={10}
          lg={modalTitle === undefined ? 3 : 4}
        >
          <Dropdown
            id="gender"
            label="Gender"
            control={control}
            selectObj={GenderObj}
            isMulti={false}
            isRequired={true}
            maxMenuHeight={200}
            proposalRead={false}
          />
          {!!errors?.gender && (
            <Error top="3px" left="3px">
              {errors.gender.message}
            </Error>
          )}
        </Grid>
        <Grid
          item
          sx={{ position: "relative" }}
          xs={10}
          md={3}
          sm={10}
          lg={modalTitle === undefined ? 3 : 4}
        >
          <DatePickerInput
            themeType="theme1"
            label="Date Of Birth"
            name="dob"
            id="dob"
            control={control}
            maxDate={new Date()}
            placeholder="Enter Date Of Birth"
            isRequired={modalTitle === "View" ? false : true}
            proposalRead={modalTitle === "View" ? true : false}
            readOnly={modalTitle === "View" ? true : false}
          />
          {!!errors?.dob && (
            <Error top="3px" left="3px">
              {errors.dob.message}
            </Error>
          )}
        </Grid>
        <Grid
          item
          sx={{ position: "relative" }}
          xs={10}
          md={3}
          sm={10}
          lg={modalTitle === undefined ? 3 : 4}
        >
          <DatePickerInput
            themeType="theme1"
            label="Anniversary Date"
            name="anniversaryDate"
            id="anniversaryDate"
            control={control}
            maxDate={new Date()}
            placeholder="Enter Anniversary Date"
            proposalRead={modalTitle === "View" ? true : false}
            readOnly={modalTitle === "View" ? true : false}
          />
        </Grid>
        <Grid
          item
          sx={{ position: "relative" }}
          xs={10}
          md={3}
          sm={10}
          lg={modalTitle === undefined ? 3 : 4}
        >
          <Dropdown
            themeType={"theme1"}
            id={"education"}
            label={`Enter Education`}
            control={control}
            selectObj={educationObj}
            isMulti={false}
            maxMenuHeight={200}
            proposalRead={false}
          />
        </Grid>
        <Grid
          item
          sx={{ position: "relative" }}
          xs={10}
          md={3}
          sm={10}
          lg={modalTitle === undefined ? 3 : 4}
        >
          <Dropdown
            themeType={"theme1"}
            id="occupation"
            label="Occupation"
            control={control}
            selectObj={OccupationOBJ}
            isMulti={false}
            maxMenuHeight={200}
            proposalRead={false}
          />
        </Grid>
        <Grid
          item
          sx={{ position: "relative" }}
          xs={10}
          md={3}
          sm={10}
          lg={modalTitle === undefined ? 3 : 4}
        >
          <Input
            themeType={"theme1"}
            label={"Designation"}
            name={"designation"}
            id={"designation"}
            maxLength="100"
            placeholder={`Enter Designation`}
            autoComplete="none"
            defaultValue={""}
            required={false}
            inputRef={register("designation")}
            proposalRead={modalTitle === "View" ? true : false}
            readOnly={modalTitle === "View" ? true : false}
          />
        </Grid>
        <Grid
          item
          sx={{ position: "relative" }}
          xs={10}
          md={3}
          sm={10}
          lg={modalTitle === undefined ? 3 : 4}
        >
          <Input
            themeType={"theme1"}
            label={"Annual Income"}
            name={"annualIncome"}
            id={"annualIncome"}
            maxLength="100"
            placeholder={`Enter Annual Income`}
            autoComplete="none"
            defaultValue={""}
            required={false}
            onChange={(e) => preventCharactersInInput(e)}
            inputRef={register("annualIncome")}
            proposalRead={modalTitle === "View" ? true : false}
            readOnly={modalTitle === "View" ? true : false}
          />
        </Grid>
        <Grid
          item
          sx={{ position: "relative" }}
          xs={10}
          md={3}
          sm={10}
          lg={modalTitle === undefined ? 3 : 4}
        >
          <Input
            themeType={"theme1"}
            label={"Pan Number"}
            name={"panNo"}
            id={"panNo"}
            maxLength="10"
            placeholder={`Enter Pan Number`}
            autoComplete="none"
            defaultValue={""}
            onChange={(e) => verifyValidPAN(e)}
            required={false}
            inputRef={register("panNo")}
            proposalRead={modalTitle === "View" ? true : false}
            readOnly={modalTitle === "View" ? true : false}
          />
          {!!errors?.panNo && (
            <Error top="3px" left="3px">
              {errors.panNo.message}
            </Error>
          )}
        </Grid>
        <Grid
          item
          sx={{ position: "relative" }}
          xs={10}
          md={3}
          sm={10}
          lg={modalTitle === undefined ? 3 : 4}
        >
          <Input
            themeType={"theme1"}
            label={"Aadhar Number"}
            name={"aadharNo"}
            id={"aadharNo"}
            maxLength="12"
            placeholder={`Enter Aadhar Number`}
            autoComplete="none"
            defaultValue={""}
            onChange={(e) => verifyValidAadhar(e)}
            required={false}
            inputRef={register("aadharNo")}
            proposalRead={modalTitle === "View" ? true : false}
            readOnly={modalTitle === "View" ? true : false}
          />
          {!!errors?.aadharNo && (
            <Error top="3px" left="3px">
              {errors.aadharNo.message}
            </Error>
          )}
        </Grid>
        <Grid
          item
          sx={{ position: "relative" }}
          xs={10}
          md={3}
          sm={10}
          lg={modalTitle === undefined ? 3 : 4}
        >
          <Input
            themeType={"theme1"}
            label={"Ucc Number"}
            name={"uccNo"}
            id={"uccNo"}
            maxLength="100"
            placeholder={`Enter Ucc Number`}
            autoComplete="none"
            defaultValue={""}
            required={false}
            inputRef={register("uccNo")}
            proposalRead={modalTitle === "View" ? true : false}
            readOnly={modalTitle === "View" ? true : false}
          />
        </Grid>
        <Grid
          item
          sx={{ position: "relative" }}
          xs={10}
          md={3}
          sm={10}
          lg={modalTitle === undefined ? 3 : 4}
        >
          <Dropdown
            themeType={"theme1"}
            label={"Sourcing Channel"}
            id={"businessChannel"}
            control={control}
            selectObj={leadSourceObj}
            isMulti={false}
            maxMenuHeight={200}
            proposalRead={false}
          />
        </Grid>
        <Grid
          item
          sx={{ position: "relative" }}
          xs={10}
          md={3}
          sm={10}
          lg={modalTitle === undefined ? 3 : 4}
        >
          <Input
            themeType={"theme1"}
            label={"Pincode"}
            name={"pincode"}
            id={"pincode"}
            maxLength="6"
            placeholder={`Enter Pincode`}
            autoComplete="none"
            value={pincode}
            onChange={(e) => {
              handlePincodeChange(e.target.value);
              verifyValidPincode(e);
            }}
            required={false}
            inputRef={register("pincode")}
          />
        </Grid>
        <Grid
          item
          sx={{ position: "relative" }}
          xs={10}
          md={3}
          sm={10}
          lg={modalTitle === undefined ? 3 : 4}
        >
          <Input
            themeType={"theme1"}
            label={"City"}
            name={"city"}
            id={"city"}
            maxLength="100"
            placeholder={`Enter City`}
            autoComplete="none"
            value={
              pincode.length === 6
                ? data?.data?.data[0]?.length !== 0
                  ? data?.data?.data[0]?.city?.cityName
                  : ""
                : ""
            }
            required={false}
            inputRef={register("city")}
          />
        </Grid>
        <Grid
          item
          sx={{ position: "relative" }}
          xs={10}
          md={3}
          sm={10}
          lg={modalTitle === undefined ? 3 : 4}
        >
          <Input
            themeType={"theme1"}
            label={"State"}
            name={"state"}
            id={"state"}
            maxLength="100"
            placeholder={`Enter State`}
            value={
              pincode.length === 6
                ? data?.data?.data[0]?.length !== 0
                  ? data?.data?.data[0]?.state?.stateName
                  : ""
                : ""
            }
            autoComplete="none"
            required={false}
            inputRef={register("state")}
          />
        </Grid>
        <Grid
          item
          sx={{ position: "relative" }}
          xs={10}
          md={3}
          sm={10}
          lg={modalTitle === undefined ? 3 : 4}
        >
          <Input
            themeType={"theme1"}
            label={"Remark"}
            name={"remark"}
            id={"remark"}
            maxLength="500"
            placeholder={`Enter Remark`}
            autoComplete="none"
            defaultValue={""}
            required={false}
            inputRef={register("remark")}
            proposalRead={modalTitle === "View" ? true : false}
            readOnly={modalTitle === "View" ? true : false}
          />
        </Grid>
      </StyledContainerGrid>
      {modalTitle === undefined ? (
        <div>
          <Grid
            item
            sx={{ position: "relative" }}
            xs={12}
            sm={12}
            md={3}
            lg={6}
          >
            {" "}
            <CustomerFilePicker
              name="files"
              control={control}
              files={files}
              trigger={trigger}
              setFiles={setFiles}
              setValue={setValue}
              open={open}
            />
          </Grid>
        </div>
      ) : (
        <></>
      )}
      {modalTitle !== "View" ? (
        <ButtonFrame modalTitle={modalTitle}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
            className="button_container"
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <NextButtonWrap>
                <Button
                  type="submit"
                  btnBack={"secondary"}
                  bgColor={"#11555F"}
                  themeType={"theme1"}
                >
                  {modalTitle === "Modify" ? "Update Customer" : "Add Customer"}
                </Button>
              </NextButtonWrap>
            </Grid>
          </Grid>
        </ButtonFrame>
      ) : (
        <></>
      )}
    </InputFrame>
  );
};

export default CustomerAddForm;

const InputFrame = styled.form`
  width: 100%;
`;
const StyledContainerGrid = styled(Grid)`
  ${(props) =>
    props.modalTitle !== undefined
      ? `
      height: 475px;
      overflow: scroll;
    `
      : ""}
`;

const NextButtonWrap = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
`;

const ButtonFrame = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
  margin-top: 0;
  background: #fff;
  ${(props) =>
    props.modalTitle !== undefined
      ? `
      border-top: 1px solid #ccc;
    `
      : ""}

  z-index: 10;
  padding: 12px 18px 12px 0px;
`;
