import { Grid } from "@mui/material";
import _, { isBoolean } from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { verifyValidPincode } from "../../HelperFunctions/HelperFunctions";
import { Input, Typography } from "../../components";
import GlobalModal from "../../components/Modal/modal";
import BulkUpload from "./components/bulkUpload";
import { HolidayModal } from "./components/holiday-modal";
import { HolidayTable } from "./components/holiday-table";
import {
  useDeleteHoliday,
  useGetHolidayDetails,
  useGetStateCityFromPincode,
} from "./service";
import { Container, StyledDiv } from "./style/holida-style";
import SkeletonLoader from "../../components/SkeletonLoader/SkeletonLoader";
import TableSkeleton from "../../components/SkeletonLoader/TableSkeleton";

//Required Typography
const { StyledH1, Error } = Typography;

export const BrokerHolidayConfiguration = () => {
  const { mutate: deleteHoliday } = useDeleteHoliday();
  const { isFetching } = useGetHolidayDetails();

  const [isOpen, setOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState(false);
  const [bulkmodal, setBulkmodal] = useState(false);
  const [id, setID] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const {
    register,
    setValue,
    formState: { errors },
  } = useForm({});

  const handleEventDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteHoliday(id);
        Swal.fire("Deleted!", "Your data has been deleted.", "success");
      }
    });
  };

  const [pincode, setPincode] = useState("");
  const { data } = useGetStateCityFromPincode(pincode);

  const handlePincodeChange = (value) => {
    const sanitizedValue = value.replace(/^0+|[^0-9]/g, "");
    setPincode(sanitizedValue);
  };

  useEffect(() => {
    _.isEmpty(data?.data?.data[0]?.state?.stateName)
      ? setValue("state", "")
      : setValue("state", data?.data?.data[0]?.state?.stateName);
  }, [data]);

  return (
    <StyledDiv>
      <div className="holiday_input">
        <StyledH1
          themeType="theme1"
          partialU
          color={"rgb(23, 142, 128)"}
          align={"left"}
        >
          Broker Holiday Configuration
        </StyledH1>
      </div>
      {isFetching ? (
        <TableSkeleton marginTop={true} />
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <Container>
              <HolidayTable
                setOpen={setOpen}
                setModalTitle={setModalTitle}
                setID={setID}
                setBulkmodal={setBulkmodal}
                handleEventDelete={handleEventDelete}
              />
            </Container>
          </Grid>
          <Grid item md={4}></Grid>
        </Grid>
      )}
      <GlobalModal
        open={isOpen}
        title={modalTitle + " Holiday"}
        onClose={handleClose}
      >
        <HolidayModal
          modalTitle={modalTitle}
          isOpen={isOpen}
          handleClose={handleClose}
          id={id}
        />
      </GlobalModal>

      <GlobalModal
        open={bulkmodal}
        title="Upcoming Holiday Sheet"
        onClose={() => {
          setBulkmodal(false);
        }}
      >
        <BulkUpload
          BulkModal={bulkmodal}
          handleClose={() => {
            setBulkmodal(false);
          }}
        />
      </GlobalModal>
    </StyledDiv>
  );
};
