import { Grid } from "@mui/material";
import { useEffect } from "react";
import styled from "styled-components";
import { Button, Input, Typography } from "../../../components";
// LOB_Obj
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Select from "react-select";
import Swal from "sweetalert2";
import * as yup from "yup";
import useGetLOBMaster from "../../../hooks/useGetLOBMaster";
import { preventSpecialAndNumbersInInput } from "../../../HelperFunctions/HelperFunctions";
import { preventCharactersInInput } from "../../../HelperFunctions/HelperFunctions";
import {
  useCreateDocumentData,
  useGetDocumentsFormat,
  useUpdateDocumentData,
} from "../service";
import { setDocumentErrorAction } from "../doc-config.slice";
import { useDispatch } from "react-redux";
import Dropdown from "../../../components/Dropdown/Dropdown";

export const DocumentManagementModal = ({
  modalTitle,
  isOpen,
  handleClose,
  preFillData,
}) => {
  const { Error } = Typography;
  const yupValidate = yup.object({
    name: yup.string().required("Document Name is Required"),
    code: yup.string().required("Document Code is Required"),
    document: yup.mixed().required("Document is Required"),
    lob: yup.mixed().required("LOB is required"),
  });
  const { mutate: createDocument } = useCreateDocumentData();
  const { mutate: updateDocument } = useUpdateDocumentData();
  const { data } = useGetLOBMaster();
  const { data: docFormat } = useGetDocumentsFormat();
  const { documentError, documentSuccess } = useSelector(
    (state) => state.documentConfig
  );
  const dispatch = useDispatch();

  const {
    register,
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yupValidate),
    mode: "all",
    reValidateMode: "onChange",
  });

  const onSubmit = (value) => {
    if (modalTitle === "Modify") {
      updateDocument({
        documentName: value.name,
        documentCode: value.code,
        documentType: value.document[0].value,
        transactionTypeId: preFillData.id,
        status: "Y",
        lobId: value.lob.map((value) => value.id),
        documentFormatMasterId: value.document.map((value) => value.id),
      });
      Swal.fire({
        text: "Data Succcesfully Updated.",
        icon: "success",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        focusCancel: false,
        scrollbarPadding: false,
      });
    } else {
      createDocument({
        documentName: value.name,
        documentCode: value.code,
        documentType: value.document[0].value,
        transactionTypeId: 0,
        status: "Y",
        lobId: value.lob.map((value) => value.id),
        documentFormatMasterId: value.document.map((value) => value.id),
      });
    }
  };

  useEffect(() => {
    if (modalTitle === "Modify") {
      reset(preFillData);
      setValue("document", preFillData.type);
      setValue("lob", preFillData.lob);
    }
  }, []);

  useEffect(() => {
    if (modalTitle === "View") {
      reset(preFillData);
    }
  }, []);
  const documentFormats = docFormat?.data?.data.map((value) => {
    return {
      id: value.documentFormatMasterId,
      value: value.documentName,
      label: value.documentName,
      image: value.filePath,
      size: value.maxUploadSize,
    };
  });

  useEffect(() => {
    if (documentError) {
      Swal.fire({
        text: documentError,
        icon: "warning",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        allowOutsideClick: false,
        focusCancel: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(setDocumentErrorAction(null));
        }
      });
    }
  }, [documentError]);

  useEffect(() => {
    if (documentSuccess) {
      Swal.fire({
        text: documentSuccess,
        icon: "success",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        focusCancel: false,
        scrollbarPadding: false,
      });
      handleClose();
    }
  }, [documentSuccess]);

  const customStyles = {
    container: (provided) => ({
      ...provided,
      marginBottom: "14px",
    }),
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      fontSize: "12px",
      minHeight: 20,
      border: "1px solid #e4e4e4",
      borderColor: state.isFocused ? "#2BB9A8" : "#e4e4e4",
      backgroundColor: "rgb(247, 248, 250)",
      marginTop: "-1px",
      borderRadius: "4px",
      padding: "0 5px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: "0",
    }),
    multiValue: (provided) => ({
      ...provided,
      color: "#2BB9A8",
      borderRadius: "5px",
      margin: "5px",
      paddingInline: "5px",
      background: " #22bdaa30",
      fontSize: "14px",
      fontWeight: "600",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#2BB9A8",
    }),
    optionImage: {
      width: "30px",
      height: "30px",
      objectFit: "contain",
      marginInline: "25px",
    },
  };

  const CustomOption = ({ innerProps, label, data }) => (
    <div
      {...innerProps}
      style={{
        padding: "10px 0",
        borderBottom: ".25px solid #2BB9A8 ",
        fontWeight: "600",
        color: "#2BB9A8",
        display: "flex",
        alignItems: "center",
      }}
    >
      <img src={data.image} alt={label} style={customStyles.optionImage} />
      {label}
      {data.size && (
        <div
          style={{
            marginLeft: "auto",
            marginRight: "10px",
            fontSize: "12px",
            fontWeight: 500,
          }}
        >
          Allowed Size &lt; {data.size}MB{" "}
        </div>
      )}
    </div>
  );

  const LOBCustomOption = ({ innerProps, label, data }) => (
    <div
      {...innerProps}
      style={{
        padding: "10px 0",
        borderBottom: ".25px solid #2BB9A8 ",
        fontWeight: "600",
        color: "#2BB9A8",
        display: "flex",
        alignItems: "center",
        textTransform: "capitalize",
      }}
    >
      <img src={data.image} alt={label} style={customStyles.optionImage} />
      {label}
    </div>
  );

  const LOB_Obj = data?.data?.data?.map((value) => {
    return {
      id: value.lob_id,
      value: value.lob,
      label: value.lob,
      image: value.filePath,
    };
  });

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ padding: "10px 20px" }}
      >
        <Grid item sx={{ position: "relative" }} xs={12} sm={12} md={6} lg={6}>
          <Input
            themeType={"theme1"}
            label={"Document  Name"}
            name={"name"}
            id={"name"}
            maxLength="100"
            placeholder={`Enter Document  Name`}
            autoComplete="none"
            defaultValue={""}
            onChange={(e) => preventSpecialAndNumbersInInput(e)}
            required={false}
            inputRef={register("name")}
            proposalRead={modalTitle === "View" ? true : false}
            readOnly={modalTitle === "View" ? true : false}
            isRequired={modalTitle === "View" ? false : true}
          />
          {!!errors?.name && (
            <Error top="3px" left="3px">
              {errors.name.message}
            </Error>
          )}
        </Grid>
        <Grid item sx={{ position: "relative" }} xs={12} sm={12} md={6} lg={6}>
          <Input
            themeType={"theme1"}
            label={"Document Code"}
            name={"code"}
            id={"code"}
            maxLength="100"
            onChange={(e) => preventCharactersInInput(e)}
            placeholder={`Enter Document Code`}
            autoComplete="none"
            defaultValue={""}
            required={false}
            inputRef={register("code")}
            proposalRead={modalTitle === "View" ? true : false}
            readOnly={modalTitle === "View" ? true : false}
            isRequired={modalTitle === "View" ? false : true}
          />
          {!!errors?.code && (
            <Error top="3px" left="3px">
              {errors.code.message}
            </Error>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 2 }}
        sx={{ padding: "0 20px" }}
      >
        <Grid item sx={{ position: "relative", marginBottom: "15px" }} md={6}>
          {modalTitle === "View" ? (
            <Grid item sx={{ position: "relative", marginLeft: "5px" }} md={8}>
              <h5 className="document-title-for-View">Document</h5>
              {preFillData?.type?.map((item, index) => (
                <>
                  <span className="display-text-for-view">{item.label}</span>
                  {index < preFillData.type.length - 1 && <span>, </span>}
                </>
              ))}
            </Grid>
          ) : (
            <Dropdown
              id="document"
              label="Allowed Document Formats"
              control={control}
              isMulti={true}
              selectObj={documentFormats}
              proposalRead={modalTitle === "View" ? true : false}
              maxMenuHeight={150}
              components={{ Option: CustomOption }}
            />
          )}

          {!!errors?.document && (
            <Error top="3px" left="3px" bottom="-16px">
              {errors.document.message}
            </Error>
          )}
        </Grid>
        <Grid item sx={{ position: "relative", marginBottom: "15px", paddingLeft: "24px !important" }} md={6}>
          {modalTitle === "View" ? (
            <Grid item sx={{ position: "relative", marginLeft: "8px" }} md={10}>
              <h5 className="document-title-for-View">Lob</h5>
              {preFillData?.lob?.map((item, index) => (
                <>
                  <span className="display-text-for-view">{item.label}</span>
                  {index < preFillData.lob.length - 1 && <span>, </span>}
                </>
              ))}
            </Grid>
          ) : (
            <Dropdown
              id="lob"
              label="Line of Business"
              control={control}
              isMulti={true}
              selectObj={LOB_Obj}
              proposalRead={modalTitle === "View" ? true : false}
              maxMenuHeight={150}
              components={{ Option: LOBCustomOption }}
            />
          )}
          {!!errors?.lob && (
            <Error top="3px" left="3px" bottom="-16px">
              {errors.lob.message}
            </Error>
          )}
        </Grid>
      </Grid>
      {modalTitle !== "View" ? (
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          className="button_container"
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <NextButtonWrap>
              <Button
                type="submit"
                btnBack={"secondary"}
                bgColor={"#11555F"}
                themeType={"theme1"}
              >
                {modalTitle === "Modify" ? "Update" : "Add"}
              </Button>
            </NextButtonWrap>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </StyledForm>
  );
};

const StyledForm = styled.form`
  .button_container {
    margin-top: 10px;
    background-color: #fff;
    box-shadow: 0 0 1px #000;
    padding: 8px 18px 16px 0;
    width: 100%;
    margin-left: 0;
  }
  .document-title-for-View {
    margin: 0;
    margin-bottom: 9px;
    cursor: text;
    pointer-events: none;
    top: -22px;
    left: 3px;
    font-family: Montserrat-Medium;
    color: rgb(17, 85, 95);
    line-height: 18px;
    font-size: 12px;
    font-weight: 500;
  }
  .display-text-for-view {
    font-weight: 700 !important;
    font-size: 14px !important;
    margin-top: 0px;
    color: rgba(0, 0, 0, 0.87);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
  }
`;

const StyledH5 = styled.h5`
  margin: 0 0 4px 0;
  font-size: 12px;
  font-weight: 500;
  font-family: ${({ theme }) =>
    theme?.regularFont ? theme?.regularFont : "sans-serif"};
  color: #11555f;
`;

const LobRequiredStyled = styled.span`
  position: relative;
  left: 3px;
  color: red;
  font-size: 0.8rem;
`;
const NextButtonWrap = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
`;
