import { useQuery } from "@tanstack/react-query";
import HttpClient from "../../api/httpClient";

export const useGetNomenclatureById = (id) => {
  const { data } = useQuery({
    queryKey: ["broker-office Nomanclatures", id],
    queryFn: async () => {
      try {
        const response = await HttpClient(`broker/list/${id}`, {
          method: "GET",
        });
        return response.data;
      } catch (error) {
        throw new Error("Failed to fetch broker data.");
      }
    },
    enabled: id ? true : false,
  });

  if (data?.data) {
    return data?.data;
  }
};
