import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HttpClient from "../../api/httpClient";
import { useDispatch } from "react-redux";
import { error, success } from "./broker-config.slice";
import {
  setOfficeErrorAction,
  setOfficeSuccessAction,
} from "../broker-office-configuration/office.slice";
import {
  setNomenclatureErrorAction,
  setNomenclatureSuccessAction,
} from "../Nomenclature/nomenclature.slice";
import Swal from "sweetalert2";
import {
  setBrokerageErrorAction,
  setBrokerageSuccessAction,
} from "../Brokerage/Brokerage.slice";

// broker office location

export const uploadBrokerFile = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const queryClient = useQueryClient();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, mutate } = useMutation(
    (payload) => {
      return HttpClient("broker_office_location/upload", {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        if (data?.data.status) {
          Swal.fire({
            text: "File Successfully Uploaded.",
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: "#DC004E",
            reverseButtons: true,
            focusConfirm: false,
            focusCancel: false,
            scrollbarPadding: false,
          });
          queryClient.invalidateQueries(["brokerlocation"]);
        }
        if (!data?.data.status) {
          Swal.fire({
            text: data?.data?.message,
            icon: "error",
            confirmButtonText: "OK",
            confirmButtonColor: "#DC004E",
            reverseButtons: true,
            focusConfirm: false,
            focusCancel: false,
            scrollbarPadding: false,
          });
        }
      },
    }
  );
  return { data, mutate };
};
export const updateBrokerOffice = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const queryClient = useQueryClient();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate } = useMutation(
    (payload) => {
      return HttpClient(`location/update/${payload.id}`, {
        method: "PUT",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        if (!data.success) {
          dispatch(setOfficeErrorAction(data.error));
          return;
        }
        queryClient.invalidateQueries(["brokerlocation"]);
        dispatch(setOfficeSuccessAction(data.message));
      },
    }
  );
  return { mutate };
};
export const deleteBrokerOffice = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const queryClient = useQueryClient();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate } = useMutation(
    (id) => HttpClient(`location/delete/${id}`, { method: "DELETE" }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["brokerlocation"]);
      },
    }
  );
  return { mutate };
};

export const getBrokerOffice = (payload) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, refetch, isFetching } = useQuery({
    queryKey: ["brokerlocation"],
    queryFn: () =>
      HttpClient("location/list", {
        method: "POST",
        data: payload,
      }),
  });
  return { data, refetch, isFetching };
};

export const useDownloadSampleFile = () => {
  const { data } = useQuery({
    queryKey: ["sampleFile"],
    queryFn: () =>
      HttpClient("broker_office_location/export/excel", {
        responseType: "blob",
      }),
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
  return { data };
};

export const addBrokerOffice = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const queryClient = useQueryClient();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate } = useMutation(
    (payload) => HttpClient("location/add", { method: "POST", data: payload }),
    {
      onSuccess: (data) => {
        if (!data.success) {
          dispatch(setOfficeErrorAction(data.error));
          return;
        }
        queryClient.invalidateQueries(["brokerlocation"]);
        dispatch(setOfficeSuccessAction(data.message));
      },
    }
  );
  return { mutate };
};
export const createBrokerMaster = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const queryClient = useQueryClient();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate } = useMutation(
    (payload) =>
      HttpClient("brokerMaster/add", {
        method: "POST",
        data: payload,
      }),
    {
      onSuccess: (data) => {
        if (data?.data?.status) {
          dispatch(success(data?.data?.message));
          queryClient.invalidateQueries(["brokerMaster"]);
        } else {
          dispatch(error(data?.data?.message));
        }
      },
    }

    // {
    //   onSuccess: (data) => {
    //     if (!data.success) {
    //       dispatch(setOfficeErrorAction(data.error));
    //       return;
    //     }
    //     queryClient.invalidateQueries(["brokerlocation"]);
    //     dispatch(setOfficeSuccessAction(data.message));
    //   },
    // }
  );
  return { mutate };
};

export const useBrokerMaster = () => {
  const { data, mutate } = useMutation((payload) => {
    return HttpClient("brokerMaster/list", {
      method: "POST",
      data: payload,
    });
  });
  return { data, mutate };
};

export const getBrokerMaster = (payload) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, refetch, isFetching } = useQuery({
    queryKey: ["brokerMaster"],
    queryFn: () =>
      HttpClient("brokerMaster/list", { method: "POST", data: payload }),
  });
  return { data, refetch, isFetching };
};

export const getBrokerById = (id) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, isLoading, isError } = useQuery(
    ["brokerMaster", id],
    async () => {
      try {
        const response = await HttpClient(`brokerMaster/list/${id}`, {
          method: "GET",
        });
        return response.data;
      } catch (error) {
        // Integrate central error handling or handle the error accordingly
        throw new Error("Failed to fetch broker data.");
      }
    }
  );

  if (data?.data) {
    return data?.data;
  }
  if (isError) {
    //Integrate central error
  }
  if (isLoading) {
    //integrate central Loading
  }
};

export const useUpdateBroker = () => {
  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  const updateBroker = async (payload) => {
    try {
      const response = await HttpClient(
        `brokerMaster/update/${payload.broker_id}`,
        {
          method: "PUT",
          data: payload,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to update broker.");
    }
  };

  const { mutate } = useMutation(updateBroker, {
    onSuccess: (data) => {
      if (data?.status) {
        dispatch(success(data?.message))
        queryClient.invalidateQueries(["brokerMaster"]);
      }
      if (!data?.status) {
        dispatch(error(data?.message))
      }
    },
  });

  return { mutate };
};

export const useDeleteBroker = () => {
  const queryClient = useQueryClient();

  const deleteBroker = async (id) => {
    await HttpClient(`brokerMaster/delete/${id}`, { method: "DELETE" });
    queryClient.invalidateQueries("brokerMaster");
  };

  const { mutate } = useMutation(deleteBroker, {
    onSuccess: (data) => {
      return data;
    },
  });

  return { mutate };
};

export const useGetStateCityFromPincode = (pincode) => {
  const { data } = useQuery({
    queryKey: ["pincode", pincode],
    queryFn: () => HttpClient(`pincode/list/${pincode}`),
    enabled: Boolean(pincode && pincode.length === 6),
  });
  return { data };
};

// nomenclature
export const getNomenclature = (searchData) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, isFetching, refetch } = useQuery({
    queryKey: ["nomenclature"],
    queryFn: () =>
      HttpClient("broker/list", {
        method: "POST",
        data: searchData,
      }),
  });
  return { data, isFetching, refetch };
};

export const addNomenclature = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const queryClient = useQueryClient();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate } = useMutation(
    (payload) => HttpClient("broker/add", { method: "POST", data: payload }),
    {
      onSuccess: (data) => {
        if (data?.data?.status) {
          Swal.fire({
            text: data?.data?.message,
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: "#DC004E",
            reverseButtons: true,
            focusConfirm: false,
            allowOutsideClick: false,
            focusCancel: false,
            scrollbarPadding: false,
          });
          queryClient.invalidateQueries(["nomenclature"]);
        }
        if (!data?.data?.status) {
          Swal.fire({
            text: data?.data?.message,
            icon: "error",
            confirmButtonText: "OK",
            confirmButtonColor: "#DC004E",
            reverseButtons: true,
            focusConfirm: false,
            allowOutsideClick: false,
            focusCancel: false,
            scrollbarPadding: false,
          });
        }
      },
    }
  );
  return { mutate };
};
export const updateNomenclature = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const queryClient = useQueryClient();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate } = useMutation(
    (payload) => {
      return HttpClient(
        `broker/update/${payload[0].brokerOfficeNomenclaturesid}`,
        {
          method: "PUT",
          data: payload,
        }
      );
    },
    {
      onSuccess: (data) => {
        if (data?.data?.status) {
          Swal.fire({
            text: data?.data?.message,
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: "#DC004E",
            reverseButtons: true,
            focusConfirm: false,
            allowOutsideClick: false,
            focusCancel: false,
            scrollbarPadding: false,
          });
          queryClient.invalidateQueries(["nomenclature"]);
        }
        if (!data?.data?.status) {
          Swal.fire({
            text: data?.data?.message,
            icon: "error",
            confirmButtonText: "OK",
            confirmButtonColor: "#DC004E",
            reverseButtons: true,
            focusConfirm: false,
            allowOutsideClick: false,
            focusCancel: false,
            scrollbarPadding: false,
          });
        }
      },
    }
  );
  return { mutate };
};

export const deleteNomenclature = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const queryClient = useQueryClient();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate } = useMutation(
    (id) => HttpClient(`broker/delete/${id}`, { method: "DELETE" }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["nomenclature"]);
      },
    }
  );
  return { mutate };
};
