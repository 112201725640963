import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useDispatch } from "react-redux";
import HttpClient from "../../api/httpClient";
import {
  setHolidayErrorAction,
  setHolidaySuccessAction,
} from "./holiday-configuration.slice";

export const useGetHolidayDetails = () => {
  const { data, isFetching } = useQuery({
    queryKey: ["holidayData"],
    queryFn: () =>
      HttpClient("broker-holiday-config/list", {
        method: "POST",
        data: {
          page: 0,
          size: 0,
          sortBy: "broker_holiday_config_id",
          sortOrder: "DESC",
          searchValue: "",
        },
      }),
  });
  return { data, isFetching };
};

//POST

export const useCreateHolidayData = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (payload) => {
      return HttpClient(`broker-holiday-config/add`, {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        if (!data.success) {
          dispatch(setHolidayErrorAction(data.error));
          return;
        }
        queryClient.invalidateQueries(["holidayData"]);
        dispatch(setHolidaySuccessAction(data.message));
      },
    }
  );
  return { mutate };
};

//PUT

export const useUpdateHolidayData = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (payload) =>
      HttpClient(
        `broker-holiday-config/update/${payload.broker_holiday_config_id}`,
        {
          method: "PUT",
          data: payload,
        }
      ),
    {
      onSuccess: (data) => {
        if (!data.success) {
          dispatch(setHolidayErrorAction(data.error));
          return;
        }
        // queryClient.invalidateQueries(["holidayData"]);
        dispatch(setHolidaySuccessAction(data.message));
      },
    },

    {
      if(error) {
        // Handle the error
        console.log("Mutation error:", error);
      },
    }
  );
  return { mutate };
};

export const useDeleteHoliday = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (id) =>
      HttpClient(`broker-holiday-config/delete/${id}`, {
        method: "DELETE",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["holidayData"]);
      },
    }
  );
  return { mutate };
};

// get a single event
export const getHolidayById = (id) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, isLoading, isError } = useQuery(
    ["holidayData", id],
    async () => {
      try {
        const response = await HttpClient(`broker-holiday-config/list/${id}`, {
          method: "GET",
        });
        return response.data;
      } catch (error) {
        // Integrate central error handling or handle the error accordingly
        throw new Error("Failed to fetch broker data.");
      }
    }
  );

  if (data?.data) {
    return data?.data;
  }
  if (isError) {
    //Integrate central error
  }
  if (isLoading) {
    //integrate central Loading
  }
};

export const useUploadExcelData = () => {
  const { mutate } = useMutation(
    (payload) =>
      HttpClient(`/events/upload`, {
        method: "POST",
        data: payload,
      }),
    {
      if(error) {
        // Handle the error
        console.log("Mutation error:", error);
      },
    }
  );
  return { mutate };
};

export const useDownloadSampleFile = () => {
  const { data } = useQuery({
    queryKey: ["holidaySampleFile"],
    queryFn: () =>
      HttpClient("events/export/excel", {
        responseType: "blob",
      }),
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
  return { data };
};

export const useGetStateCityFromPincode = (pincode) => {
  const { data } = useQuery({
    queryKey: ["pincode", pincode],
    queryFn: () => HttpClient(`pincode/list/${pincode}`),
    enabled: Boolean(pincode && pincode.length === 6),
  });
  return { data };
};
