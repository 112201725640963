import { createSlice } from "@reduxjs/toolkit";
import { serializeError } from "../../utils";

const companyMaster = createSlice({
    name: "companyMaster",
    initialState: {
        companyMasterError: null,
        companyMasterSuccess: null,
    },
    reducers: {
        setCompanyMasterSuccessAction: (state, { payload }) => {
            state.companyMasterSuccess = payload;
            state.companyMasterError = null;
        },
        setCompanyMasterErrorAction: (state, { payload }) => {
            state.loading = null;
            state.companyMasterError = serializeError(payload);
            state.companyMasterSuccess = null;
        },
    },
});

export const { setCompanyMasterSuccessAction, setCompanyMasterErrorAction } =
companyMaster.actions;
export default companyMaster.reducer;