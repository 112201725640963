import { useQuery } from "@tanstack/react-query";
import HttpClient from "../api/httpClient";

const useGetTransactionFieldData = (lobId) => {
    const { data, isLoading, isFetching, isError } = useQuery({
        queryKey: ["proposal_view_labels", lobId],
        queryFn: () => HttpClient(`/segregation/listings/${lobId}`, { method: "POST" }),
        enabled: lobId ? true : false,
    })

    return { data, isLoading, isFetching, isError }
}

export default useGetTransactionFieldData;