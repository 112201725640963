import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import httpClient from "../../api/httpClient";
import { useDispatch } from "react-redux";
import {
  setCommunicationErrorAction,
  setCommunicationSuccessAction,
} from "./communication-config.slice";

export const useGetTemplateMasterList = () => {
  const { data, isFetching } = useQuery({
    queryKey: ["templateMasterList"],
    queryFn: () => httpClient("/template_master/list"),
  });

  return { data, isFetching };
};

export const useCreateTemplateData = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (payload) => {
      return httpClient(`template_master/add`, {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        if (!data.success) {
          dispatch(setCommunicationErrorAction(data.error));
          return;
        }
        queryClient.invalidateQueries(["templateMasterList"]);
        dispatch(setCommunicationSuccessAction(data.message));
      },
    }
  );

  return { mutate };
};

export const useUpdateTemplateData = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (payload) => {
      return httpClient(`/template_master/update/${payload?.templateId}`, {
        method: "PUT",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        if (!data.success) {
          dispatch(setCommunicationErrorAction(data.error));
          return;
        }
        queryClient.invalidateQueries(["templateMasterList"]);
        dispatch(setCommunicationSuccessAction(data.message));
      },
    }
  );
  return { mutate };
};

export const useDeleteTemplateData = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (id) =>
      httpClient(`/template_master/delete/${id}`, {
        method: "DELETE",
      }),

    {
      onSuccess: () => {
        queryClient.invalidateQueries(["templateMasterList"]);
      },
    }
  );
  return { mutate };
};

export const useGetTemplateDataById = (id) => {
  const { data } = useQuery(
    ["templateMasterList", id],
    () => {
      return httpClient(`template_master/list/${id}`, { method: "GET" });
    },
    {
      enabled: Boolean(id),
    }
  );
  return { data };
};
