import Grid from "@mui/material/Grid";
import { Controller } from "react-hook-form";
import Select from "react-select";
import Checkbox from "../../../components/form-components/checkbox/checkbox";
import {
  BasicDetailsSummaryStyled,
  BrokerageAffectingFieldsStyled,
  LOBContainerStyled,
  StyledBg,
} from "../Brokerage.style";
import Rules from "./Rules";
import { useEffect, useState } from "react";
import ExcelUpload from "./ExcelUpload";
import Rearrange from "./Rearrange";
import Deviation from "./Deviation";
import { GlobalRadio, Typography } from "../../../components";
import DynamicFields from "../components/DynamicFields";
import moment from "moment";
import { defaultDateFormat } from "../../../HelperFunctions/HelperFunctions";
import ReconLoader from "../../Reconciliation/components/ReconLoader";

function ConditionalBrokerageFields({
  register,
  control,
  lobId,
  regionsObj,
  effective_start_date,
  effective_end_date,
  icType,
  transactionType,
  premiumBearingObj,
  files,
  setFiles,
  fieldSlugForExcel,
  handleFieldSlugForExcel,
  downloadMaster,
  selectedBrokerageAffectedFields,
  isDisabled,
  selectedBrokerageType,
  cloneButton,
  rulesLength,
  cardInFocus,
  handleFocus,
  setValue,
  handleSave,
  operator,
  premiumBearableFields,
  handleRuleDelete,
  multipleBrokerage,
  multipleOperators,
  selectedBrokerage,
  single,
  bundle,
  allSingleCheckedValues,
  allBundleCheckedValues,
  errors,
  isTransactionFieldFetching,
}) {
  const { Error } = Typography;

  useEffect(() => {
    if (bundle && selectedBrokerageType === "Bundled") {
      //ternary op. is used because incase mutiple rules are created and brokerages are assigned later
      // other state fields were using the state of the field which was in focus
      setValue(
        `multipleBrokerage.${[
          cardInFocus.bundle - 1,
        ]}.conditionalPremiumBearableFields`,
        multipleBrokerage?.[cardInFocus?.bundle - 1]
          ?.conditionalPremiumBearableFields
          ? multipleBrokerage?.[cardInFocus?.bundle - 1]
            ?.conditionalPremiumBearableFields
          : []
      );
    }
  }, [cardInFocus?.bundle, bundle, multipleBrokerage]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "102%",
      marginTop: "5px",
      border: "0.25px solid rgb(169, 167, 167)",
      fontSize: "12px",
      boxShadow: state.isFocused ? "0 0 0 0.2rem transparent" : "none",
      backgroundColor: "rgb(247, 248, 250)",
      padding: "0 5px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      border: 0,
      padding: 0,
    }),
    option: (styles, { isFocused, isSelected, isDisabled }) => ({
      ...styles,
      fontWeight: 600,
      letterSpacing: "0.5px",
      fontSize: "14px",
      fontFamily: "Montserrat-SemiBold",
      borderBottom: ".25px solid",
      backgroundColor: isSelected ? "#D1F8E8" : isFocused ? "#fff" : "#fff",
      color: isDisabled ? "#e4e4e4" : "#2BB9A8",
      cursor: isDisabled ? "not-allowed" : "normal",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: 0,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
    }),
    multiValue: (provided) => ({
      ...provided,
      padding: "5px 10px",
      color: "#2BB9A8",
      borderRadius: "5px",
      margin: "5px",
      background: "#22bdaa30",
      fontSize: "14px",
      fontWeight: "600",
    }),
  };

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleModal = (e) => {
    if (e.target.tagName.toLowerCase() === "div") {
      return;
    }
    setOpen(true);
  };

  const dynamicType =
    selectedBrokerageType === "Single"
      ? rulesLength.single
      : rulesLength.bundle;

  return (
    <>
      <StyledBg differentSpacing={true}>
        <p className="card_header">Basic Details Summary</p>
        <BasicDetailsSummaryStyled>
          <div>
            <p>
              LOB: <span>{lobId?.value}</span>
            </p>
            <p>
              Transaction Type: <span>{transactionType?.label}</span>
            </p>
            <p>
              Insurance Company: <span>{icType?.value}</span>
            </p>
          </div>
          <div>
            <p>
              Effective Start Date:{" "}
              <span>{defaultDateFormat(effective_start_date)}</span>
            </p>
            <p className="end_date">
              Effective End Date:{" "}
              <span>{defaultDateFormat(effective_end_date)}</span>
            </p>
          </div>
        </BasicDetailsSummaryStyled>
      </StyledBg>

      <StyledBg>
        <p className="card_header">Conditional Brokerage</p>
        <GlobalRadio
          name={`selectedBrokerageType`}
          label="Select Type:"
          options={["Single", "Bundled"]}
          control={control}
        />
        {selectedBrokerageType && (
          <>
            {" "}
            <Grid
              container
              mt={1}
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {selectedBrokerageType === "Bundled" && (
                <Grid
                  item
                  sx={{ position: "relative" }}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  <LOBContainerStyled>
                    <label
                      htmlFor={`multipleBrokerage.${[
                        cardInFocus?.bundle - 1,
                      ]}.conditionalPremiumBearableFields`}
                    >
                      Select Fields
                    </label>
                    <Controller
                      control={control}
                      name={`multipleBrokerage.${[
                        cardInFocus?.bundle - 1,
                      ]}.conditionalPremiumBearableFields`}
                      render={({ field }) => (
                        <Select
                          {...field}
                          isMulti
                          closeMenuOnSelect={true}
                          maxMenuHeight={200}
                          id={`multipleBrokerage.${[
                            cardInFocus?.bundle - 1,
                          ]}.conditionalPremiumBearableFields`}
                          placeholder="Select Fields"
                          options={premiumBearingObj}
                          styles={customStyles}
                        />
                      )}
                    />
                  </LOBContainerStyled>
                  {errors?.multipleBrokerage?.[cardInFocus?.bundle - 1]
                    ?.conditionalPremiumBearableFields?.message && (
                      <Error top="0" left="3px" bottom="-14px">
                        Select a field
                      </Error>
                    )}
                </Grid>
              )}
            </Grid>
            <BrokerageAffectingFieldsStyled>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                {regionsObj?.map((value) => {
                  if (
                    selectedBrokerageType === "Single" &&
                    allSingleCheckedValues[cardInFocus?.single - 1]?.length >= 1
                  ) {
                    if (
                      value.value ===
                      allSingleCheckedValues[cardInFocus?.single - 1]?.[0]?.slug
                    ) {
                      return (
                        <DynamicFields
                          value={value}
                          control={control}
                          handleModal={handleModal}
                          handleFieldSlugForExcel={handleFieldSlugForExcel}
                          setValue={setValue}
                          cardInFocus={cardInFocus}
                          selectedBrokerageType={selectedBrokerageType}
                          single={single}
                          bundle={bundle}
                        />
                      );
                    }
                    return (
                      <DynamicFields
                        value={value}
                        control={control}
                        handleModal={handleModal}
                        handleFieldSlugForExcel={handleFieldSlugForExcel}
                        isDisabled={true}
                        setValue={setValue}
                        cardInFocus={cardInFocus}
                        selectedBrokerageType={selectedBrokerageType}
                        single={single}
                        bundle={bundle}
                      />
                    );
                  }
                  return (
                    <DynamicFields
                      value={value}
                      control={control}
                      handleModal={handleModal}
                      handleFieldSlugForExcel={handleFieldSlugForExcel}
                      setValue={setValue}
                      cardInFocus={cardInFocus}
                      selectedBrokerageType={selectedBrokerageType}
                      single={single}
                      bundle={bundle}
                    />
                  );
                })}
              </Grid>
            </BrokerageAffectingFieldsStyled>
            {dynamicType.map((value, index) => {
              return (
                <Rules
                  control={control}
                  register={register}
                  errors={errors}
                  selectedBrokerageAffectedFields={
                    selectedBrokerageAffectedFields
                  }
                  isDisabled={isDisabled}
                  selectedBrokerageType={selectedBrokerageType}
                  cloneButton={cloneButton}
                  rulesLength={value}
                  cardInFocus={cardInFocus}
                  handleFocus={handleFocus}
                  handleModal={handleModal}
                  handleRuleDelete={handleRuleDelete}
                  handleFieldSlugForExcel={handleFieldSlugForExcel}
                  mainIndex={index}
                  handleSave={handleSave}
                  multipleBrokerage={multipleBrokerage}
                  selectedBrokerage={selectedBrokerage}
                  allSingleCheckedValues={allSingleCheckedValues}
                  allBundleCheckedValues={allBundleCheckedValues}
                />
              );
            })}
            <ExcelUpload
              open={open}
              handleClose={handleClose}
              control={control}
              files={files}
              setFiles={setFiles}
              lobId={lobId}
              fieldSlug={fieldSlugForExcel}
              downloadMaster={downloadMaster}
              selectedBrokerageType={selectedBrokerageType}
              cardInFocus={cardInFocus}
              operator={operator}
              multipleOperators={multipleOperators}
              premiumBearableFields={premiumBearableFields}
            />
            <Rearrange open={false} handleClose={handleClose} />
            <Deviation open={false} handleClose={handleClose} />
          </>
        )}
      </StyledBg>

      <ReconLoader isLoading={isTransactionFieldFetching} />
    </>
  );
}

export default ConditionalBrokerageFields;
