import React from "react";
import { Heading, TableContainer, TableHeaderContainer } from "../style";
import { DataGrid } from "@mui/x-data-grid";

const ActivityTable = ({ rows, columns }) => {
  return (
    <>
      <TableHeaderContainer>
        <Heading>Activity List</Heading>
      </TableHeaderContainer>
      <TableContainer>
        <DataGrid
          rows={rows || []}
          columns={columns}
          size="small"
          fontSize={12}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 20 },
            },
          }}
          pageSizeOptions={[5, 20]}
          sx={{
            width: "100%",
            ".MuiDataGrid-cellContent": {
              fontSize: "14px",
            },
            ".MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "&.MuiDataGrid-root": {
              border: "none",
            },
            ".MuiDataGrid-columnHeaders": {
              background: "#D2F8E9",
              color: "#11555F",
              fontSize: "14px",
              fontWeight: 500,
              fontFamily: "Montserrat-Medium",
            },
            ".MuiTablePagination-root p": {
              fontSize: "14px",
            },
          }}
        />
      </TableContainer>
    </>
  );
};

export default ActivityTable;
