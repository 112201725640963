import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { BsFiletypeXls, BsImages } from "react-icons/bs";
import Swal from "sweetalert2";
import { Button } from "../../../components";
import Excel from "../../../utils/excel.png";
import { useDownloadSampleFile, useUploadExcelData } from "../service";
import {
  PickerWrap,
  StyledContainer,
  XlsAndButtonWrap
} from "../style/holida-style";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "2px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  alignItems: "center",
  padding: "5px",
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  // overflow: "hidden",
};
const filename = {
  fontSize: "13px",
  whiteSpace: "nowrap",
  position: "relative",
  left: "5px",
  verticalAlign: "middle",
  fontWeight: "bold",
};
const img = {
  display: "block",
  width: "40px",
  height: "40px",
};

export const FilePickerHoliday = ({ isHolidayPage, themeType, handleClose }) => {
  const [files, setFiles] = useState([]);

  const { mutate } = useUploadExcelData();

  const { data } = useDownloadSampleFile();

  const handleDownloadSampleFile = () => {
    const blob = new Blob([data.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Broker Holiday Sample File.xlsx";
    a.click();
    URL.revokeObjectURL(url);
    a.remove();
    handleClose();
    Swal.fire({
      text: "Sample Excel File Successfully Downloaded.",
      icon: "success",
      confirmButtonText: "OK",
      confirmButtonColor: "#DC004E",
      reverseButtons: true,
      focusConfirm: false,
      focusCancel: false,
      scrollbarPadding: false,
    });
  };

  const handleFileSubmit = () => {
    if (files.length === 0) {
      return;
    }
    const formData = new FormData();
    formData.append("file", files[0]);
    mutate(formData);
    setFiles([]);
    handleClose();
    Swal.fire({
      text: "File Successfully Uploaded.",
      icon: "success",
      confirmButtonText: "OK",
      confirmButtonColor: "#DC004E",
      reverseButtons: true,
      focusConfirm: false,
      focusCancel: false,
      scrollbarPadding: false,
    });
  };

  const removeSelection = (file) => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "text/plain": [],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <StyledContainer className="container">
      <PickerWrap isHolidayPage={isHolidayPage} themeType={themeType}>

        <div {...getRootProps({ className: "dropzone" })}>
          <BsImages className="ImageIcon" />
          <input {...getInputProps()} />
          <p
            style={{
              //   height: "250px",
              margin: "0px",
              textAlign: "center",
              padding: "5px",
            }}
          >
            Drag and drop an Image to upload or
          </p>
          <Button themeType={themeType}>Browse Computer</Button>
          <ul>
            <li>You can upload excel file</li>
            <li>Maximum file size is 10MB</li>
            <li>Select only XLS Files</li>
          </ul>
        </div>
        <XlsAndButtonWrap>
          <div style={{ display: "flex" }}>
            <BsFiletypeXls color={"#2ca92a"} className="ImageIcon" />
            <a href="#" onClick={handleDownloadSampleFile}>
              Click to Download Sample File
            </a>
          </div>
          <Button
            themeType={themeType}
            borderRadius={"35px !important"}
            padding={"20px"}
            onClick={handleFileSubmit}
            round
          >
            Upload File
          </Button>
        </XlsAndButtonWrap>
        <aside style={thumbsContainer}>
          {files.map((file) => (
            <div style={thumb} key={file.name}>
              <div style={thumbInner}>
                <img
                  src={Excel}
                  alt="file"
                  style={img}
                  onLoad={() => {
                    URL.revokeObjectURL(file.preview);
                  }}
                />
                <p style={filename}>
                  {file.name.slice(0, file.path.indexOf("."))}
                </p>
                <CloseOutlinedIcon
                  onClick={() => removeSelection()}
                  style={{
                    position: "relative",
                    top: "10px",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          ))}
        </aside>
      </PickerWrap>
    </StyledContainer>
  );
};

