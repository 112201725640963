import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import NoData from "../../../components/NoData/NoData";
import Swal from "sweetalert2";
import {
  setDocumentSuccessAction,
  setDocumentErrorAction,
} from "../doc-config.slice";
import { useGetDocumentData } from "../service";
import { Button } from "../../../components";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import TableSkeleton from "../../../components/SkeletonLoader/TableSkeleton";
import { useLocation } from "react-router-dom";
import useGetOperations from "../../../hooks/useGetOperations";
export const DocManagementTable = ({
  setModalTitle,
  setOpen,
  setPrefillData,
  handleUserDeletion,
}) => {
  const { data, isFetching } = useGetDocumentData();

  const dispatch = useDispatch();
  const location = useLocation();

  const { allowedOperations } = useGetOperations(location.pathname);

  const rows = data?.data?.data?.map((value) => {
    const lob_list = value.lobId ? Object.values(value.lobId) : {};
    const lob_id = value.lobId ? Object.keys(value.lobId) : {};

    const doc_list = value.documentFormatMasterId
      ? Object.values(value.documentFormatMasterId)
      : {};
    const doc_id = value.documentFormatMasterId
      ? Object.keys(value.documentFormatMasterId)
      : {};

    return {
      id: value.documentManagementConfigurationId,
      name: value.documentName || "",
      doc_type: doc_list,
      doc_code: value.documentCode,
      lob: lob_list,
      lobId: lob_id,
      docId: doc_id,
    };
  });

  const columns = [
    {
      field: "name",
      headerName: "Document Name",
      flex: 1,
      background: "#E4E4E4",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "doc_type",
      headerName: "Document Type",
      flex: 2,
      background: "#E4E4E4",
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <CellContainer>
            {params.row.doc_type.map((value) => {
              return <CellStyled>{value}</CellStyled>;
            })}
          </CellContainer>
        );
      },
    },
    {
      field: "lob",
      headerName: "Line Of Business",
      flex: 2,
      background: "#E4E4E4",
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <CellContainer>
            {params.row.lob.map((value) => {
              return <CellStyled>{value}</CellStyled>;
            })}
          </CellContainer>
        );
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      headerAlign: "left",
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const lob = params.row.lob.map((item, index) => {
          return { id: params.row.lobId[index], value: item, label: item };
        });
        const doc_type = params.row.doc_type.map((item, index) => {
          return { id: params.row.docId[index], value: item, label: item };
        });
        return (
          <ActionContainer>
            {(allowedOperations.includes("View") ||
              allowedOperations.includes("Edit") ||
              allowedOperations.includes("Delete")) && (
              <PreviewOutlinedIcon
                id={`view_${params.row.id}`}
                onClick={() => {
                  dispatch(setDocumentSuccessAction(null));
                  setModalTitle("View");
                  setOpen(true);
                  setPrefillData({
                    name: params.row.name,
                    code: params.row.doc_code,
                    id: params.row.id,
                    lob,
                    type: doc_type,
                  });
                }}
                index={params.row.id}
              />
            )}
            {allowedOperations.includes("Edit") && (
              <BorderColorOutlinedIcon
                id={`edit_${params.row.id}`}
                onClick={() => {
                  dispatch(setDocumentSuccessAction(null));
                  setModalTitle("Modify");
                  setOpen(true);
                  setPrefillData({
                    name: params.row.name,
                    code: params.row.doc_code,
                    id: params.row.id,
                    lob,
                    type: doc_type,
                  });
                }}
                index={params.row.id}
              />
            )}
            {(allowedOperations.includes("Delete") ||
              allowedOperations.includes("Edit")) && (
              <DeleteForeverOutlinedIcon
                id={`delete_${params.row.id}`}
                onClick={() => {
                  Swal.fire({
                    title: "Delete record?",
                    text: "Confirmation required to delete this record.",
                    icon: "question",
                    confirmButtonText: "Delete",
                    confirmButtonColor: "#DC004E",
                    showCancelButton: true,
                    cancelButtonText: "Cancel",
                    reverseButtons: true,
                    focusConfirm: false,
                    focusCancel: false,
                    scrollbarPadding: false,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      handleUserDeletion(params.row.id);
                      Swal.fire("Deleted!", "Deleted Successfully", "success");
                    }
                  });
                }}
                index={params.row.id}
              />
            )}
          </ActionContainer>
        );
      },
    },
  ];

  const getRowHeight = (params) => {
    const length = params.model.lob.length <= params.model.doc_type.length;
    let contentHeight = length
      ? params.model.doc_type.length * 10
      : params.model.lob.length * 10;
    if (params.model.lob.length === params.model.doc_type.length) {
      contentHeight = 20;
    }
    const cellPadding = 16 * 2;
    return contentHeight + cellPadding;
  };

  if (isFetching) {
    return <TableSkeleton />;
  }
  return (
    <>
      <TableHeaderContainer>
        <Heading>Document Table</Heading>
        {allowedOperations.includes("Edit") && (
          <Button
            onClick={() => {
              setModalTitle("Add New");
              setOpen(true);
              dispatch(
                setDocumentSuccessAction(null),
                setDocumentErrorAction(null)
              );
            }}
            bgColor="transparent"
            textColor="#11555F"
            id="add_btn"
            variant="outlined"
            borderColor="#11555F"
            startIcon={<AddBoxOutlinedIcon />}
            style={{
              position: "absolute",
              right: "20px",
            }}
          >
            Add New
          </Button>
        )}
      </TableHeaderContainer>
      <TableContainer>
        {rows?.length === 0 ? (
          <NoData />
        ) : (
          data?.data && (
            <>
              <DataGrid
                rows={rows || []}
                columns={columns}
                getRowHeight={getRowHeight}
                size="small"
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 20 },
                  },
                }}
                pageSizeOptions={[5, 20]}
                sx={{
                  ".MuiDataGrid-cellContent": {
                    fontSize: "14px",
                  },
                  ".MuiDataGrid-columnSeparator": {
                    display: "none",
                  },
                  "&.MuiDataGrid-root": {
                    border: "none",
                  },
                  ".MuiDataGrid-columnHeaders": {
                    background: "#D2F8E9",
                    color: "#11555F",
                    fontSize: "14px",
                    fontWeight: 500,
                    fontFamily: "Montserrat-Medium",
                  },
                  ".MuiTablePagination-root p": {
                    fontSize: "14px",
                  },
                }}
              />
            </>
          )
        )}
      </TableContainer>
    </>
  );
};

const DataLoadingStyled = styled.div`
  text-align: center;
  margin-top: 50px;
`;
const TableContainer = styled.div`
  // padding-top: 10px;
  background: #fff;
  width: 100%;
`;
const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  svg {
    color: #178e80;
    font-size: 20px;
  }
`;

const CellContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 12px;
`;

const CellStyled = styled.p`
  margin: 5px;
  border: 0.25px solid;
  border-radius: 5px;
  padding: 5px 20px;
`;
export const Heading = styled.h2`
  margin-top: -3px;
  margin-bottom: 0;
  display: flex;
  color: #178e80;
  font-family: "Montserrat-SemiBold";
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
`;
