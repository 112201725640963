import { Button } from "../../../components"
import GlobalModal from "../../../components/Modal/modal"
import { RulesDeviationStyled } from "../Brokerage.style"
import Grid from "@mui/material/Grid"

function Deviation({ open, handleClose }) {
    const fields = ['Honda', "Maruti", "Suzuki", "BMW"]
    return (
        <GlobalModal
            open={open}
            title={"Rules & Deviation"}
            onClose={handleClose}
        >
            <RulesDeviationStyled>
                <div className="fields">
                    {fields.map(value => {
                        return (
                            <p>{value}</p>
                        )
                    })}
                </div>
                <p><b>Type: </b>Flat</p>
                <p><b>Payout: </b>$2000</p>
                <Grid container rowSpacing={1} mt={1.5} mb={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6} sm={6} md={6} lg={10} />
                    <Grid item xs={6} sm={6} md={6} lg={1}>
                        <Button themeType={"theme1"} bgColor="rgba(17, 85, 95, 1)">
                            Okay
                        </Button>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={1}>
                        <Button
                            themeType={"theme1"}
                            btnBack={"tertiary"}
                            id="cancel"
                            textColor="rgb(23, 142, 128)"
                            bgColor="transparent"
                            borderColor="none"
                        >
                            Edit
                        </Button>
                    </Grid>
                </Grid>
            </RulesDeviationStyled>
        </GlobalModal>
    )
}

export default Deviation