import { Grid } from "@mui/material";
import React from "react";
import { Button, Input, Typography } from "../../../../components";

import { allowLowerCaseAndUnderscore } from "../../../../HelperFunctions/HelperFunctions";
import Dropdown from "../../../../components/Dropdown/Dropdown";

const AddNewMasterForm = ({ control, register, errors }) => {
  const { StyledH1, Error } = Typography;
  return (
    <>
      <Grid
        container
        sx={{ paddingInline: "24px" }}
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid item sx={{ position: "relative" }} xs={12} sm={12} md={6} lg={3}>
          <Input
            themeType={"theme1"}
            label="Master Title"
            name="masterTitle"
            id="masterTitle"
            maxLength="100"
            placeholder="Enter Master Title"
            autoComplete="none"
            // onChange={(e) => preventSpecialAndNumbersInInput(e)}
            defaultValue={""}
            required={false}
            inputRef={register("masterTitle")}
          />
          {!!errors?.masterTitle && (
            <Error top="5px" left="3px">
              {errors.masterTitle.message}
            </Error>
          )}
        </Grid>
        <Grid item sx={{ position: "relative" }} xs={12} sm={12} md={6} lg={3}>
          <Input
            themeType={"theme1"}
            label="Master Slug"
            name="masterSlug"
            id="masterSlug"
            maxLength="100"
            placeholder="Enter Master Slug"
            autoComplete="none"
            onChange={(e) => allowLowerCaseAndUnderscore(e)}
            defaultValue={""}
            required={false}
            inputRef={register("masterSlug")}
            // readOnly={true}
          />
          {!!errors?.masterSlug && (
            <Error top="5px" left="3px">
              {errors.masterSlug.message}
            </Error>
          )}
        </Grid>
        <Grid item sx={{ position: "relative" }} xs={12} sm={12} md={6} lg={3}>
          <Input
            themeType={"theme1"}
            label="Master Table"
            name="masterTable"
            id="masterTable"
            maxLength="100"
            placeholder="Enter Master Table"
            autoComplete="none"
            onChange={(e) => allowLowerCaseAndUnderscore(e)}
            defaultValue={""}
            required={false}
            inputRef={register("masterTable")}
            // readOnly={true}
          />
          {!!errors?.masterTable && (
            <Error top="5px" left="3px">
              {errors.masterTable.message}
            </Error>
          )}
        </Grid>
        <Grid item sx={{ position: "relative" }} xs={12} sm={12} md={6} lg={3}>
          <Input
            themeType={"theme1"}
            label="Unique Key"
            name="uniqueKey"
            id="uniqueKey"
            maxLength="100"
            placeholder="Enter Unique Key"
            autoComplete="none"
            onChange={(e) => allowLowerCaseAndUnderscore(e)}
            defaultValue={""}
            required={false}
            inputRef={register("uniqueKey")}
            // readOnly={true}
          />
          {!!errors?.uniqueKey && (
            <Error top="5px" left="3px">
              {errors.uniqueKey.message}
            </Error>
          )}
        </Grid>
        <Grid item sx={{ position: "relative" }} xs={12} sm={12} md={6} lg={3}>
          <Dropdown
            id="isLobSpecific"
            label="Is LOB Specific ?"
            control={control}
            selectObj={[
              {
                label: "Yes",
                value: "Y",
              },
              {
                label: "No",
                value: "N",
              },
            ]}
            maxMenuHeight={200}
          />
          {!!errors?.isLobSpecific && (
            <Error top="5px" left="3px">
              {errors.isLobSpecific.message}
            </Error>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        mt={1}
        className="button_container"
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ justifyContent: "end" }}
      >
        <Grid
          item
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "end",
          }}
          xs={6}
          sm={6}
          md={6}
          lg={6}
        >
          <Button
            type="submit"
            themeType={"theme1"}
            id={"save_btn"}
            bgColor={"#11555F"}
          >
            Add
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default AddNewMasterForm;
