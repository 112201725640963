import React, { useEffect } from "react";
import { Typography } from "../../../../components";
import { Box, Step, StepButton, Stepper } from "@mui/material";
import { useForm } from "react-hook-form";
import { InputFrame, MasterConfigModalStyled } from "../../MasterConfig.style";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { setFieldsForMaster } from "../AddNewMaster.slice";
import { yupResolver } from "@hookform/resolvers/yup";
import AddNewMasterForm from "./AddNewMasterForm";
import CollectedFields from "./CollectedFields";

const NewMasterModal = ({
  createNewMaster,
  masterId,
  createFieldsForMaster,
  fieldTypeOptions,
  isCreateMasterSuccess,
  isCreateFieldSuccess,
  validationGroupOptions,
  addFieldsToMasterValidation,
  addNewMasterValidation,
}) => {
  const dispatch = useDispatch();
  const steps = ["Add Master", "Add Fields To Master"];
  const { StyledH1, Error } = Typography;
  const addMasterMethods = useForm({
    defaultValues: {
      status: "Y",
      isLobSpecific: {
        label: "No",
        value: "N",
      },
    },
    resolver: yupResolver(addNewMasterValidation),
  });
  const {
    watch,
    setValue,
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = addMasterMethods;

  const { fieldsForMaster, activeTab } = useSelector(
    (state) => state.AddNewMasterSlice
  );

  const addFieldsMethods = useForm({
    defaultValues: {
      isMasterApiAvailable: "N",
      isMandatory: "N",
    },
    resolver: yupResolver(addFieldsToMasterValidation),
  });
  const {
    watch: addColumnWatch,
    setValue: addColumnSetValue,
    handleSubmit: addColumnHandleSubmit,
    register: addColumnRegister,
    formState: { errors: addColumnErrors },
    control: addColumnControl,
    reset: addColumnReset,
  } = addFieldsMethods;

  const handleAddNewMasterFormSubmit = (data) => {
    // dispatch(setActiveTab(1));
    const payload = { ...data, isLobSpecific: data?.isLobSpecific?.value };
    createNewMaster(payload);
  };

  const addMultipleFields = (data) => {
    const payload = {
      ...data,
      fieldType: data?.fieldType?.value,
      validationGroup: data?.validationGroup?.value,
      masterId: masterId,
      // masterId: 56,
    };
    dispatch(setFieldsForMaster(payload));
    addColumnReset();
  };

  const handleAddFieldsForMasterFormSubmit = () => {
    createFieldsForMaster(fieldsForMaster);
  };
  const masterTitle = watch("masterTitle");
  const convertToUnderscore = (name) => {
    return name?.toLowerCase()?.replace(/\s+/g, "_");
  };
  useEffect(() => {
    setValue("masterSlug", masterTitle ? convertToUnderscore(masterTitle) : "");
    setValue(
      "masterTable",
      masterTitle ? convertToUnderscore(masterTitle) : ""
    );
    setValue(
      "uniqueKey",
      masterTitle ? `${convertToUnderscore(masterTitle) + "_id"}` : ""
    );
  }, [masterTitle]);

  const fieldName = addColumnWatch("fieldName");
  useEffect(() => {
    addColumnSetValue(
      "fieldSlug",
      fieldName ? convertToUnderscore(fieldName) : ""
    );
  }, [fieldName]);

  return (
    <>
      <StyledH1
        themeType="theme1"
        partialU
        color={"rgb(23, 142, 128)"}
        align={"left"}
      />
      <MasterConfigModalStyled>
        <Box
          sx={{
            background: "#fff",
            marginBottom: "16px",
            ".stepperHeader": {
              padding: "0 16px",
            },
          }}
        >
          <Stepper
            nonLinear
            // activeStep={activeStep}
            sx={{
              width: "50%",
              marginX: "auto",
              "& .MuiStepConnector-line": {
                borderTopWidth: "3px",
                borderColor: "rgba(17, 85, 95, 1)",
              },
              "& svg.MuiStepIcon-root": {
                color: "rgba(203, 249, 231, 1)",
              },
              "& svg.MuiStepIcon-root text": {
                fill: "rgba(17, 85, 95, 1)",
              },
              "& svg.Mui-active": {
                color: "rgba(17, 85, 95, 1)",
              },
              "& svg.Mui-active text": {
                fill: "rgba(203, 249, 231, 1)",
              },
              "& svg.Mui-completed": {
                color: "rgba(17, 85, 95, 1)",
              },
            }}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepButton color="inherit">{label}</StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>
        {activeTab === 0 && (
          <InputFrame onSubmit={handleSubmit(handleAddNewMasterFormSubmit)}>
            <AddNewMasterForm
              register={register}
              control={control}
              errors={errors}
              addMasterMethods={addMasterMethods}
            />
          </InputFrame>
        )}
        {activeTab === 1 && (
          <>
            <InputFrame onSubmit={addColumnHandleSubmit(addMultipleFields)}>
              <CollectedFields
                handleAddFieldsForMasterFormSubmit={
                  handleAddFieldsForMasterFormSubmit
                }
                addColumnRegister={addColumnRegister}
                addColumnControl={addColumnControl}
                addColumnErrors={addColumnErrors}
                fieldTypeOptions={fieldTypeOptions}
                validationGroupOptions={validationGroupOptions}
                addColumnWatch={addColumnWatch}
              />
            </InputFrame>
          </>
        )}
      </MasterConfigModalStyled>
    </>
  );
};

export default NewMasterModal;
