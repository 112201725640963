import { FilePickerHoliday } from "./file-picker";

const BulkUpload = ({ handleClose }) => {
  return (
    <FilePickerHoliday
      themeType={"theme1"}
      heading={"Broker Bulk Upload"}
      handleClose={handleClose}
    />

  );
};

export default BulkUpload;
