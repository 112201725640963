import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";

// holiday config style
export const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  .holiday_input {
    background: #fff;
    padding: 12px 16px;
    margin-right: 7px;
  }
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  align-items: ${({ position }) => (position ? "start" : "center")};
  flex-direction: column;
  font-family: "Nunito";
  box-shadow: 0px 22px 45px -15px #dddde2;
  background-color: #ffffff;
  margin-top: 15px;
  padding: 20px;
  max-width: 99.5% !important;
`;

export const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 12px;
  & > div {
    display: flex;
    gap: 10px;
  }
`;

// file picker style
export const StyledContainer = styled.div`
  font-family: Nunito;
`;
export const PickerWrap = styled.div`
  padding: 20px;
  background: #fcfcff;
  height: 100%;
  box-shadow: 1px 1px 5px gray;
  h2 {
    margin: 0 0 10px 0;
  }

  .dropzone {
    cursor: pointer;
    background: #fcfcff;
    border: 2px dashed #a4a4cb;
    padding: 10px 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
  }
  .ImageIcon {
    font-size: 30px;
    color: ${({ themeType, theme }) =>
      themeType && theme ? theme?.[themeType]?.litePrimary : "#24aa9a"};
  }
  ul {
    font-size: 14px;
  }
`;

export const XlsAndButtonWrap = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  a {
    margin: auto;
    color: ${({ themeType, theme }) =>
      themeType && theme ? theme?.[themeType]?.litePrimary : "#24aa9a"};
    margin-left: 10px;
    font-weight: 600;
  }
`;

export const Heading2 = styled.h2`
  padding: 12px;
  display: flex;
  justify-content: space-between;
  .CloseIcon {
    cursor: pointer;
  }
`;

export const UploadedImage = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
`;

export const ImageContainer = styled.div`
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-top: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
`;

export const Thumbnail = styled.div`
  display: flex;
  min-width: 0;
  overflow: hidden;
`;

export const Image = styled.div`
  display: block;
  width: auto;
  height: 100%;
`;

// modal style
export const ModalContainer = styled.div`
  display: flex;
  align-items: ${({ position }) => (position ? "start" : "center")};
  flex-direction: column;
  font-family: "Nunito";
  margin: 10px;
  box-shadow: 0px 22px 45px -15px #dddde2;
  background-color: #ffffff;
  border-radius: 15px;
  padding-top: 20px;
  margin-left: 6px;
  margin-right: 37px;
  margin: auto;
  .rdrStartEdge {
    color: #11555f !important;
  }
  .rdrEndEdge {
    color: #11555f !important;
  }
  .rdrInRange {
    color: #cbf9e7 !important;
  }
  .rdrInRange ~ .rdrDayNumber span {
    color: #11555f !important;
  }
  .rdrCalendarWrapper {
    width: 100%;
  }
  .customError {
    font-size: 14px;
    color: red;
  }
  .toggle_btn {
    display: flex;
    gap: 10px;
    p {
      font-size: 0.75rem;
    }
  }
  .note_text {
    color: red;
    padding-left: 20px;
  }
  .date-title-for-View {
    margin: 0;
    margin-bottom: 9px;
    cursor: text;
    pointer-events: none;
    top: -22px;
    left: 3px;
    font-family: Montserrat-Medium;
    color: rgb(17, 85, 95);
    line-height: 18px;
    font-size: 12px;
    font-weight: 500;
  }
  .display-text-for-view {
    font-weight: 700 !important;
    padding-left: 1px;
    font-size: 14px !important;
    margin-top: 0px;
    color: rgba(0, 0, 0, 0.87);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
  }
`;

export const AddHolidayStyled = styled.div`
  position: absolute;
  right: 31px;
  top: 19px;
  height: 38px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  background: rgb(43, 183, 166);
  margin-left: auto;
  border: 0.25px solid;
  border-radius: 20px;
  padding: 5px 20px;
  width: fit-content;
  font-size: 1.25rem;
  font-weight: 600;
  display: flex;
  flex-direction: row-reverse;
  -webkit-box-align: center;
  align-items: center;
  gap: 10px;
  p {
    margin: 0;
  }
  &:hover {
    background: rgb(255, 255, 255);
    color: rgb(43, 183, 166);
  }
`;

// table style
export const MajaIcon = styled(AddIcon)``;

export const TableContainer = styled.div`
  // padding-top: 10px;
  width: 100%;
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  svg {
    color: #178e80;
    font-size: 20px;
  }
`;

export const Heading = styled.h2`
  margin-top: -3px;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  color: #178e80;
  font-family: "Montserrat-SemiBold";
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
`;

export const DifferentMonthParentDate = styled.div`
  border-right: 1px solid #e4e4e4;
  width: 220px;
  display: flex;
  flex-direction: column;
  & > div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  & div {
    margin: 0;
    font-size: 16px;
    & div:last-child {
      line-height: 0;
    }
  }
`;

export const ParentDate = styled.div`
  width: 220px;
`;
export const SameMonthParentDate = styled.div`
  border-right: 1px solid #e4e4e4;
  width: 220px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  & > div:first-of-type {
    flex-shrink: 0;
    width: 100%;
  }
`;
export const Img = styled.img`
  width: 17px;
  position: relative;
  top: 36px;
  right: 6px;
`;
export const Heading3 = styled.h3`
  position: relative;
  left: 21px;
  bottom: 4px;
`;
