import styled from "styled-components";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

const GlobalModal = ({
  open,
  onClose,
  title,
  width,
  maxHeight,
  overflowVisible,
  children,
  enableBackdropClick = false,
}) => {
  const handleBackdropClick = (event) => {
    // Prevent closing the modal when clicking outside of it
    event.stopPropagation();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      BackdropProps={
        !enableBackdropClick && {
          onClick: handleBackdropClick,
        }
      }
    >
      <ModalContainer
        width={width}
        maxHeight={maxHeight}
        overflowVisible={overflowVisible}
      >
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
        </ModalHeader>
        <div>{children}</div>
      </ModalContainer>
    </Modal>
  );
};

export default GlobalModal;

const ModalContainer = styled.div`
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${(props) => (props.width ? props.width + "px" : "1000px")};
  max-height: ${(props) =>
    props.maxHeight ? props.maxHeight + "px" : "560px"};
  overflow: ${(props) => (props.overflowVisible ? "visible" : "scroll")};
  max-width: 90%;
  scrollbar-width: none;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background-color: #fff;
  position: relative;
  font-weight: 700;
  font-size: 27px;
  color: #000;
  border-bottom: 1px solid #ccc;
  font-family: "Montserrat", sans-serif;
  svg {
    font-size: 20px;
    color: #178e80;
  }
  position: sticky;
  top: 0;
  z-index: 1;
`;

const ModalTitle = styled.h3`
  margin: 0;
  color: #178e80;
  font-family: "Montserrat-SemiBold";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
`;

const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  svg {
    font-size: 20px;
    color: #178e80;
  }
`;
