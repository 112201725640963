import { useQuery } from "@tanstack/react-query";
import HttpClient from "../../api/httpClient";

export const useGetMenu = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, isLoading, isError } = useQuery({
    queryKey: ["menuMaster"],
    queryFn: () => HttpClient("menus/list", { method: "GET" }),
  });
  if (data?.data) {
    return data?.data?.data;
  }
  if (isError) {
  }
  if (isLoading) {
  }
};

export const useGetAccessRightsMenu = () => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: ["accessRightsMenus"],
    queryFn: () => HttpClient("access_rights/list"),
    // enabled: isTokenPresent && !isMenusPresent && !isLoginRoute ? true : false,
  });

  return { data, isFetching, refetch };
};
