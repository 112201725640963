import { identity } from "lodash";
import Dropdown from "../../../components/Dropdown/Dropdown";

function ProposalDropdown({
  id,
  label,
  control,
  setValue,
  masterTableData,
  isRequired,
  proposalRead,
  manufacturerData,
  modelData,
  versionData,
  branchData,
  fuelData,
  userData,
  placeholder,
  type,
  filter,
}) {
  let masterTableField = masterTableData?.find(
    (value) => value?.data?.data?.[0]?.fieldSlug === filter
  );

  let selectObj = [];

  switch (id) {
    case "fuelType":
      const fuelType_Obj = fuelData?.data?.map((value) => ({
        label: value,
        value,
      }));

      selectObj = fuelType_Obj ? [...fuelType_Obj] : [];
      break;

    case "make":
      const makeType_Obj =
        Array.isArray(manufacturerData?.data) &&
        manufacturerData?.data?.map((value) => ({
          label: value.manf_name,
          value: value.manf_name,
          id: value.manf_id,
        }));

      selectObj = makeType_Obj ? [...makeType_Obj] : [];
      break;

    case "model":
      const model_Obj = modelData?.data?.map((value) => ({
        label: value.model_name,
        value: value.model_name,
        id: value.model_id,
      }));

      selectObj = model_Obj ? [...model_Obj] : [];
      break;

    case "version":
      const version_Obj = versionData?.data?.map((value) => ({
        label: value.version_name,
        value: value.version_name,
      }));

      selectObj = version_Obj ? [...version_Obj] : [];
      break;

    case "branchNameic":
      const branch_Obj =
        Array.isArray(branchData?.data?.data) &&
        branchData?.data?.data?.map((value) => ({
          label: value.branchName,
          value: value.branchName,
        }));

      selectObj = branch_Obj ? [...branch_Obj] : [];
      break;

    default:
      selectObj = [];
  }

  if (userData && id === "Name") {
    selectObj = userData?.data?.data?.map((value) => {
      return {
        label: value?.username,
        value: value?.username,
        id: value?.user_id,
      };
    });
  }

  if (
    !["fuelType", "make", "model", "version", "branchNameic", "Name"].includes(
      id
    )
  ) {
    selectObj = masterTableField?.data?.data?.[0]?.data?.map((value) => {
      return {
        label: value.optionValue,
        value: value.optionValue,
        id: value.optionKey,
      };
    });
  }

  if (id === "manufactureMonth") {
    const monthsArray = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    selectObj = monthsArray.map((value) => {
      return {
        label: value,
        value,
      };
    });
  }

  if (type === "CHAR") {
    selectObj = [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
    ];
  }

  return (
    <Dropdown
      id={id}
      label={label}
      control={control}
      setValue={setValue}
      selectObj={selectObj}
      isRequired={isRequired}
      placeholder={placeholder}
      maxMenuHeight={200}
      proposalRead={proposalRead}
    />
  );
}

export default ProposalDropdown;
