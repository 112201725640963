import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";

const columns = [
  {
    field: "id",
    headerName: "Product Code",
    width: 100,
    background: "#E4E4E4",
  },
  {
    field: "A",
    headerName: "Product Name",
    width: 100,
    background: "#E4E4E4",
  },
  {
    field: "B",
    headerName: "Description",
    width: 100,
    background: "#E4E4E4",
  },
  {
    field: "C",
    headerName: "UIN Number",
    width: 100,
    background: "#E4E4E4",
  },
  {
    field: "D",
    headerName: "LOB",
    width: 100,
    background: "#E4E4E4",
  },
  {
    field: "E",
    headerName: "Country",
    width: 100,
    background: "#E4E4E4",
  },
  {
    field: "F",
    headerName: "State",
    width: 100,
    background: "#E4E4E4",
  },
  {
    field: "G",
    headerName: "Company",
    width: 100,
    background: "#E4E4E4",
  },
  {
    field: "H",
    headerName: "Effective Start to end date",
    width: 100,
    background: "#E4E4E4",
  },

  {
    field: "Actions",
    headerName: "Actions",
    sortable: false,
    width: 100,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return (
        <ActionContainer>
          <BorderColorRoundedIcon index={params.row.id} />
          <DeleteIcon index={params.row.id} />
        </ActionContainer>
      );
    },
  },
];

//prettier-ignore
const rows = [
    { id: "MOT100", A: "Motor Private Car", B: "Car policy", C: "997990", D: "Health", E : "IND", F : "All", G :"Local Office", H : "12 Jan 2023 To 11 Jan 2023", Edit: 1, },
    { id: "MOT100", A: "Motor Private Car", B: "Car policy", C: "997990", D: "Health", E : "IND",F : "All", G :"Local Office", H : "12 Jan 2023 To 11 Jan 2023", Edit: 1, },
    { id: "MOT100", A: "Motor Private Car", B: "Car policy", C: "997990", D: "Health", E : "IND",F : "All", G :"Local Office", H : "12 Jan 2023 To 11 Jan 2023", Edit: 1, },
    { id: "MOT100", A: "Motor Private Car", B: "Car policy", C: "997990", D: "Health", E : "IND",F : "All", G :"Local Office", H : "12 Jan 2023 To 11 Jan 2023", Edit: 1, },
    { id: "MOT100", A: "Motor Private Car", B: "Car policy", C: "997990", D: "Health", E : "IND",F : "All", G :"Local Office", H : "12 Jan 2023 To 11 Jan 2023", Edit: 1, },
    { id: "MOT100", A: "Motor Private Car", B: "Car policy", C: "997990", D: "Health", E : "IND",F : "All",  G :"Local Office", H : "12 Jan 2023 To 11 Jan 2023",Edit: 1, },
    { id: "MOT100", A: "Motor Private Car", B: "Car policy", C: "997990", D: "Health", E : "IND",F : "All",  G :"Local Office", H : "12 Jan 2023 To 11 Jan 2023",Edit: 1, },
   
  ];

export const ProductCoverageTable = () => {
  return (
    <>
      <TableContainer>
        <DataGrid
          rows={rows}
          columns={columns}
          size="small"
          paginationModel={{ page: 0, pageSize: 20 }}
          sx={{
            ".MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "&.MuiDataGrid-root": {
              border: "none",
            },
            ".MuiDataGrid-columnHeaders": {
              background: "#E4E4E4",
            },
            width: "max-content",
          }}
        />
      </TableContainer>
    </>
  );
};

const TableContainer = styled.div`
  width: max-content;
  padding-top: 10px;
  border-top: 2px solid rgba(224, 224, 224, 1);
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
`;
