import { Controller } from "react-hook-form";
import Select from "react-select";
import styled from "styled-components";

function Dropdown({
  id,
  label,
  placeholder = "Select",
  selectObj,
  setValue,
  isRequired,
  control,
  isMulti,
  isLoading,
  closeMenuOnSelect,
  maxMenuHeight,
  proposalRead,
  components,
  marginBottom,
  isDisabled,
  noOptionMessage,
}) {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      border: "0.25px solid rgb(169, 167, 167)",
      fontSize: "12px",
      minHeight: 32,
      maxHeight: 32,
      boxShadow: state.isFocused ? "0 0 0 0.2rem transparent" : "none",
      backgroundColor: "rgb(247, 248, 250)",
      marginTop: "-1px",
      padding: "0 5px",
    }),
    menu: (provided) => ({
      ...provided,
      maxHeight: 175,
      zIndex: 100,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      border: 0,
      padding: 0,
      display: isDisabled ? "none" : "",
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      fontWeight: 600,
      letterSpacing: "0.5px",
      fontSize: "14px",
      fontFamily: "Montserrat-SemiBold",
      color: "#2BB9A8",
      borderBottom: ".25px solid",
      backgroundColor: isSelected ? "#D1F8E8" : isFocused ? "#fff" : "#fff",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0",
      maxHeight: 28,
      height: 35,
      marginBottom: "5px",
      overflowY: "scroll",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: "0",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      fontFamily: "Montserrat-Medium",
    }),
    multiValue: (provided) => ({
      ...provided,
      // padding: "5px",
      color: "#2BB9A8",
      fontFamily: "Montserrat-Medium",
      borderRadius: "5px",
      margin: "5px",
      background: "#22bdaa30",
      fontSize: "12px",
      fontWeight: "600",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#2BB9A8",
    }),
  };

  const readOnlyCustomStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "106%",
      fontSize: "12px",
      minHeight: 20,
      boxShadow: state.isFocused ? "0 0 0 0.2rem transparent" : "none",
      border: "none",
      background: "#fff",
      marginTop: "4px",
      padding: "0 2px",
      maxHeight: 35,
      overflowY: "scroll",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      border: 0,
      padding: 0,
      display: "none",
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      fontWeight: 600,
      fontSize: "12px",
      color: "#2BB9A8",
      borderBottom: ".25px solid",
      backgroundColor: isSelected ? "#D1F8E8" : isFocused ? "#fff" : "#fff",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0",
      maxHeight: 35,
      overflowY: "scroll",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: "0",
      display: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      fontWeight: 600,
      fontSize: "14px",
      marginLeft: 0,
    }),
  };

  const handleValueChange = () => {
    if (["make", "model"].includes(id)) {
      setValue("version", []);
      setValue("fuelType", []);
    }
    if (["version"].includes(id)) {
      setValue("fuelType", []);
    }
  };

  return (
    <LOBContainerStyled marginBottom={marginBottom}>
      {label ? (
        <label htmlFor={id}>
          {label}
          {isRequired && <span className="isRequired">*</span>}
        </label>
      ) : (
        <></>
      )}
      <Controller
        control={control}
        name={id}
        render={({ field }) => (
          <Select
            {...field}
            id={id}
            isMulti={isMulti}
            isLoading={isLoading}
            placeholder={placeholder ? `Select` : ""}
            onChange={(value) => {
              handleValueChange();
              field.onChange(value);
            }}
            closeMenuOnSelect={closeMenuOnSelect}
            options={selectObj}
            styles={proposalRead ? readOnlyCustomStyles : customStyles}
            maxMenuHeight={maxMenuHeight}
            menuIsOpen={proposalRead ? false : undefined}
            isSearchable={!proposalRead}
            components={components}
            isDisabled={isDisabled}
            noOptionsMessage={noOptionMessage}
          />
        )}
      />
    </LOBContainerStyled>
  );
}

export default Dropdown;

const LOBContainerStyled = styled.div`
  margin-top: -3px;
  margin-bottom: 15px;
  label {
    color: rgb(17, 85, 95);
    padding-left: 1px;
    font-size: 12px;
    font-family: Montserrat-Medium;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: inline-block;
  }
  .isRequired {
    color: red;
    margin-left: 3px;
  }
`;
