import * as yup from "yup";

export const schema = (currentUrl, userVal) => {
  return yup
    .object()
    .shape({
      gender: yup.object().required("Gender is required"),
      department: yup.object().required("Department is required"),
      roleId: yup.object().required("Role is required"),
      user_type: yup.object().required("User Type is required"),
      channel: yup.object().required("Channel is required"),
      branch_name: yup
        .object()
        .required("Branch is required")
        .transform((value, originalValue) => {
          if (!value) {
            return null;
          }
          return originalValue;
        })
        .nullable()
        .optional(),
      zone: yup
        .object()
        .required("Zone is required")
        .transform((value, originalValue) => {
          if (!value) {
            return null;
          }
          return originalValue;
        })
        .nullable()
        .optional(),
      password: yup
        .string()
        .required("Password can't be empty")
        .min(8, "Must be 8 characters")
        .matches(/[a-z]/, "Required one lowercase")
        .matches(/[A-Z]/, "Required one uppercase")
        .matches(/\d/, "Should contain a number")
        .matches(/[!@#$%^&*()]/, "Required one special character"),
      first_name: yup
        .string()
        .required(`First Name Can't be empty`)
        .matches(/^([[A-Za-z\s.'])+$/, "Must contain only alphabets"),
      middle_name: yup
        .string()
        .nullable()
        .transform((v, o) => (o === "" ? null : v))
        // .required(`Middle Name Can't be empty`)
        .matches(/^([[A-Za-z\s.'])+$/, "Must contain only alphabets"),
      last_name: yup
        .string()
        .required(`Last Name Can't be empty`)
        .matches(/^([[A-Za-z\s.'])+$/, "Must contain only alphabets"),
      user_status: yup.string().required("Status is required"),
      irdai_license_no: yup
        .string()
        .required("IRDAI No is required")
        .transform((value, originalValue) => {
          if (!value) {
            return null;
          }
          return originalValue;
        })
        .nullable()
        .optional(),
      start_date: yup
        .date()
        .typeError("Enter a valid date")
        .required("Start Date is required")
        .transform((value, originalValue) => {
          if (!value) {
            return null;
          }
          return originalValue;
        })
        .nullable()
        .optional(),
      end_date: yup
        .date()
        .required("End date is required")
        .typeError("Enter a valid date")
        .min(yup.ref("start_date"), "End Date cannot be in the past")
        .transform((value, originalValue) => {
          if (!value) {
            return null;
          }
          return originalValue;
        })
        .nullable()
        .optional(),
      aadhaar_number: yup
        .string()
        .required("Aadhaar card is required")
        .matches(/^\d{4}\d{4}\d{4}$/, "Please enter a valid Aadhaar number")
        .transform((value, originalValue) => {
          if (!value) {
            return null;
          }
          return originalValue;
        })
        .nullable()
        .optional(),
      pancard: yup
        .string()
        .required("Pancard is required")
        .matches(
          /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
          "Please enter a valid PAN"
        )
        .transform((value, originalValue) => {
          if (!value) {
            return null;
          }
          return originalValue;
        })
        .nullable()
        .optional(),
    })
    .concat(userVal);
};

export const addressSchema = yup.object().shape({
  pincode: yup
    .string()
    .required("Pincode is required")
    .min(6, "Cannot be less than 6 digits")
    .max(6, "cannot be more than 6 digits")
    .matches(/^[0-9]+$/, "Must be only digits"),
  state: yup.string().required("State is required"),
  city: yup.string().required("City is required"),
  address_line1: yup.string().required("Address is required"),
});

export const contactSchema = yup.object().shape({
  mobile_no: yup
    .string()
    .required("Mobile number is required")
    .matches(/^[6-9][0-9]{9}$/, "Invalid mobile number"),
  email_id: yup
    .string()
    .required("Email is required")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
      "Please enter valid Email"
    ),
  alternate_mobile_no: yup
    .string()
    .required("Mobile number is required")
    .notOneOf([yup.ref("mobile_no")], "Alternate number cannot be same")
    .matches(/^[6-9][0-9]{9}$/, "Invalid mobile number")
    .transform((value, originalValue) => {
      if (!value) {
        return null;
      }
      return originalValue;
    })
    .nullable()
    .optional(),
  alternate_email_id: yup
    .string()
    .required("Email is required")
    .notOneOf([yup.ref("email_id")], "Alternate email cannot be same")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
      "Please enter valid Email"
    )
    .transform((value, originalValue) => {
      if (!value) {
        return null;
      }
      return originalValue;
    })
    .nullable()
    .optional(),
});
export const convertToISOString = (dateString) => {
  const dateObject = new Date(dateString);
  return dateObject.toISOString();
};
