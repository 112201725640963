import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import {
  defaultDateFormat,
  preventCharactersInInput,
  preventSpecialAndNumbersInInput,
} from "../../../HelperFunctions/HelperFunctions";
import { Button, Input, Typography } from "../../../components";
import {
  getHolidayById,
  useCreateHolidayData,
  useUpdateHolidayData,
} from "../service";
import { useSelector } from "react-redux";
import { ModalContainer } from "../style/holida-style";
import { useDispatch } from "react-redux";
import { setHolidayErrorAction } from "../holiday-configuration.slice";

const yupValidate = yup.object({
  event_name: yup.string().required("Event name is required"),
  office_code: yup.string().required("Office Relation ID is required"),
});

export const HolidayModal = ({ isOpen, handleClose, modalTitle, id }) => {
  const dispatch = useDispatch();
  const { mutate: updateHoliday } = useUpdateHolidayData();
  const { mutate: createHoliday } = useCreateHolidayData();
  const { holidayError, holidaySuccess } = useSelector(
    (state) => state.holidayConfig
  );
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yupValidate),
    mode: "all",
    reValidateMode: "onChange",
  });

  let holiday = null;
  if (modalTitle === "Modify" || modalTitle === "View") {
    holiday = id && getHolidayById(id);
  }

  useEffect(() => {
    if (holidayError) {
      Swal.fire({
        text: holidayError,
        icon: "warning",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        allowOutsideClick: false,
        focusCancel: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(setHolidayErrorAction(null));
        }
      });
    }
  }, [holidayError]);

  useEffect(() => {
    if (holidaySuccess) {
      Swal.fire({
        text: holidaySuccess,
        icon: "success",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        focusCancel: false,
        scrollbarPadding: false,
      });
      handleClose();
    }
  }, [holidaySuccess]);

  useEffect(() => {
    if (holiday ? modalTitle === "Modify" || modalTitle === "View" : "") {
      setValue("event_name", holiday?.event_name);
      setValue("office_code", holiday?.broker_office_location_relation_id);
      setSelectedDates(() => [
        {
          key: "selection",
          startDate: new Date(holiday?.start_date),
          endDate: new Date(holiday?.end_date),
        },
      ]);
    }
  }, [holiday, modalTitle, setValue]);

  const handleMultipleDateSelection = (date) => {
    setSelectedDates(date);
  };

  const [selectedDates, setSelectedDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const { Error } = Typography;

  const onSubmit = (data) => {
    let holidayData = {
      broker_id: Math.floor(Math.random() * 10) + 1,
      event_name: data.event_name,
      start_date: moment(selectedDates[0].startDate).format("YYYY-MM-DD"),
      end_date: moment(selectedDates[0].endDate).format("YYYY-MM-DD"),
      broker_office_location_relation_id: parseInt(data.office_code),
    };
    // update holiday
    if (modalTitle === "Modify") {
      let updatedHoliday = {
        broker_holiday_config_id: id,
        broker_id: holiday?.broker_id,
        event_name: data.event_name,
        start_date: moment(selectedDates[0].startDate).format("YYYY-MM-DD"),
        end_date: moment(selectedDates[0].endDate).format("YYYY-MM-DD"),
        broker_office_location_relation_id: parseInt(data.office_code),
      };

      updateHoliday(updatedHoliday);
    } else {
      createHoliday(holidayData);
    }
  };

  const date = new Date();
  const minDate = new Date("01/01/" + date.getFullYear());
  const maxDate = new Date("12/31/" + date.getFullYear());

  return (
    <form style={{ paddingTop: "10px" }} onSubmit={handleSubmit(onSubmit)}>
      <ModalContainer position>
        <Grid
          container
          mb={2}
          px={1}
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item sx={{ position: "relative" }} lg={5.7} ml={1}>
            <Input
              themeType={"theme1"}
              label="Event Name"
              name="event_name"
              id="event_name"
              maxLength="100"
              placeholder="Enter Event Name"
              autoComplete="none"
              defaultValue={holiday?.event_name || ""}
              onChange={(e) => preventSpecialAndNumbersInInput(e)}
              required={false}
              inputRef={register("event_name")}
              isRequired={modalTitle === "View" ? false : true}
              proposalRead={modalTitle === "View" ? true : false}
              readOnly={modalTitle === "View" ? true : false}
            />
            {!!errors?.event_name && (
              <Error top="3px" left="3px">
                {errors.event_name.message}
              </Error>
            )}
          </Grid>
          <Grid item sx={{ position: "relative" }} lg={5.9}>
            <Input
              themeType={"theme1"}
              label="Office Relation ID"
              name="office_code"
              id="office_code"
              maxLength="100"
              placeholder="Enter Office Relation ID"
              autoComplete="none"
              defaultValue={holiday?.office_code || ""}
              onChange={(e) => preventCharactersInInput(e)}
              required={false}
              inputRef={register("office_code")}
              isRequired={modalTitle === "View" ? false : true}
              proposalRead={modalTitle === "View" ? true : false}
              readOnly={modalTitle === "View" ? true : false}
            />
            {!!errors?.office_code && (
              <Error top="3px" left="3px">
                {errors.office_code.message}
              </Error>
            )}
          </Grid>
        </Grid>
        {modalTitle === "View" ? (
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ padding: "10px 20px", mb: "10px" }}
          >
            <Grid item lg={6}>
              <h5 className="date-title-for-View">Effective From Date</h5>
              <span className="display-text-for-view">
                {defaultDateFormat(holiday?.start_date)}
              </span>
            </Grid>
            <Grid item lg={6} sx={{ paddingLeft: "12px !important" }}>
              <h5 className="date-title-for-View">Effective End Date</h5>
              <span className="display-text-for-view">
                {defaultDateFormat(holiday?.end_date)}
              </span>
            </Grid>
          </Grid>
        ) : (
          <>
            <p className="note_text">
              <small>
                * You have the flexibility to select either a single date or
                multiple dates based on your preferences.
              </small>
            </p>

            <DateRange
              editableDateInputs={modalTitle !== "View"}
              onChange={(item) => handleMultipleDateSelection([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={selectedDates}
              minDate={minDate}
              maxDate={maxDate}
              className="date"
              name="date_range"
              range={false}
              style={{ width: "95%", margin: "0 20px" }}
            />
          </>
        )}
        {modalTitle !== "View" ? (
          <Grid
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: "20px",
              position: "sticky",
              bottom: 0,
              right: 0,
              borderTop: "1px solid #ccc",
              padding: "8px 18px 16px 0",
              background: "#fff",
            }}
          >
            <Button
              type="submit"
              id={modalTitle === "Modify" ? "update_btn" : "add_event"}
              themeType={"theme1"}
              bgColor={"#11555F"}
            >
              {modalTitle === "Modify" ? "Update Event" : "Add Event"}
            </Button>
          </Grid>
        ) : (
          <></>
        )}
      </ModalContainer>
    </form>
  );
};
