import Grid from "@mui/material/Grid";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { DatePickerInput, Typography } from "../../components";
import { LOBContainerStyled, StyledBg } from "./Brokerage.style";

function BrokerageBasicConfig({
  register,
  control,
  customStyles,
  lobId,
  LOB_Obj,
  companyData,
  transactionData,
  errors,
  effective_start_date,
}) {
  const { Error } = Typography;

  const minEndDate = effective_start_date
    ? new Date(effective_start_date)
    : "";

  const paymentTermObj = [
    { id: 1, label: "Onetime", value: "onetime" },
    { id: 2, label: "Annually", value: "annually" },
    { id: 3, label: "Quarterly", value: "quarterly" },
    { id: 4, label: "Monthly", value: "monthly" },
  ];

  return (
    <>
      <StyledBg>
        <p className="card_header">Basic Details</p>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid
            item
            sx={{ position: "relative" }}
            xs={12}
            sm={12}
            md={6}
            lg={3}
          >
            <LOBContainerStyled smallFontSize={true}>
              <label htmlFor="lobId">Select LOB</label>
              <Controller
                control={control}
                name="lobId"
                render={({ field }) => (
                  <Select
                    {...field}
                    maxMenuHeight={200}
                    id="lobId"
                    placeholder="Select Line of Business"
                    options={LOB_Obj}
                    styles={customStyles}
                  />
                )}
              />
            </LOBContainerStyled>
            {!!errors?.lobId && (
              <Error top="0px" left="3px">
                {errors.lobId.message}
              </Error>
            )}
          </Grid>
          <Grid
            item
            sx={{ position: "relative" }}
            xs={12}
            sm={12}
            md={6}
            lg={3}
          >
            <LOBContainerStyled smallFontSize={true}>
              <label htmlFor="transactionType">Business Type</label>
              <Controller
                control={control}
                name="transactionType"
                render={({ field }) => (
                  <Select
                    {...field}
                    maxMenuHeight={200}
                    id="transactionType"
                    placeholder="Select Business Type"
                    options={transactionData}
                    styles={customStyles}
                  />
                )}
              />
            </LOBContainerStyled>
            {!!errors?.transactionType && (
              <Error top="0px" left="3px">
                {errors.transactionType.message}
              </Error>
            )}
          </Grid>
          <Grid
            item
            sx={{ position: "relative" }}
            xs={12}
            sm={12}
            md={6}
            lg={3}
          >
            <DatePickerInput
              themeType="theme1"
              label="Effective Start Date"
              name="effective_start_date"
              id="effective_start_date"
              inputRef={register("effective_start_date")}
              control={control}
            />
            {!!errors?.effective_start_date && (
              <Error top="0px" left="3px">
                {errors.effective_start_date.message}
              </Error>
            )}
          </Grid>
          <Grid
            item
            sx={{ position: "relative" }}
            xs={12}
            sm={12}
            md={6}
            lg={3}
          >
            <DatePickerInput
              themeType="theme1"
              label="Effective End Date"
              name="effective_end_date"
              id="effective_end_date"
              inputRef={register("effective_end_date")}
              control={control}
              minDate={minEndDate}
            />
            {!!errors?.effective_end_date && (
              <Error top="0px" left="3px">
                {errors.effective_end_date.message}
              </Error>
            )}
          </Grid>
          <Grid
            item
            sx={{ position: "relative" }}
            xs={12}
            sm={12}
            md={6}
            lg={3}
          >
            <LOBContainerStyled smallFontSize={true}>
              <label htmlFor="icType">Select IC</label>
              <Controller
                control={control}
                name="icType"
                render={({ field }) => (
                  <Select
                    {...field}
                    maxMenuHeight={200}
                    id="icType"
                    placeholder="Select Insurance Company"
                    options={companyData}
                    styles={customStyles}
                  />
                )}
              />
            </LOBContainerStyled>
            {!!errors?.icType && (
              <Error top="0px" left="3px" bottom="-15px">
                {errors.icType.message}
              </Error>
            )}
          </Grid>
          {lobId?.lobId === 3 && (
            <Grid
              item
              sx={{ position: "relative" }}
              xs={12}
              sm={12}
              md={6}
              lg={3}
            >
              <LOBContainerStyled smallFontSize={true}>
                <label htmlFor="paymentTerm">Select Payment Term</label>
                <Controller
                  control={control}
                  name="paymentTerm"
                  render={({ field }) => (
                    <Select
                      {...field}
                      maxMenuHeight={200}
                      id="paymentTerm"
                      placeholder="Select Payment Term"
                      options={paymentTermObj}
                      styles={customStyles}
                    />
                  )}
                />
              </LOBContainerStyled>
              {!!errors?.paymentTerm && (
                <Error top="0px" left="3px" bottom="-28px">
                  {errors.paymentTerm.message}
                </Error>
              )}
            </Grid>
          )}
        </Grid>
      </StyledBg>
    </>
  );
}

export default BrokerageBasicConfig;
