import { createSlice } from "@reduxjs/toolkit";
import { serializeError } from "../../utils";

const leadSlice = createSlice({
    name: "leadSlice",
    initialState: {
        leadError: null,
        leadSuccess: null,
    },
    reducers: {
        setLeadSuccessAction: (state, { payload }) => {
            state.leadSuccess = payload;
            state.leadError = null;
        },
        setLeadErrorAction: (state, { payload }) => {
            state.loading = null;
            state.leadError = serializeError(payload);
            state.leadSuccess = null;
        },
    },
});

export const { setLeadErrorAction, setLeadSuccessAction } =
    leadSlice.actions;
export default leadSlice.reducer;