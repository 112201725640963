import { createSlice } from "@reduxjs/toolkit";
import { serializeError } from "../../utils";

const menuMaster = createSlice({
    name: "menuMaster",
    initialState: {
        menuMasterError: null,
        menuMasterSuccess: null,
    },
    reducers: {
        setMenuMasterSuccessAction: (state, { payload }) => {
            state.menuMasterSuccess = payload;
            state.menuMasterError = null;
        },
        setMenuMasterErrorAction: (state, { payload }) => {
            state.loading = null;
            state.menuMasterError = serializeError(payload);
            state.menuMasterSuccess = null;
        },
    },
});

export const { setMenuMasterSuccessAction, setMenuMasterErrorAction } =
menuMaster.actions;
export default menuMaster.reducer;