import _ from "lodash";
const isChildPresent = (module) => {
  return !_.isEmpty(module?.menu_data?.child);
};

//process module
const _processModule = (item) => {
  const { menu_slug, menu_name, file_path } = item.menu_data;
  return { route: menu_slug, name: menu_name, icon: file_path };
};

//process sub module
const _processSubModule = (item, parentName) => {
  let subMenu = [];
  item?.menu_data?.child.forEach((subMod) => {
    subMenu.push({ ..._processModule(subMod), parentName });
  });
  return subMenu;
};

//Nth Menu render
export const _renderMenu = (data) => {
  let menuList = {};
  (data || [])?.forEach((item) => {
    menuList = {
      ...menuList,
      [`${item.menu_data.menu_name}`.trim()]: isChildPresent(item)
        ? _processSubModule(item, item.menu_data.menu_name)
        : _processModule(item),
    };
  });
  return menuList;
};
